export class PagedTableModel {
    // The number of elements in the page
    pageSize = 15;
    // The total number of elements
    totalElements = 0;
    // The current page number
    pageNumber = 1;
    sortOrder = '';
    queryTerm = '';
    rows = [];
    offset = 0;
    isLoading = false;
}