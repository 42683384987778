import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '@src/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserDetailsComponent } from '@src/base-components/users/user-details/user-details.component';
import { ToastrService } from 'ngx-toastr';
import { MarketsService } from '@src/services/markets/markets.service';

@Component(
    {
        selector: 'app-user-details',
        templateUrl: '../../../../base-components/users/user-details/user-details.component.html',
        styleUrls: ['../../../../base-components/users/user-details/user-details.component.scss']
    })

export class UserDetailsMessikaComponent extends UserDetailsComponent implements OnInit {
    
    constructor(
        @Inject('AuthService')
        protected authService: IAuthService,
        protected route: ActivatedRoute,
        protected usersService: UsersService,
        protected translateService: TranslateService,
        protected toastrManager: ToastrService,
        protected marketsService: MarketsService,
    ) {

        super(authService, route, usersService, translateService, toastrManager, marketsService);
        this.allowEditUser = true;
    }
}
