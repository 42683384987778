import { Component, OnInit } from '@angular/core';
import { DashboardService } from '@src/services/dashboard/dashboard.service';

@Component({
    selector: 'app-dashboard-activation-metrics',
    templateUrl: './dashboard-activation-metrics.component.html'
})

export class DashboardActivationMetricsComponent implements OnInit {

    totalActivationsLast3Months: number;
    totalActivationsThisMonth: number;
    totalActivationsToday: number;

    constructor(private dashboardService: DashboardService) { }

    ngOnInit() {
        this.dashboardService.getLast3MonthsActivations().subscribe(c => this.totalActivationsLast3Months = c);
        this.dashboardService.getTotalActivationsThisMonth().subscribe(c => this.totalActivationsThisMonth = c);
        this.dashboardService.getTotalActivationsToday().subscribe(c => this.totalActivationsToday = c);
    }
}