import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class MessikaPageType {

    constructor(private route: Router) {}

    isInternal() {
        // bugfix: this.route.url fails in subdomain that why we use here window.location.pathname
        if (window.location.pathname.indexOf('/warranty/') > -1 || 
            window.location.pathname.indexOf('/certificate/messika/w/') > -1 || 
            window.location.pathname.indexOf('/product/of/messika') > -1 ||
            window.location.pathname.indexOf('/assistance') > -1 || 
            window.location.pathname.indexOf('/privacypolicy') > -1 || 
            window.location.pathname.indexOf('/terms') > -1 ) {
        return false;
        } else {
        return true;
        }
    }
}