import { Component, Inject } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Router } from '@angular/router';
import {environment} from '@src/environments/environment';
import { UserRolesEnum } from '@src/model/userRoles';
@Component({
  template: ``
})
// This component is used for the Login initialization.
// It shouldn't present any html since the user will be initialized and returned to the last page.
export class LoginComponent {
  constructor(@Inject('AuthService') private authService : IAuthService,  private router: Router) {
    this.authService.tryLogin().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.authService.login();
      }
      else {
        const redirect = localStorage.getItem('LastRequestedRoute') || '';
        // Redirect the user
        const loggedUser = this.authService.getCurrentUserInstant()
        if (loggedUser && ((loggedUser.role[0] === UserRolesEnum.SELLER) || (loggedUser.role[0] === UserRolesEnum.GENERICSELLER))) {
          this.router.navigate(['/activation']);
        }
        else if (loggedUser && loggedUser.role[0] === UserRolesEnum.REPAIR) {
          this.router.navigate(['/repairs']);
        }
        else {
          this.router.navigate([redirect]);
        }
      }
    });
  }
}