import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { Injectable } from '@angular/core';
import { UserRolesEnum } from '@src/model/userRoles';

@Injectable()
export class MessikaAccessRules extends UserAccessRoleRules {
    rules = {};
    constructor() {
        super();
        this.rules = {};
        this.rules['dashboard'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
            UserRolesEnum.GUEST
        ];
        this.rules['sales'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.REPAIR,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
            UserRolesEnum.GUEST,
            UserRolesEnum.SELLER
        ];
        this.rules['sales-details'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
            UserRolesEnum.GUEST,
            UserRolesEnum.SELLER,
            UserRolesEnum.GENERICSELLER
        ];
        this.rules['activation'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
            UserRolesEnum.SELLER,
            UserRolesEnum.GENERICSELLER
        ];
        this.rules['users'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.AGENT,
            UserRolesEnum.RETAILER
        ];
        this.rules['retailers'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.GUEST
        ];
        this.rules['retailer'] = [ UserRolesEnum.RETAILER ];
        this.rules['public'] = [
            UserRolesEnum.ANONYMOUS,
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
            UserRolesEnum.GUEST
        ];
        this.rules['management'] = [ UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN ];
        this.rules['inventories'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.MARKET_ADMIN,
            UserRolesEnum.AGENT,
            UserRolesEnum.MASTER_RETAILER,
            UserRolesEnum.RETAILER,
        ];
        this.rules['shipments'] = [
            UserRolesEnum.ADMIN,
            UserRolesEnum.AGENT
        ];
        this.rules['customers'] = [
            UserRolesEnum.RETAILER,
            UserRolesEnum.ADMIN,
            UserRolesEnum.MASTER_RETAILER
        ];
    }
}
