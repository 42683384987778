import { Component, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-wizard-find-product-items-result',
    templateUrl: './wizard-find-product-items-result.html',
    styleUrls: ['./wizard-find-product-items-result.scss']
})
export class WizardFindProductItemsResultComponent {
    @Input() productItem: ProductItem;
    @Input() isSelected: boolean;

    chevronsIconbyOrientation: string;

    constructor(private configService: ConfigService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
        'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';
    }

}