import { Component, Input, Inject, OnChanges } from '@angular/core';
import { User } from '@src/model/user';
import { ModalStatusUserComponent } from './modal-status-user.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component(
    {
        selector: 'app-update-status-user',
        templateUrl: './update-status-user.component.html'
    })

export class UpdateStatusUserComponent implements OnChanges {
    @Input() userInEdition: User;
    @Input() inEdition: boolean;
    @Input() canEditUser: boolean;

    switchdisabled: boolean;
    private ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
    };

    constructor(
        private modalService: NgbModal,
        private translateService: TranslateService) {}

    ngOnChanges() {
        if (this.inEdition) {
            this.switchdisabled = false;
        } else {
            this.switchdisabled = true;
        }
    }
    activateUser() {
        const modalRef = this.modalService.open(ModalStatusUserComponent, this.ngbModalOptions);
        modalRef.componentInstance.userInEdition =  this.userInEdition;
        modalRef.componentInstance.inEdition = this.inEdition;
        modalRef.componentInstance.switchdisabled = this.switchdisabled;
        modalRef.componentInstance.name = 'activation';
    }
    deactivateUser() {
        const modalRef = this.modalService.open(ModalStatusUserComponent, this.ngbModalOptions);
        modalRef.componentInstance.userInEdition =  this.userInEdition;
        modalRef.componentInstance.inEdition = this.inEdition;
        modalRef.componentInstance.switchdisabled = this.switchdisabled;
        modalRef.componentInstance.name = 'deactivation';
    }

    onChangeUserStatus() {
        if (this.userInEdition.isActive) {
            this.deactivateUser();
        } else {
            this.activateUser();
        }
    }

}