import { RepairType } from './repairType';
import { WarrantyExtension } from './warranty/warrantyExtension';

export class RepairWarranty {
    id: number;
    warrantyId: number;
    repairTypeId: number;
    startDate: Date;
    endDate: Date;
    sellerId: string;
    comments: string;
    repairType: RepairType;
    isExpired: boolean;
    warrantyExtensions?: WarrantyExtension[];
}