import { AlertMessageComponent } from './alert-message.component';
import { InfoMessageComponent } from './info-message.component';
import { WarningMessageComponent } from './warning-message.component';


export const MessagesComponents = [
    AlertMessageComponent,
    InfoMessageComponent,
    WarningMessageComponent
];
