import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import supercluster from 'supercluster';
import * as turf from '@turf/helpers';
import * as turfIterator from '@turf/meta';
import * as chroma from 'chroma-js';
import { ChartColors } from '@src/assets/charts/charts.colors';
import { environment } from '@src/environments/environment';
import { MetricWithLocation } from '@src/model/metricWithLocation';
import { ConfigService } from '@src/services/layout/config.service';
import { UserProfile } from '@src/auth/user-profile';
import { IAuthService } from '@src/auth/auth.service.interface';
import * as MapboxLanguage from '@mapbox/mapbox-gl-language';

@Component({
    selector: 'app-dashboard-map',
    templateUrl: './dashboard-map.component.html',
    styleUrls: ['./dashboard-map.component.scss']
})

export class DashboardMapComponent implements OnInit, OnChanges {

    @Input() retailersSales: MetricWithLocation[] = [];

    private mapBox: any;
    private cluster: any;
    private currentZoom: any;
    private worldBounds = [-180.0000, -90.0000, 180.0000, 90.0000];
    private colors = ChartColors;
    private colorStops: any[] = [[0, ChartColors[0]]];
    private radiusStops: any[] = [[0, 0]];
    private clusterData: any;
    private xdata: any;
    private mapIsInit = false;
    layoutOrientation: string;
    currentUser: UserProfile;
    currentLanguage: string;

    constructor(@Inject('AuthService')
                private authService: IAuthService,
                private configService: ConfigService) {
        this.layoutOrientation = this.configService.templateConf.layout.dir;
        mapboxgl.accessToken = environment.mapBox.appKey;

        this.cluster = supercluster({
            initial: function () { return { sum: 0 }; },
            map: function (props) { return { sum: props.sales }; },
            reduce: function (accumulated, props) { accumulated.sum += props.sum; }
        });
        this.authService.getCurrentUser().subscribe(u => this.currentUser = u);
    }

    ngOnInit() {
        this.currentLanguage = this.currentUser.locale;

        const mapLanguage = new MapboxLanguage({
            defaultLanguage: this.currentLanguage
        });

        this.mapBox = new mapboxgl.Map({
            container: 'mapContainer',
            style: 'mapbox://styles/mapbox/dark-v9',
            zoom: 4
        });

        this.mapBox.addControl(mapLanguage);
        this.mapBox.addControl(new mapboxgl.FullscreenControl());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.mapBox) {
            this.getMetrics();
        }
    }

    private getMetrics() {
        const salesData = this.retailersSales.map(m => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [m.longitude, m.latitude]
            },
            properties: {
                name: m.label,
                id: m.id,
                sales: m.value
            }
        })
        );
        this.xdata = {
            type: 'FeatureCollection',
            features: salesData
        };
        this.cluster.load(this.xdata.features);
        //this.swapLayer();
        this.initOrUpdateMap();
    }

    /* private checkIfMapboxIsLoaded() {
        return this.mapBox.isStyleLoaded();
    }

    private swapLayer() {
        const _this = this;
        if (!this.checkIfMapboxIsLoaded()) {
          // It's not safe to manipulate layers yet, so wait 200ms and then check again
          setTimeout(function() {
            _this.swapLayer();
          }, 100);
        }
        else {
            return;
        }
    } */

    private initOrUpdateMap() {
        this.updateClusters(false);
        if (this.mapIsInit) {
            this.mapBox.getSource('salesData').setData(this.clusterData);
            this.updateClusters(true);
        } else {
            this.mapBox.addSource('salesData', {
                type: 'geojson',
                data: this.clusterData,
                buffer: 1,
                maxzoom: 12
            });
            this.AddSalesLayers();
            this.AddEvents();
            this.mapIsInit = true;
        }

        const bounds = new mapboxgl.LngLatBounds();
        let boundsExist = false;
        this.xdata.features.forEach(function (feature) {
            bounds.extend(feature.geometry.coordinates);
            boundsExist = true;
        });

        if (Object.keys(bounds).length > 0 && boundsExist){
            this.mapBox.fitBounds(bounds, {
                padding: {
                    top: 100,
                    right: 100,
                    bottom: 100,
                    left: 100
                }
            });
        }

    }

    private updateClusters(repaint) {
        this.currentZoom = this.mapBox.getZoom();
        this.clusterData = turf.featureCollection(this.cluster.getClusters(this.worldBounds, Math.floor(this.currentZoom)));

        if (this.clusterData.features.filter(f => Object.keys(f.properties).includes('sum')).length > 0) {
            const stops_domain = chroma.limits(this.getFeatureDomain(this.clusterData, 'sum'), 'e', 8);
            const scale = chroma.scale(this.colors).domain(stops_domain).mode('lab');
            this.colorStops = this.createColorStops(stops_domain, scale) || [];
            this.radiusStops = this.createRadiusStops(stops_domain, 10, 25) || [];
            if (repaint) {
                if (this.colorStops && this.colorStops.length > 0) {
                    this.mapBox.setPaintProperty('clusters', 'circle-color', {
                        property: 'sum',
                        stops: this.colorStops
                    });
                }
                if (this.radiusStops && this.radiusStops.length > 0) {
                    this.mapBox.setPaintProperty('clusters', 'circle-radius', {
                        property: 'sum',
                        stops: this.radiusStops
                    });
                }
                this.mapBox.setLayoutProperty('cluster-count', 'text-field', '{' + 'sum' + '}');
            }
        }
        if (repaint) {
            this.mapBox.setLayoutProperty('unclustered-point', 'text-field', '{' + 'name' + '}' + ': ' + '{' + 'sales' + '}');
        }
    }
    private AddEvents() {
        const self = this;
        this.mapBox.on('zoom', function () {
            const newZoom = self.mapBox.getZoom();

            if (Math.floor(self.currentZoom) === 0) {
                self.currentZoom = 1;
            }

            if (Math.floor(newZoom) !== Math.floor(self.currentZoom)) {
                self.currentZoom = newZoom;
                self.updateClusters(true);
                self.mapBox.getSource('salesData').setData(self.clusterData);
            }
        });
    }
    private AddSalesLayers() {
        this.mapBox.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'salesData',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': {
                    property: 'sum',
                    stops: this.colorStops
                },
                // 'circle-blur': 0.1,
                'circle-radius': {
                    property: 'sum',
                    type: 'interval',
                    stops: this.radiusStops
                }
            }
        }, 'waterway-label');

        this.mapBox.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'salesData',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{' + 'sum' + '}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            paint: {
                'text-halo-color': 'white',
                'text-halo-width': 1
            }

        });

        this.mapBox.addLayer(
            {
                id: 'unclustered-point',
                type: 'symbol',
                source: 'salesData',
                filter: ['!has', 'point_count'],
                layout: {
                    'icon-image': 'marker' + '-15',
                    'icon-allow-overlap': true,
                    'text-field': '{' + 'name' + '}' + ': ' + '{' + 'sales' + '}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 14,
                    'text-anchor': 'bottom',
                    'text-offset': [0, -1]
                },
                paint: {
                    'text-color': 'white'
                }
            }
        );
    }
    private getFeatureDomain(geojson_data, myproperty) {
        const data_domain = [];
        turfIterator.propEach(geojson_data, function (currentProperties, featureIndex) {
            if (currentProperties[myproperty]) {
                data_domain.push(Math.round(Number(currentProperties[myproperty]) * 100 / 100));
            }
        });
        return data_domain;
    }

    private createColorStops(stops_domain, scale) {
        try {
            const stops = [];
            if (stops_domain) {
                stops_domain.forEach(function (d) {
                    stops.push([d, scale(d).hex()]);
                });
            }
            return stops;
        } catch (e) { return []; }
    }

    private createRadiusStops(stops_domain, min_radius, max_radius) {
        try {
            const stops = [];
            const stops_len = stops_domain.length;
            let count = 1;
            if (stops_domain) {
                stops_domain.forEach(function (d) {
                    stops.push([d, min_radius + (count / stops_len * (max_radius - min_radius))])
                    count += 1;
                });
            }
            return stops;
        } catch (e) { return []; }
    }
}