import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dashboard-activation-metrics-element',
    templateUrl: './dashboard-activation-metrics-element.component.html',
    styleUrls: ['./dashboard-activation-metrics-element.component.scss']
})

export class DashboardActivationMetricsElementComponent {

    @Input() metricValue: string;
    @Input() metricLabel: string;
    @Input() metricIcon: string;
    @Input() metricColor: string;
}