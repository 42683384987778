export class RetailersParameters {
    name?: string;
    code?: string;
    city?: string;
    marketId?: number;
    businessGroup?: string;
    active?: boolean;
    inactive: boolean;
    parentNotificationId? : number;
    hasInventories: boolean;
    inventoriesFrom: Date;
    inventoriesTo: Date;
    selectedTimeRangeInactive: string;
}