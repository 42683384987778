import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RepairType } from '@src/model/repairType';

@Component({
    selector: 'app-repair-type-element',
    templateUrl: './repair-type-element.html'
})
export class RepairTypeElementComponent implements OnInit {
   @Input() repairType: RepairType;
   @Input() color: string;
   @Input() iconType: string;

   colorClass: string;

   ngOnInit() {
        this.colorClass = 'card card-inverse text-center pointer ' + this.color;
   }
}
