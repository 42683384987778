import {Inject} from '@angular/core';
import {Component } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { SidebarComponent } from '@src/shared/sidebar/sidebar.component';
import { ROUTES } from './sidebar-routes.config';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { UsersService } from '@src/services/users/users.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-sidebar-messika',
    templateUrl: '../../../shared/sidebar/sidebar.component.html',
    styleUrls: ['./sidebarMessika.component.scss']
})

export class SidebarMessikaComponent extends SidebarComponent {

    constructor(accessRules: UserAccessRoleRules,
        public usersService: UsersService,
        public retailersService: RetailersService,
        @Inject('AuthService') authService : IAuthService,
        public configService: ConfigService) {
            super(accessRules, authService, usersService, retailersService, configService);
            //TODO: This can be a SidebarOptions configuration, injectable: See ToasterOptions
        this.RoutesInfo = ROUTES;
        this.Title = 'eActivation';
        this.LogoUrl = 'assets/default/messika-reduced-logo.jpg';
    }
}
