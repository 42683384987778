import { Component } from '@angular/core';
import { CustomerDetailsComponent } from '@src/base-components/customers/customers-details/customer-details.component';

declare var require: any;

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss']
})

export class CustomerDetailsMessikaComponent extends CustomerDetailsComponent { }
