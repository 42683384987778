import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '@src/services/layout/config.service';
import { UsersParameters } from '@src/model/filter-parameters/user-parameters';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { UsersFilterComponent } from './filter/users-filter.component';
import { Subject } from 'rxjs';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { UserRolesEnum } from '@src/model/userRoles';
import { UsersService } from '@src/services/users/users.service';
import { User } from '@src/model/user';
import { TranslateService } from '@ngx-translate/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@src/environments/environment';

@Component({ 
    selector: 'app-users', 
    templateUrl: './users.component.html' 
})

export class UsersComponent extends NgxDatatableBase implements OnInit, OnDestroy  {

   

    @ViewChild(DatatableComponent) table: DatatableComponent;
    isAddingUser: boolean;
    hasNewFilter: boolean;
    firstLoad = false;
    filterParameters: UsersParameters;
    queryTermChanged: Subject<string> = new Subject<string>();
    model = new PagedTableModel();

    headerHeight = 50;
    footerHeight = 50;
    pageSize = 20;
    rowHeight = 'auto';
    rows: User[] = [];
    isLoading = false;
    offset = 0;
    selected: User[] = [];
    isSeller = false;
    loggedUser: UserProfile;
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    newfilterParameters: UsersParameters;
    previousUrl: string;
    currentUrl:string;
    @ViewChild(UsersFilterComponent) usersFilterComponent: UsersFilterComponent;

    constructor(@Inject('AuthService')
    private authService: IAuthService,
    private usersService: UsersService,
    private router: Router,
    private translateService: TranslateService,
    private configService: ConfigService) {
        super(translateService);
        this.hasNewFilter = this.configService.hasNewFilter().users;
        this.filterParameters = new UsersParameters();
        this.model.sortOrder = 'createdOn desc';
        this.currentUrl = this.router.url;
        router.events
        .subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.router.url;
                var str = this.currentUrl.split("/");
                var str2 = this.previousUrl.split("/");
                if(str[1] === str2[1])
                {
                    // same route
                    let filters = localStorage.getItem('usersFilters');
                    let userFilters = JSON.parse(filters);
                    if(userFilters !== null)
                    {
                        this.newfilterParameters = new UsersParameters();
                        this.newfilterParameters = userFilters;
                    }
                }
                else{
                    // diferent route 
                    localStorage.removeItem('usersFilters');
                }
            }
        });
    }
    ngOnDestroy(): void {
        const userFilters = JSON.stringify({
            firstName: this.filterParameters.firstName,
            lastName: this.filterParameters.lastName,
            userName : this.filterParameters.userName,
            email : this.filterParameters.email,
            role: this.filterParameters.role,
            active: this.filterParameters.active,
            inactive: this.filterParameters.inactive,
            parentNotificationId : this.filterParameters.parentNotificationId
        });
        localStorage.setItem('usersFilters', userFilters)
    }
    ngOnInit() {
        
        this.loggedUser = this.authService.getCurrentUserInstant();
        if(this.newfilterParameters !== undefined){
            this.filterParameters = this.newfilterParameters;
        }
        this.setTableIconsByOrientation();

        if ((this.loggedUser.role[0] === UserRolesEnum.SELLER) || (this.loggedUser.role[0] === UserRolesEnum.GENERICSELLER)) {
            this.isSeller = true;
            this.getMe();
            return;
        }
        this.queryTermChanged
            .debounceTime(1000)
            .distinctUntilChanged()
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getUsers(1);
            });
        this.getUsers(1);
        
    }

    isOris() {
      return environment.clientName && environment.clientName === 'oris';
    }
    
    canAddNewUser() : boolean {
      const loggedUser = this.authService.getCurrentUserInstant();
      return (!this.isOris() || !loggedUser.isInRoles([UserRolesEnum.AGENT]));
    }
    
    onSearch(event: any) {
        const textValue = event.target.value.trim();
        this.queryTermChanged.next(textValue);
    }

    
    addUser() {
        this.isAddingUser = true;
    }
    finishAddNewUser() {
        this.isAddingUser = false;
        this.getUsers(1);
    }

    
    setPage(pageInfo) {
        this.getUsers(pageInfo.page);
    }

    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.getUsers(1);
    }

    getUserRoles(row: User) {
        return row.roles.map(x => x.name).join(', ');
    }
    fullNameComparator(propA, propB, rowA: User, rowB: User) {
        const firstCompare = rowA.firstName.localeCompare(rowB.firstName);
        if (firstCompare === 0) {
            return rowA.lastName.localeCompare(rowB.lastName);
        } else {
            return firstCompare;
        }
    }
    updateFilter(event) {
        const val = event.target.value.trim();
        // this.rows = DataTableHelpers.FilterRows(val, this.datasource);
        this.queryTermChanged.next(val);
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    getUsers(page: number) {
        this.isLoading = true;
        if (this.hasNewFilter === true) {
            this.filterUsers(page);
        } else {
            this
            .usersService
            .getUsers(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = this.LoadRomanizedNames(data.body.value);
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                this.firstLoad = true;
                this.isLoading = false;
            });
        }
    }

    updateUsers(event) {
        this.filterParameters = event;
        this.getUsers(1);
    }
    

    private filterUsers(page: number) {
        this
            .usersService
            .filterUsers(this.filterParameters, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = this.LoadRomanizedNames(data.body.value);
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
           });
    }

    getMe() {
        this.isLoading = true;
        this
            .usersService
            .getUser(this.loggedUser.sub)
            .subscribe(data => {
                this.rows[0] = this.LoadRomanizedNames([data])[0];
                this.firstLoad = true;
                this.isLoading = false;
            });
    }

    onSelected(event: any) {
        this.router.navigate([`users/${event.selected[0].id}`]);
    }

    addNewUser() {
        this.isAddingUser = true;
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }

    LoadRomanizedNames(data: User[]) {
        const currentLang = this.translateService.getDefaultLang();
        data.forEach(user => {
            if (user.locale === 'en' || user.locale === 'fr' || user.locale === 'de' || user.locale === 'es' || user.locale === currentLang) {
                return;
            }
            if (user.locale !== currentLang) {
                if (user.firstNameRomanized !== null) {
                    user.firstName = user.firstNameRomanized;
                }
                if (user.lastNameRomanized !== null) {
                    user.lastName = user.lastNameRomanized;
                }
            }
        });
        return data;
    }
}
