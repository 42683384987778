import { Component, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { Product } from '@src/model/product';
import { WizardComponent } from 'angular-archwizard';

@Component({
    selector: 'app-sale-details-correction-base',
    templateUrl: './sale-details-correction-base.component.html'
})

export class SaleDetailsCorrectionBaseComponent {

    @Output() onCorrectProductItem = new EventEmitter<{productItem:ProductItem, comment: string}>();
    @Output() onCorrectProduct = new EventEmitter<{product:Product, comment: string}>();
    @Input() productItem = new ProductItem();

    @ViewChild(WizardComponent) wizard: WizardComponent;

    selectedType = 0;
    selectedProductItem: ProductItem = new ProductItem();
    selectedProduct: Product = new Product();
    comment: string;

    selectProductType(productType: number) {
        this.selectedType = productType;
    }
    selectProductItem(prod: ProductItem) {
        this.selectedProductItem = prod;
        this.wizard.navigation.goToNextStep();
    }
    selectProduct(prod: ProductItem) {
        this.selectedProductItem = prod;
        this.wizard.navigation.goToNextStep();
    }

    correct() {
        if (this.selectedProductItem.product.isSerializable) {
            this.onCorrectProductItem.emit({productItem: this.selectedProductItem, comment: this.comment});
        } else {
            this.onCorrectProduct.emit({product: this.selectedProductItem.product, comment: this.comment});
        }
    }
}
