import { UserNotification } from '@src/model/notification';

export const notifications: UserNotification[] = [
   {
      id: 265,
      descriptionType: 2,
      isRead: false,
      isDeleted: false,
      notificationType: 2,
      linkedId: null,
      sourcePageType: 1,
      createdOn : new Date('2018-06-28T11:23:57.72'),
      userId: '99999999-9999-9999-9999-999999999999',
      user: {
         id: '99999999-9999-9999-9999-999999999999',
         isActive: true,
         firstName: 'WAS',
         lastName: 'System',
         userName: 'was-system@b-i.com',
         email: 'was-system@b-i.com',
         locale: 'en',
         roles: [],
         retailers: [],
         createdOn: new Date('2018-06-28T11:23:57.72'),
         createdById: null, 
         updatedById: null,
         allowedChangePassword: false
      },
      notificationFile: null
   },
   {
      id: 266,
      descriptionType: 3,
      isRead: false,
      isDeleted: false,
      notificationType: 3,
      linkedId: null,
      sourcePageType: 1,
      createdOn : new Date('2018-06-28T11:23:57.72'),
      userId: '99999999-9999-9999-9999-999999999999',
      user: {
         id: '99999999-9999-9999-9999-999999999999',
         isActive: true,
         firstName: 'WAS',
         lastName: 'System',
         userName: 'was-system@b-i.com',
         email: 'was-system@b-i.com',
         locale: 'en',
         roles: [],
         retailers: [],
         createdById: null, 
         updatedById: null,
         createdOn: new Date('2018-06-28T11:23:57.72'),
         allowedChangePassword: false
      },
      notificationFile: null
   },
   {
      id: 267,
      descriptionType: 4,
      isRead: false,
      isDeleted: false,
      notificationType: 4,
      linkedId: null,
      sourcePageType: 1,
      createdOn : new Date('2018-06-28T11:23:57.72'),
      userId: '99999999-9999-9999-9999-999999999999',
      user: {
         id: '99999999-9999-9999-9999-999999999999',
         isActive: true,
         firstName: 'WAS',
         lastName: 'System',
         userName: 'was-system@b-i.com',
         email: 'was-system@b-i.com',
         locale: 'en',
         roles: [],
         retailers: [],
         createdOn: new Date('2018-06-28T11:23:57.72'),
         createdById: null, 
         updatedById: null,
         allowedChangePassword: false
      },
      notificationFile: {
         id: 1,
         fileName: 'salesExport.csv',
         fileStatus: 1
      }
   },
   {
      id: 268,
      descriptionType: 5,
      isRead: false,
      isDeleted: false,
      notificationType: 5,
      linkedId: null,
      sourcePageType: 1,
      createdOn : new Date('2018-06-28T11:23:57.72'),
      userId: '99999999-9999-9999-9999-999999999999',
      user: {
         id: '99999999-9999-9999-9999-999999999999',
         isActive: true,
         firstName: 'WAS',
         lastName: 'System',
         userName: 'was-system@b-i.com',
         email: 'was-system@b-i.com',
         locale: 'en',
         roles: [],
         retailers: [],
         createdOn: new Date('2018-06-28T11:23:57.72'),
         createdById: null, 
         updatedById: null,
         allowedChangePassword: false
      },
      notificationFile: null
   },
   {
      id: 269,
      descriptionType: 2,
      isRead: false,
      isDeleted: false,
      notificationType: 2,
      linkedId: null,
      sourcePageType: 1,
      createdOn : new Date('2018-06-28T11:23:57.72'),
      userId: '99999999-9999-9999-9999-999999999999',
      user: {
         id: '99999999-9999-9999-9999-999999999999',
         isActive: true,
         firstName: 'WAS',
         lastName: 'System',
         userName: 'was-system@b-i.com',
         email: 'was-system@b-i.com',
         locale: 'en',
         roles: [],
         retailers: [],
         createdOn: new Date('2018-06-28T11:23:57.72'),
         createdById: null, 
         updatedById: null,
         allowedChangePassword: false
      },
      notificationFile: null
   }
];

