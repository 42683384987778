import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { ShipmentsService } from '@src/services/shipments/shipments.service';
import { ConfigService } from '@src/services/layout/config.service';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Shipment } from '@src/model/shipment';
import { Subject } from 'rxjs';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '@src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { ImportedFile } from '@src/model/importedFile';
import { ImportedFileHelper } from '@src/base-components/management/utils/imported-file-helper';
import { UploadType } from '@src/model/Enums/uploadType';
import { ToastrService } from 'ngx-toastr';
import { FileValidationError } from '@src/model/fileValidationError';
import { fileValidationErrorType } from '@src/model/Enums/fileValidationErrorType';
import { ImportedDataService } from '@src/services/importedData.service';
import { ShipmentsParameters } from '@src/model/filter-parameters/shipments-parameters';
import { ShipmentsFilterComponent } from './filter/shipments-filter.component';

@Component({
    selector: 'app-shipments',
    templateUrl: './shipments.component.html',
    styleUrls: ['./shipments.component.scss'],
})
export class ShipmentsComponent extends NgxDatatableBase implements OnInit {
    model = new PagedTableModel();
    showRetailer = false;
    selected: Shipment[] = [];
    firstLoad = false;
    isLoading = false;
    offset = 0;
    queryTermChanged: Subject<string> = new Subject<string>();
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    uploaderStocks: FileUploader;
    datasource: ImportedFile[] = [];
    filterParameters: ShipmentsParameters;
    newfilterParameters: ShipmentsParameters;
    previousUrl: string;
    currentUrl:string;
    hasNewFilter:undefined;
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild(ShipmentsFilterComponent) shipmentsFilterComponent: ShipmentsFilterComponent;
    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private shipmentsService: ShipmentsService,
        private router: Router,
        private configService: ConfigService,
        private oAuthservice: OAuthService,
        private toastrService: ToastrService,
        private translateService: TranslateService) {
        super(translateService);
        this.showRetailer = this.showRetailerCol();
        this.setTableIconsByOrientation();
        this.model.sortOrder = 'shipmentCreationDate desc';
        this.currentUrl = this.router.url;
        router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl = this.router.url;
                    var str = this.currentUrl.split("/");
                    var str2 = this.previousUrl.split("/");
                    if(str[1] === str2[1])
                    {
                        // same route
                        let filters = localStorage.getItem('shipmentsFilters');
                        let shipmentsFilters = JSON.parse(filters);
                        if(shipmentsFilters !== null)
                        {
                            this.newfilterParameters = new ShipmentsParameters();
                            this.newfilterParameters = shipmentsFilters;
                        }
                    }
                    else{
                        // diferent route 
                        localStorage.removeItem('shipmentsFilters');
                    }
                }
            });

    }

    ngOnInit(): void {
        this.shipmentsFilterComponent.selectedTimeRange = 'last-month';
        const intervalDates = this.shipmentsFilterComponent.computeTimeRange('last-month');
        this.filterParameters = new ShipmentsParameters();
        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.filterParameters.selectedTimeRange = 'last-month';
        if(this.newfilterParameters !== undefined){
            this.filterParameters = this.newfilterParameters;
        }
        this.queryTermChanged
            .debounceTime(1000) // wait 1000ms after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getShipments(1);
            });
        //this.getShipments(1);
        this.uploaderStocks = new FileUploader({
            removeAfterUpload: true,
            autoUpload: true,
            url: environment.apiBaseUrl + '/api/importeddata/shipments'
        });
        this.uploaderStocks.onBeforeUploadItem = () => {
            this.uploaderStocks.authToken = 'Bearer ' + this.oAuthservice.getAccessToken();
        };
        this.uploaderStocks.onCompleteItem = (item, response) => {
            // this.uploaderRetailersInput.nativeElement.value = '';
            if (item.isSuccess) {
                const result = JSON.parse(response);
                if (result.isSuccess) {
                    const newImportedFile = Object.assign(new ImportedFile, result.value);
                    newImportedFile.type = 'i';
                    newImportedFile.uploadStatusStr = ImportedFileHelper.computeFileStatus(newImportedFile.uploadStatus);
                    newImportedFile.uploadTypeStr = UploadType[newImportedFile.uploadType];

                    this.datasource.unshift(newImportedFile);
                    this.getShipments(1);
                } else {
                    this.showError(result.messageData);
                }
            } else {
                this.toastrService.error('Upload error');
            }
        };
    }

    ngOnDestroy(): void {
        const shipmentsFilters = JSON.stringify({
            store: this.filterParameters.store,
            from: this.filterParameters.from,            
            to: this.filterParameters.to,
            city: this.filterParameters.city,
            serialNumber: this.filterParameters.serialNumber,
            last : this.filterParameters.last,
            selectedTimeRange: this.filterParameters.selectedTimeRange
        });
        localStorage.setItem('shipmentsFilters', shipmentsFilters)
    }

    private showRetailerCol() {
        return this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.MARKET_ADMIN]);
    }

    getShipments(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        this
            .shipmentsService
            .queryShipments(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }
    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.filterShipments(1);
    }

    setPage(pageInfo) {
        this.getShipments(pageInfo.page);
    }

    onSearch(event: any) {
        this.queryTermChanged.next(event.target.value.trim());
    }

    goToShipmentDetails(): void {
        this.router.navigateByUrl(`shipments/${this.selected[0].id}`);
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }
    translateError(error: FileValidationError): string {
        switch (error.ErrorType) {
            case fileValidationErrorType.invalidHeaders: {
                return this.translateService.instant('Components.Shipments.ValidationsErrors.invalid-headers');
            }
            case fileValidationErrorType.invalidValue: {
                return this.translateService.instant('Components.Shipments.ValidationsErrors.invalid-value', { columnName: error.ColumnName, line: error.Line });
            }
            case fileValidationErrorType.missingRequiredValue: {
                return this.translateService.instant('Components.Shipments.ValidationsErrors.required-value', { columnName: error.ColumnName, line: error.Line });
            }
            case fileValidationErrorType.outOfSafeLimitOfRows: {
                return this.translateService.instant('Components.Shipments.ValidationsErrors.out-of-limit-rows');
            }
            default: {
                return this.translateService.instant('Components.Shipments.ValidationsErrors.unkown-error');
            }
        }
    }
    showError(messageData: string): void {
        var error = JSON.parse(messageData) as FileValidationError;
        this.toastrService.error(this.translateError(error), "", { timeOut: 10000 });
    }
    updateShipments(event) {
        this.filterParameters = event;
        this.filterShipments(1);
    }
    private filterShipments(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        this
            .shipmentsService
            .filterShipments(this.filterParameters, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }
}
