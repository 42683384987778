import { Component, ViewChild, OnInit, Inject, OnChanges } from '@angular/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Retailer } from '@src/model/retailer';
import { ActivatedRoute } from '@angular/router';
import { RetailerDetailsInfoComponent } from './retailer-details-info/retailer-details-info.component';
import { RetailerDetailsLocationComponent } from './retailers-details-location/retailer-details-location.component';
import { ModalStatusRetailerComponent } from './update-status-retailer/modal-status-retailer.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { FeatureSettings } from '@src/model/config/FeatureSettings';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-retailer-details',
    templateUrl: './retailer-details.component.html',
    styleUrls: ['./retailer-details.component.scss']
})

export class RetailerDetailsComponent implements OnChanges {
    switchdisabled = false;
    cpoSetting: FeatureSettings = new FeatureSettings();
    showCpo = false;
    editEnabled = false;
    canEditUsers = false;
    inEdition = false;
    isSeller = false;
    @ViewChild(RetailerDetailsInfoComponent) retailerDetailsInfoComponent: RetailerDetailsInfoComponent;
    @ViewChild(RetailerDetailsLocationComponent) retailerDetailsLocationComponent: RetailerDetailsLocationComponent;

    retailer: Retailer = new Retailer();
    isLoaded = false;

    private ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
    };
    constructor(@Inject('AuthService') public authService : IAuthService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        public retailersService: RetailersService,
        private warrantiesService: WarrantiesService) {
            this.getRetailerCpoSetting();
            this.route.params.subscribe(params => {
                if(params['id']) {
                    const retailerId = +params['id'];
                    this.getRetailerById(retailerId);
                } else {
                    this.getMyRetailer();
                }
        });
    }
    getRetailerCpoSetting() {
        this.warrantiesService.getCpoSettings()
        .subscribe(r =>{
            this.cpoSetting = r;
            if(this.cpoSetting.enabled && this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN]))
            {
                this.showCpo = true;
            }
            else {
                this.showCpo = false;
            }
        });
    }
    getMyRetailer() {
        this.retailersService.getMyRetailer()
        .subscribe(r => {
            this.retailer = r;
            this.isLoaded = true;
            this.editEnabled = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN]);
            this.canEditUsers = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.RETAILER]);
        });
    }
    getRetailerById(retailerId: number) {
        this.retailersService.getRetailerById(retailerId)
            .subscribe(r => {
                this.retailer = r;
                this.isLoaded = true;
                this.isSeller = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.SELLER]);
                this.editEnabled = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN]);
                this.canEditUsers = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN, UserRolesEnum.RETAILER]);
            });
    }

    updateRetailerInfo(retailer: Retailer): void {
        this.retailersService.updateRetailer(retailer)
            .subscribe(r => {
                this.retailer = r;
                this.retailerDetailsInfoComponent.inEdition = false;
            });
    }
    updateRetailerLocation(retailer: Retailer): void {
        this.retailersService.updateRetailer(retailer)
            .subscribe(r => {
                this.retailer = r;
                this.retailerDetailsLocationComponent.inEdition = false;
                this.retailerDetailsLocationComponent.setMapMarker(+r.longitude, +r.latitude, this.retailerDetailsLocationComponent.mapBox);
            });
    }
    updateEditionValue(inEdit: boolean) {
        this.inEdition = inEdit;
    }
    ngOnChanges() {
        if (this.inEdition) {
            this.switchdisabled = false;
        } else {
            this.switchdisabled = true;
        }
    }
    activateRetailer() {
        const modalRef = this.modalService.open(ModalStatusRetailerComponent, this.ngbModalOptions);
        modalRef.componentInstance.retailerInEdition =  this.retailer;
        modalRef.componentInstance.switchdisabled = this.switchdisabled;
        modalRef.componentInstance.name = 'activation';
    }
    deactivateRetailer() {
        const modalRef = this.modalService.open(ModalStatusRetailerComponent, this.ngbModalOptions);
        modalRef.componentInstance.retailerInEdition =  this.retailer;
        modalRef.componentInstance.switchdisabled = this.switchdisabled;
        modalRef.componentInstance.name = 'deactivation';
    }

    onChangeRetailerStatus() {
        if (this.retailer.isActive) {
            this.deactivateRetailer();
        } else {
            this.activateRetailer();
        }
    }
}
