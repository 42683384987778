import { Component, OnInit } from '@angular/core';
import { InventoriesService } from '@src/services/inventories/inventories.service';
import { ActivatedRoute } from '@angular/router';
import { Inventory } from '@src/model/inventory';
import { WarrantiesService } from '@src/services/warranty/warranties.service';

@Component({
    selector: 'app-inventory-details',
    templateUrl: './inventory-details.component.html'
})
export class InventoryDetailsComponent implements OnInit {
    inventory: Inventory;
    showBlockChainIcon: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private inventoriesService: InventoriesService,
        private warrantiesService: WarrantiesService) {
    }

    ngOnInit() {
        this.loadInventory();
    }

    private loadInventory(): void {
        this.route.params.subscribe(params => {
            const inventoryId = +params['id'];

            if (inventoryId) {
                this.getInventory(inventoryId);
            }
        });
    }

    private getInventory(inventoryId: number) {
        this.inventoriesService.getInventory(inventoryId).subscribe(inventory => {
            if (inventory) {
                this.inventory = inventory;
                this.inventory.inventoryProductItems.map(productItem => {
                    return productItem;
                });
            }
        });
    }
}
