import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { ExternalService } from '@src/services/external/external.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Sale } from '@src/model/sale';
import { SearchSaleDetailsComponent } from './sale-details/sale-details.component';
import { Product } from '@src/model/product';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-external-search-after-sale',
    templateUrl: './external-search.component.html',
    styleUrls: ['./external-search.component.scss']
})

export class ExternalSearchComponent {
    srcLogoClient: string;
    searchTerm: string;
    saleResult: Sale;
    productResult: Product;
    notFound: boolean;
    duplicateSale: boolean;
    isLoading = false;
    currentLang = 'en';
    @ViewChild(SearchSaleDetailsComponent) saleDetails: SearchSaleDetailsComponent;

    constructor(
        @Inject('AuthService')
        protected authService: IAuthService,
        protected externalService: ExternalService,
        private configService: ConfigService,
        private translateService: TranslateService) {
        this.configService.setIsPublicPage(true);

        let initialLanguage: string = localStorage.getItem('CurrentLanguage');
        if (!initialLanguage || initialLanguage.length === 0) {
            initialLanguage = 'en';
            translateService.setDefaultLang('en');
            translateService.use(initialLanguage);
            this.currentLang = 'en';
        } else {
            this.translateService.setDefaultLang(initialLanguage);
            translateService.use(initialLanguage);
            this.currentLang = initialLanguage;
        }
    }

    salesForm = new FormGroup({
        salesRecaptcha: new FormControl(null, Validators.required)
    });

    changeSearchTerm(e) {
        if (!e.target.value || e.target.value === '') {
            return;
        } else {
            this.searchTerm = e.target.value;
        }
    }
    searchSale() {
        if (this.salesForm.status !== 'VALID' || !this.searchTerm) {
            return;
        }
        this.isLoading = true;
        this.externalService.getAfterSale(this.searchTerm).subscribe(data => {
            this.saleResult = null;
            if (data) {
                if (data.length > 1) {
                    this.duplicateSale = true;
                    this.notFound = false;
                    this.isLoading = false;
                    return;
                }
                this.saleResult = data.length < 1 ? null : data[0];

                this.externalService.getProductDetails(this.saleResult.productSku).subscribe(product => {
                    if (product) {
                        this.productResult = product;
                    }
                });

                this.notFound = false;
                this.isLoading = false;
                this.duplicateSale = false;
                return;
            }
            this.duplicateSale = false;
            this.notFound = true;
            this.isLoading = false;
        });
    }
}
