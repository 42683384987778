import { Component, Input, OnInit } from "@angular/core";
import { Shipment } from "@src/model/shipment";

@Component({
    selector: 'app-shipment-details-info',
    templateUrl: './shipment-details-info.component.html',
})
export class ShipmentDetailsInfoComponent {
    @Input() shipment: Shipment;

    constructor() {};
}
