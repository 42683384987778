export const saleretailers = [
    {
        'id': 1,
        'label': 'Oris SA MyWatches',
        'latitude': '47.4242013',
        'longitude': '7.7692057',
        'value': 1
     },
     {
        'id': 2,
        'label': 'Oris SA',
        'address': 'Ribigasse 1',
        'latitude': '47.424084',
        'longitude': '7.769381',
        'value': 1
     },
     {
        'id': 3,
        'label': 'Châtelain SA',
        'latitude': '46.521369',
        'longitude': '6.6331421',
        'value': 1
     },
     {
        'id': 4,
        'label': 'Guignard Sylvain',
        'latitude': '46.4570857',
        'longitude': '6.3372647',
        'value': 1
     },
     {
        'id': 5,
        'label': 'CADHOR S.A.',
        'latitude': '46.2084539',
        'longitude': '6.1445028',
        'value': 1
     },
     {
        'id': 6,
        'label': 'Bijouterie Bader',
        'latitude': '46.203745',
        'longitude': '6.1449776',
        'value': 1
     },
     {
        'id': 7,
        'label': 'Rigacci Alex. & Cie.',
        'latitude': '46.2036616',
        'longitude': '6.1439819',
        'value': 1
     },
     {
        'id': 8,
        'label': 'PIGUET Horloger - Bijoutier',
        'latitude': '46.38270493',
        'longitude': '6.239425434',
        'value': 1
     },
     {
        'id': 9,
        'label': 'Meylan Lionel SA',
        'latitude': '46.45968207',
        'longitude': '6.842881787',
        'value': 1
     },
     {
        'id': 10,
        'label': 'Gaillard SA',
        'latitude': '46.2337267',
        'longitude': '7.3603748',
        'value': 1
     },
     {
        'id': 11,
        'label': 'KURZ Schmuck und Uhren',
        'latitude': '46.9479335',
        'longitude': '7.4415474',
        'value': 1
     },
     {
        'id': 12,
        'label': 'Nicklès Daniel',
        'latitude': '46.948402',
        'longitude': '7.4471705',
        'value': 1
     },
     {
        'id': 13,
        'label': 'Skala Franz',
        'latitude': '46.9481227',
        'longitude': '7.4514789',
        'value': 1
     },
     {
        'id': 14,
        'label': 'Kocher Bijouterie GmbH',
        'latitude': '46.8742971',
        'longitude': '7.5618845',
        'value': 1
     },
     {
        'id': 15,
        'label': 'Probst Uhren AG',
        'latitude': '46.7578192',
        'longitude': '7.6286642',
        'value': 1
     },
     {
        'id': 16,
        'label': 'Kirchhofer AG',
        'latitude': '46.687411',
        'longitude': '7.8594635',
        'value': 1
     },
     {
        'id': 17,
        'label': 'Theodor Schertenleib',
        'latitude': '46.6849213',
        'longitude': '7.8490927',
        'value': 1
     },
     {
        'id': 18,
        'label': 'Muther Georges',
        'latitude': '46.02154404',
        'longitude': '7.746975763',
        'value': 1
     },
     {
        'id': 19,
        'label': 'Foedinger Erich',
        'latitude': '46.2921007',
        'longitude': '7.8813512',
        'value': 1
     }
];
