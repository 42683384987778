import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@src/model/user';
import { UsersService } from '@src/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Retailer } from '@src/model/retailer';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { Market } from '@src/model/market';
import { MarketsService } from '@src/services/markets/markets.service';

declare var require: any;
const cloneDeep = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-user-add-retailer',
        templateUrl: './user-add-retailer.component.html'
    })

export class UserAddRetailerComponent extends NgxDatatableBase {
    @Input() roles: Array<string>;
    @Input() userInEdition: User;
    @Input() inEdition: boolean;
    @Input() selectedRole: string;

    private user: User;
    addingNewRetailer = false;
    canAddRetailers = false;
    markets: Array<Market> = [];

    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private retailerService: RetailersService,
        private marketsService: MarketsService,
        private translateService: TranslateService
    ) {

        super(translateService);

        this.onUserReceived = this.onUserReceived.bind(this);
        const currentUser = authService.getCurrentUserInstant();
        if (currentUser.role[0] !== (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER)) {
            this.route.params.subscribe(params => {
                this.usersService.getUser(params['id']).subscribe(user => this.user = user);
            });

            this.canAddRetailers = currentUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.MARKET_ADMIN]);

        }
    }

    onUserReceived(user: User) {
        this.selectedRole = user.roles[0].name;
        this.user = user;
        this.userInEdition = cloneDeep(this.user);
    }
    canAddRetailer() {
        const currentUserRole = this.userInEdition.roles[0].name;
        if (currentUserRole === UserRolesEnum.RETAILER) {
            return this.userInEdition.retailers.length === 0;
        }
        return true;
    }
    updateUser() {
        this.usersService.updateUser(this.userInEdition.id, this.userInEdition)
            .subscribe(u => {
                this.onUserReceived(u);
                this.inEdition = false;
            });
    }

    addNewRetailer() {
        this.addingNewRetailer = true;
    }

    closeAddNewRetailer() {
        this.addingNewRetailer = false;
    }
    addRetailer(retailer: Retailer) {
        if (this.userInEdition.retailers.filter(r => r.id === retailer.id).length === 0) {
            this.userInEdition.retailers.push(retailer);
        }
        this.addingNewRetailer = false;
    }
    removeRetailer(retailer: Retailer) {
        this.userInEdition.retailers = this.userInEdition.retailers.filter(r => r.id !== retailer.id);
    }
    CanAddRetailers() {
        return this.canAddRetailers && !(this.selectedRole === UserRolesEnum.AGENT) && !(this.selectedRole === UserRolesEnum.MARKET_ADMIN)
    }
}