import { Component, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { Customer } from '@src/model/customer';

@Component(
    {
        selector: 'app-customer-product-info-details',
        templateUrl: './customer-product-info-details.component.html'
    })

export class CustomerProductInfoDetailsComponent {
    @Input() productItem: ProductItem;
    @Input() customer: Customer;
}