export const repairtypes = [
    {
        'id': 1,
        'code': 'code1',
        'name': 'Global',
        'description': 'This is a global repairment with warranty of 24 months',
        'durationMonths': 24,
        'parentRepairTypeId': null
    },
    {
        'id': 2,
        'code': 'code2',
        'name': 'Partial',
        'description': 'This a partial repairment',
        'durationMonths': 12,
        'parentRepairTypeId': null
    }
]