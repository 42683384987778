import { Component, ViewChild, OnInit, Inject, OnDestroy } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable/release";
import { DataTableHelpers } from '@src/shared/helpers/datatable-helper';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { Subject } from 'rxjs';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { UserProfile } from '@src/auth/user-profile';
import { User } from '@src/model/user';
import { ConfigService } from '@src/services/layout/config.service';
import { Retailer } from '@src/model/retailer';
import { romanizedLocaleCodes, supportedLocaleCodes } from '@src/shared/navbar/language/languages.consts';
import { RetailersParameters } from '@src/model/filter-parameters/retailers-parameters';
import { RetailersFilterComponent } from './filter/retailers-filter.component';

@Component({
    selector: 'app-retailers',
    templateUrl: './retailers.component.html',
    styleUrls: ['./retailers.component.scss']
})
export class RetailersComponent extends NgxDatatableBase implements OnInit, OnDestroy {
    @ViewChild(DatatableComponent) table: DatatableComponent;
    model = new PagedTableModel();
    selected: any[] = [];
    firstLoad = false;
    queryTermChanged: Subject<string> = new Subject<string>();
    currentUser: UserProfile;
    selectedRole: string;
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    hasNewFilter: boolean;
    filterParameters: RetailersParameters;
    newfilterParameters: RetailersParameters;
    previousUrl: string;
    currentUrl: string;
    @ViewChild(RetailersFilterComponent) retailersFilterComponent: RetailersFilterComponent;

    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private retailersService: RetailersService,
        private translateService: TranslateService,
        private router: Router,
        private configService: ConfigService) {
        super(translateService);
        this.model.sortOrder = 'name asc';
        this.hasNewFilter = this.configService.hasNewFilter().retailers;
        this.filterParameters = new RetailersParameters();
        this.currentUrl = this.router.url;
        router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl = this.router.url;
                    var str = this.currentUrl.split("/");
                    var str2 = this.previousUrl.split("/");
                    if (str[1] === str2[1]) {
                        // same route
                        let filters = localStorage.getItem('retailersFilters');
                        let retailersFilters = JSON.parse(filters);
                        if (retailersFilters !== null) {
                            this.newfilterParameters = new RetailersParameters();
                            this.newfilterParameters = retailersFilters;
                        }
                    }
                    else {
                        // diferent route 
                        localStorage.removeItem('retailersFilters');
                    }
                }
            });
    }
    ngOnDestroy(): void {
        const retailersFilters = JSON.stringify({
            name: this.filterParameters.name,
            code: this.filterParameters.code,
            city: this.filterParameters.city,
            marketId: this.filterParameters.marketId,
            businessGroup: this.filterParameters.businessGroup,
            active: this.filterParameters.active,
            inactive: this.filterParameters.inactive,
            parentNotificationId: this.filterParameters.parentNotificationId
        });
        localStorage.setItem('retailersFilters', retailersFilters)
    }
    ngOnInit() {
        this.currentUser = this.authService.getCurrentUserInstant();
        this.selectedRole = this.currentUser.role[0];
        if (this.newfilterParameters !== undefined) {
            this.filterParameters = this.newfilterParameters;
        }
        this.setTableIconsByOrientation();

        this.queryTermChanged
            .debounceTime(1000) // wait 1000ms after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getRetailers(1);
            });
        this.getRetailers(1);
    }

    setPage(pageInfo) {
        this.getRetailers(pageInfo.page);
    }
    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.getRetailers(1);
    }
    onSelect(retailer: any) {
        this.router.navigateByUrl(`retailers/${this.selected[0].id}`);
    }
    onSearch(event: any) {
        const textValue = event.target.value.trim();
        this.queryTermChanged.next(textValue);
    }
    getRetailers(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;

        if (this.selectedRole === (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER)) {
            this
                .retailersService
                .getRetailersByUser(this.currentUser.sub)
                .subscribe(data => {
                    this.model.rows = this.LoadRomanizedNames(data.body.value);
                    this.model.totalElements = data.headers.get('pageCount');
                    this.model.pageNumber = page;
                    this.model.offset = page - 1;
                    this.firstLoad = true;
                    this.model.isLoading = false;
                });
        } else {
            if (this.hasNewFilter === true) {
                this.filterRetailers(page);
            } else {
                this.queryRetailers(page);
            }
        }
    }

    private filterRetailers(page: number) {
        this
            .retailersService
            .filterRetailers(this.filterParameters, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = this.LoadRomanizedNames(data.body.value);
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    private queryRetailers(page: number) {
        this.retailersService
            .queryRetailers(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = this.LoadRomanizedNames(data.body.value);
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    updateRetailers(event) {
        this.filterParameters = event;
        this.getRetailers(1);
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }
    LoadRomanizedNames(data: Retailer[]) {
        const currentLang = this.translateService.getDefaultLang();
        data.forEach(pos => {
            if (romanizedLocaleCodes.includes(pos.locale) || pos.locale === currentLang) {
                return;
            }
            if (supportedLocaleCodes.includes(pos.locale)) {
                if (pos.nameRomanized !== null) {
                    pos.name = pos.nameRomanized;
                }
                if (pos.cityNameRomanized !== null) {
                    pos.city = pos.cityNameRomanized;
                }
            }
        });
        if (this.model.sortOrder === 'name asc') {
            data = data.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }
        if (this.model.sortOrder === 'name desc') {
            data = data.sort((a, b) => (a.name < b.name) ? 1 : -1)
        }
        return data;
    }
}
