import { Product } from './product';
import { User } from './user';

export class ProductItem {
    constructor() {
        this.product = new Product();
        this.updatedBy = new User();
    }
    id: number;
    serialNumber: string;
    securityCode: string;
    product: Product;
    isUsed: boolean;
    isFake: boolean;
    isValid: boolean;
    isCpo: boolean;
    warrantyDurationMonths?: number;
    updatedBy: User;
    updatedOn: Date;
  }