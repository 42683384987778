import { Component } from '@angular/core';
import { ProfileComponent } from '@src/base-components/profile/profile.component';

@Component({ 
//  selector: 'app-profile', 
  templateUrl: './profile.component.html'
//  styleUrls: ['./profile.component.scss']
})

export class ProfileComponentMessika extends ProfileComponent { }
