import { Component, OnInit, ViewChild } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';
import { RepairType } from '@src/model/repairType';
import { Warranty } from '@src/model/warranty/warranty';
import { FindWarrantyComponent } from '../find-warranty/find-warranty.component';
import { RepairActivationComponent } from '../activation/repair-activation.component';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { ProductItem } from '@src/model/productItem';
import { FindProductItemsComponent } from '../find-product-items/find-product-items.component';
import { CreateWarrantyComponent } from '../create-warranty/create-warranty.component';
import { WizardComponent } from 'angular-archwizard';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-repair-steps',
    templateUrl: './repair-steps.component.html'
})
export class RepairStepsComponent implements OnInit {
    newRepairWarranty: RepairWarranty;
    selectedWarranty: Warranty;
    isSpecialCase = false;
    selectedProductItem: ProductItem;
    serialNumberSelected: string;

    @ViewChild(FindWarrantyComponent) findWarrantyComponent: FindWarrantyComponent;
    @ViewChild(RepairActivationComponent) repairActivationComponent: RepairActivationComponent;
    @ViewChild(FindProductItemsComponent) findProductItemsComponent: FindProductItemsComponent;
    @ViewChild(CreateWarrantyComponent) createWarrantyComponent: CreateWarrantyComponent;
    @ViewChild(WizardComponent) wizard: WizardComponent;

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.newRepairWarranty = new RepairWarranty();
        this.newRepairWarranty.repairType = new RepairType();
        this.selectedWarranty = new Warranty();
        this.selectedWarranty.warrantyCard = new WarrantyCard();
    }

    resetToStep1() {
        this.findWarrantyComponent.reset();
    }

    resetToStepRepairActivation() {
        this.repairActivationComponent.reset();
    }

    resetToStepCreateWarranty() {
        this.createWarrantyComponent.reset();
    }

    resetToStepFindProductItem() {
        this.findProductItemsComponent.reset();
    }

    setRepairType(repairType: RepairType) {
        this.newRepairWarranty.repairType = repairType;
        this.newRepairWarranty.repairTypeId = repairType.id;
        this.wizard.navigation.goToNextStep();
    }

    setSelectedWarranty(warranty: Warranty) {
        if (warranty == null)  {
            this.isSpecialCase = true;
        } else {
            this.newRepairWarranty.warrantyId = warranty.id;
            this.selectedWarranty = warranty;
        }
        setTimeout(() => {
            this.wizard.navigation.goToNextStep();
        }, 500);
    }

    setSelectedSerialNumber(serialNumber: string) {
        this.serialNumberSelected = serialNumber;
    }

    setCreatedWarranty(warranty: Warranty) {
        this.selectedWarranty = warranty;
        this.newRepairWarranty.warrantyId = warranty.id;
        this.selectedWarranty = warranty;
        this.wizard.navigation.goToNextStep();
    }

    setSelectedProductItem(productItem: ProductItem) {
        this.selectedProductItem = productItem;
        this.wizard.navigation.goToNextStep();
    }

    returnRepairWarranty(repairWarranty: RepairWarranty) {
        if (repairWarranty !== null) {
            this.newRepairWarranty = repairWarranty;
        }
        this.wizard.navigation.goToNextStep();
    }

    setTitleStepRepairType() {
        if (this.newRepairWarranty == undefined || this.newRepairWarranty.repairType == undefined) {
            return;
        }
        return this.newRepairWarranty.repairType.name == undefined ? this.translateService.instant('Components.RepairSteps.select-type-repair')
            : this.translateService.instant('Labels.repair-type') + ': ' + this.newRepairWarranty.repairType.name;
    }

    setTitleStepWarranty() {
        return this.selectedWarranty.serialNumber == undefined ? this.translateService.instant('Components.RepairSteps.find-warranty')
            : this.translateService.instant('Labels.sale-warranty') + ': ' + this.selectedWarranty.serialNumber;
    }

    setTitleStepProductItem() {
        return this.selectedProductItem == undefined ? this.translateService.instant('Components.RepairSteps.find-product-item')
            : this.translateService.instant('Labels.reference') + ': ' + this.selectedProductItem.product.name;
    }

    setTitleStepWarrantyCard() {
        return this.selectedWarranty.warrantyCard.code == undefined ? this.translateService.instant('Components.RepairSteps.create-warranty')
            : this.translateService.instant('Labels.warranty-code') + ': ' + this.selectedWarranty.warrantyCard.code;
      }
}
