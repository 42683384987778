import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { ConfigService } from '@src/services/layout/config.service';
import { SalesService } from '@src/services/sales/sales.service';
import { Sale } from '@src/model/sale';

@Component({
    selector: 'app-find-warranty-result',
    templateUrl: './find-warranty-result-element.component.html',
    styleUrls: ['./find-warranty-result-element.component.scss']
})
export class WarrantyResultComponent implements OnInit {
    @Input() warranty: Warranty;

    chevronsIconbyOrientation: string;
    saleSelected: Sale =  new Sale();
    
    constructor(private configService: ConfigService,
        private salesService: SalesService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
        'ml-3 ft-chevrons-left font-large-1 primary' : 'ml-3 ft-chevrons-right font-large-1 primary';
    }

    ngOnInit() {
        this.warranty.isExpired = new Date() > new Date(this.warranty.warrantyEndDate);
        this.warranty.warrantyCardCode = this.warranty.warrantyCard.code;

        this.salesService.getSalesBySerialNumberOrWarrantyCard(this.warranty.serialNumber).subscribe( sales => {
            if (sales === null || sales.length === 0) {
                return;
            }
            this.saleSelected = sales.find(sale => sale.warrantyId === this.warranty.id);
        });
    }
}