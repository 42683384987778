import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@src/model/user';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesEnum, AllowedRolesCreationByRole } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Market } from '@src/model/market';
import { environment } from '@src/environments/environment';
import { UsersService } from '../../../../services/users/users.service';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');
const breitiling = 'breitling';
const oris = 'oris';

@Component(
    {
        selector: 'app-user-info-details',
        templateUrl: './user-info-details.component.html'
    })

export class UserInfoDetailsComponent {
    @Input() userInEdition: User;
    @Input() createdByUser: User;
    @Input() updatedByUser: User;
    @Input() inEdition: boolean;
    @Input() selectedRole: string;
    @Input() validationErrors = [];
    @Input() markets: Array<Market> = [];
    @Input() roles: Array<string>;

    @Output() sendInEdition = new EventEmitter();
    @Output() selectedRoleOption = new EventEmitter();

    allowEditUser = true;
    allowChangePassword = true;

    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private userService: UsersService
    ) {
        const currentUser = authService.getCurrentUserInstant();
        if (currentUser.role[0] === UserRolesEnum.AGENT || currentUser.role[0] === UserRolesEnum.MASTER_RETAILER) {
            this.allowEditUser = false;
        }

        // depends of the role can change password
        this.allowChangePassword = currentUser.role[0] === UserRolesEnum.ADMIN;
    }

    isBreitling() {
        return environment.clientName && environment.clientName === breitiling;
    }

    isOris() {
      return environment.clientName && environment.clientName === oris;
    }

    canEditUser() : boolean {
      const loggedUser = this.authService.getCurrentUserInstant();
      return (!this.isOris() || !loggedUser.isInRoles([UserRolesEnum.AGENT]));
    }

    sendEdition() {
        this.sendInEdition.emit(this.userInEdition);
    }

    edit() {
        this.inEdition = true;
        this.sendEdition();
    }

    changeRole() {
        this.selectedRoleOption.emit(this.selectedRole);
    }

    getInvalidErrorMessage(fieldName: string) : string {
        return this.translateService.instant('Components.UserDetails.Sections.invalid-field', { fieldName: fieldName });
    }

    removeErrorMessage(fieldName: string, event) {
        this.validationErrors = this.validationErrors.filter(function(field) { return field !== fieldName; });
    }
}