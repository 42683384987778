import { Component, Inject, Input } from '@angular/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { DashboardDefaultFilterBaseComponent } from '../dashboard-default-filter-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-dashboard-default-filter',
  templateUrl: './dashboard-default-filter.component.html',
  styleUrls: ['./dashboard-default-filter.component.scss']
})

export class DashboardDefaultFilterComponent extends DashboardDefaultFilterBaseComponent{
  @Input() isRetailerDashboard: boolean = false;

  constructor(
    @Inject('AuthService') public authService: IAuthService,
    public retailersService: RetailersService,
    public productsService: ProductsService,
    public configService: ConfigService) {
      super(authService, retailersService, productsService, configService);
  }
}
