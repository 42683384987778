import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductType } from '@src/model/Enums/productType';

@Component({
    selector: 'app-wizard-select-type',
    templateUrl: './wizard-select-type.html'
})
export class WizardSelectTypeComponent {
    @Input() selectedType = ProductType.serialized;
    @Output() onselectProductType = new EventEmitter<number>();
    selectSerializableItems() {
        this.onselectProductType.emit(ProductType.serialized);
    }
    selectNotSerializableItems() {
        this.onselectProductType.emit(ProductType.notSerialized);
    }
    reset() {
        this.selectedType = ProductType.serialized;
    }
}
