import { Component, ViewChild, Input } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@src/model/user';
import { UserRolesEnum } from '@src/model/userRoles';
import { DataTableHelpers } from '@src/shared/helpers/datatable-helper';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Retailer } from '@src/model/retailer';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';

@Component({
    selector: 'app-retailer-details-users',
    templateUrl: './retailer-details-users.component.html',
    styleUrls: ['./retailer-details-users.component.scss']
})

export class RetailerDetailsUsersComponent extends NgxDatatableBase{
    @Input() editEnabled = false;
    private _retailer: Retailer;
    headerHeight = 50;
    footerHeight = 50;
    pageSize = 5;
    rowHeight = 'auto';
    rows: User[] = [];
    datasource: User[] = [];
    firstLoad = false;
    isLoading = false;
    offset = 0;
    isAddingSeller = false;

    @ViewChild(DatatableComponent)table : DatatableComponent;
    constructor(private retailersService : RetailersService,
        private translateService : TranslateService) {
            super(translateService)
    }
    @Input()
    set retailer(r: Retailer) {
        this._retailer = r;
        this.getUsers();
    }
    cannotRemoveUser(row: User) {
        // for now allow removal of sellers only, rest must be done by admin on the user page.
        const sellerRole = row.roles.find(x => x.name === (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER));
        return (sellerRole == null);
    }
    removeRetailerUser(row) {
        if (this.isLoading) {
            return;
        }
        this.isAddingSeller = false;
        this.isLoading = true;
        this
        // .retailersService.getRetailerUsers(retailerId, [UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER])
        .retailersService.removeUserFromRetailer(this._retailer.id, row.id)
        .subscribe(data => {
            this.getUsers();
        });
    }
    onUserNameResolved(user: User) {
        if (this.isLoading) {
            return;
        }
        this.isAddingSeller = false;
        this.isLoading = true;
        this
        // .retailersService.getRetailerUsers(retailerId, [UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER])
        .retailersService.addSellerToRetailer(this._retailer.id, user)
        .subscribe(data => {
            this.getUsers();
        });
    }
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        this.rows = DataTableHelpers.FilterRows(val, this.datasource);
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }
    fullNameComparator(propA, propB, rowA: User, rowB: User) {
        const firstCompare = rowA.firstName.localeCompare(rowB.firstName);
        if (firstCompare === 0) {
            return rowA.lastName.localeCompare(rowB.lastName);
        } else {
            return firstCompare;
        }
      }
    getUserRoles(row: User) {
        return row.roles.map(x => x.name).join(', ');
    }
    getUsers() {
        this.isLoading = true;
        this
            // .retailersService.getRetailerUsers(retailerId, [UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER])
            .retailersService.getRetailerUsers(this._retailer.id, [])
            .subscribe(data => {
                this.rows = data;
                this.datasource = data;
                this.firstLoad = true;
                this.isLoading = false;
            });
    }
}