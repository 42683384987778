export class DataTableHelpers {
    public static FilterRows(val: string, allRows: any[]): any[] {
        let filteredRows = allRows;
        if (allRows && allRows.length > 0 && val && val.length > 0) {
             // filter our data
            filteredRows = allRows.filter(function (d) {
                const values = Object.keys(d).map(key => d[key]);
                return values.filter(function (v) {
                    //Quick hack to support checking inside objects (arrays)
                    if (v instanceof Array) {
                        return JSON.stringify(v).toLowerCase().indexOf(val) !== -1;
                    }
                    return v && v != null && v.toString().toLowerCase().indexOf(val) !== -1;
                }).length !== 0;
            });
        }
        return filteredRows;
    }
}