import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { WizardFindSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-serializable-product';

@Component({
    selector: 'app-correction-find-product',
    templateUrl: './sale-details-correction-find-product.component.html'
})

export class SaleDetailsCorrectionFindProductComponent {

    @ViewChild(WizardFindSerializableProductComponent) wizardFindProductComponent: WizardFindSerializableProductComponent;

    @Input() isSerializable : boolean;
    @Input() selectedProductItem: ProductItem;
    @Input() productItem = new ProductItem();
    @Output() onselectProductItem = new EventEmitter<ProductItem>();
    @Output() onselectProduct = new EventEmitter<ProductItem>();

    selectProductItem(productItem: ProductItem): void {
        this.onselectProductItem.emit(productItem);
    }
    selectProduct(productItem: ProductItem): void {
        this.onselectProduct.emit(productItem);
    }

    reset(): void {
        this.wizardFindProductComponent.reset();
    }
}
