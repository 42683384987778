import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { RepairsService } from '@src/services/repairs/repairs.service';

@Component({
    selector: 'app-find-warranty',
    templateUrl: './find-warranty.component.html'
})

export class FindWarrantyComponent {

    @Output() warrantySelected = new EventEmitter<Warranty>();
    @Output() serialNumberDefined = new EventEmitter<String>();

    serialNumber = '';
    warrantiesList: Warranty[] = [];
    searchResult: string = null;

    constructor(
        private warrantiesService: WarrantiesService,
        private repairServices: RepairsService) { }

    getWarrantiesBySerialNumber(): void {
        if (!this.serialNumber || this.serialNumber === '') {
            return;
        }
        this.warrantiesService.getWarrantiesBySerialNumber(this.serialNumber)
            .subscribe(data => {
                if (data.length === 0 || data == null) {
                    this.warrantiesList = [];
                    this.searchResult = 'e';
                } else {
                    this.warrantiesList = data;
                    this.searchResult = 's';

                    if (this.warrantiesList.length > 0) {
                        this.warrantiesList.forEach(warranty => {
                            {
                                this.repairServices.getRepairWarrantiesByWarrantyId(warranty.id).subscribe(
                                    repairsWarranty => {
                                        if (repairsWarranty == null) {
                                            warranty.repairs = [];
                                        } else {
                                            warranty.repairs = repairsWarranty;
                                        }
                                    }
                                )
                            }
                        });
                    }
                }
            });
    }

    getWarrantiesBySerialNumberKeyDonw(event) {
        if (event.keyCode === 13) {
            this.getWarrantiesBySerialNumber();
        }
    }

    reset() {
        this.warrantiesList = [];
        this.serialNumber = '';
        this.searchResult = null;
    }

    searchAgain() {
        this.reset();
    }

    continueWithVirtualActivation() {
        this.warrantySelected.emit(null);
        this.serialNumberDefined.emit(this.serialNumber);
    }

    setSelectedWarranty(warranty: Warranty) {
        this.warrantySelected.emit(warranty);
    }

}
