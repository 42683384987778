import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ProductItem } from '@src/model/productItem';
import { UserProfile } from '@src/auth/user-profile';
import { Retailer } from '@src/model/retailer';

@Component({
    selector: 'app-find-create-warranty',
    templateUrl: './create-warranty.component.html'
})

export class CreateWarrantyComponent implements OnInit {
    @Input() selectedProductItem: ProductItem;
    @Output() createdWarranty = new EventEmitter<Warranty>();

    warrantyCode = '';
    warrantCard: WarrantyCard;
    searchResult: string = null;
    loggedUser: UserProfile;
    virtualRetailer: Retailer;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private warrantiesService: WarrantiesService,
        private warrantyCardService: WarrantycardsService,
        private retailersService: RetailersService) { }

    ngOnInit() {
        this.retailersService.getRepairRetailer().subscribe(repairRetailer => {
            this.virtualRetailer = repairRetailer;
        });
        this.loggedUser = this.authService.getCurrentUserInstant();
    }

    getWarrantiesByWarrantyCode(): void {
        if (!this.warrantyCode || this.warrantyCode === '') {
            return;
        }
        this.warrantyCardService.getWarrantyCardByCode(this.warrantyCode)
            .subscribe(data => {
                if (data === null) {
                    this.searchResult = 'e';
                } else {
                    this.warrantCard = data;
                    if (!this.warrantCard.isUsed){
                        this.searchResult = 's';
                        this.createWarranty(this.warrantCard);
                    } else {
                        this.searchResult = 'e';
                    }
                }
            });
    }

    getWarrantiesByWarrantyCodeKeyDonw(event) {
        if (event.keyCode === 13) {
            this.getWarrantiesByWarrantyCode();
        }
    }

    reset() {
        this.warrantCard = null;
        this.warrantyCode = '';
        this.searchResult = null;
    }

    searchAgain() {
        this.reset();
    }

    setCreatedWarranty(warranty: Warranty) {
        this.createdWarranty.emit(warranty);
    }

    createWarranty(warrantCard: WarrantyCard) {
        const warranty = new Warranty();
        warranty.warrantyActivationDate =  new Date();
        warranty.productItemId = this.selectedProductItem.id;
        warranty.warrantyCardCode = warrantCard.code;
        warranty.warrantyCardId = warrantCard.id;
        warranty.productId = this.selectedProductItem.product.id;
        warranty.isFake = false;
        warranty.isLegacy = true;
        warranty.isSerializedItem = true;
        warranty.distribution = 'Website';

        warranty.productItemSerialNumber = this.selectedProductItem.serialNumber;
        warranty.productSku = this.selectedProductItem.product.sku;
        warranty.productName = this.selectedProductItem.product.name;
        warranty.sellerId = this.loggedUser.sub;
        warranty.retailerId = this.virtualRetailer.id;

        this.warrantiesService.addWarranty(warranty).subscribe(w => {
            if (w) {
                if (w !== null) {
                    this.setCreatedWarranty(w);
                } else {
                    this.searchResult = 'e';
                }
            }
        });
    }

}
