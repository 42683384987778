
export const retailersuser = [
    {
        'id': 1,
        'code': '1169',
        'name': 'Oris SA MyWatches',
        'address': 'Ribigasse 1',
        'addressIndications': '',
        'zip': '4434',
        'city': 'Hölstein',
        'isoCountry': 'CH',
        'latitude': '47.4242013',
        'longitude': '7.7692057',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 52.9197467',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 52.9197467'
     },
     {
        'id': 2,
        'code': '1511',
        'name': 'Oris SA',
        'address': 'Ribigasse 1',
        'addressIndications': '',
        'zip': '4434',
        'city': 'Hölstein',
        'isoCountry': 'CH',
        'latitude': '47.424084',
        'longitude': '7.769381',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.1221954',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.1221954'
     },
     {
        'id': 3,
        'code': '1512',
        'name': 'Châtelain SA',
        'address': '10, rue du Pont',
        'addressIndications': '',
        'zip': '1003',
        'city': 'Lausanne',
        'isoCountry': 'CH',
        'latitude': '46.521369',
        'longitude': '6.6331421',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.249264',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.249264'
     }
];
