import { ActivationSelectRetailerComponent } from '@src/base-components/activation/steps/select-retailer/activation-select-retailer';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';
import { ActivationFindProductComponent } from '@src/base-components/activation/steps/find-product/activation-find-product';
import { ActivationFindWCComponent } from '@src/base-components/activation/steps/find-wc/activation-find-wc';
import { ActivationConfirmationComponent } from '@src/base-components/activation/steps/confirmation/activation-confirmation';
import { ActivationResultComponent } from '@src/base-components/activation/steps/result/activation-result';

import { WizardFindSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-serializable-product';
import { WizardFindNotSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-not-serializable-product';
import { WizardFindProductItemsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-product-items-result';
import { WizardFindProductsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-products-result';
import { WizardSelectTypeComponent } from '@src/base-components/wizard/find-product/wizard-select-type';
import { WizardSelectRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-select-retailer';
import { WizardSelectMarketComponent } from '@src/base-components/wizard/select-market/wizard-select-market';
import { WizardPreSelectedRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-pre-selected-retailer';
import { WizardFindWarrantyCardsResultComponent } from '@src/base-components/wizard/find-wc/wizard-find-wc-result';
import { WarningMessageFindResultComponent } from '@src/base-components/wizard/find-product/warning-message-find-result.component';
import { ActivationFindSNWCComponent } from '@src/base-components/activation/steps/find-wc/activation-find-sn-wc';
import { ActivationCustomerCreationComponent } from './steps/customer/activation-customer-creation';

export const ActivationComponents = [
    ActivationSelectRetailerComponent,
    ActivationSelectTypeComponent,
    ActivationFindProductComponent,
    ActivationFindWCComponent,
    ActivationFindSNWCComponent,
    ActivationConfirmationComponent,
    ActivationResultComponent,
    WizardFindProductItemsResultComponent,
    WizardFindProductsResultComponent,
    WizardFindSerializableProductComponent,
    WizardFindNotSerializableProductComponent,
    WizardSelectTypeComponent,
    WizardSelectRetailerComponent,
    WizardSelectMarketComponent,
    WizardPreSelectedRetailerComponent,
    WarningMessageFindResultComponent,
    WizardFindWarrantyCardsResultComponent,
    ActivationCustomerCreationComponent
];