export const products = [
    {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9002',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9003',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9004',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9005',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9006',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9007',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9008',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9009',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9010',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9011',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     },
     {
        'id': 1,
        'name': 'High Jewellery',
        'sku': '820787-9001',
        'description': 'RING - UNIQUE PIECE',
        'productDate': '2017-06-19T13: 47: 00',
        'productFamilyId': 1,
        'productTypeId': 1,
        'isSerializable': true,
        'iscustomizable': false,
        'isWarrantyCardMandatory': false,
        'imageUrl': 'https: //www.oris.ch/data/19464_01%20752%207698%204284-set_verticale.png'
     }
];