export class ShipmentsParameters {    
    store?: string;    
    city?: string;
    serialNumber: string;
    from: Date;
    to: Date;
    last: boolean;
    selectedTimeRange: string;
    parentNotificationId? : number;

}