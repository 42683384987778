export const importedproductsentries = [
   {
      'id': '3:771919',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.6981279Z',
      'serialNumber': '3529809',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771923',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.763588Z',
      'serialNumber': '3529814',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771921',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.7302977Z',
      'serialNumber': '3529812',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771927',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.8572744Z',
      'serialNumber': '3530147',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771929',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.9326172Z',
      'serialNumber': '3530149',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771918',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.5641599Z',
      'serialNumber': '3529808',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771925',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.7963444Z',
      'serialNumber': '3530144',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771926',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.8286267Z',
      'serialNumber': '3530146',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771928',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.8985713Z',
      'serialNumber': '3530148',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   },
   {
      'id': '3:771930',
      'fileId': 3,
      'lastUpdatedDate': '2018-12-27T12:31:41.9638387Z',
      'serialNumber': '3530150',
      'isNew': false,
      'family': {
         'id': 396,
         'name': 'Oris Big Crown ProPilot',
         'code': 'col_32',
         'isNew': false
      },
      'product': {
         'id': 2738,
         'name': 'Oris Big Crown ProPilot Calibre 111',
         'sku': '01 111 7711 4165-Set 1 22 72FC',
         'isNew': false
      }
   }
];