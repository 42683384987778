import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Sale } from '@src/model/sale';
import { Product } from '@src/model/product';
import { WarrantiesService } from '@src/services/warranty/warranties.service';

@Component({ 
    selector: 'app-search-sale-details',
    templateUrl: './sale-details.component.html',
    styleUrls: ['./sale-details.component.scss']
})

export class SearchSaleDetailsComponent implements OnInit {
    @Input() saleDetails : Sale;
    @Input() product: Product;

    constructor(private warrantiesServices: WarrantiesService) {}

    ngOnInit(){
      }
  }
