import { DashboardComponent } from './dashboard.component';
import { DashboardSalesChartComponent } from './charts/sales-chart/dashboard-sales-chart.component';
import { DashboardActivationMetricsComponent } from './metrics/dashboard-activation-metrics.component';
import { DashboardActivationMetricsElementComponent } from './metrics/dashboard-activation-metrics-element.component';
import { DashboardDefaultFilterBaseComponent } from './dashboard-profiles/default/dashboard-default-filter-base.component';
import { DashboardDefaultNewFilterComponent } from './dashboard-profiles/default/new-filter/dashboard-default-new-filter.component';
import { DashboardDefaultFilterComponent } from './dashboard-profiles/default/filter/dashboard-default-filter.component';
import { DashboardDonutChartComponent } from './charts/donut-chart/dashboard-donut-chart-component';
import { DashboardRetailerFilterComponent } from './dashboard-profiles/retailer/filter/dashboard-retailer-filter.component';
import { DashboardRetailerNewFilterComponent } from './dashboard-profiles/retailer/new-filter/dashboard-retailer-new-filter.component';
import { DashboardRetailerFilterBaseComponent } from './dashboard-profiles/retailer/dashboard-retailer-filter-base.component';
import { DashboardDefaultComponent } from './dashboard-profiles/default/dashboard-default.component';
import { DashboardRetailerComponent } from './dashboard-profiles/retailer/dashboard-retailer.component';
import { DashboardMapComponent } from './map/dashboard-map.component';
import { SalesValueChartComponent } from './charts/sales-value-chart/sales-value-chart.component';

import { SalesTotalVolumeChartComponent } from './charts/sales-total-volume-chart/sales-total-volume-chart-component';
import { SalesTotalValueChartComponent } from './charts/sales-total-value-chart/sales-total-value-chart-component';
import { TopStoresVolumeChartComponent } from './charts/top-stores-volume-chart/top-stores-volume-chart-component';
import { TopStoresValueChartComponent } from './charts/top-stores-value-chart/top-stores-value-chart-component';
import { TopProductsVolumeChartComponent } from './charts/top-products-volume-chart/top-products-volume-chart-component';
import { TopProductsValueChartComponent } from './charts/top-products-value-chart/top-products-value-chart-component';
import { TopCollectionsVolumeChartComponent } from './charts/top-collections-volume-chart/top-collections-volume-chart-component';
import { TopCollectionsValueChartComponent } from './charts/top-collections-value-chart/top-collections-value-chart-component';
import { TopFamiliesVolumeChartComponent } from './charts/top-families-volume-chart/top-families-volume-chart-component';
import { TopFamiliesValueChartComponent } from './charts/top-families-value-chart/top-families-value-chart-component';

export const DashboardComponents = [
    DashboardComponent,
    DashboardSalesChartComponent,
    DashboardActivationMetricsComponent,
    DashboardActivationMetricsElementComponent,
    DashboardDefaultFilterBaseComponent,
    DashboardDefaultNewFilterComponent,
    DashboardDefaultFilterComponent,
    DashboardDonutChartComponent,
    DashboardRetailerFilterBaseComponent,
    DashboardRetailerFilterComponent,
    DashboardRetailerNewFilterComponent,
    DashboardDefaultComponent,
    DashboardRetailerComponent,
    DashboardMapComponent,
    SalesValueChartComponent,
    SalesTotalVolumeChartComponent,
    SalesTotalValueChartComponent,
    TopStoresVolumeChartComponent,
    TopStoresValueChartComponent,
    TopProductsVolumeChartComponent,
    TopProductsValueChartComponent,
    TopCollectionsVolumeChartComponent,
    TopCollectionsValueChartComponent,
    TopFamiliesVolumeChartComponent,
    TopFamiliesValueChartComponent
];