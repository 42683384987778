export const importedretailers = [
    {
        'id': 1,
        'fileName': 'Retailer_ORIS_20180312-181700.csv',
        'fileContent': 'UmV0YWlsZXJJRDtJc0FjdGl2ZTtSZXRhaWxlck5hbWU7UmV0YWlsZXJBZGRyZXNzMTtSZXRhaWxlckFkZHJlc3MyO1JldGFpbGVyWmlwO1JldGFpbGVyQ2l0eTtSZXRhaWxlcklzb0NvdW50cnk7UmV0YWlsZXJMYXRpdHVkZTtSZXRhaWxlckxvbmdpdHVkZTtFbWFpbEFkZHJlc3M7TWFuYWdlckxhc3ROYW1lO01hbmFnZXJGaXJzdE5hbWU7TWFya2V0SUQxO01hcmtldE5hbWUxO01hcmtldElEMjtNYXJrZXROYW1lMg0KVEVTVE07VFJVRTtURVNUTSBuYW1lO1AuTy4gQm94IDIyMDtURVNUO05MLTExNjEgQlc7WndhbmVuYnVyZztMVTs1MS40NjAxODM5ODszLjUzMjgzNDA1MzttYXJpby5mZXJuYW5kZXNAYi1pLmNvbTtNO0Y7MDA1RDAwMDAwMDJGWkE4O0JJIE1hcmtldDswMTtCSQ==',
        'userId': '99999999-9999-9999-9999-999999999999',
        'uploadType': 2,
        'uploadStatus': 4,
        'errorMsg': null,
        'createdById': '00000000-0000-0000-0000-000000000000',
        'createdBy': null,
        'createdOn': '2018-09-11T17:57:58.0574182',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-09-11T17:58:05.6630418',
        'user':  {
            'createdOn':  '2018-07-30T19:00:39.957',
            'email':  'was-system@b-i.com',
            'firstName':  'WAS',
            'id':  '99999999-9999-9999-9999-999999999999',
            'imageUrl':  null,
            'isActive':  true,
            'lastName':  'System',
            'locale':  'en',
            'marketId':  null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers':  [],
            'roles':  [],
            'userName':  'was-system@b-i.com'
        }
    },
    {
        'id': 2,
        'fileName': 'Retailer_ORIS_20180312-181700.csv',
        'fileContent': 'UmV0YWlsZXJJRDtJc0FjdGl2ZTtSZXRhaWxlck5hbWU7UmV0YWlsZXJBZGRyZXNzMTtSZXRhaWxlckFkZHJlc3MyO1JldGFpbGVyWmlwO1JldGFpbGVyQ2l0eTtSZXRhaWxlcklzb0NvdW50cnk7UmV0YWlsZXJMYXRpdHVkZTtSZXRhaWxlckxvbmdpdHVkZTtFbWFpbEFkZHJlc3M7TWFuYWdlckxhc3ROYW1lO01hbmFnZXJGaXJzdE5hbWU7TWFya2V0SUQxO01hcmtldE5hbWUxO01hcmtldElEMjtNYXJrZXROYW1lMg0KVEVTVE07VFJVRTtURVNUTSBuYW1lO1AuTy4gQm94IDIyMDtURVNUO05MLTExNjEgQlc7WndhbmVuYnVyZztMVTs1MS40NjAxODM5ODszLjUzMjgzNDA1MzttYXJpby5mZXJuYW5kZXNAYi1pLmNvbTtNO0Y7MDA1RDAwMDAwMDJGWkE4O0JJIE1hcmtldDswMTtCSQ==',
        'userId': '99999999-9999-9999-9999-999999999999',
        'uploadType': 2,
        'uploadStatus': 3,
        'errorMsg': null,
        'createdById': '00000000-0000-0000-0000-000000000000',
        'createdBy': null,
        'createdOn': '2018-09-11T19:17:18.5861856',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-09-11T19:17:19.1522489',
        'user':  {
            'createdOn':  '2018-07-30T19:00:39.957',
            'email':  'was-system@b-i.com',
            'firstName':  'WAS',
            'id':  '99999999-9999-9999-9999-999999999999',
            'imageUrl':  null,
            'isActive':  true,
            'lastName':  'System',
            'locale':  'en',
            'marketId':  null,
            'privacyPolicyAcceptationDate':  '2018-09-21T16:49:53.761961',
            'retailers':  [],
            'roles':  [],
            'userName':  'was-system@b-i.com'
        }
    },
    {
        'id':  1002,
        'fileName': 'Retailer_ORIS_20180312-181700.csv',
        'fileContent': 'UmV0YWlsZXJJRDtJc0FjdGl2ZTtSZXRhaWxlck5hbWU7UmV0YWlsZXJBZGRyZXNzMTtSZXRhaWxlckFkZHJlc3MyO1JldGFpbGVyWmlwO1JldGFpbGVyQ2l0eTtSZXRhaWxlcklzb0NvdW50cnk7UmV0YWlsZXJMYXRpdHVkZTtSZXRhaWxlckxvbmdpdHVkZTtFbWFpbEFkZHJlc3M7TWFuYWdlckxhc3ROYW1lO01hbmFnZXJGaXJzdE5hbWU7TWFya2V0SUQxO01hcmtldE5hbWUxO01hcmtldElEMjtNYXJrZXROYW1lMg0KVEVTVE07VFJVRTtURVNUTSBuYW1lO1AuTy4gQm94IDIyMDtURVNUO05MLTExNjEgQlc7WndhbmVuYnVyZztMVTs1MS40NjAxODM5ODszLjUzMjgzNDA1MzttYXJpby5mZXJuYW5kZXNAYi1pLmNvbTtNO0Y7MDA1RDAwMDAwMDJGWkE4O0JJIE1hcmtldDswMTtCSQ==',
        'userId': '99999999-9999-9999-9999-999999999999',
        'uploadType': 2,
        'uploadStatus': 3,
        'errorMsg': null,
        'createdById': '00000000-0000-0000-0000-000000000000',
        'createdBy': null,
        'createdOn': '2018-09-12T11:48:18.0725174',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-09-11T19:17:18.5861856',
        'user':  {
            'createdOn':  '2018-07-30T19:00:39.957',
            'email':  'was-system@b-i.com',
            'firstName':  'WAS',
            'id':  '99999999-9999-9999-9999-999999999999',
            'imageUrl':  null,
            'isActive':  true,
            'lastName':  'System',
            'locale':  'en',
            'marketId':  null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers':  [],
            'roles':  [],
            'userName':  'was-system@b-i.com'
        }
    },
    {
        'id':  1003,
        'fileName':  'Retailer_ORIS_20180312-181700.csv',
        'fileContent':  'UmV0YWlsZXJJRDtJc0FjdGl2ZTtSZXRhaWxlck5hbWU7UmV0YWlsZXJBZGRyZXNzMTtSZXRhaWxlckFkZHJlc3MyO1JldGFpbGVyWmlwO1JldGFpbGVyQ2l0eTtSZXRhaWxlcklzb0NvdW50cnk7UmV0YWlsZXJMYXRpdHVkZTtSZXRhaWxlckxvbmdpdHVkZTtFbWFpbEFkZHJlc3M7TWFuYWdlckxhc3ROYW1lO01hbmFnZXJGaXJzdE5hbWU7TWFya2V0SUQxO01hcmtldE5hbWUxO01hcmtldElEMjtNYXJrZXROYW1lMg0KVEVTVE07VFJVRTtURVNUTSBuYW1lO1AuTy4gQm94IDIyMDtURVNUO05MLTExNjEgQlc7WndhbmVuYnVyZztMVTs1MS40NjAxODM5ODszLjUzMjgzNDA1MzttYXJpby5mZXJuYW5kZXNAYi1pLmNvbTtNO0Y7MDA1RDAwMDAwMDJGWkE4O0JJIE1hcmtldDswMTtCSQ==',
        'userId':  '99999999-9999-9999-9999-999999999999',
        'uploadType':  2,
        'uploadStatus':  3,
        'errorMsg':  null,
        'createdById':  '00000000-0000-0000-0000-000000000000',
        'createdBy':  null,
        'createdOn':  '2018-09-12T11:57:30.2286633',
        'updatedById':  '00000000-0000-0000-0000-000000000000',
        'updatedBy':  null,
        'updatedOn':  '2018-09-12T11:57:30.4768274',
        'user':  {
            'createdOn':  '2018-07-30T19:00:39.957',
            'email':  'was-system@b-i.com',
            'firstName':  'WAS',
            'id':  '99999999-9999-9999-9999-999999999999',
            'imageUrl':  null,
            'isActive':  true,
            'lastName':  'System',
            'locale':  'en',
            'marketId':  null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers':  [],
            'roles':  [],
            'userName':  'was-system@b-i.com'
        }
    }
];