import { Component, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivationFindWCComponent } from '@src/base-components/activation/steps/find-wc/activation-find-wc';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../../environments/environment';
const CODE_SCANNER_APP_URL = environment.scannerURL;
@Component({
    selector: 'app-activation-find-wc-messika',
    templateUrl: './activation-find-wc.html',
    styleUrls: ['./activation-find-wc.scss']
})
export class ActivationFindWCComponentMessika extends ActivationFindWCComponent implements OnDestroy {
    cameraIframeVisible = false;
    code: string = '';
    barcodeScannerURL: SafeResourceUrl;

    constructor(warrantycardsService: WarrantycardsService,
        public toastrService: ToastrService,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer) {
        super(warrantycardsService);
        this.barcodeScannerURL = this.sanitizer.bypassSecurityTrustResourceUrl(CODE_SCANNER_APP_URL);
    }

    public openCameraIframe() {
        this.cameraIframeVisible = !this.cameraIframeVisible;
    }

    public closeCameraIframe() {
        this.cameraIframeVisible = false;
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent) {
        if (event.data.code) {
            const code = event.data.code;
            console.log('Received broadcasted message', code);
            this.code = this.extractValueFromUrl(code);
            this.ref.detectChanges();
            this.getWarrantyCardByCode();
        }
        if (event.data === 'closeIframe') {
            this.closeCameraIframe();
        }
    }

    extractValueFromUrl(codeString) {
        const splitted = codeString.split('/')
        const splittedParams = splitted[splitted.length - 1].split('=');
        const scannedValue = splittedParams[splittedParams.length - 1];
        return scannedValue ? scannedValue : '';
    }

    ngOnDestroy(): void {
        this.closeCameraIframe();;
    }
}
