import { Injectable, Injector, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SaleForFinalCustomer } from '@src/model/saleForFinalCustomer';
import { MessikaExternalService } from '../services/messika-external.service';
import { ConfigService } from '@src/services/layout/config.service';
import { MessikaWarrantyCertificateComponent } from '../warranty-certificate/warranty-certificate.component'

@Component({
    templateUrl: '../warranty-certificate/warranty-certificate.component.html',
    styleUrls: ['../warranty-certificate/warranty-certificate.component.scss'],
    providers: [ MessikaExternalService ]
})
export class ProductOfMessikaComponent extends MessikaWarrantyCertificateComponent implements OnInit {
    public saleForFinalCustomer : SaleForFinalCustomer;
    public cardNumber: string;
    public loader = true;
    public warrantyCode: string;

    // added _ prefix to variables and methods to rename/override the parent ones with the new logic
    constructor (
        injector: Injector,
        route: ActivatedRoute,
        _externalService: MessikaExternalService,
        configService: ConfigService,
        public activatedRoute: ActivatedRoute,
    ) {
        super(injector, route, _externalService, configService);
        configService.setIsPublicPage(true);
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .subscribe(params => {
                this.warrantyCode = params['w'];
                this._externalService.getSaleByWarrantyCardCode(this.warrantyCode)
                .subscribe(
                    (sffc) => {
                        this.saleForFinalCustomer = sffc;
                    },
                    err => {
                        console.log('HTTP Error', err)
                    },
                    () => {
                        // request completed
                        this.loader = false;
                    }
                );
        });
    }
}
