import { Injectable, Injector } from '@angular/core';
import { BaseService } from "@src/services/base.service";
import { environment } from '../../environments/environment';

@Injectable()
export class DownloadFileService extends BaseService {
    protected baseUrl = environment.apiBaseUrl + '/api/files';

    constructor(injector: Injector) {
        super(injector);
    }

    async downloadFile(data: any) {
        if (data.fileContent) {
            const blob = this.b64toBlob(data.fileContent, 'text/csv');
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //save file for IE
                window.navigator.msSaveOrOpenBlob(blob, data.fileName);
            } else {
                const blobUrl = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    
                a.href = blobUrl;
                a.download = data.fileName;
                document.body.appendChild(a);
                a.click();
    
                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);
            }
        }
        else {
            this.setupAuthHeaders();
            await fetch(`${this.baseUrl}/${data.fileName}`, this.httpAuthHeaders)
            .then(resp => resp.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');

              a.style.display = 'none';
              a.href = url;
              a.download = data.fileName;
              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            });
        }
    }

    private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}