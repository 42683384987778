import { Component, OnInit, Inject } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';

@Component({
  selector: 'app-repairs',
  templateUrl: './repairs.component.html'
})

export class RepairsComponent implements OnInit {
  loadRepairProfile?: boolean = null;

  constructor(@Inject('AuthService') private authService: IAuthService) { }
  ngOnInit() {
    this.authService.getCurrentUser()
      .subscribe(u =>
        this.loadRepairProfile = u.isInRole(UserRolesEnum.REPAIR));
  }
}
