import { Component, OnInit, Inject} from '@angular/core';
import { PageLayoutComponent } from '@src/base-components/pagelayout/pagelayout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IAuthService } from '@src/auth/auth.service.interface';
import { MessikaPageType } from './../../messika/services/messika-page-type.service';

@Component({
  selector: 'app-page-layout-messika',
  templateUrl: './pagelayout-messika.component.html',
  styleUrls: ['./pagelayout-messika.component.scss'],
  providers: [ MessikaPageType ]
})
export class PageLayoutMessikaComponent extends PageLayoutComponent implements OnInit {

  constructor(
    @Inject('AuthService') authService: IAuthService,
    modalService: NgbModal,
    route: Router,
    public messikaPageType: MessikaPageType,
    private router: Router) {
      super(modalService, authService, route);
  }

  ngOnInit() {}

  onActivate() {}

  IsInternalPage() {
    return this.messikaPageType.isInternal();
  }
}
