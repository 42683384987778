import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserNotification } from '@src/model/notification';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { ConfigService } from '@src/services/layout/config.service';
import { SharedDataService } from '@src/services/sharedData.service';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsDropdownComponent implements OnInit {
    notifications: UserNotification[];
    selectedLanguage: string;
    unreadNotifications: UserNotification[];
    notificationSub: Subscription;

    constructor(public configService: ConfigService, private notificationsService: NotificationsService, private sharedDataService : SharedDataService) {
      this.notifications = [];
      this.unreadNotifications = [];
    }

    ngOnInit() {
      var observable = Observable.interval(20000).flatMap(() => {
        return this.notificationsService.getNotifications();
      })
      this.notificationSub = observable.take(1).subscribe(notifications => {
        this.setResponse(notifications);
      });

      this.sharedDataService.currentMessage.subscribe(message => {
        if(message == "notification:created") {
          this.refresh();
        }
      });
    }

    setResponse(notifications: UserNotification[]) {
      if (notifications) {
        this.notifications = notifications;
        this.unreadNotifications = notifications.filter(n => n.isRead === false);
      } else {
        this.notifications = [];
        this.unreadNotifications = [];
      }
    }

    updateListStatus(newNotificationStatus: UserNotification) {
      this.notifications.forEach(n => {
        if (n.id == newNotificationStatus.id) {
          n = newNotificationStatus;
        }
      });
      this.unreadNotifications = this.notifications.filter(n => n.isRead === false);
    }
    refresh()
    {
      this.notificationsService.getNotifications().subscribe(notifications => {
        if (notifications) {
          this.notifications = notifications;
          this.unreadNotifications = notifications.filter(n => n.isRead === false);
        } else {
          this.notifications = [];
          this.unreadNotifications = [];
        }
      });
    }
}
