import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '@src/services/users/users.service';
import { ConfigService } from '@src/services/layout/config.service';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component({
    selector: 'app-privacy-modal-content-e',
    templateUrl: './privacy-modal-content.component.html',
    styleUrls: ['./privacy-modal-content.component.scss']
})
export class PrivacyModalContentComponent implements OnInit {
    configOrientation: string;
    constructor(private activeModal: NgbActiveModal,
        private userService: UsersService,
        @Inject('AuthService') private authService: IAuthService,
        private configService: ConfigService) {}

    ngOnInit() {
        this.configOrientation = this.configService.templateConf.layout.dir;
    }

    close() {
        this.activeModal.close();
    }
    accept() {
        this.userService.acceptPrivacyPolicy().subscribe(r => this.activeModal.close());
        this.authService.acceptPrivacyPolicy();
    }
}
