export  const warrantycards = [
    {
      'id' :  1,
      'code' :  '00001',
      'isUsed' : true
    },
    {
      'id' :  2,
      'code' :  '00002',
      'isUsed' : false,
      'PrePairedProductItemId': 1,
      'PrePairedSerialNumber': '00001'
    },
    {
      'id' :  3,
      'code' :  '00003',
      'isUsed' : false

    },
    {
      'id' :  4,
      'code' :  '00004',
      'isUsed' : false
    },
    {
      'id' :  5,
      'code' :  '00005',
      'isUsed' : false
    },
    {
      'id' :  6,
      'code' :  '00006',
      'isUsed' : false
    },
    {
      'id' :  7,
      'code' :  '00007',
      'isUsed' : false
    },
    {
      'id' :  8,
      'code' :  '00008',
      'isUsed' : false
    },
    {
      'id' :  9,
      'code' :  '00009',
      'isUsed' : false
    },
    {
      'id' :  10,
      'code' :  '00010',
      'isUsed' : false
    }
  ];
