import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProductsService } from '@src/services/products/products.service';
import { WizardFindSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-serializable-product';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-wizard-find-serializable-product-messika',
    templateUrl: './wizard-find-serializable-product.html'
})
export class WizardFindSerializableProductComponentMessika extends WizardFindSerializableProductComponent {

    constructor(productsService: ProductsService, translateServices: TranslateService, private ref: ChangeDetectorRef) {
        super(productsService, translateServices);
    }

    handleResponseMessage(): string {
        if (this.canSelectFakeSN) {
            return this.translateService.instant('Components.ActivationSteps.product-serialnumber-not-found-fake');
        } else if (this.showProductSold) {
            return this.translateService.instant('Components.ActivationSteps.product-already-activated');
        } else {
            return this.translateService.instant('Components.ActivationSteps.product-serialnumber-not-found');
        }
    }
   
    public onErrorSn(e: any): void {
        alert(e);
    }
}
