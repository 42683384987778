import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { WarrantyUpdateWithComment } from '@src/model/warranty/WarrantyUpdateWithComment';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { Product } from '@src/model/product';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { environment } from '@src/environments/environment';
import { SaleDetails } from '@src/model/saleDetails';

@Component({
    selector: 'app-sale-details-product-base',
    templateUrl: './sale-details-product.component.html',
    styleUrls: ['./sale-details-product.component.scss']
})

export class SaleDetailsProductComponent {
    @Input() productItem = new ProductItem();
    @Input() product = new Product();
    @Input() warranty = new Warranty();
    @Input() saleDetails = new SaleDetails();
    @Output() refreshOperationList = new EventEmitter<WarrantyOperation[]>();
    @Input() editEnabled = false;
    @Input() isSeller: boolean;
    @Input() isSearchResult: boolean;
    @Input() isFake: boolean;
    @Input() isCpo: boolean;
    @Input() isDuplicate: boolean;
    @Input() hasRepairWarranties: boolean;
    @Input() isValid: boolean;

    isCorrectionVisible = false;

    inCorrection = false;
    isCollapsed = false;
    canEditItem = true;
    isRepairUser = false;
    constructor(private warrantiesService: WarrantiesService,
        @Inject('AuthService')
        private authService: IAuthService) {
            const currentUser = this.authService.getCurrentUserInstant();
            if (currentUser.role[0] === UserRolesEnum.REPAIR) {
                this.isRepairUser = true;
            }

            if (environment.clientName && environment.clientName === 'oris') {
                this.isCorrectionVisible = true;
            }
    }

    toggleCorrectItem(): void {
        this.inCorrection = !this.inCorrection;
    }

    cancelItemCorrection(): void {
        this.inCorrection = false;
    }

    correctProductItem({productItem, comment}:{productItem:ProductItem, comment: string}) {
        const warrantyToUpdate = this.warranty;
        warrantyToUpdate.productItemId = productItem.id;
        warrantyToUpdate.productItemSerialNumber = productItem.serialNumber;

        const warrantyReturn: WarrantyUpdateWithComment = {
            warranty: warrantyToUpdate,
            comment: comment
        };
        this.warrantiesService.updateWarrantyWithComment(warrantyReturn)
            .subscribe((w) => {
                this.productItem = productItem;
                this.inCorrection = false;
                this.refreshOperationList.emit(w.warrantyOperations);
            });

    }
    correctProduct({product, comment}:{product:Product, comment: string}) {
        const warrantyToUpdate = this.warranty;
        warrantyToUpdate.productId = product.id;
        warrantyToUpdate.productName = product.name;
        warrantyToUpdate.productSku=product.sku;
        
        const warrantyReturn: WarrantyUpdateWithComment = {
            warranty: warrantyToUpdate,
            comment: comment
        };
        this.warrantiesService.updateWarrantyWithComment(warrantyReturn)
            .subscribe((w) => {
                
                this.inCorrection = false;
                this.refreshOperationList.emit(w.warrantyOperations);
            });

    }
}
