import { Component, ViewChild, OnInit, Inject, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { DashboardRetailerNewFilterComponent } from './new-filter/dashboard-retailer-new-filter.component';
import { UserRolesEnum } from '@src/model/userRoles';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardRetailerFilterComponent } from './filter/dashboard-retailer-filter.component';
import { DashboardSalesChartComponent } from '../../charts/sales-chart/dashboard-sales-chart.component';

@Component({
  selector: 'app-dashboard-retailer',
  templateUrl: './dashboard-retailer.component.html'
})

export class DashboardRetailerComponent implements OnChanges, OnInit {

  @Input() from?: Date;
  @Input() to?: Date;
  @Input() interval?: string;
  @Input() retailerId?: number;
  marketId?: number;
  private _retailerId?: number = null;
  isLoaded = false;
  topSellersSales: { name: string, value: number }[];
  @Input() topSellers: number;
  isSeller = false;
  canExportSales = false;
  hasNewFilter: boolean;
  isLoadingExport = false;

  @ViewChild(DashboardRetailerNewFilterComponent) dashboardRetailerNewFilterComponent: DashboardRetailerNewFilterComponent;
  @ViewChild(DashboardRetailerFilterComponent) dashboardRetailerFilterComponent: DashboardRetailerFilterComponent;
  @ViewChild(DashboardSalesChartComponent) dashboardSalesChartComponent: DashboardSalesChartComponent;

  constructor(@Inject('AuthService') private authService: IAuthService,
    private dashboardService: DashboardService,
    private configService: ConfigService) {
      this.authService.getCurrentUser()
      .subscribe(u =>
        this.isSeller = u.isInRole(UserRolesEnum.SELLER));

      this.hasNewFilter = this.configService.hasNewFilter().dashboard;
  }
  ngOnInit(): void {
    this.dashboardRetailerFilterComponent.selectedTimeRange = 'last-month';
    this.dashboardRetailerNewFilterComponent.selectedTimeRange = 'last-month';

    const dateStr = this.hasNewFilter ? this.dashboardRetailerNewFilterComponent.computeTimeRange('last-month')
        : this.dashboardRetailerFilterComponent.computeTimeRange('last-month');
    this.from = this.from == undefined ? dateStr.from : this.from;
    this.to =  this.to == undefined ? dateStr.to : this.to;
    this.interval = dateStr.interval;

    this.getSellersMetrics();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._retailerId = changes.retailerId ? changes.retailerId.currentValue : this._retailerId;
  }
  getSellersMetrics() {
    this
      .dashboardService
      .getTopSellersMetrics(5, this._retailerId, this.from, this.to)
      .subscribe(data => {
        this.topSellersSales = data.map(m => {
          return { name: m.label, value: m.value };
        });
      });
  }
  changeRangeTime({ from, to, interval }: { from: Date, to: Date, interval: string }) {
    this.from = from;
    this.to = to;
    this.interval = interval;
    this.getSellersMetrics();
  }

  handleExportSales(event) {
    if (event === true) {
      this.dashboardSalesChartComponent.canExportSales = event;
    }
  }

  loadingExportSales(event) {
    this.isLoadingExport = event;
  }
}