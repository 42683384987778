import { Component, ViewChild } from '@angular/core';
import { ActivationSelectRetailerComponent } from '@src/base-components/activation/steps/select-retailer/activation-select-retailer';
import { WizardPreSelectedRetailerComponentMessika } from '@src/clients/messika/wizard/select-retailer/wizard-pre-selected-retailer';

@Component({
    selector: 'app-activation-select-retailer-messika',
    templateUrl: './activation-select-retailer.html'
})
export class ActivationSelectRetailerComponentMessika extends ActivationSelectRetailerComponent {
    @ViewChild(WizardPreSelectedRetailerComponentMessika) wizardPreSelectedRetailerComponent: WizardPreSelectedRetailerComponentMessika;
}

