import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

import { UserProfile } from '@src/auth/user-profile';
import { IAuthService } from '@src/auth/auth.service.interface';
import { MarketsService } from '@src/services/markets/markets.service';

@Injectable()
export class AuthService implements IAuthService {
    private currentUser = new BehaviorSubject<UserProfile>(null);
    private lastCurrentUser: UserProfile = null;
    isLoggedIn = false;
    privacyPolicyAccepted = true;

    // Most services/components (AuthGuard, SideBar, User menu, etc) react when the User is loaded.
    // Use Observable pattern to signal async changes
    getCurrentUser(): Observable<UserProfile> {
        return this.currentUser.asObservable();
    }
    getCurrentUserInstant(): UserProfile {
        return this.lastCurrentUser;
    }
    acceptPrivacyPolicy(): void {
        this.privacyPolicyAccepted = true;
    }
    silentRefresh() {
        this
            .oauthService
            .silentRefresh()
            .then(info => console.debug('refresh ok', info))
            .catch(err => console.error('refresh error', err));
    }
    // store the URL so we can redirect after logging in
    constructor(private oauthService: OAuthService, private authConfig: AuthConfig, private router: Router, private marketsService: MarketsService) {
        this.oauthService.setStorage(localStorage);
        this
            .oauthService
            .configure(this.authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this
            .oauthService
            .setupAutomaticSilentRefresh();

        if (this.authConfig.showDebugInformation) {
            this
                .oauthService
                .events
                .filter(e => e.type === 'token_received')
                .subscribe(e => {
                    console.debug('oauth/oidc token received', e);
                });
        }
    }
    private debug(msg: string, obj: any = null) {
        if (this.authConfig.showDebugInformation) {
            console.debug(msg, obj);
        }
    }
    tryLogin(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this
                .oauthService
                .loadDiscoveryDocumentAndTryLogin()
                .then(() => {
                    this.debug('AuthService::tryLogin() Loaded Discovery and Tried Login');
                    // Check if there is already a valid token on storage
                    if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
                        this.isLoggedIn = true;
                        this.oauthService.loadUserProfile()
                            .then(obj => {
                                // this.currentUser = <UserProfile>up;
                                const up = new UserProfile(obj);
                                this.marketsService.getMyMarkets().subscribe((markets) => {
                                    if (markets) {
                                        up.markets = markets.map(m => m.code);
                                    }
                                });
                                this.debug('AuthService::tryLogin() Loaded user profile', up);
                                this.lastCurrentUser = up;
                                this.privacyPolicyAccepted = up.cnf && up.cnf !== '' ;
                                this.currentUser.next(up);
                                resolve(true);
                            }).catch(err => {
                                console.error('AuthService::tryLogin() Failed to get user profile', err);
                                reject('Failed to get user profile');
                            });
                    } else {
                        resolve(false);
                    }
                }).catch(err => {
                    console.error('AuthService::tryLogin() Failed auth discovery', err);
                    resolve(false);
                    // reject('Failed auth discovery');
                });
        });
    }
    login(): void {
        this
            .oauthService
            .initImplicitFlow();
    }

    logOut(): void {
        this
            .oauthService
            .logOut();
        this.isLoggedIn = false;
        this.currentUser.next(null);
    }
}