import { Component, Input } from '@angular/core';
import { ImportedFile } from '@src/model/importedFile';


@Component({
    selector: 'app-imported-file-details',
    templateUrl: './imported-file-details.component.html',
    styleUrls: ['./imported-file-details.component.scss']
})
export class ImportedFileDetailsComponent {
    @Input() importedFile: ImportedFile = new ImportedFile();

}