import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { KeyValueTypedPair } from '@src/model/productOrFamilyName';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartColors } from '@src/assets/charts/charts.colors';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-sales-value-chart',
    templateUrl: './sales-value-chart.component.html',
    styleUrls: ['./sales-value-chart.component.scss']
})
  
export class SalesValueChartComponent implements OnInit, OnChanges {

    salesChart: { options: any, data: { id: string, name: string, color: string, series: { name: Date, value: number }[] }[] };
    searchEntries: KeyValueTypedPair[] = [];
    inputQuery: string;
    modelTemp: any;
    hasNewFilters: boolean;

    @Input() from: Date;
    @Input() to: Date;
    @Input() interval: string;
    @Input() retailerId?: number;
    @Input() marketId?: number;
    @Input() entryName: string;
    @Input() entryType?: string;
    @Input() entryId?: number;
    @Input() clearEntriesFlag: boolean;

    private _from: Date = null;
    private _to: Date = null;
    private _interval: string;
    private _retailerId?: number;
    private _marketId?: number;
    private _entryName: string;
    private _entryType?: string;
    private _entryId?: number;
    isLoading: boolean = false;

    localizations = {
        total: this.translateService.instant('Components.Dashboard.total')
    };

    serieColors: { value: string, used: boolean }[];

    constructor(private dashboardService: DashboardService,
        private translateService: TranslateService,
        private configService: ConfigService) {
            this.hasNewFilters = this.configService.hasNewFilter().dashboard;
    }


    ngOnChanges(changes: SimpleChanges) {
        this._from = changes.from ? changes.from.currentValue : this._from;
        this._to = changes.to ? changes.to.currentValue : this._to;
        this._interval = changes.interval ? changes.interval.currentValue : this._interval;
        this._retailerId = changes.retailerId ? changes.retailerId.currentValue : this._retailerId;
        this._marketId = changes.marketId ? changes.marketId.currentValue : this._marketId;
        this._entryName = changes.entryName ? changes.entryName.currentValue : this._entryName;
        this._entryType = changes.entryType ? changes.entryType.currentValue : this._entryType;
        this._entryId = changes.entryId ? changes.entryId.currentValue : this._entryId;

        if (this.salesChart && this.salesChart.data.length > 0) {
            this.salesChart.options.lineChartxScaleMin= this._from;
            this.salesChart.options.lineChartxScaleMax= this._to;
            if (this.entryType === 'total') {
                this.getSalesMetrics({ entryName: this.localizations.total, entryType: this.entryType });
            } else {
                // Refresh the existing data
                this.salesChart.data
                .forEach(s => {
                    if (s.id === 'total') {
                        this.getSalesMetrics({ entryName: this.localizations.total });
                    } else {
                        const ids = s.id.split(':');
                        const entryId = ids[1];
                        const entryType = ids[0];
                        this.getSalesMetrics({ entryId: +entryId, entryType: entryType, entryName: s.name });
                    }
                });
            }

            // Clear Product/Product Family chart data if the user click the clear btn (we leave the Total one)
            if (changes.clearEntriesFlag && changes.clearEntriesFlag.currentValue) {
                const nds = this.salesChart.data.filter(s => s.id === 'total');
                this.salesChart.options.customColors = this.salesChart.options.customColors.filter(c => c.name  === 'Total');
                let color = this.serieColors.filter(c => c.value !== nds[0].color);
                color = color.map(item => {
                    if (item.used) {
                        item.used = false;
                    }
                    return item;
                });
                this.salesChart.data = [...nds];
            }
        }
        // if the user select a Product/Product family from the autocomplete
        if (this._entryId && this._entryType && this._entryName) {
            this.getSalesMetrics({ entryId: this._entryId, entryType: this._entryType, entryName: this._entryName });
        }
    }

    ngOnInit() {
        this.serieColors = ChartColors.map(c => ({ value: c, used: false }));
        this.salesChart = {
            options: {
                lineChartView: [550, 400],
                lineChartShowXAxis: true,
                lineChartShowYAxis: true,
                lineChartGradient: false,
                lineChartShowLegend: false,
                lineChartShowXAxisLabel: true,
                lineChartShowYAxisLabel: true,
                lineChartAutoScale: true,
                lineChartxScaleMin: this._from,
                lineChartxScaleMax: this._to,
                customColors: []
            }, data: []
        };
        this.getSalesMetrics({ entryName: this.localizations.total });
    }

    getSalesMetrics({ entryName, entryType = null, entryId = null }
        : { entryName: string, entryType?: string, entryId?: number }) {

        // const request = entryType == null || entryType === 'total' ?
        //     { interval: this._interval, from: this._from, to: this._to, retailerId: this.retailerId, marketId: this._marketId }
        //     : entryType === 'f' ?
        //         { interval: this._interval, familyId: entryId, from: this._from, to: this._to,
        //             retailerId: this.retailerId, marketId: this._marketId }
        //         : { interval: this._interval, productId: entryId, from: this._from, to: this._to,
        //             retailerId: this.retailerId, marketId: this._marketId };

        // this.dashboardService.getSalesValueMetrics(request).subscribe(r => {

        //     const metricId = entryType == null || entryType === 'total' ? 'total' : entryType + ':' + entryId;
        //     const nds = this.salesChart.data;

        //     const ns = nds.filter(s => s.id === metricId)[0];
        //     if (ns) {
        //         ns.series = r.map(m => {
        //             const date = new Date(m.label);
        //             return { name: new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), value: m.value };
        //         });
        //     } else {
        //         let color = this.serieColors.filter(c => !c.used)[0];
        //         color.used = true;

        //         nds.push({
        //             series: r.map(m => {
        //                 const date = new Date(m.label);
        //                 return { name: new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), value: m.value };
        //             }),
        //             name: entryName,
        //             id: metricId,
        //             color: color.value
        //         });
        //         this.salesChart.options.customColors.push({
        //             name: entryName,
        //             value: color.value
        //         });
        //     }
        //     this.salesChart.data =  entryType === 'total' ? nds.filter(s => s.id === entryType) : [...nds];
        // });
    }


    removeSerie(serie: any) {
        const nds = this.salesChart.data.filter(s => s.id !== serie.id);
        this.salesChart.options.customColors = this.salesChart.options.customColors.filter(c => c.name !== serie.name);
        const color = this.serieColors.filter(c => c.value === serie.color)[0];
        color.used = false;
        this.salesChart.data = [...nds];
    }

    formatter = (x: { name: string }) => x.name;
}
