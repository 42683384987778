import { Component, Input } from '@angular/core';
import { ImportedFileError } from '@src/model/importedFileError';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { ImportedDataService } from '@src/services/importedData.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-imported-products-error-list',
    templateUrl: './imported-products-error-list.component.html'
})
export class ImportedProductsErrorComponent extends NgxDatatableBase {
    importedProductsFileErrors: ImportedFileError[] = [];
    fileId: number;
    onLoading = false;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        translateService: TranslateService) {
        super(translateService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];
                this.onLoading = true;
                this.importDataService.getProductLogErrors(this.fileId).subscribe(
                    errors => {
                        this.importedProductsFileErrors = errors.sort((a,b) => a.lineFile > b.lineFile ? 1 : -1);
                        this.onLoading = false;
                    }
                );
            }
        });
    }
}