import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';

@Component({
    selector: 'app-find-warranties-result',
    templateUrl: './find-warranty-result.component.html'
})
export class FindWarrantiesComponent {
    @Input() warranties: Warranty[];
    @Output() warrantySelected = new EventEmitter<Warranty>();

    selectWarranty(warranty: Warranty) {
        this.warrantySelected.emit(warranty);
    }
}