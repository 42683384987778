import { PrintResultComponent } from './steps/print-result/print-result';
import { FindProductitemsComponent } from '@src/base-components/qr-code-printing/steps/find-productitems/find-productitems.component';
import { WizardFindProductItemsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-product-items-result';
import { WarningMessageFindResultComponent } from '@src/base-components/wizard/find-product/warning-message-find-result.component';
import { PrintConfirmationComponent } from './steps/print-confirmation/print-confirmation.component';

export const QRCodePrintingComponents = [
    PrintConfirmationComponent,
    PrintResultComponent,
    FindProductitemsComponent,
    WizardFindProductItemsResultComponent,
    WarningMessageFindResultComponent
];