import { Output, EventEmitter, Input, Inject, Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { LayoutService } from '@src/services/layout/layout.service';
import { ConfigService } from '@src/services/layout/config.service';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
    selector: 'app-global-filter',
    templateUrl: './global-filter.component.html',
    styleUrls: ['./global-filter.component.scss']
})

export class GlobalFilterComponent {
    @Input() isOpen = true;
    @Input() titleFilter: string;
    @Input() descriptionFilter: string;
    @Input() secondFilter = false;
    @Output() toggleButtonOption = new EventEmitter<any>();
    buttonOption: Number = 1;

    @ViewChild('globalFilter') globalFilter: ElementRef;

    @HostListener('window:scroll', [])
    onWindowScroll() {
      const offset = this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      this.document.getElementById('globalFilter').style.setProperty('top', offset + 'px');
    }

    constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private translateService: TranslateService,) {}

    closeGlobalFilter() {
        this.renderer.removeClass(this.globalFilter.nativeElement, 'open');
        this.isOpen = false;
    }

    toggleGlobalFilter() {
        if (this.isOpen && this.buttonOption == 1) {
          this.renderer.removeClass(this.globalFilter.nativeElement, 'open');
          this.isOpen = false;
        } else {
          this.renderer.addClass(this.globalFilter.nativeElement, 'open');
          this.isOpen = true;
          this.buttonOption = 1;
          this.toggleButtonOption.emit(this.buttonOption);
        }
        this.descriptionFilter = this.translateService.instant("Components.Dashboard.description-filter-inventory");
      }

    toggleSecondGlobalFilter() {
      if (this.isOpen && this.buttonOption == 2) {
        this.renderer.removeClass(this.globalFilter.nativeElement, 'open');
        this.isOpen = false;
      } else {
        this.renderer.addClass(this.globalFilter.nativeElement, 'open');
        this.isOpen = true;
        this.buttonOption = 2;
        this.toggleButtonOption.emit(this.buttonOption);
      }
      this.descriptionFilter = this.translateService.instant("Components.Dashboard.description-inactive-inventory");
    }
}
