import { Component, OnInit } from '@angular/core';
import { ShipmentsService } from '@src/services/shipments/shipments.service';
import { ActivatedRoute } from '@angular/router';
import { Shipment } from '@src/model/shipment';
import { WarrantiesService } from '@src/services/warranty/warranties.service';

@Component({
    selector: 'app-shipment-details',
    templateUrl: './shipment-details.component.html'
})
export class ShipmentDetailsComponent implements OnInit {
    shipment: Shipment;

    constructor(
        private route: ActivatedRoute,
        private shipmentsService: ShipmentsService,
        private warrantiesService: WarrantiesService) {
    }

    ngOnInit() {
        this.loadShipment();
    }

    private loadShipment(): void {
        this.route.params.subscribe(params => {
            const shipmentId = +params['id'];

            if (shipmentId) {
                this.getShipment(shipmentId);
            }
        });
    }

    private getShipment(shipmentId: number) {
        this.shipmentsService.getShipment(shipmentId).subscribe(shipment => {
            if (shipment) {
                this.shipment = shipment;
                this.shipment.shipmentProductItems.map(productItem => {
                    return productItem;
                });
            }
        });
    }
}
