import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RepairType } from '@src/model/repairType';
import { RepairTypesService } from '@src/services/repairs/repairtypes.service';

@Component({
    selector: 'app-repair-select-type',
    templateUrl: './repair-select-type.html'
})
export class RepairSelectTypeComponent implements OnInit {
    parentRepairTypes: RepairType[];
    colorOptions = ['bg-primary', 'bg-success', 'bg-info', 'bg-teal'];

    @Output() selectedRepairType = new EventEmitter<RepairType>();

    constructor(
       private repairTypesService: RepairTypesService
    ) {}

    ngOnInit() {
        this.repairTypesService.getParentsRepairTypes().subscribe(data => {
            this.parentRepairTypes = data;
        })
    }

    setColorOptionByType(index: string) : string {
        return this.colorOptions[index];
    }

    setIconByRepairCode(code: string) : string {
        switch(code) {
            case 'Global':
                return 'ft-globe';
            case 'Partial':
                return 'icon-settings';
        }
    }

    setRepairType(repairType: RepairType) {
        this.selectedRepairType.emit(repairType);
    }
}
