import { Component, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-find-product-item-result',
    templateUrl: './find-product-item.component.html',
    styleUrls: ['./find-product-item.component.scss']
})
export class ProductItemResultComponent {
    @Input() productItem: ProductItem;
    chevronsIconbyOrientation: string;

    constructor(private configService: ConfigService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
            'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';
    }
}