import { Component, Input, ViewChild } from '@angular/core';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { WarrantyOperationType } from '@src/model/Enums/warrantyOperationType';
import { TranslateService } from '@ngx-translate/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';

@Component({
    selector: 'app-sale-details-operations',
    templateUrl: './sale-details-operations.component.html'
})

export class SaleDetailsOperationsComponent extends NgxDatatableBase {
    isCollapsed: boolean;
    pageSize: 10;
    WarrantyOperationType: typeof WarrantyOperationType = WarrantyOperationType;
    rows: WarrantyOperation[] = [];
    constructor(private translateService : TranslateService) {
        super(translateService);
    }
    @Input()
    set operations(ops: WarrantyOperation[]) {
        this.rows = this.sortOperationsByDate(ops);
    }
    sortOperationsByDate(ops: WarrantyOperation[]): WarrantyOperation[] {
        return ops.sort((a, b) => a.operationDate > b.operationDate ? 0 : 1);
    }
    hasExpandedDetails(row) {
        return row.operationType !== WarrantyOperationType.activation && row.operationType !== WarrantyOperationType.extension ;
    }
    hasSoldBydata(){
        if(this.rows.find(x=>x.soldBy === null || x.soldBy === ""))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

}
