export const importedproductserrors = [
    {
        'id': '1',
        'fileId': 31,
        'errorCode': '1003',
        'description': 'Some fields are missing',
        'lineFile': 4
    },
    {
        'id': '1',
        'fileId': 31,
        'errorCode': '1003',
        'description': 'Some fields are missing',
        'lineFile': 7
    },
    {
        'id': '1',
        'fileId': 31,
        'errorCode': '1005',
        'description': 'Some fields are missing',
        'lineFile': 15
    },
    {
        'id': '1',
        'fileId': 31,
        'errorCode': '1002',
        'description': 'Some fields are missing',
        'lineFile': 10
    },
];