import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Customer } from '@src/model/customer';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component(
    {
        selector: 'app-customer-info-details',
        templateUrl: './customer-info-details.component.html'
    })

export class CustomerInfoDetailsComponent implements OnChanges {
    @Input() customer: Customer;
    customerForm = this.fb.group({
        foreign: [''],
        accepted: [''],
        want: ['']
    });
    birthDay: string;
    birthMonth: string;
    birthYear: string;

    constructor(public fb: FormBuilder,
        protected translateService: TranslateService) {}

    ngOnChanges() {
        if (!this.customer) {
            return;
        }
        if (this.customer.birthDate) {
            const bD = moment.utc(this.customer.birthDate);
            this.birthDay = bD.date().toString();
            this.birthMonth = (bD.month() + 1).toString();
            this.birthYear = bD.year().toString();
            if(this.birthDay == '1' && this.birthMonth == '1' && this.birthYear == '1'){
                this.birthDay = '';
                this.birthMonth = '';
                this.birthYear = '';
            }
        }
        this.customerForm = this.fb.group({
            foreign: [this.customer.foreign.toString()],
            accepted: [this.customer.acceptedTerms.toString()],
            want: [this.customer.wantEmails.toString()]
        });
    }

    setCountry() : string {
        return this.customer.isoCountry != undefined ? this.translateService.instant('CountriesName.' + this.customer.isoCountry.toUpperCase()) : '';
    }
}