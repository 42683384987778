import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OperationResult } from '../../../model/operationresult';

import { BaseService } from '@src/services/base.service';

// Note: extended & overridden methods from the base service ONLY MEANT TO BE USED in the Warranty Certificate views
export class MessikaBaseService extends BaseService {
    // added _ prefix to variables and methods to rename/override the parent ones with the new logic
    private _oauthService: OAuthService;
    private _toastrManager: ToastrService;
    protected _translateService: TranslateService;

    constructor(injector: Injector) {
        super(injector);
        this._oauthService = injector.get<OAuthService>(OAuthService);
        this._translateService = injector.get<TranslateService>(TranslateService);
        this._toastrManager = injector.get<ToastrService>(ToastrService);
    }

    protected handleResponse<T>(response: Observable<OperationResult<T>>): Observable<T> {
        return response.pipe(
            catchError(this._handleError<OperationResult<T>>()),
            tap((r) => {
                if (r && !r.isSuccess && r.code) {
                    this._toastrManager.warning(this._translateService.instant('validation-errors.' + r.code) + ((r.messageData && r.messageData != null) ? r.messageData : ''));
                }
            }),
            map(data => data ? data.value : null));
    }

    private _handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // removed switch case from the parent method (base.service.ts) containing autorefresh

            console.debug(error); // log to console instead
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}