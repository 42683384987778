import { Component, Input } from '@angular/core';
import { ImportedProductsFileMetrics } from '@src/model/importedProductsFileMetrics';
import { ImportedDataService } from '@src/services/importedData.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-imported-products-metrics',
    templateUrl: './imported-products-metrics.component.html'
})
export class ImportedProductsMetricsComponent {
    metrics: ImportedProductsFileMetrics =  new ImportedProductsFileMetrics();
    fileId: number;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];
                this.importDataService.getProductLogMetrics(this.fileId).subscribe(
                    m=>this.metrics = m
                );
            }
        });
    }
}