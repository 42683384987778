import { Inventory } from "@src/model/inventory";
import { InventoryProductItem } from "@src/model/inventoryProductItem";
import { retailers } from "./retailers-data";

export const inventoryItems: InventoryProductItem[] = [{
    serialNumber: 'SN-123456',
    familyName: 'FAMILY-NAME',
    name: 'PRODUCT NAME DESCRIPTION',
    sku: 'SKU12345/789',
    prePairedWarrantyCode: '1234-5678-9012',
    isValidated: true,
    validationFailureReason: null,
    isRevised: false,
    hasBlockchainCertificate: false,
    createdBy : {
        firstName: 'John', lastName: 'Doe', firstNameRomanized: null, lastNameRomanized: null, id: '0', isActive: true, userName: 'mockuser',
        email: '', locale: 'en-EN', createdOn: new Date('2019-01-01'),
        createdById: null, updatedById: null, allowedChangePassword: false,
        roles: [], retailers: [], markets: []
    },
    createdOn: new Date('2019-01-05'),
}];

export const inventories: Inventory[] = [
    {
        id: 1,
        createdBy: {
            firstName: 'John', lastName: 'Doe', firstNameRomanized: null, lastNameRomanized: null, id: '0', isActive: true, userName: 'mockuser',
            email: '', locale: 'en-EN', createdOn: new Date('2019-01-01'), 
            createdById: null, 
            updatedById: null, allowedChangePassword: false,
            roles: [], retailers: [], markets: []
        },
        createdOn: new Date('2019-01-05'),
        inventoryProductItems: [
            inventoryItems[0],
        ],
        referenceNumber: 'RF_RET1512',
        inventoryName: 'this is the inventory name',
        comment: 'This is inventory of RET1512',
        retailerId: '3',
        inventoryCreationDate: new Date('2019-01-05'),
        isLast: true,
        retailer: {
            id: 3,
            code: 'RET1512',
            name: 'Châtelain SA',
            address: '10, rue du Pont',
            addressIndications: '',
            zip: '1003',
            city: 'Lausanne',
            isoCountry: 'CH',
            latitude: '46.521369',
            longitude: '6.6331421',
            isActive: true,
            hasCpo: false,
            market: null,
            status: null,
            cityNameRomanized: null,
            nameRomanized: null,
            locale: 'en',
            commercialGroupName: null,
        },
    }];
