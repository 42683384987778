export const UserRolesEnum = {
    GUEST: 'guest',
    RETAILER: 'retailer',
    MASTER_RETAILER: 'master retailer',
    AGENT: 'agent',
    ADMIN: 'admin',
    MARKET_ADMIN: 'market admin',
    SELLER: 'seller',
    GENERICSELLER: 'generic seller',
    REPAIR: 'repair',
    ANONYMOUS: 'anonymous'
};
export const UserRolesCollection = [
    UserRolesEnum.ADMIN,
    UserRolesEnum.MARKET_ADMIN,
    UserRolesEnum.AGENT,
    UserRolesEnum.REPAIR,
    UserRolesEnum.MASTER_RETAILER,
    UserRolesEnum.RETAILER,
    UserRolesEnum.SELLER,
    UserRolesEnum.GENERICSELLER,
    UserRolesEnum.GUEST
];
export const AllowedRolesCreationByRole = {
    'admin': UserRolesCollection,
    'market admin': [UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER, UserRolesEnum.SELLER,UserRolesEnum.GENERICSELLER, UserRolesEnum.GUEST],
    'master retailer': [UserRolesEnum.RETAILER, UserRolesEnum.SELLER, UserRolesEnum.GENERICSELLER],
    'agent': [UserRolesEnum.RETAILER, UserRolesEnum.MASTER_RETAILER],
    'repair': [],
    'retailer': [UserRolesEnum.SELLER, UserRolesEnum.GENERICSELLER],
    'seller': [UserRolesEnum.SELLER,UserRolesEnum.GENERICSELLER],
}

export const ReadOnlyRoles = [
    UserRolesEnum.GUEST,
    //UserRolesEnum.ADMIN
]
