import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Warranty } from '../../model/warranty/warranty';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { WarrantyUpdateWithComment } from '../../model/warranty/WarrantyUpdateWithComment';
import { PastWarrantyActivationSettings } from '@src/model/config/PastWarrantyActivationSettings';
import { FeatureSettings } from '../../model/config/FeatureSettings';
import { WarrantyReturnSettings } from '@src/model/config/WarrantyReturnSettings';
@Injectable()
export class WarrantiesService extends BaseService {
  private baseUrl = environment.apiBaseUrl + '/api/warranties';

  constructor(injector: Injector) {
    super(injector);
  }
  addWarranty(warranty: Warranty): Observable<Warranty> {
    const url = `${this.baseUrl}`;
    return this.post<Warranty>(url, warranty);
  }

  updateWarranty(warranty: Warranty): Observable<Warranty> {
    const url = `${this.baseUrl}`;
    return this.put<Warranty>(url, warranty);
  }

  updateWarrantyWithComment(warranty: WarrantyUpdateWithComment): Observable<Warranty> {
    const url = `${this.baseUrl}`;
    return this.put<Warranty>(url, warranty);
  }

  getWarrantiesBySerialNumber(serialNumber: string): Observable<Warranty[]> {
    const url = `${this.baseUrl}?serialNumber=${serialNumber}`;
    return this.get<Warranty[]>(url);
  }

  getWarranty(warrantyId: number): Observable<Warranty> {
    const url = `${this.baseUrl}/${warrantyId}`;
    return this.get<Warranty>(url);
  }

  getPastWarrantyActivationSettings(): Observable<PastWarrantyActivationSettings> {
    const url = `${this.baseUrl}/pastwarrantyactivationsettings`;
    return this.get<PastWarrantyActivationSettings>(url);
  }

  getWarrantyReturnSettings(): Observable<WarrantyReturnSettings> {
    return this.get<WarrantyReturnSettings>(`${this.baseUrl}/warrantyReturnSettings`);
  }
  
  getOverrideWarrantySettings(): Observable<FeatureSettings> {
    return this.get<FeatureSettings>(`${this.baseUrl}/overrideWarrantySettings`);
  }
  getCpoSettings(): Observable<FeatureSettings> {
    return this.get<FeatureSettings>(`${this.baseUrl}/cpoSettings`);
  }

}
