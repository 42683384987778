import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { Inventory } from '@src/model/inventory';
import { InventoryExport } from '@src/model/inventoryExport';
import { OperationResultErrorMessage } from '@src/model/operationResultErrorMessage';
import { InventoriesParameters } from '@src/model/filter-parameters/inventories-parameters';
import * as moment from 'moment';
import { ShipmentFromClient } from '@src/model/shipmentFromClient';
@Injectable()
export class InventoriesService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/inventories';

  constructor(injector: Injector) {
    super(injector);
  }

  queryInventories(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/queries`;
    return this.getWithHeader<Inventory[]>(`${url}?` +
      `${this.buildQueryParameters('queryTerm', queryTerm, )}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  filterInventories(inventoriesParameters: InventoriesParameters, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/filters`;
    return this.getWithHeader<Inventory[]>(`${url}?` +
      `${this.buildQueryParameters('store', inventoriesParameters.store, )}&` +
      `${this.buildQueryParameters('serialNumber', inventoriesParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('businessGroup', inventoriesParameters.businessGroup, )}&` +
      `${this.buildQueryParameters('city', inventoriesParameters.city, )}&` +
      `${this.buildQueryParameters('isLast', inventoriesParameters.last, )}&` +
      `${this.buildQueryParameters('from', inventoriesParameters.from == null ? null : moment(inventoriesParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', inventoriesParameters.to == null ? null : moment(inventoriesParameters.to).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getInventory(inventoryId: number): Observable<Inventory> {
    const url = `${this.baseUrl}/${inventoryId}`;  
    return this.get<Inventory>(url);
  }

  exportInventory(inventoryId: number): Observable<InventoryExport> {
    const url = `${this.baseUrl}/${inventoryId}/export`;
    return this.get<InventoryExport>(url);
  }

  addSerialNumber(retailerCode: string, inventoryId: number, serialNumberList: string[] ) : Observable<OperationResultErrorMessage>{    
    const url = `${this.baseUrl}/addSerialNumber?`+
    `${this.buildQueryParameters('inventoryId', inventoryId)}&` +
    `${this.buildQueryParameters('retailerCode', retailerCode)}`;
    return this.post<OperationResultErrorMessage>(url, serialNumberList);
  }
  deleteSerialNumber(retailerCode: string, inventoryId: number, serialNumberList: string[] ) : Observable<OperationResultErrorMessage>{    
    const url = `${this.baseUrl}/deleteSerialNumber?`+
    `${this.buildQueryParameters('inventoryId', inventoryId)}&` +
    `${this.buildQueryParameters('retailerCode', retailerCode)}`;
    return this.post<OperationResultErrorMessage>(url, serialNumberList);
  }
  
  revalidateInventory(referenceNumber: string): Observable<boolean> {
    const url = `${this.baseUrl}/revalidateInventory?` +
    `${this.buildQueryParameters('referenceNumber', referenceNumber)}`;
    return this.put<boolean>(url, null);
  }

  exportInventories(inventoriesParameters: InventoriesParameters): Observable<string> {
    const url = `${this.baseUrl}/export?` +
      `${this.buildQueryParameters('store', inventoriesParameters.store, )}&` +
      `${this.buildQueryParameters('serialNumber', inventoriesParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('city', inventoriesParameters.city, )}&` +
      `${this.buildQueryParameters('from', inventoriesParameters.from == null ? null : moment(inventoriesParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', inventoriesParameters.to == null ? null : moment(inventoriesParameters.to).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('isLast', inventoriesParameters.last, )}&` +
      `${this.buildQueryParameters('parentNotificationId', inventoriesParameters.parentNotificationId, )}`;

      return this.get<string>(url);
  }

  getShipmentItems(retailerCode : string) : Observable<ShipmentFromClient[]>{
    const url = `${this.baseUrl}/ShipmentsInTransit?retailerCode=${retailerCode}`;  
    return this.get<ShipmentFromClient[]>(url);
  }

  addInventoryShipmentItems(retailerCode : string) : Observable<ShipmentFromClient[]>{
    const url = `${this.baseUrl}/ShipmentsInTransit?retailerCode=${retailerCode}`;  
    return this.get<ShipmentFromClient[]>(url);
  }
}
