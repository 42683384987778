
export const retailers = [
    {
        'id': 1,
        'code': '1169',
        'name': 'Oris SA MyWatches',
        'address': 'Ribigasse 1',
        'addressIndications': '',
        'zip': '4434',
        'city': 'Hölstein',
        'isoCountry': 'CH',
        'latitude': '47.4242013',
        'longitude': '7.7692057',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 52.9197467',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 52.9197467'
     },
     {
        'id': 2,
        'code': '1511',
        'name': 'Oris SA',
        'address': 'Ribigasse 1',
        'addressIndications': '',
        'zip': '4434',
        'city': 'Hölstein',
        'isoCountry': 'CH',
        'latitude': '47.424084',
        'longitude': '7.769381',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.1221954',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.1221954'
     },
     {
        'id': 3,
        'code': '1512',
        'name': 'Châtelain SA',
        'address': '10, rue du Pont',
        'addressIndications': '',
        'zip': '1003',
        'city': 'Lausanne',
        'isoCountry': 'CH',
        'latitude': '46.521369',
        'longitude': '6.6331421',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.249264',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.249264'
     },
     {
        'id': 4,
        'code': '1513',
        'name': 'Guignard Sylvain',
        'address': 'Grand-Rue 80',
        'addressIndications': '',
        'zip': '1180',
        'city': 'Rolle',
        'isoCountry': 'CH',
        'latitude': '46.4570857',
        'longitude': '6.3372647',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.3618993',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.3618993'
     },
     {
        'id': 5,
        'code': '1514',
        'name': 'CADHOR S.A.',
        'address': '11, rue du Mont-Blanc',
        'addressIndications': '',
        'zip': '1201',
        'city': 'Genève',
        'isoCountry': 'CH',
        'latitude': '46.2084539',
        'longitude': '6.1445028',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.4803643',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.4803643'
     },
     {
        'id': 6,
        'code': '1515',
        'name': 'Bijouterie Bader',
        'address': '11, rue de la Confédération',
        'addressIndications': '',
        'zip': '1204',
        'city': 'Genève',
        'isoCountry': 'CH',
        'latitude': '46.203745',
        'longitude': '6.1449776',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.6063864',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.6063864'
     },
     {
        'id': 7,
        'code': '1516',
        'name': 'Rigacci Alex. & Cie.',
        'address': 'rue de la Confédération 4',
        'addressIndications': '',
        'zip': '1204',
        'city': 'Genève',
        'isoCountry': 'CH',
        'latitude': '46.2036616',
        'longitude': '6.1439819',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.7229219',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.7229219'
     },
     {
        'id': 8,
        'code': '1517',
        'name': 'PIGUET Horloger - Bijoutier',
        'address': '36 rue St-Jean',
        'addressIndications': '',
        'zip': '1260',
        'city': 'Nyon',
        'isoCountry': 'CH',
        'latitude': '46.38270493',
        'longitude': '6.239425434',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.835921',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.835921'
     },
     {
        'id': 9,
        'code': '1518',
        'name': 'Meylan Lionel SA',
        'address': '4, Place du marché',
        'addressIndications': '',
        'zip': '1800',
        'city': 'Vevey',
        'isoCountry': 'CH',
        'latitude': '46.45968207',
        'longitude': '6.842881787',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 53.9545347',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 53.9545347'
     },
     {
        'id': 10,
        'code': '1519',
        'name': 'Gaillard SA',
        'address': 'Grand Pont 9',
        'addressIndications': '',
        'zip': '1950',
        'city': 'Sion',
        'isoCountry': 'CH',
        'latitude': '46.2337267',
        'longitude': '7.3603748',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.0659633',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.0659633'
     },
     {
        'id': 11,
        'code': '1520',
        'name': 'KURZ Schmuck und Uhren',
        'address': 'Spitalgasse 38',
        'addressIndications': '',
        'zip': '3011',
        'city': 'Bern',
        'isoCountry': 'CH',
        'latitude': '46.9479335',
        'longitude': '7.4415474',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.1893982',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.1893982'
     },
     {
        'id': 12,
        'code': '1521',
        'name': 'Nicklès Daniel',
        'address': 'Kornhausplatz 14',
        'addressIndications': '',
        'zip': '3011',
        'city': 'Bern',
        'isoCountry': 'CH',
        'latitude': '46.948402',
        'longitude': '7.4471705',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.3130719',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.3130719'
     },
     {
        'id': 13,
        'code': '1522',
        'name': 'Skala Franz',
        'address': 'Kramgasse 14',
        'addressIndications': '',
        'zip': '3011',
        'city': 'Bern',
        'isoCountry': 'CH',
        'latitude': '46.9481227',
        'longitude': '7.4514789',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.4187049',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.4187049'
     },
     {
        'id': 14,
        'code': '1523',
        'name': 'Kocher Bijouterie GmbH',
        'address': 'Neue Bahnhofstrasse 3',
        'addressIndications': '',
        'zip': '3110',
        'city': 'Münsingen',
        'isoCountry': 'CH',
        'latitude': '46.8742971',
        'longitude': '7.5618845',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.5371945',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.5371945'
     },
     {
        'id': 15,
        'code': '1526',
        'name': 'Probst Uhren AG',
        'address': 'Bälliz 57',
        'addressIndications': '',
        'zip': '3601',
        'city': 'Thun',
        'isoCountry': 'CH',
        'latitude': '46.7578192',
        'longitude': '7.6286642',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.6546983',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.6546983'
     },
     {
        'id': 16,
        'code': '1527',
        'name': 'Kirchhofer AG',
        'address': 'Höheweg 73',
        'addressIndications': '',
        'zip': '3800',
        'city': 'Interlaken',
        'isoCountry': 'CH',
        'latitude': '46.687411',
        'longitude': '7.8594635',
        'isActive': true,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.7668975',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.7668975'
     },
     {
        'id': 17,
        'code': '1528',
        'name': 'Theodor Schertenleib',
        'address': 'Bahnhofstrasse 23c',
        'addressIndications': '',
        'zip': '3800',
        'city': 'Interlaken',
        'isoCountry': 'CH',
        'latitude': '46.6849213',
        'longitude': '7.8490927',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 54.8933053',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 54.8933053'
     },
     {
        'id': 18,
        'code': '1529',
        'name': 'Muther Georges',
        'address': 'Bahnhofstrasse',
        'addressIndications': '',
        'zip': '3920',
        'city': 'Zermatt',
        'isoCountry': 'CH',
        'latitude': '46.02154404',
        'longitude': '7.746975763',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 55.0031008',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 55.0031008'
     },
     {
        'id': 19,
        'code': '1530',
        'name': 'Foedinger Erich',
        'address': 'Bahnhofstrasse 19',
        'addressIndications': '',
        'zip': '3930',
        'city': 'Visp',
        'isoCountry': 'CH',
        'latitude': '46.2921007',
        'longitude': '7.8813512',
        'isActive': false,
        'marketId': 2,
        'email': 'fakeemail@b-i.com',
        'market': null,
        'createdById': '99999999-9999-9999-9999-999999999999',
        'createdBy': null,
        'createdOn': '2018-06-06T16: 59: 55.1058921',
        'updatedById': '99999999-9999-9999-9999-999999999999',
        'updatedBy': null,
        'updatedOn': '2018-06-06T16: 59: 55.1058921'
     }
];
