import { Component } from '@angular/core';
import { SaleDetailsComponent } from '@src/base-components/sales/sale-details/sale-details.component';
import { ModalReturnSaleMessikaComponent } from './return-sale/modal-return-sale.component';
import * as moment from 'moment';
import { ProductItem } from '@src/model/productItem';
import { UserRolesEnum } from '@src/model/userRoles';

@Component({
    selector: 'app-sale-details',
    templateUrl: '../../../../base-components/sales/sale-details/sale-details.component.html',
    styleUrls: ['../../../../base-components/sales/sale-details/sale-details.component.scss']
})

export class SaleDetailsMessikaComponent extends SaleDetailsComponent { 

    cancelSale() {
        const modalRef = this.modalService.open(ModalReturnSaleMessikaComponent, this.ngbModalOptions);
        modalRef.componentInstance.warranties =  this.warranties;
        modalRef.componentInstance.sale = this.sale;
        modalRef.componentInstance.operations = this.operations;
        modalRef.componentInstance.editEnabled = this.editEnabled;
        modalRef.componentInstance.returnSaleEnabled = this.returnSaleEnabled;
        modalRef.componentInstance.correctWarrantyEnabled = this.correctWarrantyEnabled;
        modalRef.componentInstance.refreshWarranties.subscribe((warranties) => {
            this.warranties = warranties;
        });
        modalRef.componentInstance.refreshReturnSaleButton.subscribe((returnSaleEnabled) => {
            this.returnSaleEnabled = returnSaleEnabled;
        });
    }

    getSaleDetails(saleId: number, isSearch: boolean): void {
        const currentUser = this.authService.getCurrentUserInstant();
        
        this.salesService.getSaleDetails(saleId, isSearch)
            .subscribe((sd) => {
                this.saleDetails = sd;

                this.saleDetails.warranty.isExpired = new Date() > new Date(this.saleDetails.warranty.warrantyEndDate);
                this.saleDetails.warranty.type = 'sale';
                this.saleDetails.warranty.seller = sd.warranty.seller;
                this.saleDetails.warranty.sellerId = sd.warranty.sellerId;
                this.saleDetails.warranty.retailer = sd.sale.retailer;
                this.saleDetails.warranty.retailerId = sd.sale.retailer.id;

                this.warranties = [this.saleDetails.warranty];
                this.saleWarranty = this.saleDetails.warranty;
                this.operations = sd.warranty.warrantyOperations;

                this.productItem = sd.productItem || new ProductItem();

                this.sale = sd.sale;
                this.sale.isReturned = sd.warranty.isReturned;
                this.isLoaded = true;
                this.correctWarrantyEnabled = this.editEnabled = !this.sale.isReturned;
                this.correctProductEnabled = this.editEnabled && !this.appSettings.PrePairedWarrantyCards;
                // if (currentUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN, UserRolesEnum.AGENT])) {
                if (currentUser.isInRoles([UserRolesEnum.ADMIN])) {
                    this.returnSaleEnabled = moment.utc(sd.warranty.warrantyActivationDate).add(this.appSettings.MaximumDaysAllowedForWarrantyReturnExtended+1, 'day').isAfter();
                }
                else{
                    this.returnSaleEnabled = moment.utc(sd.warranty.warrantyActivationDate).add(this.appSettings.MaximumDaysAllowedForWarrantyReturn+1, 'day').isAfter();
                }

                this.validateUserReturnButton();
            });
    }
    
}
