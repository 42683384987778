import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ImportedDataService } from '@src/services/importedData.service';
import { ImportedFile } from '@src/model/importedFile';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { DataTableHelpers } from '@src/shared/helpers/datatable-helper';

import { FileUploader } from 'ng2-file-upload';
import { environment } from '@src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { UploadStatus } from '@src/model/Enums/uploadStatus';
import { UploadType } from '@src/model/Enums/uploadType';
import { Router } from '@angular/router';
import { ImportedFileHelper } from '@src/base-components/management/utils/imported-file-helper';
import { FileValidationError } from '@src/model/fileValidationError';
import { fileValidationErrorType } from '@src/model/Enums/fileValidationErrorType';
import { ConfigService } from '@src/services/layout/config.service';
import { UserProfile } from '@src/auth/user-profile';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';


@Component({
    selector: 'app-imported-files-list',
    templateUrl: './imported-files-list.component.html',
    styleUrls: ['./imported-files-list.component.scss']
})
export class ImportedFilesListComponent extends NgxDatatableBase implements OnInit {
    firstLoad = false;
    isLoading = false;
    isMarketAdmin = false;
    datasource: ImportedFile[] = [];
    importedDataRows: ImportedFile[] = [];
    selected: ImportedFile[] = [];
    uploaderRetailers: FileUploader;
    uploaderNotSerializedProducts: FileUploader;
    uploaderSerializedProducts: FileUploader;
    dropdownClassByOrientation: string;
    hasNoSerializedProducts: boolean;
    disabledImportProduct: boolean;
    disabledImportRetailer: boolean;
    loggedUser: UserProfile;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private router: Router,
        private importedDataService: ImportedDataService,
        private toastrService: ToastrService,
        private oAuthservice: OAuthService,
        private translateService: TranslateService,
        private configService: ConfigService) {
        super(translateService);

        const layoutOrientation = this.configService.templateConf.layout.dir;
        this.dropdownClassByOrientation = layoutOrientation === 'rtl' ? 'dropdown-item pointer text-left' : 'dropdown-item pointer';
        this.hasNoSerializedProducts = this.configService.templateConf.noSerializedProducts;
    }

    ngOnInit(): void {
        this.getImportedData();
        this.loggedUser = this.authService.getCurrentUserInstant();
        if (this.loggedUser.role[0] === UserRolesEnum.MARKET_ADMIN) {
            this.isMarketAdmin = true;
            //return;
        }
        this.uploaderSerializedProducts = new FileUploader({
            removeAfterUpload: true,
            autoUpload: true,
            url: environment.apiBaseUrl + '/api/importeddata/products/serializedproducts'
        });
        this.uploaderSerializedProducts.onBeforeUploadItem = () => {
            this.uploaderSerializedProducts.authToken = 'Bearer ' + this.oAuthservice.getAccessToken();
        };

        this.uploaderSerializedProducts.onCompleteItem = (item, response) => {
            if (item.isSuccess) {
                const result = JSON.parse(response);
                if (result.isSuccess) {
                    const newImportedFile = Object.assign(new ImportedFile, result.value);
                    newImportedFile.type = 'p';
                    newImportedFile.uploadStatusStr = ImportedFileHelper.computeFileStatus(newImportedFile.uploadStatus);
                    newImportedFile.uploadTypeStr = UploadType[newImportedFile.uploadType];
                    this.datasource.unshift(newImportedFile);
                } else {
                    this.showError(result.messageData);
                }
            } else {
                this.toastrService.error('Upload error');
            }
        };

        this.uploaderNotSerializedProducts = new FileUploader({
            removeAfterUpload: true,
            autoUpload: true,
            url: environment.apiBaseUrl + '/api/importeddata/products/notserializedproducts'
        });
        this.uploaderNotSerializedProducts.onBeforeUploadItem = () => {
            this.uploaderNotSerializedProducts.authToken = 'Bearer ' + this.oAuthservice.getAccessToken();
        };

        this.uploaderNotSerializedProducts.onCompleteItem = (item, response) => {
            if (item.isSuccess) {
                const result = JSON.parse(response);
                if (result.isSuccess) {
                    const newImportedFile = Object.assign(new ImportedFile, result.value);
                    newImportedFile.type = 'p';
                    newImportedFile.uploadStatusStr = ImportedFileHelper.computeFileStatus(newImportedFile.uploadStatus);
                    newImportedFile.uploadTypeStr = UploadType[newImportedFile.uploadType];
                    this.datasource.unshift(newImportedFile);
                } else {
                    this.showError(result.messageData);
                }
            } else {
                this.toastrService.error('Upload error');
            }
        };

        this.uploaderRetailers = new FileUploader({
            removeAfterUpload: true,
            autoUpload: true,
            url: environment.apiBaseUrl + '/api/importeddata/retailers'
        });
        this.uploaderRetailers.onBeforeUploadItem = () => {
            this.uploaderRetailers.authToken = 'Bearer ' + this.oAuthservice.getAccessToken();
        };
        this.uploaderRetailers.onCompleteItem = (item, response) => {
            // this.uploaderRetailersInput.nativeElement.value = '';
            if (item.isSuccess) {
                const result = JSON.parse(response);
                if (result.isSuccess) {
                    const newImportedFile = Object.assign(new ImportedFile, result.value);
                    newImportedFile.type = 'r';
                    newImportedFile.uploadStatusStr = ImportedFileHelper.computeFileStatus(newImportedFile.uploadStatus);
                    newImportedFile.uploadTypeStr = UploadType[newImportedFile.uploadType];

                    this.datasource.unshift(newImportedFile);
                } else {
                    this.showError(result.messageData);
                }
            } else {
                this.toastrService.error('Upload error');
            }
        };
    }

    getImportedData() {
        this.importedDataService.getImportedData().subscribe(d => {
            const products = d.products,
                  retailers = d.retailers;

            products.forEach((p) => p.type = 'p');
            retailers.forEach((p) => p.type = 'r');

            const rows = products.concat(retailers).sort((a, b) =>
                new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime());
            rows.forEach((r) => r.uploadStatusStr = ImportedFileHelper.computeFileStatus(r.uploadStatus));
            rows.forEach((r) => r.uploadTypeStr = UploadType[r.uploadType]);
            this.importedDataRows = rows;
            if (!this.firstLoad) {
                this.datasource = rows;
            }

            this.disabledImportProduct = products.length > 0 ? products.find(p => p.uploadStatus === UploadStatus.inprogress) !== undefined : undefined;
            this.disabledImportRetailer = retailers.length > 0 ? retailers.find(p => p.uploadStatus === UploadStatus.inprogress) !== undefined : undefined;
            this.firstLoad = true;
            this.isLoading = false;
        });
    }

    onSort(event) {
        if (this.isLoading) {
            return;
        }
        const sort = event.sorts[0];
        if (sort.dir === 'asc') {
            this.importedDataRows = this.importedDataRows.sort((i, j) => i[sort.prop].toString() > j[sort.prop].toString() ? -1 : 1);
        }

        if (sort.dir === 'desc') {
            this.importedDataRows = this.importedDataRows.sort((i, j) => i[sort.prop].toString() < j[sort.prop].toString() ? -1 : 1);
        }
    }
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        this.importedDataRows = DataTableHelpers.FilterRows(val, this.datasource);
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    GoToLog(event: any) {
        const entry = event.selected[0];
        if (entry.type === 'r') {
            this.router.navigateByUrl(`management/retailerslogs/${entry.id}`);
        }
        if (entry.type === 'p') {
            this.router.navigateByUrl(`management/productslogs/${entry.id}`);
        }

    }

    translateError(error: FileValidationError): string {
        switch (error.ErrorType) {
            case fileValidationErrorType.invalidHeaders: {
                return this.translateService.instant('Components.Management.ValidationsErrors.invalid-headers');
            }
            case fileValidationErrorType.invalidValue: {
                return this.translateService.instant('Components.Management.ValidationsErrors.invalid-value', { columnName: error.ColumnName, line: error.Line });
            }
            case fileValidationErrorType.missingRequiredValue: {
                return this.translateService.instant('Components.Management.ValidationsErrors.required-value', { columnName: error.ColumnName, line: error.Line });
            }
            case fileValidationErrorType.outOfSafeLimitOfRows: {
                return this.translateService.instant('Components.Management.ValidationsErrors.out-of-limit-rows');
            }
            default: {
                return this.translateService.instant('Components.Management.ValidationsErrors.unkown-error');
            }
        }
    }

    getMessageBlockUpdate() : string {
        return this.translateService.instant('Components.Management.warning-to-block-upload-file');
    }

    showError(messageData: string): void {
        var error = JSON.parse(messageData) as FileValidationError;
        this.toastrService.error(this.translateError(error), "", { timeOut: 10000 });
    }
}
