import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../../model/customer';
import { CustomerDetails } from '../../model/customerDetails';
import { CustomerExport } from '@src/model/customerExport';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';

@Injectable()
export class CustomersService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/customers';
  constructor(injector: Injector) {
    super(injector);
  }
  queryCustomers(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<Customer[]>(`${url}/queries?` +
      `${this.buildQueryParameters('queryTerm', queryTerm, )}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getCustomerDetails(id: number): Observable<CustomerDetails> {
    const url = this.baseUrl;
    return this.get<CustomerDetails>(`${url}/${id}`);
  }

  exportCustomers(): Observable<CustomerExport> {
    const url = `${this.baseUrl}/export`;
    return this.get<CustomerExport>(url);
  }
}
