import { Component, Input, OnInit } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';
import { RepairTypesService } from '@src/services/repairs/repairtypes.service';
import { RepairType } from '@src/model/repairType';

@Component({
    selector: 'app-type-warranty',
    templateUrl: './type-warranty.component.html',
    styleUrls: ['./type-warranty.component.scss']
})
export class TypeWarrantyComponent implements OnInit {
    @Input() repairWarranty: RepairWarranty;
    repairType: RepairType;

    constructor(private repairTypesService: RepairTypesService) { }

    ngOnInit() {
        this.repairWarranty.isExpired = new Date() > new Date(this.repairWarranty.endDate);
        this.repairType = new RepairType();
        
        this.repairTypesService.getRepairTypeById(this.repairWarranty.repairTypeId).subscribe(repairType => {
            this.repairType = repairType;
        });
    }

}