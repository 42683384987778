import { Component, Inject, Input } from '@angular/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardDefaultFilterBaseComponent } from '../dashboard-default-filter-base.component';

@Component({
  selector: 'app-dashboard-default-new-filter',
  templateUrl: './dashboard-default-new-filter.component.html',
  styleUrls: ['./dashboard-default-new-filter.component.scss']
})

export class DashboardDefaultNewFilterComponent extends DashboardDefaultFilterBaseComponent {

  @Input() isLoadingExport: boolean;
  
  constructor(
    @Inject('AuthService') public authService: IAuthService,
    public retailersService: RetailersService,
    public productsService: ProductsService,
    public configService: ConfigService) {

      super(authService, retailersService, productsService, configService);
  }

}
