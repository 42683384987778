export const productitems = [
    {
        'id': 1,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9002',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img1.jpg'
        },
        'serialNumber': '00001',
        'isFake': true,
        'isUsed': true
     },
     {
        'id': 2,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9003',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img2.jpg'
        },
        'serialNumber': '00002',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 3,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9004',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00003',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 4,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9005',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00004',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 5,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9006',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00005',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 6,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9007',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00006',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 7,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9008',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00007',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 8,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9009',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00008',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 9,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9010',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00009',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 10,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00010',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 11,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img2.jpg'
        },
        'serialNumber': '00011',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 12,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00012',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 13,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img4.jpg'
        },
        'serialNumber': '00013',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 14,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00014',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 15,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00015',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 16,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00016',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 17,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00017',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 18,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00018',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 19,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00019',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 20,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00020',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 21,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00021',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 22,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00022',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 23,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00023',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 24,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00024',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 25,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00025',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 26,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00026',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 27,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00027',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 28,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00028',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 29,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00029',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 30,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00030',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 31,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00031',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 32,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00032',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 33,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00033',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 34,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00034',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 35,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00035',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 36,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00036',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 37,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00037',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 38,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00038',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 39,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00039',
        'isFake': false,
        'isUsed': false
     },
     {
        'id': 40,
        'product': {
           'id': 1,
           'name': 'High Jewellery',
           'sku': '820787-9001',
           'description': 'RING - UNIQUE PIECE',
           'productDate': '2017-06-19T13 :  47 :  00',
           'productFamilyId': 1,
           'productTypeId': 1,
           'isSerializable': true,
           'iscustomizable': false,
           'isWarrantyCardMandatory': false,
           'imageUrl': ' /assets/img/products/prod-img5.jpg'
        },
        'serialNumber': '00040',
        'isFake': false,
        'isUsed': false
     }
];