import { ReadOnlyRoles } from "@src/model/userRoles";

export class UserProfile {
    sid: string;
    sub: string;
    name: string;
    firstName: string;
    familyName: string;
    email: string;
    locale: string;
    role: string[];
    cnf: string;
    markets: string[];
    
    constructor(obj) {
        if (obj.role && !(obj.role instanceof Array)) {
            obj.role = [obj.role];
        }
        Object.assign(this, obj);
    }
    isInRole(roleToCheck: string ) {
        const hasRole = this.role.indexOf(roleToCheck) > -1;
        return hasRole;
    }
    isReadOnlyRole():boolean {
        return ReadOnlyRoles.filter(r=> r == this.role[0]).length > 0;
    }
    isInRoles(rolesToCheck: string[]) {
        if (!this.role || this.role.length === 0) {
            return false;
        }
        const hasRole = this.role.some(r => rolesToCheck.indexOf(r) > -1);
        return hasRole;
    }
    IsUsMarket(){
        if (!this.markets || this.markets.length === 0) {
            return false;
        }
        const isUs = this.markets.some(x => x === "US");
        return isUs;
    }
}
