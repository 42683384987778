import { Component, OnInit, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';

@Component({
  selector: 'app-print-confirmation',
  templateUrl: './print-confirmation.component.html',
  styleUrls: ['./print-confirmation.component.scss']
})
export class PrintConfirmationComponent implements OnInit {
  @Input() productItem: ProductItem;
  @Input() qrCodeUrl : string;
  @Input() LogoUrl : string;
  @Input() Title : string;

  title = 'print warranty';
  elementType = 'url';

  constructor() { 
   }

  ngOnInit() {
  }

}
