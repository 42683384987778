import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@src/model/user';
import { UsersService } from '@src/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Retailer } from '@src/model/retailer';
import { UserRolesEnum, AllowedRolesCreationByRole } from '@src/model/userRoles';
import { Role } from '@src/model/role';
import { IAuthService } from '@src/auth/auth.service.interface';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { UserProfile } from '@src/auth/user-profile';
import { ToastrService } from 'ngx-toastr';
import { MarketsService } from '@src/services/markets/markets.service';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-user-details',
        templateUrl: './user-details.component.html',
        styleUrls: ['./user-details.component.scss']
    })

export class UserDetailsComponent extends NgxDatatableBase implements OnInit {
    roles = [];
    markets = [];
    private user: User;
    userInEdition: User;
    inEdition = false;
    createdByUser: User;
    updatedByUser: User;
    validationErrors = [];
    addingNewRetailer = false;
    selectedRole = '';
    canAddRetailers = false;
    canAddMarkets = false;
    isSeller = false;
    allowEditUser = true;
    currentUser: UserProfile;
    canResetPassword = true;

    constructor(@Inject('AuthService')
    protected authService: IAuthService,
    protected route: ActivatedRoute,
    protected usersService: UsersService,
    protected translateService: TranslateService,
    protected toastrManager: ToastrService,
    protected marketsService: MarketsService,
    ) {
        super(translateService);
        this.onUserReceived = this.onUserReceived.bind(this);
        this.currentUser = authService.getCurrentUserInstant();
        this.roles = AllowedRolesCreationByRole[this.currentUser.role[0]];
        this.usersService.getRolesByUserRole(this.currentUser.role[0]).subscribe(newroles => {
            const allowedroles = newroles;
            this.roles.forEach((role, index) => {
                if (!allowedroles.find(x => x.name === role))
                {
                    this.roles.splice(index, 1);
                }
            });
        });
        if (this.currentUser.role[0] === (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER)) {
            this.isSeller = true;
        }
        if (this.currentUser.role[0] === UserRolesEnum.AGENT || this.currentUser.role[0] === UserRolesEnum.MASTER_RETAILER) {
            this.allowEditUser = false;
        }

        this.canUserResetPassword();
    }

    ngOnInit() {
        if (this.isSeller) {
            this.usersService.getUser(this.currentUser.sub).subscribe(this.onUserReceived);
        } else {
            this.route.params.subscribe(params => {
                this.usersService.getUser(params['id']).subscribe(this.onUserReceived);
            });
        }
    }

    canUserResetPassword() {
        this.usersService.getMyUser().subscribe(us => {
            if (us) {
                const role = us.roles && us.roles[0] ? us.roles[0].name : '';

                if (role === '') {
                    this.canResetPassword = false;
                }
                this.canResetPassword = role === UserRolesEnum.ADMIN || us.allowedChangePassword;
            }
        });
    }

    updateEdition(inEdition: boolean) {
        this.inEdition = inEdition;
    }

    canEditUser() : boolean {
        return this.userInEdition && !this.isSeller;
    }

    onUserReceived(user: User) {
       if (user) {
            this.selectedRole = user.roles[0].name;
            this.user = user;
            this.userInEdition = cloneDeep(this.user);
            // Get the createdbyId and updated by Id from the this.userInEdition
            // let createdById = this.userInEdition.createdById;
            // let updatedById = this.userInEdition.updatedById;
            // // Call get user(createdbyId)
            // if(createdById) {
            //     this.usersService.getUser(createdById).subscribe(x=>this.loadCreadeByUpdatedByUser(x,'createdBy'));
            // }
            // // Call get user(updatedbyId)
            // if(updatedById) {
            //     this.usersService.getUser(updatedById).subscribe(x=>this.loadCreadeByUpdatedByUser(x,'updatedBy'));
            // }
       }
    }

    // loadCreadeByUpdatedByUser(user: User, whatUser: string){
    //     if(user){
    //         if(whatUser == 'createdBy'){
    //             this.createdByUser = cloneDeep(user);
    //         }
    //         if(whatUser == 'updatedBy'){
    //             this.updatedByUser = cloneDeep(user);
    //         }
    //     }
    // }

    resetPassword() {
        this.usersService.resetUserPassword(this.user.userName).subscribe(resp => {
            if (resp != null) {
                this.toastrManager.info(this.translateService.instant('Components.UserDetails.confirm-user-reset-email'));
            }
        });
    }
    cancel() {
        this.inEdition = false;
        this.userInEdition = cloneDeep(this.user);
        this.selectedRole = this.user.roles[0].name;
    }

    getSelectedRole(role: string) {
        this.selectedRole = role;
    }

    updateUser() {
        this.validationErrors = [];
        if (this.selectedRole === '') {
            this.validationErrors.push('selectedrole');
        }
        if (this.selectedRole === UserRolesEnum.AGENT || this.selectedRole === UserRolesEnum.MARKET_ADMIN) {
            if (this.userInEdition.markets.length == 0) {
                this.validationErrors.push('selectedmarket');
            }
        }
        if (this.validationErrors.length > 0) {
            return;
        }

        this.userInEdition.roles = [new Role(this.selectedRole)];
        //clear out retailers if it's and agent
        if (this.selectedRole === UserRolesEnum.AGENT || this.selectedRole === UserRolesEnum.MARKET_ADMIN) {
            this.userInEdition.retailers = [];
        }
        else {
            //ensure marketId is null if not an agent
            this.userInEdition.markets = [];
        }

        this.usersService.updateUser(this.userInEdition.id, this.userInEdition)
            .subscribe(u => {
                this.onUserReceived(u);
                this.inEdition = false;
            });
    }
}
