import { Component, Input, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UsersService } from '@src/services/users/users.service';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-language-element',
  templateUrl: './language.element.component.html',
  styleUrls: ['../language.component.scss']
})

export class LanguageElementComponent {
    @Input() srcImage: string;
    @Input() languageName: string;
    @Input() language: string;

    constructor(
        public translate: TranslateService,
        @Inject('AuthService') private authService : IAuthService,
        private configService: ConfigService,
        private userService: UsersService) {
             
        }

    public isSelectedLanguage(language: string) : boolean {
        return localStorage.getItem('CurrentLanguage') === language;
    }



    OnChangeLanguage(language: string) {
        if (!language) {
            return;
        }
        localStorage.setItem('CurrentLanguage', language);
        this.translate.use(language);
        if(language == 'ar'){
            this.configService.placement = 'bottom-left';
            this.configService.setOrientationConfig('rtl');
        } else {
            this.configService.placement = 'bottom-right';
            this.configService.setOrientationConfig('ltr');
        }
        this.userService.updateMyLocale({locale: language}).subscribe( response => { });        
    }
}
