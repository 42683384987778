export const importedproducts = [
    {
        'id': 2,
        'fileName': 'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId': '99999999-9999-9999-9999-999999999999',
        'uploadType': 2,
        'uploadStatus': 4,
        'errorMsg': null,
        'createdById': '00000000-0000-0000-0000-000000000000',
        'createdBy': null,
        'createdOn': '2018-09-11T19:11:33.8217018',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-09-11T19:11:34.5662682',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id': 3,
        'fileName': 'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId': '99999999-9999-9999-9999-999999999999',
        'uploadType': 2,
        'uploadStatus': 3,
        'errorMsg': null,
        'createdById': '00000000-0000-0000-0000-000000000000',
        'createdBy': null,
        'createdOn': '2018-09-11T19:12:53.6292204',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-09-11T19:12:54.1536671',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id': 4,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:18:48.1195896',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:18:48.5605587',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },{
        'id':5,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:19:30.2820697',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:19:30.534342',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id':6,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:20:09.3665636',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:20:09.7138072',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id':7,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:23:57.682814',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:23:57.9169605',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id':8,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:25:03.9298207',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,'updatedOn':'2018-09-11T19:25:04.275507',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id':9,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:26:58.3815947',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:26:58.6427702',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    },
    {
        'id':10,
        'fileName':'Production_ORIS_20180208-080857.csv',
        'fileContent':'U2VyaWFsTnVtYmVyO1Byb2R1Y3RTa3U7UHJvZHVjdE5hbWU7UHJvZHVjdERlc2NyaXB0aW9uO1Byb2R1Y3RJbWFnZTtXYXJyYW50eUNhcmRDb2RlO1dhcnJhbnR5RHVyYXRpb247UHJvZHVjdGlvbkRhdGU7TGFzdFVwZGF0ZURhdGU7UHJvZHVjdEZhbWlseUNvZGU7UHJvZHVjdEZhbWlseU5hbWU7UHJvZHVjdEZhbWlseUNvZGUxO1Byb2R1Y3RGYW1pbHlOYW1lMTtQcm9kdWN0RmFtaWx5Q29kZTI7UHJvZHVjdEZhbWlseU5hbWUyDQowMzYxNzQ2MTswMSAxMTMgNzczOCA0MDMxLVNldCAxIDIzIDgzRkM7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMztPcmlzIEFydGVsaWVyLCA0My4wMCBtbSwgU3RhaW5sZXNzIHN0ZWVsO2h0dHBzOi8vd3d3Lm9yaXMuY2gvREFUQS8xOTk5Nl8wMSUyMDExMyUyMDc3MzglMjA0MDMxLXNldCUyMDElMjAyMyUyMDgzZmNfdmVydGljYWxlLnBuZzswMDAyMjU2OTg4NzU7MjsxMS4xMi4yMDE3IDA5OjE5OzExLjEyLjIwMTcgMDk6MTk7Y29sXzE1O09yaXMgQXJ0ZWxpZXI7d2F0Y2hpZF8yMTk7T3JpcyBBcnRlbGllciBDYWxpYnJlIDExMw0K',
        'userId':'99999999-9999-9999-9999-999999999999',
        'uploadType':2,
        'uploadStatus':3,
        'errorMsg':null,
        'createdById':'00000000-0000-0000-0000-000000000000',
        'createdBy':null,
        'createdOn':'2018-09-11T19:27:37.7134193',
        'updatedById':'00000000-0000-0000-0000-000000000000',
        'updatedBy':null,
        'updatedOn':'2018-09-11T19:27:37.988356',
        'user': {
            'createdOn': '2018-07-30T19:00:39.957',
            'email': 'was-system@b-i.com',
            'firstName': 'WAS',
            'id': '99999999-9999-9999-9999-999999999999',
            'imageUrl': null,
            'isActive': true,
            'lastName': 'System',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-09-21T16:49:53.761961',
            'retailers': [],
            'roles': [],
            'userName': 'was-system@b-i.com'
        }
    }
];