import { ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { TranslateService } from '@ngx-translate/core';

export class NgxDatatableBase {
    messages: {
        emptyMessage: string,
        totalMessage: string
    };
    constructor(translateService: TranslateService) {
        this.messages = {
            emptyMessage: translateService.instant('Components.Tables.emptyMessage'),
            totalMessage: translateService.instant('Components.Tables.totalMessage')
        };
    }
    @ViewChild(DatatableComponent) table: DatatableComponent;
    toggleExpandRow(e, row) {
        e.stopPropagation();
        this.table.rowDetail.toggleExpandRow(row);
    }
}