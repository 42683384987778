import { NavbarComponent } from './navbar.component';
import { GlobalSearchSaleComponent } from './global-search-sale/global-search-sale.component';
import { LanguageDropdownComponent } from './language/language.component';
import { LanguageElementComponent } from './language/element/language.element.component';
import { NotificationsDropdownComponent } from './notifications/notifications.component';
import { NotificationElementComponent } from './notifications/element/notification.element.component';


export const NavbarComponents = [
    NavbarComponent,
    GlobalSearchSaleComponent,
    LanguageDropdownComponent,
    LanguageElementComponent,
    NotificationsDropdownComponent,
    NotificationElementComponent
];
