
export class FiltersPages {

    constructor(dashboard?: boolean, sales?: boolean, retailers?: boolean, users?: boolean, products?: boolean) {
        this.dashboard = dashboard;
        this.sales = sales;
        this.retailers = retailers;
        this.users=users;
        this.products = products;
    }
    dashboard: boolean;
    sales: boolean;    
    retailers: boolean;
    users:boolean;
    products: boolean;
    cardOrderings: boolean;
}