import { ImportedFileDetailsComponent } from './imported-file-details/imported-file-details.component';
import { ImportedFilesListComponent } from './imported-files-list/imported-files-list.component';
import { ImportedProductsMetricsComponent } from './imported-products/imported-products-metrics/imported-products-metrics.component';
import { ImportedProductsEntriesComponent } from './imported-products/imported-products-entries/imported-products-entries.component';
import { ImportedProductsErrorComponent } from './imported-products/imported-products-error-list/imported-products-error-list.component';
import { ImportedProductsLogComponent } from './imported-products/imported-products-log.component';
import { ImportedRetailersMetricsComponent } from './imported-retailers/imported-retailers-metrics/imported-retailers-metrics.component';
import { ImportedRetailersEntriesComponent } from './imported-retailers/imported-retailers-entries/imported-retailers-entries.component';
import { ImportedRetailersErrorComponent } from './imported-retailers/imported-retailers-error-list/imported-retailers-error-list.component';
import { ImportedRetailersLogComponent } from './imported-retailers/imported-retailers-log.component';
import { ManagementComponent } from './management.component';
import { ImportedDownloadFileButtonComponent } from './utils/imported-download-file-button/imported-download-file-button.component';
import { ImportedMetricsElementComponent } from './utils/imported-metrics-element/imported-metrics-element.component';

export const ManagementComponents = [
        ImportedFileDetailsComponent,
        ImportedFilesListComponent,
        ImportedProductsMetricsComponent,
        ImportedProductsEntriesComponent,
        ImportedProductsErrorComponent,
        ImportedProductsLogComponent,
        ImportedRetailersMetricsComponent,
        ImportedRetailersEntriesComponent,
        ImportedRetailersErrorComponent,
        ImportedRetailersLogComponent,
        ImportedDownloadFileButtonComponent,
        ManagementComponent,
        ImportedMetricsElementComponent
    ];
