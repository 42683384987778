import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '@src/model/customer';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { AppSettings } from '@src/core/AppSettings';
import { PastWarrantyActivationSettings } from '@src/model/config/PastWarrantyActivationSettings';

@Component({
    selector: 'app-customer-creation',
    templateUrl: './activation-customer-creation.html',
    styleUrls:['./activation-customer-creation.scss']
})

export class ActivationCustomerCreationComponent implements OnChanges {
    customer: Customer = new Customer();
    countriesTranslation = this.translateService.instant('CountriesName');
    countries = [];
    genders = ['M', 'F', 'O'];
    maxFieldLengthFor = {
        'firstName': 50,
        'lastName': 50,
        'email': 255,
        'firstNameRomanized': 50,
        'lastNameRomanized': 50,
        'address': 250,
        'postalCode': 10
    };

    @Input() pastWarrantyActivationSettings: PastWarrantyActivationSettings;

    @Input() validationErrors = [];
    @Output() onCustomerDefined = new EventEmitter<Customer>();
    @Output() onCheckAcceptedTerms = new EventEmitter<boolean>();

    warningCheked = false;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    maxDateBirthDay: NgbDateStruct;
    minDateBirthDay: NgbDateStruct;
    startBirthDay: any = {year: 2000, month: 1, day: 1}

    constructor(private calendar: NgbCalendar,
        protected translateService: TranslateService,
        private fb: FormBuilder,
        private appSettings : AppSettings) {
        this.setPastActivationWarrantyDateLimits(appSettings);
        this.countries = Object.keys(this.countriesTranslation);
        var today = moment.utc().toDate();
        this.maxDateBirthDay = { day: today.getUTCDate(), month: (today.getUTCMonth() + 1), year: today.getUTCFullYear() };
        this.minDateBirthDay = { day: today.getUTCDate(), month: (today.getUTCMonth() + 1), year: today.getUTCFullYear() - 100 };
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pastWarrantyActivationSettings) {
            this.setPastActivationWarrantyDateLimits(this.appSettings);
        }
    }
    private setPastActivationWarrantyDateLimits(appSettings: AppSettings) {
        if (this.pastWarrantyActivationSettings
            && this.pastWarrantyActivationSettings.enabled) {
            appSettings.MaximumDaysForPastActivationDate = this.pastWarrantyActivationSettings.maximumDaysForPastActivationDate;
        }
        const date = new Date();
        const targetMaxDate = new Date();
        const targetMinDate = moment.utc().add(appSettings.MaximumDaysForPastActivationDate * -1, 'day').toDate();
        // Native date months start @ 0 index. NgbDateStruct starts @ 1.
        this.maxDate = { day: targetMaxDate.getUTCDate(), month: (targetMaxDate.getUTCMonth() + 1), year: targetMaxDate.getUTCFullYear() };
        // Allow activations to occur in the past for 1 month, according to the appsettings of the client.
        this.minDate = { day: targetMinDate.getUTCDate(), month: (targetMinDate.getUTCMonth() + 1), year: targetMinDate.getUTCFullYear() };
    }
    onDateSelection(date: NgbDate) {
        // Activation time part is not relevant. Create new UTC Date with the given Year, Month, Date to avoid Local to UTC conversion problems.
        const utcDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
        this.customer.birthDate = utcDate;
        this.startBirthDay = {year: date.year, month: date.month - 1, day: date.day}
    }

    acceptTermsChange(event){
        this.customer.acceptedTerms = event.target.checked;
    }

    setCountry(isoCountry: string) : string {
        return this.translateService.instant('CountriesName.' + isoCountry);
    }

    setGender(gender: string) : string {
        return this.translateService.instant('Labels.' + gender);
    }

    changeCustomer(event) {
        if (this.customer.acceptedTerms !== undefined && this.customer.acceptedTerms.toString() == 'true') {
            this.validationErrors = this.validationErrors.filter( error => error != 'acceptedTerms');
        }
        this.onCustomerDefined.emit(this.customer);
    }
    removeErrorMessage(fieldName: string, event) {
        const fieldValue = event.target.value;

        if (fieldValue && fieldValue.length > 0) {
            if (fieldName === 'email') {
                if (this.IsValidEmail(fieldValue)) {
                    this.validationErrors = this.validationErrors.filter(function (field) { return field !== fieldName; });
                }
            } else {
                this.validationErrors = this.validationErrors.filter(function (field) { return field !== fieldName; });
            }
        }
    }
    getInvalidErrorMessage(fieldName: string, fieldLength: number = 0): string {
        if (fieldName === '"email"') {
            if (!this.IsValidEmail(this.customer.email) && this.customer.email) {
                return this.translateService.instant('Components.UserDetails.Sections.invalid-email');
            }
        }

        const maxLength = this.maxFieldLengthFor[fieldName.replace(/"/g, '')];
        const errorMessage = fieldLength > maxLength ?
            this.translateService.instant('validation-errors.FieldValueLengthTooBig', { numberOfCharacters: maxLength}) :
            this.translateService.instant('Components.UserDetails.Sections.invalid-field', { fieldName: fieldName });
        return  errorMessage;
    }

    private IsValidEmail(email: string): boolean {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

}
