export const metrics = [
    {
        'value': 2257,
        'label': '2013-06-01T00:00:00.000Z'
    },
    {
        'value': 2492,
        'label': '2013-07-01T00:00:00.000Z'
    },
    {
        'value': 2504,
        'label': '2013-08-01T00:00:00.000Z'
    },
    {
        'value': 2609,
        'label': '2013-09-01T00:00:00.000Z'
    },
    {
        'value': 2552,
        'label': '2013-10-01T00:00:00.000Z'
    },
    {
        'value': 2598,
        'label': '2013-11-01T00:00:00.000Z'
    },
    {
        'value': 2570,
        'label': '2013-12-01T00:00:00.000Z'
    },
    {
        'value': 2578,
        'label': '2014-01-01T00:00:00.000Z'
    },
    {
        'value': 2613,
        'label': '2014-02-01T00:00:00.000Z'
    },
    {
        'value': 2577,
        'label': '2014-03-01T00:00:00.000Z'
    },
    {
        'value': 2585,
        'label': '2014-04-01T00:00:00.000Z'
    },
    {
        'value': 2541,
        'label': '2014-05-01T00:00:00.000Z'
    },
    {
        'value': 67915,
        'label': '2014-06-01T00:00:00.000Z'
    },
    {
        'value': 74883,
        'label': '2014-07-01T00:00:00.000Z'
    },
    {
        'value': 74775,
        'label': '2014-08-01T00:00:00.000Z'
    },
    {
        'value': 74598,
        'label': '2014-09-01T00:00:00.000Z'
    },
    {
        'value': 74232,
        'label': '2014-10-01T00:00:00.000Z'
    },
    {
        'value': 73924,
        'label': '2014-11-01T00:00:00.000Z'
    },
    {
        'value': 74424,
        'label': '2014-12-01T00:00:00.000Z'
    },
    {
        'value': 74411,
        'label': '2015-01-01T00:00:00.000Z'
    },
    {
        'value': 74553,
        'label': '2015-02-01T00:00:00.000Z'
    },
    {
        'value': 74481,
        'label': '2015-03-01T00:00:00.000Z'
    },
    {
        'value': 74325,
        'label': '2015-04-01T00:00:00.000Z'
    },
    {
        'value': 74372,
        'label': '2015-05-01T00:00:00.000Z'
    },
    {
        'value': 142708,
        'label': '2015-06-01T00:00:00.000Z'
    },
    {
        'value': 85471,
        'label': '2015-07-01T00:00:00.000Z'
    },
    {
        'value': 77217,
        'label': '2015-08-01T00:00:00.000Z'
    },
    {
        'value': 77093,
        'label': '2015-09-01T00:00:00.000Z'
    },
    {
        'value': 77039,
        'label': '2015-10-01T00:00:00.000Z'
    },
    {
        'value': 77097,
        'label': '2015-11-01T00:00:00.000Z'
    },
    {
        'value': 77118,
        'label': '2015-12-01T00:00:00.000Z'
    },
    {
        'value': 76948,
        'label': '2016-01-01T00:00:00.000Z'
    },
    {
        'value': 76876,
        'label': '2016-02-01T00:00:00.000Z'
    },
    {
        'value': 76542,
        'label': '2016-03-01T00:00:00.000Z'
    },
    {
        'value': 76648,
        'label': '2016-04-01T00:00:00.000Z'
    },
    {
        'value': 77022,
        'label': '2016-05-01T00:00:00.000Z'
    },
    {
        'value': 144484,
        'label': '2016-06-01T00:00:00.000Z'
    },
    {
        'value': 86124,
        'label': '2016-07-01T00:00:00.000Z'
    },
    {
        'value': 77004,
        'label': '2016-08-01T00:00:00.000Z'
    },
    {
        'value': 76540,
        'label': '2016-09-01T00:00:00.000Z'
    },
    {
        'value': 76782,
        'label': '2016-10-01T00:00:00.000Z'
    },
    {
        'value': 76801,
        'label': '2016-11-01T00:00:00.000Z'
    },
    {
        'value': 76779,
        'label': '2016-12-01T00:00:00.000Z'
    },
    {
        'value': 77152,
        'label': '2017-01-01T00:00:00.000Z'
    },
    {
        'value': 76911,
        'label': '2017-02-01T00:00:00.000Z'
    },
    {
        'value': 76845,
        'label': '2017-03-01T00:00:00.000Z'
    },
    {
        'value': 77142,
        'label': '2017-04-01T00:00:00.000Z'
    },
    {
        'value': 77019,
        'label': '2017-05-01T00:00:00.000Z'
    },
    {
        'value': 142282,
        'label': '2017-06-01T00:00:00.000Z'
    },
    {
        'value': 83778,
        'label': '2017-07-01T00:00:00.000Z'
    },
    {
        'value': 74387,
        'label': '2017-08-01T00:00:00.000Z'
    },
    {
        'value': 74467,
        'label': '2017-09-01T00:00:00.000Z'
    },
    {
        'value': 74207,
        'label': '2017-10-01T00:00:00.000Z'
    },
    {
        'value': 74463,
        'label': '2017-11-01T00:00:00.000Z'
    },
    {
        'value': 74599,
        'label': '2017-12-01T00:00:00.000Z'
    },
    {
        'value': 74377,
        'label': '2018-01-01T00:00:00.000Z'
    },
    {
        'value': 73835,
        'label': '2018-02-01T00:00:00.000Z'
    },
    {
        'value': 74104,
        'label': '2018-03-01T00:00:00.000Z'
    },
    {
        'value': 74616,
        'label': '2018-04-01T00:00:00.000Z'
    },
    {
        'value': 74271,
        'label': '2018-05-01T00:00:00.000Z'
    },
    {
        'value': 76411,
        'label': '2018-06-01T00:00:00.000Z'
    },
    {
        'value': 11006,
        'label': '2018-07-01T00:00:00.000Z'
    },
    {
        'value': 2575,
        'label': '2018-08-01T00:00:00.000Z'
    },
    {
        'value': 2582,
        'label': '2018-09-01T00:00:00.000Z'
    },
    {
        'value': 2575,
        'label': '2018-10-01T00:00:00.000Z'
    },
    {
        'value': 2545,
        'label': '2018-11-01T00:00:00.000Z'
    },
    {
        'value': 2580,
        'label': '2018-12-01T00:00:00.000Z'
    },
    {
        'value': 2522,
        'label': '2019-01-01T00:00:00.000Z'
    },
    {
        'value': 2570,
        'label': '2019-02-01T00:00:00.000Z'
    },
    {
        'value': 2544,
        'label': '2019-03-01T00:00:00.000Z'
    },
    {
        'value': 2518,
        'label': '2019-04-01T00:00:00.000Z'
    },
    {
        'value': 2600,
        'label': '2019-05-01T00:00:00.000Z'
    },
    {
        'value': 2555,
        'label': '2019-06-01T00:00:00.000Z'
    },
    {
        'value': 300,
        'label': '2019-07-01T00:00:00.000Z'
    }
];