import { Component, Input, OnChanges } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { ConfigService } from '@src/services/layout/config.service';
import { ProductsService } from '@src/services/products/products.service';
import { WizardFindProductItemsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-product-items-result';

@Component({
    selector: 'app-wizard-find-product-items-result-messika',
    templateUrl: './wizard-find-product-items-result.html',
    styleUrls: ['../../../../base-components/wizard/find-product/wizard-find-product-items-result.scss']
})
export class WizardFindProductItemsResultComponentMessika extends WizardFindProductItemsResultComponent {//implements OnChanges {
   
    constructor(configService: ConfigService, public productsService: ProductsService) {
        super(configService)
    }
}