import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WizardSelectTypeComponent } from '@src/base-components/wizard/find-product/wizard-select-type';
import { ProductType } from '@src/model/Enums/productType';

@Component({
    selector: 'app-activation-select-type',
    templateUrl: './activation-select-type.html'
})
export class ActivationSelectTypeComponent {
    @Input() selectedType: ProductType = ProductType.serialized;
    @Output() onselectProductType = new EventEmitter<number>();
    @ViewChild(WizardSelectTypeComponent) wizardSelectTypeComponent: WizardSelectTypeComponent;

    selectProductType(productType: ProductType) {
        this.onselectProductType.emit(productType);
    }
    reset() {
        this.wizardSelectTypeComponent.reset();
    }
}
