import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductItem } from '@src/model/productItem';
import { ProductsService } from '@src/services/products/products.service';
import {Location} from '@angular/common';
import { FeatureSettings } from '@src/model/config/FeatureSettings';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  isCollapsed: boolean;
  disabledCpo: boolean = false;
  cpoStatus: boolean = false;
  @Input() productItem = new ProductItem();
  cpoSettings: FeatureSettings = new FeatureSettings();
  WarrantyDurations: number[];
  selectWarrantyDuration:number = null; 
  validationError: boolean = false;
  constructor(private route: ActivatedRoute, private location : Location, private productService: ProductsService,
    private warrantiesService: WarrantiesService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const Id = +params['id'];
      if (Id) {
        this.productService.getProductItemById(Id).subscribe((productItem) => {
          this.productItem  = productItem;
          if(this.productItem){
            this.cpoStatus = this.productItem.isCpo;
            this.selectWarrantyDuration = this.productItem.warrantyDurationMonths;
            if(this.productItem.isUsed){
              this.disabledCpo = true;
            }
          }
        });
      }
      this.getCpoSetting();
    });
  }

  getCpoSetting() {
    this.warrantiesService.getCpoSettings()
    .subscribe(r =>{
        this.cpoSettings = r;
        if(this.cpoSettings != null && this.cpoSettings.enabled){
          this.WarrantyDurations = this.cpoSettings.allowedDurations;  
        }  
    });
  }

  warrantyDurationChanged(){
    this.validationError = false;
  }

  onChangeCpoStatus(event) {
    this.cpoStatus = event;
    if(!this.cpoStatus) {
      this.validationError = false;
      this.selectWarrantyDuration = null;
    }
 }

 save(){
  if(this.cpoStatus && this.selectWarrantyDuration == null){
    return this.validationError = true;
  } else {
    this.validationError = false;
    this.productService.updateProductItem(this.productItem.id, this.cpoStatus, this.selectWarrantyDuration).subscribe((productItem) => {
      if(productItem)  this.location.back();            
    });
  }
 }

}
