import { InventoriesComponent } from "./inventories.component";
import { InventoryDetailsComponent } from "./inventory-details/inventory-details.component";
import { InventoryDetailsItemsTableComponent } from "./inventory-details/items-table/inventory-details-items-table.component";
import { InventoryDetailsInfoComponent } from "./inventory-details/info/inventory-details-info.component";
import { InventoriesFilterComponent } from "./filter/inventories-filter.component";
import { ShipmentSerialNumberItemsTableComponent } from "./inventory-details/items-table/shipment-serial-number/shipment-serial-number.component";

export const InventoriesComponents = [
    InventoriesComponent,
    InventoryDetailsComponent,
    InventoryDetailsItemsTableComponent,
    InventoryDetailsInfoComponent,
    InventoriesFilterComponent
];
