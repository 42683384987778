import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SimplePagerComponent } from './tables/simple-pager.component';
import { SimplePagerChartTableComponent } from './tables/simple-pager-chart-table.component';
import { TypedButtonComponent } from './buttons/typed-button.component';
import { SanitizeHtmlPipe } from './directives/sanitize-html-pipe';
import { LoadingComponent } from './loading/loading.component';
import { EmptyMessageComponent } from './empty-message/empty-message.component';
import { NavbarComponents } from './navbar/navbar.container';
import { GlobalFilterComponent } from './global-filter/global-filter.component';
import { MessagesComponents } from './alert-message/messages.container';
import { LayoutService } from '@src/services/layout/layout.service';
import { ConfigService } from '@src/services/layout/config.service';
import { NotificationsService } from '@src/services/notifications/notifications.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        ...NavbarComponents,
        SidebarComponent,
        TypedButtonComponent,
        ...MessagesComponents,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SanitizeHtmlPipe,
        SimplePagerComponent,
        SimplePagerChartTableComponent,
        NgbModule,
        TranslateModule,
        LoadingComponent,
        EmptyMessageComponent,
        GlobalFilterComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        PerfectScrollbarModule
    ],
    declarations: [
        FooterComponent,
        ...NavbarComponents,
        SidebarComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SanitizeHtmlPipe,
        SimplePagerComponent,
        SimplePagerChartTableComponent,
        TypedButtonComponent,
        ...MessagesComponents,
        LoadingComponent,
        EmptyMessageComponent,
        GlobalFilterComponent
    ],
    providers: [
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                LayoutService,
                ConfigService,
                NotificationsService
            ],
        };
    }
}
