import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { EnvironmentNames } from '../environments/environments.name';
import { OperationResult } from '../model/operationresult';

export class BaseService {

    protected httpAuthHeaders: {};
    protected httpOptions: {};
    protected http: HttpClient;
    private oauthService: OAuthService;
    private toastrManager: ToastrService;
    protected translateService: TranslateService;

    constructor(injector: Injector) {
        this.http = injector.get<HttpClient>(HttpClient);

        this.oauthService = injector.get<OAuthService>(OAuthService);
        this.translateService = injector.get<TranslateService>(TranslateService);
        this.toastrManager = injector.get<ToastrService>(ToastrService);
    }
    // Access token chan change over time, this needs to be called on each service call
    protected setupHttpOptions() {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this
                    .oauthService
                    .getAccessToken()
            })
        };
    }

    protected setupHttpOptionsWithHeaders() {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this
                    .oauthService
                    .getAccessToken()
            }), withCredentials: true,
            observe: 'response'
        };
    }

    protected setupAuthHeaders() {
        this.httpAuthHeaders = {
            headers: {
                'Authorization': 'Bearer ' + this.oauthService.getAccessToken()
            }
        };
    }

    protected buildQueryParameters(parameterName: string, parameterValue: any): string {
        if (parameterValue == null || parameterValue === '' || !parameterName || parameterName === '') {
            return '';
        }
        return environment.envName === EnvironmentNames.mock ? `${parameterName}=^${parameterValue}$` : `${parameterName}=${parameterValue}`;
    }

    protected get<T>(url: string, options?: object): Observable<T> {
        this.setupHttpOptions();
        return this.handleResponse(this.http.get<OperationResult<T>>(url, options || this.httpOptions));
    }

    protected getWithHeader<T>(url: string, options?: object): Observable<T> {
        this.setupHttpOptionsWithHeaders();
        return this.handleHeaderResponse(this.http.get<T>(url, options || this.httpOptions));
    }

    protected getMapboxAddesses<T>(url: string, options?: object): Observable<T> {
        return this.handleMapboxAddessesResponse(this.http.get<any>(url));
    }

    protected post<T>(url: string, body: any, options?: object): Observable<T> {
        this.setupHttpOptions();
        return this.handleResponse(this.http.post<OperationResult<T>>(url, body, options || this.httpOptions));
    }

    protected put<T>(url: string, body: any, options?: object): Observable<T> {
        this.setupHttpOptions();
        return this.handleResponse(this.http.put<OperationResult<T>>(url, body, options || this.httpOptions));
    }
    protected delete<T>(url: string, options?: object): Observable<T> {
        this.setupHttpOptions();
        return this.handleResponse(this.http.delete<OperationResult<T>>(url, options || this.httpOptions));
    }

    protected handleResponse<T>(response: Observable<OperationResult<T>>): Observable<T> {
        return response.pipe(
            catchError(this.handleError<OperationResult<T>>()),
            tap((r) => {
                if (r && !r.isSuccess && r.code) {
                    this.toastrManager.warning(this.translateService.instant('validation-errors.' + r.code) + ((r.messageData && r.messageData != null) ? r.messageData : ''));
                }
            }),
            map(data => data ? data.value : null));
    }

    protected handleHeaderResponse<T>(response: Observable<T>): Observable<T> {
        return response;
    }

    protected handleMapboxAddessesResponse<T>(response: Observable<T>): Observable<T> {
        return response.pipe(
            catchError(this.handleError<T>()),
            map(data => data));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // catch cors error when trying to get notifications data when the session has expired
            error.status = (error.status === 0
                && (!this.oauthService.hasValidIdToken()
                || !this.oauthService.hasValidAccessToken())) ? 4011 : error.status;

            switch (error.status) {
                case (404):
                    {
                        // Return 404 as an empty result;
                        this
                            .toastrManager
                            .warning(this.translateService.instant('ServiceHttpErrorCodes.404'));
                        break;
                    }
                case (401):
                    {
                        this
                            .toastrManager
                            .warning(this.translateService.instant('ServiceHttpErrorCodes.401'));
                            this.reloardAfter3Seconds();
                        break;
                    }
                case (4011):
                    {
                        this
                            .toastrManager
                            .warning(this.translateService.instant('ServiceHttpErrorCodes.4011'));
                            this.reloardAfter3Seconds();
                        break;
                    }
                case (403):
                    {
                        this
                            .toastrManager
                            .warning(this.translateService.instant('ServiceHttpErrorCodes.403'));
                        break;
                    }
                case (400):
                    {
                        this.toastrManager.warning(this.translateService.instant(error.error.value[0]));
                        break;
                    }
                default:
                    {
                        this
                            .toastrManager
                            .error(this.translateService.instant('ServiceHttpErrorCodes.Default'));
                        break;
                    }
            }
            console.debug(error); // log to console instead
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
    private reloardAfter3Seconds() {
        setTimeout(function() { location.reload(); }, 3000);
    }



}
