import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-imported-download-file-button',
    templateUrl: './imported-download-file-button.component.html'
})
export class ImportedDownloadFileButtonComponent {

    @Output() clickEvent = new EventEmitter();

    click(): void {
        this.clickEvent.emit();
    }
}