import { Component, Input, OnInit } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';

@Component({
    selector: 'app-type-warranties',
    templateUrl: './type-warranties.component.html',
    styleUrls: ['./type-warranties.component.scss']
})
export class TypeWarrantiesComponent implements OnInit {
    @Input() repairWarranties: RepairWarranty[];

    ngOnInit() {
        if (this.repairWarranties == null) {
            this.repairWarranties = [];
        }
    }
}