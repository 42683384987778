import { RetailersComponent } from './retailers.component';
import { RetailerDetailsComponent } from './retailer-details/retailer-details.component';
import { RetailerDetailsDashboardComponent } from './retailer-details/retailer-details-dashboard/retailer-details-dashboard.component';
import { RetailerDetailsInfoComponent } from './retailer-details/retailer-details-info/retailer-details-info.component';
import { RetailerDetailsLocationComponent } from './retailer-details/retailers-details-location/retailer-details-location.component';
import { RetailerDetailsUsersComponent } from './retailer-details/retailers-details-users/retailer-details-users.component';
import { RetailersFilterComponent } from './filter/retailers-filter.component';

export const RetailersComponents = [
    RetailersComponent,
    RetailerDetailsDashboardComponent,
    RetailerDetailsComponent,
    RetailerDetailsDashboardComponent,
    RetailerDetailsInfoComponent,
    RetailerDetailsLocationComponent,
    RetailerDetailsUsersComponent,
    RetailersFilterComponent
];