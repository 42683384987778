import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { WarrantyCard } from '../../model/warranty/warrantycard';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';

@Injectable()
export class WarrantycardsService extends BaseService {
  private baseUrl = environment.apiBaseUrl + '/api/warrantycards';
  private PrePaired = environment.apiBaseUrl + '/api/warrantycards/prepaired'

  constructor(injector: Injector) {
    super(injector);
  }

  getWarrantyCardByCode(code: string): Observable<WarrantyCard> {
    const url = `${this.baseUrl}/${code}`;
    return this.get<WarrantyCard>(url);
  }

  GetPrePaired(itemCode: string): Observable<WarrantyCard[]> {
    const url = `${this.PrePaired}/${itemCode}`;
    return this.get<WarrantyCard[]>(url);
  }
}
