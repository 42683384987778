import { Component, Input, EventEmitter, Inject, OnChanges, Output } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';
import { Warranty } from '@src/model/warranty/warranty';
import { RepairsService } from '@src/services/repairs/repairs.service';
import { RepairTypesService } from '@src/services/repairs/repairtypes.service';
import { AppSettings } from '@src/core/AppSettings';
import * as moment from 'moment';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';

@Component({
    selector: 'app-repair-activation',
    templateUrl: './repair-activation.component.html'
})
export class RepairActivationComponent implements OnChanges {
    @Input() repairWarranty: RepairWarranty;
    @Input() selectedWarranty: Warranty;

    @Output() createdRepairWarranty = new EventEmitter<RepairWarranty>();

    repairTypes = [];
    calendarModel: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    loggedUser: UserProfile;
    comments: string;
    hasSubTypeSelectedId: boolean;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private repairServices: RepairsService,
        private repairTypeService: RepairTypesService,
        private appSettings: AppSettings,
        private calendar: NgbCalendar ) {
            const targetMaxDate = new Date();
            // Native date months start @ 0 index. NgbDateStruct starts @ 1.
            this.maxDate = { day: targetMaxDate.getUTCDate(), month: (targetMaxDate.getUTCMonth() + 1), year: targetMaxDate.getUTCFullYear() };
            // Allow activations to occur in the past for 1 month.
        }

    ngOnChanges() {
        if (this.repairWarranty.repairTypeId !== undefined) {
            this.repairTypeService.getChildrenRepairType(this.repairWarranty.repairTypeId).subscribe(data => {
                if (data.length > 0) {
                    this.repairTypes = data;
                }
            });
        }
        // Change the minimum date according to warranty activation date
        const targetMinDate = new Date(this.selectedWarranty.warrantyActivationDate);
        this.minDate = { day: targetMinDate.getUTCDate(), month: (targetMinDate.getUTCMonth() + 1), year: targetMinDate.getUTCFullYear() };

        this.loggedUser = this.authService.getCurrentUserInstant();
        if (this.loggedUser !== undefined) {
            this.repairWarranty.sellerId = this.loggedUser.sub;
        }
        this.calendarModel = this.calendar.getToday();
    }

    selectRepairType(repairTypeId: number) {
      this.repairWarranty.repairTypeId = repairTypeId;
      this.hasSubTypeSelectedId = true;
      this.repairTypeService.getRepairTypeById(this.repairWarranty.repairTypeId).subscribe(data=> {
        this.repairWarranty.repairType = data;
    });
    }

    onDateSelection(date: NgbDateStruct) {
        // Activation time part is not relevant. Create new UTC Date with the given Year, Month, Date to avoid Local to UTC conversion problems.
        const utcDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
        this.repairWarranty.startDate = utcDate;
    }

    reset() {
        this.repairTypes = [];
        this.comments = '';
    }

    createRepairWarranty(event) {
        if (event.detail > 1) {
            return;
        }

        this.repairWarranty.comments = this.comments;
        if (this.repairWarranty.startDate == null) {
            const today = new Date();
            const utcDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours() - 1));
            this.repairWarranty.startDate = utcDate;
        }
        if (this.repairWarranty.repairTypeId == null || this.repairWarranty.repairType.code=="Partial") {
            this.hasSubTypeSelectedId = false;
            return;
        }
      
        this.repairWarranty.repairType=null;
        this.repairServices.addRepairWarranty(this.repairWarranty).subscribe(data => {
            this.createdRepairWarranty.emit(data);
        });
    }
}