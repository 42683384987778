import { Component, Output, EventEmitter } from '@angular/core';
import { UsersService } from '@src/services/users/users.service';
import { Subject } from 'rxjs';
import { User } from '@src/model/user';

@Component(
    {
        selector: 'app-find-user-username',
        templateUrl: './find-user-username.component.html',
        styleUrls: ['./find-user-username.component.scss']
    })

export class FindUserByUsernameComponent {
    userName = '';
    userNameConfirmed;
    requiresValidation = true;
    isValidating = false;

    @Output() userNameResolved = new EventEmitter<User>();
    @Output() cancel: EventEmitter<any> = new EventEmitter();

    constructor(
        private usersService: UsersService) {
    }

    onKeyUp(e) {
        const textValue = e.target.value.trim();
        if (textValue.length > 3) {
            this.requiresValidation = true;
        }
    }
    getStatusIconClass() {
        if (this.requiresValidation === true) {
            if (this.isValidating) {
                return 'spinner ft-refresh-cw';
            } else {
                return 'ft-user-plus';
            }
        } else if (this.userNameConfirmed) {
            return 'ft-check';
        } else {
            return 'ft-alert-circle';
        }

    }
    onButtonClick(e) {
        if (this.userName.length === 0 || this.isValidating) {
            return;
        }
        this.isValidating = true;
        this.usersService.findSellerIdByUserName(this.userName.trim()).subscribe( userId => {
            this.requiresValidation = false;
            this.userNameConfirmed = userId != null;
            this.isValidating = false;

            if (this.userNameConfirmed) {
                //Done
                const u = new User();
                u.id = userId;
                u.userName = this.userName;
                this.userNameResolved.emit(u);
            }
        });
    }
    onCancel() {
        this.cancel.emit();
    }
}