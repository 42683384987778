import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { WizardFindSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-serializable-product';
import { User } from '@src/model/user';


@Component({
    selector: 'app-correction-confirmation',
    templateUrl: './sale-details-correction-confirmation.component.html',
    styleUrls: ['./sale-details-correction-confirmation.component.scss']
})
export class SaleDetailsCorrectionConfirmationComponent {

    @ViewChild(WizardFindSerializableProductComponent) wizardFindProductComponent: WizardFindSerializableProductComponent;
    @Input() productItem: ProductItem;
    @Input() sellers: User[];
    @Output() onSelectSeller = new EventEmitter<string>();

    selectSeller(sellerId: string) {
        this.onSelectSeller.emit(sellerId);
    }
}
