import { Component, Input } from '@angular/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { ImportedProductsFileEntry } from '@src/model/importedProductsFileEntry';
import { ImportedDataService } from '@src/services/importedData.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-imported-products-entries',
    templateUrl: './imported-products-entries.component.html'
})
export class ImportedProductsEntriesComponent extends NgxDatatableBase {
    importedProductsFileEntries: ImportedProductsFileEntry[] = [];
    fileId: number;
    onLoading = false;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        translateService: TranslateService) {
        super(translateService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];
                this.onLoading = true;

                this.importDataService.getProductLogEntries(this.fileId).subscribe(
                    entries => {
                        this.importedProductsFileEntries = entries.sort((a,b) => a.lastUpdatedDate > b.lastUpdatedDate ? 1 : -1);
                        this.onLoading = false;
                    }
                );
            }
        });
    }
}