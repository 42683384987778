import { Component, OnInit, Input } from '@angular/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { RepairWarranty } from '@src/model/repairWarranty';
import { Retailer } from '@src/model/retailer';

@Component({
  selector: 'app-repair-warranties-table',
  templateUrl: './repair-warranties-table.component.html',
  styleUrls: ['./repair-warranties-table.component.scss']
})
export class RepairWarrantiesTableComponent extends NgxDatatableBase implements OnInit {

  @Input() repairWarranties : RepairWarranty[];
  @Input() virtualRetailer: Retailer;
  @Input() isSearchResult: boolean;

  constructor(private translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
  }

}
