import { Customer } from './customer';
import { ProductItem } from './productItem';

export class CustomerDetails {
    constructor() {
        this.customer = new Customer();
        this.productItem = new ProductItem();
    }
    productItem: ProductItem;
    customer: Customer;
}