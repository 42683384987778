import { Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { Retailer } from '@src/model/retailer';
import { WizardPreSelectedRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-pre-selected-retailer';
// import {WizardSelectRetailerComponent} from '@src/base-components/wizard/select-retailer/wizard-select-retailer';

@Component({
    selector: 'app-activation-select-retailer',
    templateUrl: './activation-select-retailer.html'
})
export class ActivationSelectRetailerComponent {
    // @ViewChild(WizardSelectRetailerComponent) wizardSelectRetailerComponent: WizardSelectRetailerComponent;
    @ViewChild(WizardPreSelectedRetailerComponent) wizardPreSelectedRetailerComponent: WizardPreSelectedRetailerComponent;

    @Output() onRetailerSelect = new EventEmitter<Retailer>();
    onSelect(retailer: Retailer) {
        this.onRetailerSelect.emit(retailer);
    }

    reset(): void {
        this.wizardPreSelectedRetailerComponent.reset();
    }
}

