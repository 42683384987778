import { Component, Input, EventEmitter, Output} from '@angular/core';
import { ConfigService } from '@src/services/layout/config.service';
import { WarrantyInfo } from '@src/model/warranty/warrantyInfo';
import { WarrantyExtensionType } from '@src/model/Enums/warrantyExtensionType';

@Component({
    selector: 'app-list-warranties',
    templateUrl: './list-warranties.component.html',
    styleUrls: ['./list-warranties.component.scss']
})
export class ListWarrantiesComponent {
    @Input() warranties: WarrantyInfo[];
    @Input() isSelected: boolean;
    @Output() selectedWarranty = new EventEmitter<WarrantyInfo>();
    chevronsIconbyOrientation: string;

    constructor(private configService: ConfigService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
            'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';
    }

    selectWarranty(warranty: WarrantyInfo) {
        this.selectedWarranty.emit(warranty);
    }

    isSaleExtension(warranty: WarrantyInfo) : boolean {
        return warranty.type === WarrantyExtensionType.saleWarrantyExtension;
    }

    isRepairExtension(warranty: WarrantyInfo) : boolean {
        return warranty.type === WarrantyExtensionType.repairWarrantyExtension;
    }
}