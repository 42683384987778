import { retailers } from './retailers-data';

export const settings = [{
       'id': 1,
       'type': 'CRM',
       'description': 'Display CRM feature',
    },
    {
        'id': 2,
        'type': 'TEST',
        'description': 'Basic settings',
     }];

export const settingsRetailer = [{
    'settingsId': settings[0],
    'retailerid': retailers[0],
    'active': true
}];