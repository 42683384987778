import { Component, ViewChild } from '@angular/core';
import { ActivationBaseComponent } from '@src/base-components/activation/activation.component';

import * as enums from '@src/model/Enums/productType';
//children

import { ActivationSelectRetailerComponentMessika } from './steps/select-retailer/activation-select-retailer';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';
import { ActivationFindProductComponent } from '@src/base-components/activation/steps/find-product/activation-find-product';
import { ActivationFindWCComponentMessika } from './steps/find-wc/activation-find-wc';


@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent extends ActivationBaseComponent {

  ProductType = enums.ProductType;
  @ViewChild(ActivationSelectRetailerComponentMessika) activationSelectRetailerComponentMessika: ActivationSelectRetailerComponentMessika;
  @ViewChild(ActivationSelectTypeComponent) activationSelectTypeComponent: ActivationSelectTypeComponent;
  @ViewChild(ActivationFindProductComponent) activationFindProductComponent: ActivationFindProductComponent;
  @ViewChild(ActivationFindWCComponentMessika) activationFindWCComponentMessika: ActivationFindWCComponentMessika;

  resetToStep0() {
    if (this.activationSelectRetailerComponentMessika) {
      this.activationSelectRetailerComponentMessika.reset();
    }
    this.resetToStep1();
  }

  resetToStep1() {
    if (this.activationSelectTypeComponent) {
      this.activationSelectTypeComponent.reset();
    }
    this.resetToStep2();
  }

  resetToStep2() {
    if (this.activationFindProductComponent) {
      this.activationFindProductComponent.reset();
    }
    this.resetToStep3();
  }

  resetToStep3() {
    if (this.activationFindWCComponentMessika) {
      this.activationFindWCComponentMessika.reset();
    }
    this.resetToStep4();
  }

  resetToStep4() {
    this.validationErrors = [];
  }

  setTitleProductType() {
    return this.selectedType == undefined ? this.translateService.instant('Components.ActivationSteps.step-type') :
      this.selectedType == 1 ? this.translateService.instant('CustomLabels.post-2017-title')
        : this.selectedType == 2 ? this.translateService.instant('CustomLabels.pre-2017-title') : '';
  }

}
