import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DateInterval } from '@src/model/DateInterval';
import { Observable } from 'rxjs';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { DashboardRetailerFilterBaseComponent } from '../dashboard-retailer-filter-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-dashboard-retailer-filter',
  templateUrl: './dashboard-retailer-filter.component.html',
  styleUrls: ['./dashboard-retailer-filter.component.scss']
})

export class DashboardRetailerFilterComponent extends DashboardRetailerFilterBaseComponent {

  constructor(public configService: ConfigService) {
    super(configService);
  }
}
