export const markets = [
    {
       'id': 126,
       'parentMarketId': 125,
       'code': 'AD',
       'name': 'Andorra',
       'parentMarket': {
          'id': 125,
          'parentMarketId': null,
          'code': '007',
          'name': 'Region 7',
          'parentMarket': null
       }
    },
    {
       'id': 145,
       'parentMarketId': 142,
       'code': 'AO',
       'name': 'Angola',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 172,
       'parentMarketId': 171,
       'code': 'AR',
       'name': 'Argentina',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 103,
       'parentMarketId': 93,
       'code': 'AM',
       'name': 'Armenia',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 133,
       'parentMarketId': 130,
       'code': 'AW',
       'name': 'Aruba',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 159,
       'parentMarketId': 158,
       'code': 'AU',
       'name': 'Australia',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 121,
       'parentMarketId': 117,
       'code': 'AT',
       'name': 'Austria',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 152,
       'parentMarketId': 142,
       'code': 'BH',
       'name': 'Bahrain',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 119,
       'parentMarketId': 117,
       'code': 'BE',
       'name': 'Belgium',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 134,
       'parentMarketId': 130,
       'code': 'BM',
       'name': 'Bermuda',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 173,
       'parentMarketId': 171,
       'code': 'BR',
       'name': 'Brazil',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 114,
       'parentMarketId': 93,
       'code': 'BG',
       'name': 'Bulgaria',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 131,
       'parentMarketId': 130,
       'code': 'CA',
       'name': 'Canada',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 135,
       'parentMarketId': 130,
       'code': 'KY',
       'name': 'Cayman Islands',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 174,
       'parentMarketId': 171,
       'code': 'CL',
       'name': 'Chile',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 181,
       'parentMarketId': 171,
       'code': 'CN',
       'name': 'China',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 176,
       'parentMarketId': 171,
       'code': 'CO',
       'name': 'Colombia',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 100,
       'parentMarketId': 93,
       'code': 'HR',
       'name': 'Croatia',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 105,
       'parentMarketId': 93,
       'code': 'CY',
       'name': 'Cyprus',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 98,
       'parentMarketId': 93,
       'code': 'CZ',
       'name': 'Czech Republic',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 101,
       'parentMarketId': 93,
       'code': 'DK',
       'name': 'Denmark',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 146,
       'parentMarketId': 142,
       'code': 'EG',
       'name': 'Egypt',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 99,
       'parentMarketId': 93,
       'code': 'FI',
       'name': 'Finland',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 94,
       'parentMarketId': 93,
       'code': 'FR',
       'name': 'France',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 123,
       'parentMarketId': 117,
       'code': 'DE',
       'name': 'Germany',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 129,
       'parentMarketId': 125,
       'code': 'GI',
       'name': 'Gibraltar',
       'parentMarket': {
          'id': 125,
          'parentMarketId': null,
          'code': '007',
          'name': 'Region 7',
          'parentMarket': null
       }
    },
    {
       'id': 104,
       'parentMarketId': 93,
       'code': 'GR',
       'name': 'Greece',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 160,
       'parentMarketId': 158,
       'code': 'GU',
       'name': 'Guam',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 175,
       'parentMarketId': 171,
       'code': 'GT',
       'name': 'Guatemala',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 182,
       'parentMarketId': 171,
       'code': 'HK',
       'name': 'Hong Kong',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 106,
       'parentMarketId': 93,
       'code': 'HU',
       'name': 'Hungary',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 147,
       'parentMarketId': 142,
       'code': 'IN',
       'name': 'India',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 164,
       'parentMarketId': 158,
       'code': 'ID',
       'name': 'Indonesia',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 154,
       'parentMarketId': 142,
       'code': 'IR',
       'name': 'Iran, Islamic Republic Of',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 144,
       'parentMarketId': 142,
       'code': 'IE',
       'name': 'Ireland',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 149,
       'parentMarketId': 142,
       'code': 'IL',
       'name': 'Israel',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 97,
       'parentMarketId': 93,
       'code': 'IT',
       'name': 'Italy',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 136,
       'parentMarketId': 130,
       'code': 'JM',
       'name': 'Jamaica',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 168,
       'parentMarketId': 158,
       'code': 'JP',
       'name': 'Japan',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 111,
       'parentMarketId': 93,
       'code': 'KZ',
       'name': 'Kazakstan',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 165,
       'parentMarketId': 158,
       'code': 'KR',
       'name': 'Korea, Republic Of',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 153,
       'parentMarketId': 142,
       'code': 'KW',
       'name': 'Kuwait',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 112,
       'parentMarketId': 93,
       'code': 'LV',
       'name': 'Latvia',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 124,
       'parentMarketId': 117,
       'code': 'LI',
       'name': 'Liechtenstein',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 118,
       'parentMarketId': 117,
       'code': 'LU',
       'name': 'Luxembourg',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 163,
       'parentMarketId': 158,
       'code': 'MY',
       'name': 'Malaysia',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 150,
       'parentMarketId': 142,
       'code': 'MT',
       'name': 'Malta',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 157,
       'parentMarketId': 142,
       'code': 'MU',
       'name': 'Mauritius',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 177,
       'parentMarketId': 171,
       'code': 'MX',
       'name': 'Mexico',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 120,
       'parentMarketId': 117,
       'code': 'NL',
       'name': 'Netherlands',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 138,
       'parentMarketId': 130,
       'code': 'AN',
       'name': 'Netherlands Antilles',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 166,
       'parentMarketId': 158,
       'code': 'NZ',
       'name': 'New Zealand',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 95,
       'parentMarketId': 93,
       'code': 'NO',
       'name': 'Norway',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 151,
       'parentMarketId': 142,
       'code': 'OM',
       'name': 'Oman',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 155,
       'parentMarketId': 142,
       'code': 'PK',
       'name': 'Pakistan',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 180,
       'parentMarketId': 171,
       'code': 'PY',
       'name': 'Paraguay',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 179,
       'parentMarketId': 171,
       'code': 'PE',
       'name': 'Peru',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 162,
       'parentMarketId': 158,
       'code': 'PH',
       'name': 'Philippines',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 102,
       'parentMarketId': 93,
       'code': 'PL',
       'name': 'Poland',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 127,
       'parentMarketId': 125,
       'code': 'PT',
       'name': 'Portugal',
       'parentMarket': {
          'id': 125,
          'parentMarketId': null,
          'code': '007',
          'name': 'Region 7',
          'parentMarket': null
       }
    },
    {
       'id': 141,
       'parentMarketId': 130,
       'code': 'PR',
       'name': 'Puerto Rico',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 142,
       'parentMarketId': null,
       'code': '001',
       'name': 'Region 1',
       'parentMarket': null
    },
    {
       'id': 171,
       'parentMarketId': null,
       'code': '002',
       'name': 'Region 2',
       'parentMarket': null
    },
    {
       'id': 158,
       'parentMarketId': null,
       'code': '003',
       'name': 'Region 3',
       'parentMarket': null
    },
    {
       'id': 130,
       'parentMarketId': null,
       'code': '004',
       'name': 'Region 4',
       'parentMarket': null
    },
    {
       'id': 93,
       'parentMarketId': null,
       'code': '005',
       'name': 'Region 5',
       'parentMarket': null
    },
    {
       'id': 117,
       'parentMarketId': null,
       'code': '006',
       'name': 'Region 6',
       'parentMarket': null
    },
    {
       'id': 125,
       'parentMarketId': null,
       'code': '007',
       'name': 'Region 7',
       'parentMarket': null
    },
    {
       'id': 113,
       'parentMarketId': 93,
       'code': 'RE',
       'name': 'Reunion',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 96,
       'parentMarketId': 93,
       'code': 'RO',
       'name': 'Romania',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 115,
       'parentMarketId': 93,
       'code': 'RU',
       'name': 'Russian Federation',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 156,
       'parentMarketId': 142,
       'code': 'SA',
       'name': 'Saudi Arabia',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 161,
       'parentMarketId': 158,
       'code': 'SG',
       'name': 'Singapore',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 110,
       'parentMarketId': 93,
       'code': 'SK',
       'name': 'Slovakia',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 108,
       'parentMarketId': 93,
       'code': 'SI',
       'name': 'Slovenia',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 148,
       'parentMarketId': 142,
       'code': 'ZA',
       'name': 'South Africa',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 128,
       'parentMarketId': 125,
       'code': 'ES',
       'name': 'Spain',
       'parentMarket': {
          'id': 125,
          'parentMarketId': null,
          'code': '007',
          'name': 'Region 7',
          'parentMarket': null
       }
    },
    {
       'id': 107,
       'parentMarketId': 93,
       'code': 'SE',
       'name': 'Sweden',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 122,
       'parentMarketId': 117,
       'code': 'CH',
       'name': 'Switzerland',
       'parentMarket': {
          'id': 117,
          'parentMarketId': null,
          'code': '006',
          'name': 'Region 6',
          'parentMarket': null
       }
    },
    {
       'id': 183,
       'parentMarketId': 171,
       'code': 'TW',
       'name': 'Taiwan (R.O.C)',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 170,
       'parentMarketId': 158,
       'code': 'TH',
       'name': 'Thailand',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 116,
       'parentMarketId': 93,
       'code': 'TR',
       'name': 'Turkey',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 139,
       'parentMarketId': 130,
       'code': 'TC',
       'name': 'Turks And Caicos Islands',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 109,
       'parentMarketId': 93,
       'code': 'UA',
       'name': 'Ukraine',
       'parentMarket': {
          'id': 93,
          'parentMarketId': null,
          'code': '005',
          'name': 'Region 5',
          'parentMarket': null
       }
    },
    {
       'id': 143,
       'parentMarketId': 142,
       'code': 'GB',
       'name': 'United Kingdom',
       'parentMarket': {
          'id': 142,
          'parentMarketId': null,
          'code': '001',
          'name': 'Region 1',
          'parentMarket': null
       }
    },
    {
       'id': 132,
       'parentMarketId': 130,
       'code': 'US',
       'name': 'United States',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 178,
       'parentMarketId': 171,
       'code': 'UY',
       'name': 'Uruguay',
       'parentMarket': {
          'id': 171,
          'parentMarketId': null,
          'code': '002',
          'name': 'Region 2',
          'parentMarket': null
       }
    },
    {
       'id': 167,
       'parentMarketId': 158,
       'code': 'VU',
       'name': 'Vanuatu',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 169,
       'parentMarketId': 158,
       'code': 'VN',
       'name': 'Vietnam',
       'parentMarket': {
          'id': 158,
          'parentMarketId': null,
          'code': '003',
          'name': 'Region 3',
          'parentMarket': null
       }
    },
    {
       'id': 140,
       'parentMarketId': 130,
       'code': 'VG',
       'name': 'Virgin Islands, British',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    },
    {
       'id': 137,
       'parentMarketId': 130,
       'code': 'VI',
       'name': 'Virgin Islands, U.S.',
       'parentMarket': {
          'id': 130,
          'parentMarketId': null,
          'code': '004',
          'name': 'Region 4',
          'parentMarket': null
       }
    }
 ]