import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ActivationFindProductComponent } from '@src/base-components/activation/steps/find-product/activation-find-product';


@Component({
    selector: 'app-activation-find-product-messika',
    templateUrl: './activation-find-product.html'
})
export class ActivationFindProductComponentMessika extends ActivationFindProductComponent {

}
