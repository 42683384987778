export const importedretailersentries = [
      {
         'id': '31_602',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 58.7703965+01: 00',
         'code': '13001197',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Leeds',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_603',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 59.5922556+01: 00',
         'code': '13001124',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Bluewater',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_617',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 09.2056696+01: 00',
         'code': '13001334',
         'isActive': true,
         'name': 'Watches Of Switzerland',
         'city': 'Heathrow',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_614',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 08.1700072+01: 00',
         'code': '13001117',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Milton Keynes',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_601',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 58.3019425+01: 00',
         'code': '13001306',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Gateshead',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_633',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 15.2114229+01: 00',
         'code': '13003353',
         'isActive': true,
         'name': 'LVMH Ltd',
         'city': 'Manchester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_621',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 10.5596306+01: 00',
         'code': '13001196',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'London',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_622',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 10.8806323+01: 00',
         'code': '13001133',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'London',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_585',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 51.139812+01: 00',
         'code': '13001847',
         'isActive': true,
         'name': 'MARTHAN LORAND',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_588',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 52.5099371+01: 00',
         'code': '13001868',
         'isActive': true,
         'name': 'SAGIL',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_591',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 53.7582168+01: 00',
         'code': '13001777',
         'isActive': true,
         'name': 'ARIJE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_579',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 48.7609798+01: 00',
         'code': '13001812',
         'isActive': true,
         'name': 'FORGES',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_580',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 49.0980335+01: 00',
         'code': '13001837',
         'isActive': true,
         'name': 'LES MONTRES PASSY',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_581',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 49.3840347+01: 00',
         'code': '13001838',
         'isActive': true,
         'name': 'LES MONTRES BONAPARTE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_595',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 55.5216984+01: 00',
         'code': '13001784',
         'isActive': true,
         'name': 'BARRIER SERGE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_600',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 57.844381+01: 00',
         'code': '13001144',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Bristol',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_604',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 00.2746672+01: 00',
         'code': '13001128',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Glasgow',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_613',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 07.8479701+01: 00',
         'code': '13001162',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Leicester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_615',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 08.4937232+01: 00',
         'code': '13001177',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Sheffield',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_619',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 09.8962389+01: 00',
         'code': '13001420',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Liverpool',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_629',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 13.2594026+01: 00',
         'code': '13002962',
         'isActive': true,
         'name': 'TAG Heuer Boutique Lakeside',
         'city': 'Thurrock',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_630',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 13.7063991+01: 00',
         'code': '13003202',
         'isActive': true,
         'name': 'JO\'S',
         'city': 'PARIS',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_582',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 49.6690145+01: 00',
         'code': '13001842',
         'isActive': true,
         'name': 'LOUIS PION CHAMPS ELYSEES',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_586',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 51.6121846+01: 00',
         'code': '13001850',
         'isActive': true,
         'name': 'MATY',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_587',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 52.0621009+01: 00',
         'code': '13001733',
         'isActive': true,
         'name': 'PRINTEMPS Haussmann',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_590',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 53.3358201+01: 00',
         'code': '13001809',
         'isActive': true,
         'name': 'FARADAY',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_597',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 56.3761862+01: 00',
         'code': '13001727',
         'isActive': true,
         'name': 'Paris Eiffel',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_610',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 06.8846554+01: 00',
         'code': '13001175',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Reading',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_611',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 07.216749+01: 00',
         'code': '13001180',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Southampton',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_618',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 09.5298697+01: 00',
         'code': '13001139',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Liverpool',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_623',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 11.2332064+01: 00',
         'code': '13001129',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'London',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_628',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 12.9198791+01: 00',
         'code': '13001556',
         'isActive': true,
         'name': 'Watches of Switzwerland',
         'city': 'London',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_631',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 14.10183+01: 00',
         'code': '13003263',
         'isActive': true,
         'name': 'BHV MARAIS',
         'city': 'PARIS',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_608',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 03.1948143+01: 00',
         'code': '13000974',
         'isActive': true,
         'name': 'TAG Heuer Boutique - Bicester Designer Outlet',
         'city': 'Bicester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_583',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 50.0194668+01: 00',
         'code': '13001718',
         'isActive': true,
         'name': 'LOUIS PION MONTMARTRE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_584',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 50.3140988+01: 00',
         'code': '13001841',
         'isActive': true,
         'name': 'LOUIS PION MADELEINE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_593',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 54.6086488+01: 00',
         'code': '13001716',
         'isActive': true,
         'name': 'LOUIS PION AUBER',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_594',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 55.1151051+01: 00',
         'code': '13001738',
         'isActive': true,
         'name': 'ROYAL QUARTZ',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_575',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 46.8003209+01: 00',
         'code': '13002900',
         'isActive': true,
         'name': 'BARRIER ROGER',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 91,
               'name': 'Continental Europe',
               'code': '11',
               'isNew': true
            },
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': true
            }
         ]
      },
      {
         'id': '31_609',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 06.5756375+01: 00',
         'code': '13001215',
         'isActive': true,
         'name': 'Watches Of Switzerland',
         'city': 'Cardiff',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_625',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 11.8761716+01: 00',
         'code': '13001187',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Manchester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_589',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 52.870635+01: 00',
         'code': '13001709',
         'isActive': true,
         'name': 'GALERIES LAFAYETTE HAUSSMANN ',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_612',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 07.5428709+01: 00',
         'code': '13001147',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Dudley',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_578',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 48.241935+01: 00',
         'code': '13001839',
         'isActive': true,
         'name': 'ETOILE D\'OR ARONSON',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_598',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 56.8153575+01: 00',
         'code': '13001731',
         'isActive': true,
         'name': 'PRINTEMPS DU LOUVRE',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_626',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 12.2591985+01: 00',
         'code': '13001189',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Manchester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_627',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 12.5857868+01: 00',
         'code': '13001115',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Birmingham',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_605',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 00.90192+01: 00',
         'code': '13001135',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Cardiff',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_606',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 01.3835456+01: 00',
         'code': '13001191',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Belfast',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_576',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 47.6388094+01: 00',
         'code': '13001836',
         'isActive': true,
         'name': 'CHAMPS D\'OR',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_577',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 47.9372673+01: 00',
         'code': '13001808',
         'isActive': true,
         'name': 'ELYSEES SHOPPING',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_624',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 11.5436709+01: 00',
         'code': '13001555',
         'isActive': true,
         'name': 'TAG Heuer Boutique Manchester',
         'city': 'Manchester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_599',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 57.3587873+01: 00',
         'code': '13001121',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'Basingstoke',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 93,
               'name': 'United Kingdom',
               'code': '5000',
               'isNew': true
            },
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': true
            }
         ]
      },
      {
         'id': '31_616',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 08.8448215+01: 00',
         'code': '13001209',
         'isActive': true,
         'name': 'Watches of Switzerland',
         'city': 'Heathrow',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_607',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 02.0099685+01: 00',
         'code': '13001205',
         'isActive': true,
         'name': 'Mappin & Webb',
         'city': 'Glasgow',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_620',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 10.2207966+01: 00',
         'code': '13001222',
         'isActive': true,
         'name': 'Goldsmiths',
         'city': 'London',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_596',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 55.9420653+01: 00',
         'code': '13001818',
         'isActive': true,
         'name': 'GODECHOT ET PAULIET',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_592',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 47: 54.1632981+01: 00',
         'code': '13001823',
         'isActive': true,
         'name': 'HEURGON',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_632',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 14.8682558+01: 00',
         'code': '13001682',
         'isActive': true,
         'name': 'BATEAU LE DAUPHIN',
         'city': 'Paris',
         'country': 'FR',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 92,
               'name': 'France',
               'code': 'FR',
               'isNew': false
            }
         ]
      },
      {
         'id': '31_634',
         'fileId': 31,
         'lastUpdatedDate': '2018-11-20T16: 48: 15.8680638+01: 00',
         'code': '13001450',
         'isActive': true,
         'name': 'LVMH Ltd',
         'city': 'Manchester',
         'country': 'GB',
         'managerName': 'FirstName Name',
         'marketsStr': null,
         'isNew': true,
         'markets': [
            {
               'id': 94,
               'name': 'United Kingdom',
               'code': 'UK',
               'isNew': false
            }
         ]
      }
   ];