import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import * as moment from 'moment';
import { SharedDataService } from '@src/services/sharedData.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { SalesService } from '@src/services/sales/sales.service';
import { SalesParameters } from '@src/model/filter-parameters/sales-parameters';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { UsersService } from '@src/services/users/users.service';
import { SalesFilterComponent } from '@src/base-components/sales/filter/sales-filter.component';

@Component({
    selector: 'app-sales-filter-oris',
    templateUrl: './sales-filter.component.html',
    styleUrls: ['./sales-filter.component.scss']
})

export class SalesFilterOrisComponent extends SalesFilterComponent implements OnInit {

    constructor(@Inject('AuthService') authService: IAuthService,
        retailersService: RetailersService,
        productsService: ProductsService,
        configService: ConfigService,
        dashboardService: DashboardService,
        salesService: SalesService,
        translateService: TranslateService,
        toastrManager: ToastrService,
        notificationService: NotificationsService,
        usersService: UsersService,
        sharedDataService: SharedDataService) {
        super(authService, retailersService, productsService, configService, dashboardService,
            salesService, translateService, toastrManager, notificationService, usersService, sharedDataService);
    }

    selectedTimeRange: string;
    timeRangeList: string[];
    inputSNWCQuery: string;
    inputLocationQuery: string;
    inputProductQuery: string;
    customFrom?: Date;
    customTo?: Date;
    loggedUser: UserProfile;
    layoutDirection: string;
    filterParameters: SalesParameters;
    isPrepairedClient: boolean;
    selectedProductId: number;
    checkedActivation: boolean;
    checkedReturn: boolean;
    isLoading = false;
    hasCorrection: boolean;
    isOpen = true;

    @Output() changeFilter = new EventEmitter<SalesParameters>();
    @Output() clearFilter = new EventEmitter<SalesParameters>();
    @Input() newfilterParameters: SalesParameters;

    ngOnInit() {
        this.selectedTimeRange = 'last-month';
        this.timeRangeList = [
            'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'today', 'custom'
        ];

        const intervalDates = this.computeTimeRange(this.selectedTimeRange);
        this.filterParameters = new SalesParameters();
        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.filterParameters.selectedTimeRange = 'last-month';
        if (this.newfilterParameters !== undefined) {
            this.filterParameters = this.newfilterParameters;
            if (this.newfilterParameters.operationsType.includes(1)) {
                this.checkedActivation = true;
            }
            if (this.newfilterParameters.operationsType.includes(2)) {
                this.checkedReturn = true;
            }
            this.selectedTimeRange = this.newfilterParameters.selectedTimeRange === undefined ? 'last-month' : this.newfilterParameters.selectedTimeRange;
            if (this.newfilterParameters.selectedTimeRange === 'custom') {
                this.customTo = this.newfilterParameters.to;
                this.customFrom = this.newfilterParameters.from;
            }
            this.inputSNWCQuery = this.newfilterParameters.serialNumber;
            this.inputProductQuery = this.newfilterParameters.productSku;
        }
        this.changeFilter.emit(this.filterParameters);
    }

    clear() {
        this.selectedTimeRange = 'last-month';
        this.inputLocationQuery = '';
        this.inputProductQuery = '';
        this.inputSNWCQuery = '';
        this.checkedActivation = false;
        this.checkedReturn = false;

        const intervalDates = this.computeTimeRange(this.selectedTimeRange);
        this.filterParameters = new SalesParameters();
        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.clearFilter.emit(this.filterParameters);
    }

    changeTimeRange(event) {
        this.filterParameters.selectedTimeRange = this.selectedTimeRange;
        if (this.selectedTimeRange !== 'custom') {
            const intervalDates = this.computeTimeRange(this.selectedTimeRange);
            this.filterParameters.from = intervalDates.from;
            this.filterParameters.to = intervalDates.to;
            this.changeFilter.emit(this.filterParameters);
        }
    }
    selectCustomFrom(date: any) {
        const intervalDates = this.computeInterval(this.customFrom, this.customTo);

        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.changeFilter.emit(this.filterParameters);
    }
    selectCustomTo(date: any) {
        const intervalDates = this.computeInterval(this.customFrom, this.customTo);

        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.changeFilter.emit(this.filterParameters);
    }

    changeLocation(target: any) {
        const entryId = target.item.id;
        const entryType = target.item.type;
        this.inputLocationQuery = target.item.name;

        if (entryType === 'r') {
            this.filterParameters.retailerId = entryId;
        } else if (entryType === 'm') {
            this.filterParameters.marketId = entryId;
        } else {
            this.filterParameters.businessGroup = this.inputLocationQuery;
        }
        this.changeFilter.emit(this.filterParameters);
    }

    changeSNWC(target: any) {
        const entryType = target.item.type;
        this.inputSNWCQuery = target.item.name;

        if (entryType === 's') {
            this.filterParameters.serialNumber = this.inputSNWCQuery;
        } else {
            this.filterParameters.warrantyCard = this.inputSNWCQuery;
        }
        this.changeFilter.emit(this.filterParameters);
    }

    formatterSNWC = (x: { name: string }) => {
        if (this.inputSNWCQuery === '') {
            x = { name: undefined };
        } else {
            x = { name: this.inputSNWCQuery };
        }
        return x.name;
    }

    formatterMarketRetailer = (x: { name: string }) => {
        if (this.inputLocationQuery === '') {
            x = { name: undefined };
        } else {
            x = { name: this.inputLocationQuery };
        }
        return x.name;
    }

    formatterProduct = (x: { name: string }) => {
        if (this.inputProductQuery === '') {
            x = { name: undefined };
        } else {
            x = { name: this.inputProductQuery };
        }
        return x.name;
    }

    public computeTimeRange(timeRange: string): { from: Date, to: Date } {
        const now = new Date();
        switch (timeRange) {
            case 'last-month': {
                return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate() };
            }
            case 'last-week': {
                return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate() };
            }
            case 'last-year': {
                return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
            }
            case 'last-5-years': {
                return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
            }
            case 'today': {
                return { from: moment().add(0, 'd').toDate(), to: moment(now).add(1, 'd').toDate() };
            }
            default: {
                return { from: null, to: null };
            }
        }
    }

    computeInterval(from = null, to = null): { from: Date, to: Date } {
        let _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
        const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');

        if (moment(_to.toDate()).isSameOrAfter(_from.toDate())) {
            _to = moment(_to).add(1, 'd');
        }

        return { to: _to.toDate(), from: _from.toDate() };
    }

    toggleOperationType(operationType: string) {
        switch (operationType) {
            case 'activation':
                this.addOrRemoveOperationsType(1);
                break;
            case 'return':
                this.addOrRemoveOperationsType(2);
                break;
        }
        this.changeFilter.emit(this.filterParameters);
    }

    addOrRemoveOperationsType(operationType: number) {
        if (this.filterParameters.operationsType.find(o => o === operationType)) {
            this.filterParameters.operationsType = this.filterParameters.operationsType.filter(o => o !== operationType);
        } else {
            this.filterParameters.operationsType.push(operationType);
        }
    }
}
