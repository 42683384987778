import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ProductItem } from '@src/model/productItem';
import { WizardComponent } from 'angular-archwizard';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@src/services/users/users.service';
import { ConfigService } from '@src/services/layout/config.service';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { EnvironmentNames } from '@src/environments/environments.name';
import { environment } from '@src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-qrcodeprinting-base',
  templateUrl: './qrcodeprinting.component.html'
})
export class QrCodePrintingBaseComponent implements OnInit {

  @ViewChild(WizardComponent) wizard: WizardComponent;

  selectedProductItem: ProductItem = new ProductItem();

  warningCheked = false;
  validationErrors = [];
  chevronsIconbyOrientation: string;
  qrcodeUrl : string;
  qrBaseUrl : string;
  qrQABaseUrl : string;
  Title : string;
  LogoUrl : string;

  constructor(
    @Inject('AuthService') public authService: IAuthService,
    public translateService: TranslateService,
    public usersService: UsersService,
    private toastrManager: ToastrService,
    public configService: ConfigService
  ) {

  }

  ngOnInit() {
    const loggedUser = this.authService.getCurrentUserInstant();
    this.usersService.getUser(loggedUser.sub).subscribe(user => {
      if (user == undefined) {
          return;
      }
    });

    const layoutOrientation = this.configService.templateConf.layout.dir;

    this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
        'ft-chevrons-left primary' : 'ft-chevrons-right primary';
  }

  selectProductItem(prod: ProductItem) {
    this.selectedProductItem = prod;
    this.qrcodeUrl = this.generateQrUrl(this.selectedProductItem);
    this.wizard.navigation.goToNextStep();
  }
  generateQrUrl(productitem: ProductItem) : string
  {
    let url:string = this.qrQABaseUrl;

    if (environment.envName === EnvironmentNames.prod) {
      url =  this.qrBaseUrl;//demo
    } 

    if(productitem.serialNumber != "" && productitem.serialNumber != null)
    {
      url = url + "SN=" + productitem.serialNumber;
    }
    if(productitem.securityCode != "" && productitem.securityCode != null)
    {
      url = url + "&SEC=" + productitem.securityCode;
    }
    return url;
  }
  resetComponent() {
    this.wizard.navigation.reset();
  }
  
  resetToStep0() {
    this.validationErrors = [];
  }

  resetToStep1() {
   this.validationErrors = [];
  }
  setTitleStepProductItem() {
    return this.selectedProductItem.product.name == undefined ? this.translateService.instant('Components.ActivationSteps.step-find')
        : this.translateService.instant('Labels.reference') + ': ' + this.selectedProductItem.product.name;
  }
  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.qrcodeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  goBack() {
    this.wizard.navigation.reset();
  }

  printSerialNumberV1() {
      const scale = window.innerWidth / window.outerWidth;
      if(scale != 1)
      {
        this.toastrManager.warning(this.translateService.instant('Components.QrPrintingSteps.PrintingScaleIssue'));
      }
      let element = document.getElementById('card-to-print');
      let opt = {
          margin: 1,
          filename: this.selectedProductItem.serialNumber + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf().set(opt).from(element).save();
      this.wizard.navigation.goToNextStep();
  }
  async printSerialNumber() { 
    var docDefinition = {
      content: [
        {
          columns: [           
            [{ image: await this.getBase64ImageFromURL(this.LogoUrl), alignment: 'left', width: 26, height: 26}],
            [{ text: this.Title.toUpperCase(), color: 'white', alignment: 'left', fontSize: 18, characterSpacing: 1.6, absolutePosition: { x: 227, y: 108 }}],            
            [{ image: $(".aclass img").attr('src'), alignment: 'left', width: 45, height: 45, absolutePosition: { x: 356, y: 103 } }],
          ],
          style: 'cardMargin',     
        },   
        {
          text: this.selectedProductItem.product.description, 
          color: 'white',
          fontSize: 9,
          characterSpacing: 0.3,
          italics: true,
          fontFamily: 'Arial',
          margin: [160, 0],
          absolutePosition: { x: 192, y: 183 }
        },
        {
          text: 'Serial Number : ' + this.selectedProductItem.serialNumber, 
          color: 'white',
          fontSize: 8,
          characterSpacing: 0.3,
          italics: true,
          fontFamily: 'Arial',
          margin: [160, 0],
          absolutePosition: { x: 192, y: 200 }
        }
      ],
      background: {
        canvas:
          [
            {
              type: 'rect',
              x: 1,
              y: 1,
              w: 242,
              h: 152,
              r: 10,
              lineColor: '#232a3a',
              color: '#232a3a',              
              alignment: 'center',
            },     
          ], 
           style: 'superMargin'
      },
      styles: {
        cardMargin: {
          margin: [153, 66]
        },
        superMargin: {
          margin: [176, 85]
        }
      }
    };
    pdfMake.createPdf(docDefinition).download(this.selectedProductItem.serialNumber + '.pdf');  
    this.wizard.navigation.goToNextStep();
  }
  
  async getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}
