import { Component, Input, OnChanges } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';

@Component({
    selector: 'app-repair-confimation',
    templateUrl: './repair-confirmation.component.html'
})
export class RepairConfirmationComponent implements OnChanges {
    @Input() repairWarranty: RepairWarranty;

    linkDetailsSale = '';
    ngOnChanges() {
        this.linkDetailsSale = '/sales';
    }
}