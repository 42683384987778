import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '@src/services/customers/customers.service';
import { Customer } from '@src/model/customer';
import { CustomerDetails } from '@src/model/customerDetails';
import { ProductItem } from '@src/model/productItem';
import { AppSettings } from '@src/core/AppSettings';
import { IAuthService } from '@src/auth/auth.service.interface';
import * as moment from 'moment';
import { ConfigService } from '@src/services/layout/config.service';

declare var require: any;

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss']
})

export class CustomerDetailsComponent implements OnInit {
    customerDetails: CustomerDetails = new CustomerDetails();
    productItem: ProductItem;
    customer: Customer;
    isLoaded = false;
    layoutOrientation: string;


    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private route: ActivatedRoute,
        private customersService: CustomersService,
        private configService: ConfigService
    ) { 
        this.layoutOrientation = this.configService.templateConf.layout.dir;
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const customerId = +params['id'];
            if (customerId) {
                this.getCustomerDetails(customerId);
            }
        });
    }

    getCustomerDetails(customerId: number): void {
        this.customersService.getCustomerDetails(customerId)
            .subscribe((cd) => {
                this.customerDetails = cd;
                this.customer = this.customerDetails.customer;
                this.productItem = this.customerDetails.productItem;
            });
    }
}
