import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';
import { RepairType } from '@src/model/repairType';

@Injectable()
export class RepairTypesService extends BaseService {
  private baseUrl = environment.apiBaseUrl + '/api/repairtypes';

  constructor(injector: Injector) {
    super(injector);
  }
  getParentsRepairTypes(): Observable<RepairType[]> {
    const url = `${this.baseUrl}/parents`;
    return this.get<RepairType[]>(url);
  }
  getRepairTypeById(id: number): Observable<RepairType> {
    const url = `${this.baseUrl}/${id}`;
    return this.get<RepairType>(url);
  }
  getChildrenRepairType(parentId: number): Observable<RepairType[]> {
    const url = `${this.baseUrl}/${parentId}/children`;
    return this.get<RepairType[]>(url);
  }
}
