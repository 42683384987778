export const metricssellers = [
    {
        'value': 2257,
        'label': 'Was System'
    },
    {
        'value': 2492,
        'label': 'Was System 2'
    },
    {
        'value': 2504,
        'label': 'Was System 3'
    },
    {
        'value': 2609,
        'label': 'Was System 4'
    },
    {
        'value': 2552,
        'label': 'Was System 5'
    },
    {
        'value': 2598,
        'label': 'Was System 6'
    },
    {
        'value': 2570,
        'label': 'Was System 7'
    },
    {
        'value': 2578,
        'label': 'Was System 8'
    },
    {
        'value': 2613,
        'label': 'Was System 9'
    },
    {
        'value': 2577,
        'label': 'Was System 10'
    }
];