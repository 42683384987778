import { Component, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyModalContentComponent } from '@src/base-components/privacy/privacy-modal-content.component';
import { Router } from '@angular/router';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component({
  selector: 'app-page-layout',
  templateUrl: './pagelayout.component.html',
  styleUrls: ['./pagelayout.component.css'],
})
export class PageLayoutComponent {

  modalIsAlreadyOpen = false;
  constructor(private modalService: NgbModal,
    @Inject('AuthService')
    private authService: IAuthService,
    private route: Router) { }
  onActivate() {
    if (!this.modalIsAlreadyOpen
      && !this.authService.privacyPolicyAccepted
      && this.route.url.toLocaleLowerCase().indexOf('privacypolicy') === -1) {
      this.modalService.open(PrivacyModalContentComponent, {
        beforeDismiss: () => false,
        size: 'lg'
      });
      this.modalIsAlreadyOpen = true;
    }
  }

  IsInternalPage() {
    return window.location.pathname !== '/AfterSalesService/CustomerWarranties';
  }
}
