import { Component, ViewChild } from '@angular/core';
import { SaleDetailsCorrectionFindProductComponent } from '@src/base-components/sales/sale-details/product/correction/sale-details-correction-find-product.component';
import { ProductItem } from '@src/model/productItem';
import { SaleDetailsCorrectionBaseComponent } from '@src/base-components/sales/sale-details/product/correction/sale-details-correction-base.component';

@Component({
    selector: 'app-sale-details-correction',
    templateUrl: './sale-details-correction.component.html',
    styleUrls: ['./sale-details-correction.component.scss']
})

export class SaleDetailsCorrectionComponent extends SaleDetailsCorrectionBaseComponent {
    
    @ViewChild(SaleDetailsCorrectionFindProductComponent) correctionFindProductComponent: SaleDetailsCorrectionFindProductComponent;

    changeStep(event: any) {
        const stepIndex = event.selectedIndex;
        this.resetStepper(stepIndex);
    }

    step0Completed(): boolean {//select productItem
        return this.selectedProductItem && this.selectedProductItem.id !== undefined;
    }
    step1Completed(): boolean {//confirmation
        return false;
    }

    resetStepper(stepIndex: number): void {
        switch (stepIndex) {
            case 0:
                this.resetToStep0();
                break;
            case 1:
                this.resetToStep1();
                break;
            default:
                return;
        }
    }


    resetToStep0() {
        this.selectedProductItem = new ProductItem();
        this.correctionFindProductComponent.reset();
        this.resetToStep1();
    }

    resetToStep1() {
    }
}
