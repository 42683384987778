
export const languages = [
    { name: 'English', language: 'en', flag: './assets/img/flags/uk.png' },
    { name: 'Français', language: 'fr', flag: './assets/img/flags/fr.png' },
    { name: 'Deutsch', language: 'de', flag: './assets/img/flags/de.png' },
    { name: 'Español', language: 'es', flag: './assets/img/flags/es.png' },
    { name: 'Italiano', language: 'it', flag: './assets/img/flags/it.png' },
    { name: '日本語', language: 'ja', flag: './assets/img/flags/jp.png' },
    { name: '한국어', language: 'ko', flag: './assets/img/flags/ko.png' },
    { name: 'Pусский', language: 'ru', flag: './assets/img/flags/ru.png' },
    { name: '中文 (简)', language: 'zh', flag: './assets/img/flags/zh-hans.png' },
    { name: '中文 (傳統)', language: 'zh-TW', flag: './assets/img/flags/zh-hans.png' },
    { name: 'عربى', language: 'ar', flag: './assets/img/flags/ar.png' }
];
export const supportedLocaleCodes = ['en', 'fr', 'de', 'es','it', 'ja', 'ko', 'ru', 'zh', 'zh-TW', 'ar'];
export const romanizedLocaleCodes = ['en', 'fr', 'de', 'es', 'it'];