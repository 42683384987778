export class WindowEventsDispatcher {
    public static fireDelayedResizeEvent = function (delay: number) {
        setTimeout( () => {
           this.fireResizeEvent();
        }, delay);
    };
    public static fireResizeEvent = function() {
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('resize', true, false);
        window.dispatchEvent(evt);
    };
}