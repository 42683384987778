import {
  Component, Input, Output, EventEmitter, ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'simple-pager',
  template: `
      <ul class="pager">
        <li>
          <a
            *ngIf="canPrevious()"
            role="button"
            aria-label="go to first page"
            href="javascript:void(0)"
            (click)="selectPage(1)">
            <i class="{{pagerPreviousIcon}}"></i>
          </a>
        </li>
        <li>
          <a
            *ngIf="canPrevious()"
            role="button"
            aria-label="go to previous page"
            href="javascript:void(0)"
            (click)="prevPage()">
            <i class="{{pagerLeftArrowIcon}}"></i>
          </a>
        </li>
        {{page}}/{{totalPages}}
        <li>
          <a
          *ngIf="canNext()"
            role="button"
            aria-label="go to next page"
            href="javascript:void(0)"
            (click)="nextPage()">
            <i class="{{pagerRightArrowIcon}}"></i>
          </a>
        </li>
        <li>
        <a
          *ngIf="canNext()"
          role="button"
          aria-label="go to last page"
          href="javascript:void(0)"
          (click)="selectPage(totalPages)">
          <i class="{{pagerNextIcon}}"></i>
        </a>
      </li>
      </ul>
    `,
  host: {
    class: 'simple-pager'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePagerComponent {

  @Input() pagerLeftArrowIcon: string;
  @Input() pagerRightArrowIcon: string;
  @Input() pagerPreviousIcon: string;
  @Input() pagerNextIcon: string;

  @Input()
  set size(val: number) {
    this._size = val;
    // this.pages = this.calcPages();
  }

  get size(): number {
    return this._size;
  }

  @Input()
  set count(val: number) {
    this._count = val;
    // this.pages = this.calcPages();
  }

  get count(): number {
    return this._count;
  }

  @Input()
  set page(val: number) {
    this._page = val;
    // this.pages = this.calcPages();
  }

  get page(): number {
    return this._page;
  }

  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);
    return Math.max(count || 0, 1);
  }

  @Output() change: EventEmitter<any> = new EventEmitter();

  _count: number = 0;
  _page: number = 1;
  _size: number = 0;
  pages: any;

  canPrevious(): boolean {
    return this.page > 1;
  }

  canNext(): boolean {
    return this.page != this.totalPages;
  }

  prevPage(): void {
    if (this.canPrevious()) {
      this.selectPage(this.page - 1);
    }
  }

  nextPage(): void {
    if (this.canNext()) {
      this.selectPage(this.page + 1);
    }
  }

  selectPage(page: number): void {
    if (page > 0 && page !== this.page) {
      // this.page = page;
      this.change.emit({
        page
      });
    }
  }

  calcPages(page?: number): any[] {
    const pages = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = 5;
    const isMaxSized = maxSize < this.totalPages;

    page = page || this.page;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: <string><any>num
      });
    }

    return pages;
  }

}