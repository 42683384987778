export const retailerOrMarketNames = [
    {
        'id': 1561,
        'name': 'Serendipity / Braveheart SA',
        'type': 'r'
     },
     {
        'id': 1,
        'name': 'Switzerland',
        'type': 'm'
     },
     {
        'id': 4535,
        'name': 'Swiss Timecorner AG',
        'type': 'r'
     },
     {
        'id': 5115,
        'name': 'Scherbel',
        'type': 'r'
     },
     {
        'id': 3645,
        'name': 'Swiss Timecorner AG',
        'type': 'r'
     },
     {
        'id': 1522,
        'name': 'Skala Franz',
        'type': 'r'
     },
     {
        'id': 1553,
        'name': 'Swiss Lion AG',
        'type': 'r'
     },
     {
        'id': 1169,
        'name': 'Oris SA MyWatches',
        'type': 'r'
     },
     {
        'id': 1511,
        'name': 'Oris SA',
        'type': 'r'
     }
];