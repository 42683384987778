import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductItem } from '@src/model/productItem';

@Component({
    selector: 'app-find-product-items-result',
    templateUrl: './find-product-items-result.component.html'
})

export class FindProductItemsResultComponent {
    @Input() productItems: ProductItem[];
    @Output() productItemSelected = new EventEmitter<ProductItem>();

    selectProductItem(productItem: ProductItem) {
        this.productItemSelected.emit(productItem);
    }
}