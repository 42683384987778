import { User } from './user';
import { NotificationFile } from './notificationFile';

export class UserNotification {
    id: number;
    userId: string;
    user: User;
    descriptionType: number;
    isRead: boolean;
    isDeleted: boolean;
    notificationType: number;
    notificationFile: NotificationFile;
    linkedId?: number;
    sourcePageType: number; 
    createdOn: Date;
}