import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-activation-result',
    templateUrl: './activation-result.html'
})
export class ActivationResultComponent {
    @Input() activationResult: string;
    @Input() warrantyCode: string = undefined;
    @Input() isSeller: boolean;
    @Output() resetComponent = new EventEmitter<boolean>();

    constructor() { }

    resetSteps() {
        this.resetComponent.emit(true);
    }
}
