import { Component, Input, Output, EventEmitter, Inject, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { ShipmentProductItem } from '@src/model/shipmentProductItem';
import { ConfigService } from '@src/services/layout/config.service';
import { ShipmentsService } from '@src/services/shipments/shipments.service';
import { ActivatedRoute } from '@angular/router';
import { DownloadFileService } from '@src/services/files/downloadfile.service';
import { UserRolesEnum } from '@src/model/userRoles';
import { Shipment } from "@src/model/shipment";
import { IAuthService } from '@src/auth/auth.service.interface';
import { ToastrService } from 'ngx-toastr';  
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-shipment-details-items-table',
    templateUrl: './shipment-details-items-table.component.html',
})
export class ShipmentDetailsItemsTableComponent extends NgxDatatableBase implements OnInit {
    @Input() items: ShipmentProductItem[];
    @Input() shipment: Shipment;
    showWarrantyCode: boolean;
    model = new PagedTableModel();

    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    isAddingSerial = false;
    isDeletingSerial = false;
    @Input() editEnabled = true;
    serial: Array<any> = [];
    constructor(
        private router: Router,
        private toastr: ToastrService,
        @Inject('AuthService')
        private authService: IAuthService,
        private configService: ConfigService,
        private translateService: TranslateService,
        private shipmentsService: ShipmentsService,
        private downloadFileService: DownloadFileService,
        private route: ActivatedRoute) {
        super(translateService);

        if(environment.clientName == "oris") {
          if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN, UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER])){
            this.editEnabled = false;
          }
        } else {
          if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN])){
            this.editEnabled = false;
          }
        }
        
    }

    ngOnInit() {
        this.model.rows = this.items;
    }

    exportShipment() {
        this.route.params.subscribe(params => {
            const shipmentId = + params['id'];
            this.shipmentsService.exportShipment(shipmentId).subscribe(data => this.downloadFileService.downloadFile(data));
        });
    }

    revalidateShipment(){
        this.shipmentsService.revalidateShipment(this.shipment.referenceNumber).subscribe( data =>
            {
                if(data){
                    location.reload(); 
                }
            }
        );
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }

    onSaveEvent(serials) {     
        this.serial = [];
        serials.forEach(element => {
            this.serial.push(element.serialNumber);
        });
        this.shipmentsService.addSerialNumber(this.shipment.retailer.code.trim(), this.shipment.id, this.serial).subscribe(result => {           
            if(result) {
                if(result.received == result.created) {
                    location.reload();                
                } else if(result.errored > 0) {
                    var message = this.translateService.instant('Components.Shipments.error-saving');
                    if(result.details){
                        result.details.forEach(details => {
                           if(details.type === 'Error'){
                               if (details.id) message += details.id;
                               else message += details.description +"\n";
                           }
                        });
                    }                    
                    this.toastr.warning(message);           
                }   
            }                     
        });;
    }

    onDeleteEvent(serials) {     
        this.serial = [];
        serials.forEach(element => {
            this.serial.push(element.serialNumber);
        });
        this.shipmentsService.deleteSerialNumber(this.shipment.retailer.code.trim(), this.shipment.id, this.serial).subscribe(result => {           
            if(result) {
                if(result.received == result.created) {
                    location.reload();                
                } else if(result.errored > 0) {
                    var message = this.translateService.instant('Components.Inventories.error-saving');
                    if(result.details){
                        result.details.forEach(details => {
                           if(details.type === 'Error'){
                               if (details.id) message += details.id;
                               else message += details.description +"\n";
                           }
                        });
                    }                    
                    this.toastr.warning(message);           
                }   
            }                     
        });;
    }
    revertModalEvent(button){
        //const val = event.target.value.toLowerCase();
        
        this.isAddingSerial = true;
        this.isDeletingSerial = true;
    }
}
