import { Component, Input, Inject } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { SalesService } from '@src/services/sales/sales.service';
import { Sale } from '@src/model/sale';
import { Router } from '@angular/router';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-global-search-sale',
  templateUrl: './global-search-sale.component.html',
  styleUrls: ['./global-search-sale.component.scss']
})

export class GlobalSearchSaleComponent {
      @Input() placeholder: string;
      @Input() tooptip: string;

      searchTerm: string;
      saleResult : Sale;
      notFound = false;
      configOrientation: string;
      isLoading = false;


      constructor(
        @Inject('AuthService')
        private authService : IAuthService,
        private salesService: SalesService,
        private router: Router,
        private configService: ConfigService) {
          this.configOrientation = this.configService.templateConf.layout.dir;
        }

      changeSearchTerm(e) {
        if (!e.target.value || e.target.value === '') {
          return;
        } else {
            this.searchTerm = e.target.value;
        }
      }

      searchSale() {
        if (this.searchTerm === undefined || this.searchTerm === '' || this.isLoading === true) {
          this.isLoading = false;
          return;
        }
        this.isLoading = true;
        this.salesService.getSalesBySerialNumberOrWarrantyCard(this.searchTerm).subscribe(data => {
              if (data.length === 1) {
                this.saleResult = data[0];
                this.router.navigateByUrl(`/sales/search/${this.saleResult.id}`);
                this.notFound = false;
                this.searchTerm = undefined;
              } else if (data.length > 1) {
                this.router.navigateByUrl(`/sales/search/list/${this.searchTerm}`);
                this.notFound = false;
                this.searchTerm = undefined;
              } else {
                this.notFound = true;
              }
              this.isLoading = false;
          });
      }
}
