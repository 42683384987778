import { Inject, OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { RouteInfo } from './sidebar.metadata';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { UserRolesEnum } from '@src/model/userRoles';
import { UsersService } from '@src/services/users/users.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ConfigService } from '@src/services/layout/config.service';

declare var $: any;

@Component({ selector: 'app-sidebar', templateUrl: './sidebar.component.html' })

export class SidebarComponent implements OnInit {
    public Title: string;
    public LogoUrl: string;
    public LogoClient: string;
    public RoutesInfo: RouteInfo[];
    public topMenuItems: any[];
    public bottomMenuItems: any[];
    public currentUser: UserProfile;
    private hasCRM: boolean;
    constructor(public accessRules: UserAccessRoleRules,
        @Inject('AuthService') public authService: IAuthService,
        public usersService: UsersService,
        public retailersService: RetailersService,
        public configService: ConfigService) {
        this.RoutesInfo = [];
        this.authService.getCurrentUser().subscribe(v => {
            this.currentUser = v;
            if (this.currentUser == undefined) {
                this.hasCRM = false;
                this.getMenuItems();
            } else if (this.currentUser.role[0] === UserRolesEnum.RETAILER || this.currentUser.role[0] === UserRolesEnum.MASTER_RETAILER) {
                this.usersService.getUser(this.currentUser.sub).subscribe(user => {
                    if (user == undefined) {
                        return;
                    }
                    this.hasCRM = false;
                    this.getMenuItems();
                    //this.checkCardOrderingRoles();
                    if (user.retailers.length > 0) {
                        user.retailers.forEach(element => {
                            this.retailersService.getSettingsByRetailerId(element.id).subscribe(sts => {
                                if (sts) {
                                    if(sts.find(setting => setting.type === 'CRM') !== undefined){
                                        this.hasCRM = true;
                                        this.getMenuItems();
                                        //this.checkCardOrderingRoles();
                                    }
                                }
                            });
                        });
                    }
                });
            } else if (this.currentUser.role[0] === UserRolesEnum.ADMIN) {
                this.usersService.getUser(this.currentUser.sub).subscribe(user => {
                    if (user == undefined) {
                        return;
                    }
                    // An admin will always see the customer link 
                    // https://dentsutracking.visualstudio.com/Luxe%20V2/_workitems/edit/21682/
                    this.hasCRM = true;
                    this.getMenuItems();
                    //this.checkCardOrderingRoles();

                });

            } else {
                this.hasCRM = false;
                this.getMenuItems();
                //this.checkCardOrderingRoles();
            }
        });
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        $.getScript('./assets/js/vertical-timeline.js');
        this.getMenuItems();
        //this.checkCardOrderingRoles();
    }

    get isExternalPage(): boolean {
        return this.configService.templateConf.isPublicPage;
    }

    private getMenuItems() {
        const menuItems = this.RoutesInfo.filter(menuItem => {
            let isAllowed = menuItem.accessRuleKey === 'public';
            if (!isAllowed && menuItem.accessRuleKey) {
                const allowedRoles = this.accessRules.rules[menuItem.accessRuleKey];
                if (allowedRoles && allowedRoles.length > 0) {
                    if (this.currentUser == null) {
                        isAllowed = false;
                        return;
                    } else {
                        isAllowed = this.currentUser.isInRoles(allowedRoles);
                    }
                }
            }
            if (menuItem.path == 'customers') {
                isAllowed = this.hasCRM;
            }
            return isAllowed
                ? menuItem
                : null;
        });

        this.topMenuItems = menuItems.filter(menuItem => menuItem.position === 'top');
        this.bottomMenuItems = menuItems.filter(menuItem => menuItem.position === 'bottom');
    }

    private checkCardOrderingRoles(){        
            let isCardOrdering = this.topMenuItems.some(x => x.accessRuleKey === 'cardordering');

            if(isCardOrdering && this.currentUser.isInRole('market admin')) {
                
                this
                .usersService
                .getMyUser()
                .subscribe(u =>{            
                    if(!u.markets.some(m => m.code === 'GB') && !u.markets.some(m => m.code === 'CH')){ 
                        this.topMenuItems = this.topMenuItems.filter(item => item.accessRuleKey !== 'cardordering');
                    }     

                });            
            }    
    }

    // deleteMsg(msg:string) {
    //     const index: number = this.topMenuItems.indexOf(msg);
    //     if (index !== -1) {
    //         this.topMenuItems.splice(index, 1);
    //     }        
    // }
}
