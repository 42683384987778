import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';

@Component({
    selector: 'app-activation-select-type-messika',
    templateUrl: './activation-select-type.html'
})
export class ActivationSelectTypeComponentMessika extends ActivationSelectTypeComponent {

}
