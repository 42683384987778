import { Component, Input} from '@angular/core';
import { Product } from '@src/model/product';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-wizard-find-products-result',
    templateUrl: './wizard-find-products-result.html',
    styleUrls: ['./wizard-find-products-result.scss']
})
export class WizardFindProductsResultComponent {
    @Input() product: Product;
    @Input() isSelected: boolean;
    
    chevronsIconbyOrientation: string;

    constructor(private configService: ConfigService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
            'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';
    }
}