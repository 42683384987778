import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IAuthService } from './auth.service.interface';
import { UserProfile } from './user-profile';
import { UserAccessRoleRules } from './user-access-roles-rules';
import { UserRolesEnum } from '@src/model/userRoles';

@Injectable()
export class AuthGuard implements CanActivate {

  private currentUser: UserProfile;
  constructor(@Inject('AuthService') private authService: IAuthService,
    private router: Router, private accessRules: UserAccessRoleRules) {
      this.authService.getCurrentUser().subscribe(v => {
        this.currentUser = v;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      if (route.data && route.data.accessRuleKey) {
        const allowedRoles = this.accessRules.rules[route.data.accessRuleKey];
        if (!allowedRoles || allowedRoles.length === 0) {
          return true;
        }
        const currentUser = this.currentUser;
        if (currentUser === null) {
          return false;
        }
        return currentUser.isInRoles(allowedRoles);
      }
      return true;
    } else {
      if (route.data && route.data.accessRuleKey) {
        const allowedRoles = this.accessRules.rules[route.data.accessRuleKey];
        if (allowedRoles && allowedRoles.length > 0 && allowedRoles.includes(UserRolesEnum.ANONYMOUS)) {
          return true;
        }
      }
      // Store the attempted URL for redirecting
      localStorage.setItem('LastRequestedRoute', state.url);
      // Navigate to the login page
      this
        .router
        .navigate(['/login']);
      return false;
    }
  }
}