import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { ProductsService } from '@src/services/products/products.service';
import { ProductItem } from '@src/model/productItem';

@Component({
    selector: 'app-activation-find-sn-wc',
    templateUrl: './activation-find-sn-wc.html'
})
export class ActivationFindSNWCComponent {

    @Output() onWarrantyCardRecieved = new EventEmitter<WarrantyCard>();
    @Output() onProductItemRecived = new EventEmitter<ProductItem>();

    warrantyCardresult: string;
    productItems: ProductItem[];
    code = '';
    WarrantyCardRecived: WarrantyCard;
    warrantyCardsRecieved: WarrantyCard[] = [];

    constructor(protected warrantycardsService: WarrantycardsService, protected productsService: ProductsService) { }


    getPairedItem(): void {
        if (!this.code || this.code === '') {
            return;
        }
        this.warrantycardsService.GetPrePaired(this.code)
            .subscribe(warrantyCards => {
                if (warrantyCards.length != 0) {
                    if (warrantyCards.length === 1) {
                        const warrantyCard = warrantyCards[0];

                        if (!warrantyCard.isUsed) {
                            this.warrantyCardresult = 's';
                            this.onWarrantyCardRecieved.emit(warrantyCard);
                            this.WarrantyCardRecived = warrantyCard;

                            this.productsService.getProductItemsBySN(warrantyCard.prePairedSerialNumber)
                            .subscribe(productsItems => {
                                if (productsItems) {
                                    this.productItems = productsItems;
                                    this.onProductItemRecived.emit(this.productItems[0]);
                                }
                            });
                        } else {
                            this.warrantyCardresult = 'u';
                        }
                    } else if (warrantyCards.length > 1) {
                        let isUsedWc = warrantyCards.filter(e => e.isUsed);                        
                        if (isUsedWc.length > 0) {
                            this.warrantyCardresult = 'u'; 
                        }
                        else{                            
                            let wcSorted = warrantyCards.sort((a, b) => a.id - b.id);                            
                            this.warrantyCardsRecieved = wcSorted.slice(wcSorted.length - 1, wcSorted.length);   
                        }  
                    }
                } else {
                    this.warrantyCardresult = 'e';
                }
            });
    }

    getWarrantyCardOrSnByCodeKeyDonw(event) {
        if (event.keyCode === 13) {
            this.getPairedItem();
        }
    }

    selectWarrantyCard(warrantyCard: WarrantyCard, index: string) {
        this.productsService.getProductItemsBySN(warrantyCard.prePairedSerialNumber)
            .subscribe(productsItems => {
            if (productsItems) {
                this.productItems = productsItems;
                this.onWarrantyCardRecieved.emit(warrantyCard);
                this.onProductItemRecived.emit(this.productItems[index]);
            }
        });
    }

    reset() {
        this.warrantyCardresult = undefined;
        this.code = '';
    }
}