import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShipmentProductItem } from '@src/model/shipmentProductItem';
import { ToastrService } from 'ngx-toastr';
import { zip } from 'rxjs';
@Component(
    {
        selector: 'app-delete-serial-number-inventory',
        templateUrl: './shipment-items-delete-serial-number.component.html',
        styleUrls: ['./shipment-items-delete-serial-number.scss']
    })

export class ShipmentItemDeleteSerialNumberComponent {
    private newAttribute: any = { "serialNumber": "", "validationError": ""};
    public fieldArray: Array<any> = [];
    @Input() items: ShipmentProductItem[];
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    constructor(private toastr: ToastrService, private translateService: TranslateService) {
    }
    ngOnInit(): void {
        this.newAttribute = { "serialNumber": "", "validationError": "" };
        this.fieldArray.push(this.newAttribute)
    }

    onKeyUp(e) {
        if (this.fieldArray.length > 0){
            this.fieldArray.forEach(a => {
                if (a.serialNumber.length == 0) {
                    a.validationError = this.translateService.instant('validation-errors.SerialNumberMandatory');
                }
                else if(this.items.filter(e => e.serialNumber === a.serialNumber).length == 0 ) {
                    a.validationError = this.translateService.instant('validation-errors.SerialNumberAlready');
                } else {
                    a.validationError = null;
                }
            });
        }

    }

    onCancel() {
        this.fieldArray = [];
        this.cancel.emit();
    }

    onDelete() {
        if(this.fieldArray.find(x => x.validationError !== null)){
            return;
        }
        this.save.emit(this.fieldArray);
    }

    addFieldValue() {
        this.newAttribute = { "serialNumber": "", "validationError": "" };
        this.fieldArray.push(this.newAttribute)
    }

    deleteFieldValue(index) {
        if (this.fieldArray.length != 1) {
            this.fieldArray.splice(index, 1);
        }
    }
}