import { Component, Input } from '@angular/core';
import { ImportedRetailerFileEntry } from '@src/model/importedRetailersFileEntry';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ImportedDataService } from '@src/services/importedData.service';

@Component({
    selector: 'app-imported-retailers-entries',
    templateUrl: './imported-retailers-entries.component.html',
    styleUrls: ['./imported-retailers-entries.component.scss']
})
export class ImportedRetailersEntriesComponent extends NgxDatatableBase {
    importedRetailerFileEntries: ImportedRetailerFileEntry[] = [];
    onLoading = false;
    fileId: number;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        translateService: TranslateService) {
        super(translateService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];
                this.onLoading = true;

                this.importDataService.getRetailerLogEntries(this.fileId).subscribe(
                    entries => {
                        entries.forEach(e => e.marketsStr = e.markets && e.markets.length > 0 ?
                            e.markets.map(m => m.isNew ? '*' + m.name : m.name).join(', ') : '-');
                        this.importedRetailerFileEntries = entries.sort((a,b) => a.lastUpdatedDate > b.lastUpdatedDate ? 1 : -1);
                        this.onLoading = false;
                    }
                );
            }
        });
    }
}