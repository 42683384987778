import { Retailer } from './retailer';
import { User } from './user';
import { InventoryProductItem } from './inventoryProductItem';

export class Inventory {
    id: number;
    inventoryProductItems: InventoryProductItem[];
    retailerId: string;
    retailer: Retailer;
    referenceNumber: string;
    inventoryName: string;
    comment: string;
    createdOn: Date;
    createdBy: User;
    isLast: boolean;
    inventoryCreationDate: Date;
}
