import { WarrantyOperationType } from '../Enums/warrantyOperationType';

export class WarrantyOperation {
    id: number;
    warrantyId: number;
    operationType: WarrantyOperationType;
    data: string;
    operationDate: Date;
    comment: string;
    soldBy : string;
}