import { Component, OnInit, Output, EventEmitter, Inject, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { DateInterval } from '@src/model/DateInterval';
import { Observable } from 'rxjs';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-dashboard-default-filter-base',
  template: './dashboard-default-filter-base.component.html'
})

export class DashboardDefaultFilterBaseComponent implements OnInit {
  selectedTimeRange: string;
  timeRangeList: string[];
  inputQuery: string;
  inputProductQuery: string;
  customFrom?: Date;
  customTo?: Date;
  loggedUser: UserProfile;
  displayMap = false;
  layoutDirection: string;
  isOpen = true;

  @Output() onChangeTimeRange = new EventEmitter<{ from: Date, to: Date, interval: string }>();
  @Output() onChangeLocation = new EventEmitter<{ marketId?: number, retailerId?: number }>();
  @Output() onChangeProductInfo = new EventEmitter<{ familyId?: number, productModel: string }>();
  @Output() onClearFilter = new EventEmitter<{ from: Date, to: Date, interval: string, marketId?: number, retailerId?: number, familyId?: number, productModel: string }>();
  @Output() toggleMap = new EventEmitter<boolean>();
  @Output() callExportSales = new EventEmitter<boolean>();

  constructor(
    @Inject('AuthService') protected authService: IAuthService,
    protected retailersService: RetailersService,
    protected productsService: ProductsService,
    protected configService: ConfigService) {

      this.layoutDirection = this.configService.getLayoutOrientation();
      this.authService.getCurrentUser().subscribe(u => {
        this.loggedUser = u;
      });
  }
  ngOnInit() {
    this.selectedTimeRange = 'last-month';
    this.timeRangeList = [
      'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'custom'
    ];
  }

  clear() {
    this.selectedTimeRange = 'last-month';
    this.onClearFilter.emit({...this.computeTimeRange(this.selectedTimeRange), marketId: null, retailerId: null, familyId: null, productModel: null});
    this.inputQuery = '';
    this.inputProductQuery = '';
  }

  changeTimeRange(event) {
    if (this.selectedTimeRange !== 'custom') {
      this.onChangeTimeRange.emit(this.computeTimeRange(this.selectedTimeRange));
    }
  }
  selectCustomFrom(date: any) {
    this.onChangeTimeRange.emit(this.computeInterval(this.customFrom, this.customTo));
  }
  selectCustomTo(date: any) {
    this.onChangeTimeRange.emit(this.computeInterval(this.customFrom, this.customTo));
  }

  toggleDisplayMap() {
      this.displayMap = !this.displayMap;
      this.toggleMap.emit(this.displayMap);
  }

  changeLocationy(target: any) {
    this.inputQuery = target.item.name;
    this.onChangeLocation.emit(target.item.type === 'r' ? { retailerId: target.item.id, marketId: null } : { retailerId: null, marketId: target.item.id });
  }

  selectProductEntry(target: any) {
    this.inputProductQuery = target.item.name;
    this.onChangeProductInfo.emit(target.item.type === 'f' ? { familyId: target.item.id, productModel: null } : { familyId: null, productModel: target.item.model });
  }

  formatter = (x: { name: string }) => x.name;
 
  searchEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .filter(value => typeof value === "string")
      .filter(value => {
        return value.length >= 4;
      })
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'name', 'marketname')
      )

  searchProductEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .filter(value => typeof value === "string")
      .filter(value => {
        return value.length >= 4;
      })
      .distinctUntilChanged()
      .switchMap(term =>
          this.productsService.getProductsNamesByModel(term)
      )

  public computeTimeRange(timeRange: string): { from: Date, to: Date, interval: string } {
    const now = new Date();
    switch (timeRange) {
      case 'last-month': {
        return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
      }
      case 'last-week': {
        return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
      }
      case 'last-year': {
        return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Month };
      }
      case 'last-5-years': {
        return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Year };
      }
      default: {
        return { from: null, to: null, interval: DateInterval.Month };
      }
    }
  }

  private computeInterval(from = null, to = null): { from: Date, to: Date, interval: string } {
    const _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
    const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');
    const timeDif = _to.diff(_from);
    const halfYear = moment.duration(6, 'M').asMilliseconds();

    return timeDif > halfYear ? { to: _to.toDate(), from: _from.toDate(), interval: DateInterval.Month }
      : { to: _to.toDate(), from: _from.toDate(), interval: DateInterval.Day };
  }

  exportSales() {
    this.isOpen = !this.isOpen;
    this.callExportSales.emit(true);
  }
  
}
