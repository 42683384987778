import { PrivacyComponentParameters } from '@src/model/privacyComponentParameters';
import { ConfigService } from '@src/services/layout/config.service';

export class PrivacyBaseComponent {
    params: PrivacyComponentParameters = {
        appName: '',
        companyName: '',
        products: '',
        ContactEmail: ''
    };

    logoUrl: '';
    layoutOrientation: string;

    constructor(params: PrivacyComponentParameters, logoUrl, configService: ConfigService) {
        this.params.appName = params.appName;
        this.params.companyName = params.companyName;
        this.params.products = params.products;
        this.logoUrl = logoUrl;
        this.layoutOrientation = configService.templateConf.layout.dir;
        this.params.ContactEmail = params.ContactEmail;

        configService.setIsPublicPage(true);
    }
}