import { Component, OnInit } from '@angular/core';
import { ImportedDataService } from '@src/services/importedData.service';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { ImportedFile } from '@src/model/importedFile';
import { ActivatedRoute } from '@angular/router';
import { ImportedProductsFileEntry } from '@src/model/importedProductsFileEntry';
import { ImportedFileError } from '@src/model/importedFileError';
import { DownloadFileService } from '@src/services/files/downloadfile.service';
import { ImportedFileHelper } from '@src/base-components/management/utils/imported-file-helper';
import { UploadType } from '@src/model/Enums/uploadType';
import { ImportedProductsFileMetrics } from '@src/model/importedProductsFileMetrics';

@Component({
    selector: 'app-imported-products-log',
    templateUrl: './imported-products-log.component.html'
})
export class ImportedProductsLogComponent extends NgxDatatableBase implements OnInit {
    importedProduct: ImportedFile = new ImportedFile();
    fileId: number;
    onLoading = false;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        private downloadFileService: DownloadFileService,
        translateService: TranslateService) {
        super(translateService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];
                this.onLoading = true;
                this.importDataService.getProductLog(this.fileId).subscribe(
                    p => {
                        p.uploadStatusStr = ImportedFileHelper.computeFileStatus(p.uploadStatus);
                        p.uploadTypeStr =  UploadType[p.uploadType];
                        this.importedProduct = p
                    }
                );
            }
        });
    }

    downloadProductFile() {
        this.importDataService.downloadProductLog(this.fileId).subscribe(data => {
            this.downloadFileService.downloadFile(data);
        });
    }

    get HaveErrorStatus() {
        return this.importedProduct.uploadStatusStr === 'error';
    }
}