import { Retailer } from './retailer';
import { User } from './user';
import { ShipmentProductItem } from './shipmentProductItem';

export class Shipment {
    id: number;
    shipmentProductItems: ShipmentProductItem[];
    retailerId: string;
    retailer: Retailer;
    referenceNumber: string;
    inventoryName: string;
    comment: string;
    createdOn: Date;
    createdBy: User;
    isLast: boolean;
    inventoryCreationDate: Date;
}
