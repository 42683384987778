import { productitems } from './productItems-data';
import { warranties } from './warranties-data';

export const sales = [
   {
      'id': 26503,
      'date': '2018-09-10T03:25:38.9018153',
      'endDate': '2020-09-10T03:25:38.9018153',
      'warrantyId': 15539,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 691953,
      'productSku': '01 733 7720 3185-Set LS',
      'productName': 'Oris Carl Brashear Limited Edition',
      'isFake': true,
      'warrantyCardCode': '000162780034',
      'serialNumber': '77200082',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 26486,
      'date': '2018-09-06T00:00:00.002',
      'endDate': '2020-09-06T00:00:00.002',
      'warrantyId': 15522,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720040,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000245186660',
      'serialNumber': '7720220',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.6364589',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.6699404'
   },
   {
      'id': 25059,
      'date': '2018-09-28T03:31:27.6909593',
      'endDate': '2020-09-28T03:31:27.6909593',
      'warrantyId': 14095,
      'retailerId': 3175,
      'deviceId': null,
      'productItemId': 965751,
      'productSku': '01 561 7718 4071-07 8 14 10',
      'productName': 'Oris Classic Date',
      'isFake': true,
      'warrantyCardCode': '000239956850',
      'serialNumber': '3651793',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 3175,
         'code': '5190',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': 'An Hui',
         'zip': '230031',
         'city': 'Westeros',
         'isoCountry': 'GB',
         'latitude': '31.81858218',
         'longitude': '117.2216218',
         'isActive': true,
         'marketId': 181,
         'email': 'chenyuanyuan@harmonywatch.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:13:16.642208',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:13:16.642208'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:27.6828617',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:57.7026541'
   },
   {
      'id': 23794,
      'date': '2018-10-21T01:59:52.4615612',
      'endDate': '2020-10-21T01:59:52.4615612',
      'warrantyId': 12830,
      'retailerId': 2851,
      'deviceId': null,
      'productItemId': 1071893,
      'productSku': '01 733 7730 4135-07 8 24 05PEB',
      'productName': 'Oris Aquis Date',
      'isFake': false,
      'warrantyCardCode': '000243023207',
      'serialNumber': '3726995',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 2851,
         'code': '5895',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '04530',
         'city': 'Westeros',
         'isoCountry': 'GB',
         'latitude': '37.56065061',
         'longitude': '126.9935417',
         'isActive': true,
         'marketId': 165,
         'email': 'amanda.park@bonanza11.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:09:50.4300042',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:09:50.4300042'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:12.7359018',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:42.7627593'
   },
   {
      'id': 23650,
      'date': '2018-08-03T05:40:53.5734549',
      'endDate': '2020-08-03T05:40:53.5734549',
      'warrantyId': 12686,
      'retailerId': 2851,
      'deviceId': null,
      'productItemId': 611171,
      'productSku': '01 781 7729 4031-07 5 21 65FC',
      'productName': 'Oris Artelier Complication',
      'isFake': true,
      'warrantyCardCode': '000237529335',
      'serialNumber': '3665757',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 2851,
         'code': '5895',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '04530',
         'city': 'Westeros',
         'isoCountry': 'GB',
         'latitude': '37.56065061',
         'longitude': '126.9935417',
         'isActive': true,
         'marketId': 165,
         'email': 'amanda.park@bonanza11.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:09:50.4300042',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:09:50.4300042'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:11.2952992',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:41.3277762'
   },
   {
      'id': 23587,
      'date': '2018-07-30T04:29:15.4134931',
      'endDate': '2020-07-30T04:29:15.4134931',
      'warrantyId': 12623,
      'retailerId': 2849,
      'deviceId': null,
      'productItemId': 1066562,
      'productSku': '01 733 7730 4135-07 4 24 65EB',
      'productName': 'Oris Aquis Date',
      'isFake': false,
      'warrantyCardCode': '000239884725',
      'serialNumber': '3683980',
      'isReturned': true,
      'lastOperationType': 2,
      'device': null,
      'retailer': {
         'id': 2849,
         'code': '5810',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '34956',
         'city': 'Westeros',
         'isoCountry': 'GB',
         'latitude': '36.32065511',
         'longitude': '127.4086887',
         'isActive': true,
         'marketId': 165,
         'email': 'ghost9801@naver.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:09:42.973603',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:09:42.973603'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:10.6346947',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11: 23: 57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:40.655018'
   },
   {
      'id': 26778,
      'date': '2018-12-11T02:56:24.6437828',
      'endDate': '2020-12-11T02:56:24.6437828',
      'warrantyId': 15814,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720312,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000247421029',
      'serialNumber': '7720336',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Westeros',
         'isoCountry': 'GB',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1678573',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1678573'
   },
   {
      'id': 26777,
      'date': '2018-12-11T02:56:20.003333',
      'endDate': '2020-12-11T02:56:20.003333',
      'warrantyId': 15813,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720277,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000247421107',
      'serialNumber': '7720121',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [

         ],
         'retailers': [

         ]
      },
      'createdOn': '2018-12-20T12:07:39.1543609',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1543609'
   },
   {
      'id': 26776,
      'date': '2018-12-11T02:55:58.0511911',
      'endDate': '2020-12-11T02:55:58.0511911',
      'warrantyId': 15812,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720013,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000245233741',
      'serialNumber': '7720187',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1466183',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1466183'
   },
   {
      'id': 26775,
      'date': '2018-12-04T03:24:32.5020955',
      'endDate': '2020-12-04T03:24:32.5020955',
      'warrantyId': 15811,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720261,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000245198641',
      'serialNumber': '7720486',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1339386',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1339386'
   },
   {
      'id': 26774,
      'date': '2018-12-04T03:24:28.2520149',
      'endDate': '2020-12-04T03:24:28.2520149',
      'warrantyId': 15810,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 719904,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000240998268',
      'serialNumber': '7720062',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1288232',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1288232'
   },
   {
      'id': 26773,
      'date': '2018-12-04T03:24:24.1112807',
      'endDate': '2020-12-04T03:24:24.1112807',
      'warrantyId': 15809,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720186,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000245233286',
      'serialNumber': '7720404',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1070911',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1070911'
   },
   {
      'id': 26772,
      'date': '2018-12-04T03:24:20.1112045',
      'endDate': '2020-12-04T03:24:20.1112045',
      'warrantyId': 15808,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 719858,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000240998278',
      'serialNumber': '7720015',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11: 23: 57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.1038874',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.1038874'
   },
   {
      'id': 26771,
      'date': '2018-12-04T03:24:15.9704967',
      'endDate': '2020-12-04T03:24:15.9704967',
      'warrantyId': 15807,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 719928,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000240998350',
      'serialNumber': '7720086',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.090059',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.090059'
   },
   {
      'id': 26770,
      'date': '2018-12-04T03:24:10.9547944',
      'endDate': '2020-12-04T03:24:10.9547944',
      'warrantyId': 15806,
      'retailerId': 3370,
      'deviceId': null,
      'productItemId': 720233,
      'productSku': '01 733 7720 4388-Set LS',
      'productName': 'Oris Formosa Violin Limited Edition',
      'isFake': false,
      'warrantyCardCode': '000245233829',
      'serialNumber': '7720456',
      'isReturned': false,
      'lastOperationType': 1,
      'device': null,
      'retailer': {
         'id': 3370,
         'code': '6310',
         'name': 'Jon snow',
         'address': 'The wall',
         'addressIndications': '',
         'zip': '114',
         'city': 'Taipei City',
         'isoCountry': 'TW',
         'latitude': '25.079717',
         'longitude': '121.569694',
         'isActive': true,
         'marketId': 183,
         'email': 'yoyo@formosawatch.com.tw',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'createdOn': '2018-12-20T00:16:19.979861',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': true,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': 'en',
            'marketId': null,
            'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
            'createdOn': '2018-06-28T11:23:57.72',
            'imageUrl': null,
            'roles': [],
            'retailers': []
         },
         'updatedOn': '2018-12-20T00:16:19.979861'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:39.085869',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:07:39.085869'
   }
];

export const saleDetails = [{
    'sale':   sales[0],
    'warranty':   {
      'id': 15814,
      'warrantyCardId': 721208,
      'productId': 2601,
      'customerId': null,
      'productItemId': 720312,
      'serialNumber': null,
      'warrantyActivationDate':'2018-12-11T02:56:24.6437828',
      'warrantyEndDate':'2020-12-11T02:56:24.6437828',
      'isReturned': false,
      'isDuplicate':false,
      'customer': null,
      'warrantyCard': {
         'id': 721208,
         'code': '000247421029',
         'isUsed': true,
         'prePairedProductItemId': 720312,
         'prePairedSerialNumber': '7720336',
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': false,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': null,
            'marketId': null,
            'privacyPolicyAcceptationDate': null,
            'createdOn': '2018-06-28T11:22:21.2',
            'imageUrl': null,
            'roles': null,
            'retailers': null
         },
         'createdOn': '2018-12-18T21:12:27.7294691',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': {
            'id': '99999999-9999-9999-9999-999999999999',
            'isActive': false,
            'firstName': 'WAS',
            'lastName': 'System',
            'userName': 'was-system@b-i.com',
            'email': 'was-system@b-i.com',
            'locale': null,
            'marketId': null,
            'privacyPolicyAcceptationDate': null,
            'createdOn': '2018-06-28T11:22:21.2',
            'imageUrl': null,
            'roles': null,
            'retailers': null
         },
         'updatedOn': '2018-12-20T00:16:52.8840092'
      },
      'sellerId': '99999999-9999-9999-9999-999999999999',
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': false,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': null,
         'marketId': null,
         'privacyPolicyAcceptationDate': null,
         'createdOn': '2018-06-28T11:22:21.2',
         'imageUrl': null,
         'roles': null,
         'retailers': null
      },
      'warrantyOperations':[
         {
            'id': 16407,
            'warrantyId': 15814,
            'operationType': 1,
            'data': '{\'IsSerializedItem\':false,\'WarrantyCardId\':721208,\'ProductId\':2601,\'ProductSku\':\'01 733 7720 4388-Set LS\',\'ProductName\':\'Oris Formosa Violin Limited Edition\',\'CustomerId\':null,\'ProductItemId\':720312,\'ProductItemSerialNumber\':\'7720336\',\'WarrantyActivationDate\':\'2018-12-11T02:56:24.6437828\',\'WarrantyEndDate\':\'2020-12-11T02:56:24.6437828\',\'IsReturned\':false,\'IsDuplicate\':false,\'RetailerId\':3370,\'SellerId\':\'99999999-9999-9999-9999-999999999999\',\'IsFake\':false,\'Distribution\':null}',
            'comment': null,
            'createdById': '99999999-9999-9999-9999-999999999999',
            'createdBy': {
               'id': '99999999-9999-9999-9999-999999999999',
               'isActive': false,
               'firstName': 'WAS',
               'lastName': 'System',
               'userName': 'was-system@b-i.com',
               'email': 'was-system@b-i.com',
               'locale': null,
               'marketId': null,
               'privacyPolicyAcceptationDate': null,
               'createdOn': '2018-06-28T11:22:21.2',
               'imageUrl': null,
               'roles': null,
               'retailers': null
            },
            'createdOn': '2018-12-11T02:56:24.6437828',
            'updatedById': '00000000-0000-0000-0000-000000000000',
            'updatedBy': null,
            'updatedOn': '2018-12-20T00:16:52.8801407'
         }
      ],
      'productSku': null,
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': false,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': null,
         'marketId': null,
         'privacyPolicyAcceptationDate': null,
         'createdOn': '2018-06-28T11:22:21.2',
         'imageUrl': null,
         'roles': null,
         'retailers': null
      },
      'createdOn': '2018-12-11T02:56:24.6437828',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': false,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': null,
         'marketId': null,
         'privacyPolicyAcceptationDate': null,
         'createdOn': '2018-06-28T11:22:21.2',
         'imageUrl': null,
         'roles': null,
         'retailers': null
      },
      'updatedOn': '2018-12-20T00:16:52.8705545'
   },
   'productItem': {
      'id': 720312,
      'productId': 2601,
      'serialNumber': '7720336',
      'isFake': false,
      'isUsed': true,
      'product': {
         'id': 2601,
         'name': 'Oris Formosa Violin Limited Edition',
         'sku': '01 733 7720 4388-Set LS',
         'description': 'Oris Divers, 42.00 mm, 1.654 inches, Stainless steel',
         'productDate': '2018-10-03T00:00:00',
         'productFamilyId': 387,
         'productTypeId': 26,
         'isSerializable': true,
         'iscustomizable': true,
         'isWarrantyCardMandatory': true,
         'imageUrl': 'https://www.oris.ch/data/24184_0173377204388-SetLS_verticale.png',
         'productFamily': null,
         'productType': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-12-18T21:12:05.9314994',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-12-18T21:12:05.9314994'
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': null,
      'createdOn': '2018-12-18T21:12:27.7055283',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': null,
      'updatedOn': '2018-12-20T00:16:52.8857528'
   }
},
{
    'sale':   sales[1],
    'warranty':   warranties[1],
    'productItem':   productitems[1]
}];