import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { RepairWarranty } from '@src/model/repairWarranty';

@Injectable()
export class RepairsService extends BaseService {
  private baseUrl = environment.apiBaseUrl + '/api/repairwarranties';

  constructor(injector: Injector) {
    super(injector);
  }
  addRepairWarranty(repairWarranty: RepairWarranty): Observable<RepairWarranty> {
    const url = `${this.baseUrl}`;
    return this.post<RepairWarranty>(url, repairWarranty);
  }

  getRepairWarrantiesByWarrantyId(warrantyId: number) : Observable<RepairWarranty[]> {
    const url = `${this.baseUrl}/warranty/${warrantyId}`;
    return this.get<RepairWarranty[]>(url);
  }

}
