import { Component, ViewChild, Inject, OnInit, OnDestroy } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { User } from '@src/model/user';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { ConfigService } from '@src/services/layout/config.service';
import { ProductsFilterComponent } from './filter/products-filter.component';
import { ProductsParameters } from '@src/model/filter-parameters/products-parameters';
import { ProductsService } from '@src/services/products/products.service';
import { ProductItem } from '@src/model/productItem';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})

export class ProductsComponent extends NgxDatatableBase implements OnInit,OnDestroy {
    model = new PagedTableModel();
    firstLoad = false;
    isLoading = false;
    offset = 0;
    queryTermChanged: Subject<string> = new Subject<string>();
    selected: ProductItem[] = [];  
    isSearchResult = false;
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    hasNewFilter: boolean;
    filterParameters: ProductsParameters;
    newfilterParameters: ProductsParameters;
    previousUrl: string;
    currentUrl:string;
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild(ProductsFilterComponent) productFilterComponent: ProductsFilterComponent;

    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private router: Router,
        private route: ActivatedRoute,
        private productsService: ProductsService,
        private translateService: TranslateService,
        private configService: ConfigService) {
        super(translateService);
        this.model.sortOrder = 'updatedOn desc';
        this.setTableIconsByOrientation();
        this.hasNewFilter = this.configService.hasNewFilter().products;
        this.model.rows = [];
        this.currentUrl = this.router.url;
        router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl = this.router.url;
                    var str = this.currentUrl.split("/");
                    var str2 = this.previousUrl.split("/");
                    if(str[1] === str2[1])
                    {
                        // same route
                        let filters = localStorage.getItem('productsFilters');
                        let productsFilters = JSON.parse(filters);
                        if(productsFilters !== null)
                        {
                            this.newfilterParameters = new ProductsParameters();
                            this.newfilterParameters = productsFilters;
                        }
                    }
                    else{
                        // diferent route 
                        localStorage.removeItem('productsFilters');
                    }
                }
            });
    }
    ngOnInit(): void {
        this.productFilterComponent.selectedTimeRange = 'last-month';
        const intervalDates = this.productFilterComponent.computeTimeRange('last-month');
        this.filterParameters = new ProductsParameters();
        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;
        this.filterParameters.selectedTimeRange = 'last-month';
        if(this.newfilterParameters !== undefined){
            this.filterParameters = this.newfilterParameters;
        }
        this.setTableIconsByOrientation();
        this.queryTermChanged
            .debounceTime(1000) // wait 1000ms after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getProducts(1);
            });
        this.getProducts(1);
    }

    ngOnDestroy(): void {
        const productsFilters = JSON.stringify({
            from: this.filterParameters.from,
            to: this.filterParameters.to,
            productSku: this.filterParameters.productSku,
            serialNumber: this.filterParameters.serialNumber,
            yesCPO : this.filterParameters.yesCPO,
            noCPO : this.filterParameters.noCPO,
            selectedTimeRange: this.filterParameters.selectedTimeRange
        });
        localStorage.setItem('productsFilters', productsFilters)
    }
    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.getProducts(1);
    }
    onSearch(event: any) {
        this.queryTermChanged.next(event.target.value.trim());
    }
    setPage(pageInfo) {
        this.getProducts(pageInfo.page);
    }
    getUserName(u: User) {
        return `${u.firstName} ${u.lastName}`;
    }
    getLastAction(action: string) {
        return this.translateService.instant(`Components.Sales.operations.${action.toLowerCase()}`);
    }
    getProducts(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        if (this.hasNewFilter === true) {
            this.filterProducts(page);
        }
    }

    private filterProducts(page: number) {
        this
            .productsService
            .filterProducts(this.filterParameters, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    updateProducts(event) {
        this.filterParameters = event;
        this.getProducts(1);
    }

    GoToProductDetails() {
        this.router.navigateByUrl(`productreferences/${this.selected[0].id}`);
    }
    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }
}
