import { UsersComponent } from './users.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AddUserComponent } from './add-user/add-user.component';
import { UpdateStatusUserComponent } from './user-details/update-status-user/update-status-user.component';
import { UserAddRetailerComponent } from './user-details/user-add-retailer/user-add-retailer.component';
import { UserAddMarketComponent } from './user-details/user-add-market/user-add-market.component';
import { UserInfoDetailsComponent } from './user-details/user-info-details/user-info-details.component';
import { UsersFilterComponent } from './filter/users-filter.component';

export const UsersComponents = [
    UsersComponent,
    UserDetailsComponent,
    AddUserComponent,
    UpdateStatusUserComponent,
    UserAddRetailerComponent,
    UserAddMarketComponent,
    UserInfoDetailsComponent,
    UsersFilterComponent
];
