import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { WarrantyCard } from '@src/model/warranty/warrantycard';

@Component({
    selector: 'app-activation-find-wc',
    templateUrl: './activation-find-wc.html'
})
export class ActivationFindWCComponent {

    @Output() onWarrantyCardRecieved = new EventEmitter<WarrantyCard>();

    warrantyCardresult: string;
    code = '';

    constructor(private warrantycardsService: WarrantycardsService) { }

    getWarrantyCardByCode(): void {
        if (!this.code || this.code === '') {
            return;
        }
        this.warrantycardsService.getWarrantyCardByCode(this.code)
            .subscribe(warrantyCard => {
                if (warrantyCard) {
                    if (!warrantyCard.isUsed) {
                        this.warrantyCardresult = 's';
                        this.onWarrantyCardRecieved.emit(warrantyCard);
                    } else {
                        this.warrantyCardresult = 'u';
                    }
                } else {
                    this.warrantyCardresult = 'e';
                }
            });
    }

    getWarrantyCardByCodeKeyDonw(event) {
        if (event.keyCode === 13) {
            this.getWarrantyCardByCode();
        }
    }

    reset() {
        this.warrantyCardresult = undefined;
        this.code = '';
    }

}
