import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { BaseService } from '../services/base.service';
import { ImportedDataResponse } from '../model/importedDataResponse';
import { ImportedFile } from '../model/importedFile';
import { ImportedProductsFileEntry } from '../model/importedProductsFileEntry';
import { ImportedRetailerFileEntry } from '../model/importedRetailersFileEntry';
import { ImportedProductsFileMetrics } from '../model/importedProductsFileMetrics';
import { ImportedRetailersFileMetrics } from '../model/importedRetailersFileMetrics';
import { ImportedFileError } from '../model/importedFileError';

@Injectable()
export class ImportedDataService extends BaseService {
    protected baseUrl = environment.apiBaseUrl + '/api/importeddata';
    constructor(injector: Injector) {
        super(injector);
    }
    getImportedData(): Observable<ImportedDataResponse> {
        return this.get<ImportedDataResponse>(this.baseUrl);
    }
    getProductLog(id: number): Observable<ImportedFile> {
        const url = this.baseUrl + `/products/${id}`;
        return this.get<ImportedFile>(url);
    }
    downloadProductLog(id: number): Observable<ImportedFile> {
        const url = this.baseUrl + `/products/${id}/download`;
        return this.get<ImportedFile>(url);
    }
    getRetailerLog(id: number): Observable<ImportedFile> {
        const url = this.baseUrl + `/retailers/${id}`;
        return this.get<ImportedFile>(url);
    }
    downloadRetailerLog(id: number): Observable<ImportedFile> {
        const url = this.baseUrl + `/retailers/${id}/download`;
        return this.get<ImportedFile>(url);
    }
    getProductLogErrors(id: number): Observable<ImportedFileError[]> {
        const url = this.baseUrl + `/products/${id}/errors`;
        return this.get<ImportedFileError[]>(url);
    }
    getRetailerLogErrors(id: number): Observable<ImportedFileError[]> {
        const url = this.baseUrl + `/retailers/${id}/errors`;
        return this.get<ImportedFileError[]>(url);
    }
    getProductLogEntries(id: number): Observable<ImportedProductsFileEntry[]> {
        const url = this.baseUrl + `/products/${id}/entries`;
        return this.get<ImportedProductsFileEntry[]>(url);
    }
    getRetailerLogEntries(id: number): Observable<ImportedRetailerFileEntry[]> {
        const url = this.baseUrl + `/retailers/${id}/entries`;
        return this.get<ImportedRetailerFileEntry[]>(url);
    }
    getProductLogMetrics(id: number): Observable<ImportedProductsFileMetrics> {
        const url = this.baseUrl + `/products/${id}/metrics`;
        return this.get<ImportedProductsFileMetrics>(url);
    }
    getRetailerLogMetrics(id: number): Observable<ImportedRetailersFileMetrics> {
        const url = this.baseUrl + `/retailers/${id}/metrics`;
        return this.get<ImportedRetailersFileMetrics>(url);
    }


}

