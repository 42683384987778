export const warranties = [
  {
    'id': 1,
    'warrantyCardId': 89,
    'productId': 2,
    'customerId': null,
    'productItemId': 6,
    'warrantyActivationDate': '2018-05-09T23:00:00',
    'warrantyEndDate': '2020-05-09T23:00:00',
    'isReturned': false,
    'isDuplicate': false,
    'customer': null,
    'warrantyCard': null,
    'sellerId': 'd4a2d75c-597f-4ba1-d79f-08d5bf3d67c7',
    'seller': null,
    'warrantyOperations': [
      {
        'id': 1,
        'warrantyId': 1,
        'operationTypeId': 1,
        'data': '{"WarrantyCardId":89,"ProductId":2,"ProductSku":"01 752 7698 4784-Set","ProductName":"Oris Air Racing Edition V","CustomerId":null,"ProductItemId":6,"ProductItemSerialNumber":"769850573","WarrantyActivationDate":"2018-05-09T23:00:00Z","WarrantyEndDate":"2020-05-09T23:00:00Z","IsReturned":false,"IsDuplicate":false,"RetailerId":1,"SellerId":"d4a2d75c-597f-4ba1-d79f-08d5bf3d67c7","IsFake":false}',
        'createdById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
        'createdBy': null,
        'createdOn': '2018-05-21T19:13:37.5469348',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-05-21T17:13:36.5504636',
        'comment': 'The best comment in world :)'
      }
    ],
    'createdById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
    'createdBy': null,
    'createdOn': '2018-05-21T17:13:35.753532',
    'updatedById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
    'updatedBy': null,
    'updatedOn': '2018-05-21T17:13:35.753532'
  },
  {
    'id':2,
    'warrantyCardId': 89,
    'productId': 2,
    'customerId': null,
    'productItemId': 6,
    'warrantyActivationDate': '2018-05-09T23:00:00',
    'warrantyEndDate': '2020-05-09T23:00:00',
    'isReturned': false,
    'isDuplicate': false,
    'customer': null,
    'warrantyCard': null,
    'sellerId': 'd4a2d75c-597f-4ba1-d79f-08d5bf3d67c7',
    'seller': null,
    'warrantyOperations': [
      {
        'id': 1,
        'warrantyId': 1,
        'operationTypeId': 1,
        'data': '{"WarrantyCardId":89,"ProductId":2,"ProductSku":"01 752 7698 4784-Set","ProductName":"Oris Air Racing Edition V","CustomerId":null,"ProductItemId":6,"ProductItemSerialNumber":"769850573","WarrantyActivationDate":"2018-05-09T23:00:00Z","WarrantyEndDate":"2020-05-09T23:00:00Z","IsReturned":false,"IsDuplicate":false,"RetailerId":1,"SellerId":"d4a2d75c-597f-4ba1-d79f-08d5bf3d67c7","IsFake":false}',
        'createdById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
        'createdBy': null,
        'createdOn': '2018-05-21T19:13:37.5469348',
        'updatedById': '00000000-0000-0000-0000-000000000000',
        'updatedBy': null,
        'updatedOn': '2018-05-21T17:13:36.5504636',
        'comment': 'very nice comment :)'
      }
    ],
    'createdById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
    'createdBy': null,
    'createdOn': '2018-05-21T17:13:35.753532',
    'updatedById': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
    'updatedBy': null,
    'updatedOn': '2018-05-21T17:13:35.753532'
  }
];