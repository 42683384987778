import { Component } from '@angular/core';
import { AlertMessageComponent } from './alert-message.component';

@Component({
    selector: 'app-info-message',
    templateUrl: './alert-message.component.html',
    styles: [`:host >>> .alert-custom {
        background-image: linear-gradient(45deg,#0288d1,#26c6da);
    }`]
})

export class InfoMessageComponent extends AlertMessageComponent {
}