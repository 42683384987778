import { Component, Input, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { UserNotification } from '@src/model/notification';
import { TranslateService } from '@ngx-translate/core';
import { ExportedFile } from '@src/model/exportedFile';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { DatePipe } from '@angular/common';
import { DownloadFileService } from '@src/services/files/downloadfile.service';

@Component({
  selector: 'app-notification-element',
  templateUrl: './notification.element.component.html',
  styleUrls: ['../notifications.component.scss'],
  providers: [DatePipe]
})

export class NotificationElementComponent implements OnInit {
    @Input() notification: UserNotification = new UserNotification();
    @Input() isEmpty: boolean;
    @Output() updatedNotification = new EventEmitter<UserNotification>();
    inProgress = false;

    notificationIcon: string;
    translateNotificationType: string;
    notificationTitleClass: string;
    notificationDescription: string;
    notificationIdentifier : string;
    SourcePage : string;

    constructor(
      private translateService: TranslateService,
      private notificationService: NotificationsService,
      private datePipe: DatePipe,
      private downloadFileService: DownloadFileService) {}

    ngOnInit() {
      if (this.isEmpty) {
        this.translateNotificationType = this.translateService.instant('Components.Notifications.types.is-coming');
        this.notificationDescription = this.translateService.instant('Components.Notifications.descriptions.is-coming');
        this.notificationIcon = 'ft-settings spinner info float-left d-block mt-1 mr-2';
        this.notificationTitleClass = 'noti-title d-block text-bold-400 info';
      } else {

      
        switch (this.notification.sourcePageType) {
          case 1:
            this.SourcePage="Sales/"
            break;
          case 2:
            this.SourcePage="Users/"
              break;
          case 3:
            this.SourcePage="Retailers/"
            break;
          case 4:
            this.SourcePage="Inventories/"
            break;
          case 5:
            this.SourcePage="Shipments/"
            break;
            case 6:
            this.SourcePage="CardOrdering/"
            break;
          default:
            this.SourcePage=""
            break;
        }
        

        switch (this.notification.notificationType) {
          case 1:
            this.translateNotificationType = this.translateService.instant('Components.Notifications.types.on-hold');
            this.notificationIcon = 'icon-ban primary float-left d-block mt-1 mr-2';
            this.notificationTitleClass = 'noti-title d-block text-bold-400 primary';
            break;
          case 2:
            this.translateNotificationType = `${this.translateService.instant('Components.Notifications.types.in-progress')}${' ('}${this.SourcePage}${'Id: '}${this.notification.id.toString()}${') '}${this.datePipe.transform(this.notification.createdOn, 'dd.MM.yyyy hh:mm')}`;
            this.notificationIcon = 'icon-arrow-right float-left d-block mt-1 mr-2';
            this.notificationTitleClass = 'noti-title d-block text-bold-400 info';
            this.notificationDescription = this.translateService.instant('Components.Notifications.descriptions.in-progress');
            break;
          case 3:
            this.translateNotificationType = this.translateService.instant('Components.Notifications.types.confirmation');
            this.notificationIcon = 'icon-check success float-left d-block mt-1 mr-2';
            this.notificationTitleClass = 'noti-title d-block text-bold-400 success';
            break;
          case 4:
            this.translateNotificationType = this.translateService.instant('Components.Notifications.types.file-creation') + this.SourcePage + this.datePipe.transform(this.notification.createdOn, 'dd.MM.yyyy hh:mm'); 
            if(this.notification.linkedId){
              this.translateNotificationType = `${this.translateService.instant('Components.Notifications.types.file-creation')}${' ('}${this.SourcePage}${'Id: '}${this.notification.linkedId.toString()}${') '}${this.datePipe.transform(this.notification.createdOn, 'dd.MM.yyyy hh:mm')}`;
            }
            this.notificationIcon = 'icon-check success float-left d-block mt-1 mr-2';
            this.notificationTitleClass = 'noti-title d-block text-bold-400 success';
            this.notificationDescription = this.translateService.instant('Components.Notifications.descriptions.file-creation');
            break;
          case 5:
            this.translateNotificationType = this.translateService.instant('Components.Notifications.types.error');
            this.notificationIcon = 'ft-x-circle danger float-left d-block mt-1 mr-2';
            this.notificationTitleClass = 'noti-title d-block text-bold-400 danger';
            break;
        }
      }
    }

    downloadFile(event) {
      if (this.inProgress) {
        return;
      }
      
      if(this.notification.notificationType==4){
        this.inProgress = true;
        event.stopPropagation();
        this.notificationService.getNotification(this.notification.id).subscribe(async n => {
            if (n != undefined) {
              const exportedFile = new ExportedFile();
              exportedFile.fileName = n.notificationFile.fileName;
              await this.downloadFileService.downloadFile(exportedFile);
              this.inProgress = false;
            }
        });
      }
      
    }

    deleteNotification(event) {
      event.stopPropagation();
      if (!this.notification) {
        return;
      }

      this.notification.isDeleted = true;
      this.notification.isRead = true;
      this.notificationService.updateNotification(this.notification.id, this.notification).subscribe(updatedNotification => {
        this.notification = updatedNotification;
        this.updatedNotification.emit(this.notification);
      });
    }

    readNotification(event) {
      event.stopPropagation();

      if (!this.notification  || this.notification.isRead == true) {
        return;
      }
      
      this.notification.isRead = true;
      this.notificationService.updateNotification(this.notification.id, this.notification).subscribe(updatedNotification => {
        this.notification = updatedNotification;
        this.updatedNotification.emit(this.notification);
      });
    }
}
