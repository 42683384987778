import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ProductItem } from '@src/model/productItem';
import { Product } from '@src/model/product';

import { WizardFindSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-serializable-product';
import { WizardFindNotSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-not-serializable-product';


@Component({
    selector: 'app-activation-find-product',
    templateUrl: './activation-find-product.html'
})
export class ActivationFindProductComponent {

    @ViewChild(WizardFindSerializableProductComponent) wizardFindProductComponent: WizardFindSerializableProductComponent;
    @ViewChild(WizardFindNotSerializableProductComponent) wizardFindNotSerializableProductComponent: WizardFindNotSerializableProductComponent;

    canSelectFakeSN = true;
    canDoDuplication = true;
    @Input() serializableItems = true;
    @Input() selectedProductItem: ProductItem;
    @Input() serializableProduct: boolean;
    @Input() showWarningMessage: boolean;
    @Output() onselectProductItem = new EventEmitter<ProductItem>();
    @Output() onselectProduct = new EventEmitter<ProductItem>();

    selectProductItem(productItem: ProductItem): void {
        this.onselectProductItem.emit(productItem);
    }
    selectProduct(productItem: ProductItem): void {
        this.onselectProduct.emit(productItem);
    }
    reset(): void {
        if (this.wizardFindProductComponent) {
            this.wizardFindProductComponent.reset();
        }
        if (this.wizardFindNotSerializableProductComponent) {
            this.wizardFindNotSerializableProductComponent.reset();
        }
    }
}
