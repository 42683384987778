import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '@src/services/layout/config.service';
import { ProductsService } from '@src/services/products/products.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductItem } from '@src/model/productItem';
@Component({
  selector: 'app-wizard-create-serial-number',
  templateUrl: './wizard-create-serial-number.component.html',
  styleUrls: ['./wizard-create-serial-number.component.scss']
})
export class WizardCreateSerialNumberComponent {

  @Input() serialNumber: string;
  @Output() save: EventEmitter<any> = new EventEmitter();
  productItem: ProductItem;
  chevronsIconbyOrientation: string;
  inputProductQuery: string;
  SKU: string;
  validationError: string;
  constructor(private translateService: TranslateService,
    private configService: ConfigService, private productsService: ProductsService) {
    const layoutOrientation = this.configService.templateConf.layout.dir;

    this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
      'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';

  }
  selectProductEntry(target: any) {
    this.inputProductQuery = target.item.name;  
    this.productsService.getProductById(target.item.id).subscribe(product => {
        if (product) {
          this.SKU = product.sku;
        }
    });
  }

  formatterProduct = (x: { name: string }) => {
    if (this.inputProductQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputProductQuery };
    }
    return x.name;
  };

  searchProductEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.productsService.getProductsNames(term, false)
      )
  create(){    
    if(this.inputValidation()) {      
      this.validationError = "";    
      // call create function
      this.productsService.createProductItem(this.serialNumber, this.SKU).subscribe( data =>{
        if (data === null) {
          this.productItem = null;
          } else {
              this.productItem = data;
              this.save.emit(this.productItem);
          }
      });
    }
  }

  inputValidation() {
    if(this.serialNumber === undefined || this.serialNumber === null || this.serialNumber === ""){      
      this.validationError = this.translateService.instant('validation-errors.SerialNumberMandatory'); 
      return false;
    } else if(this.inputProductQuery === undefined || this.inputProductQuery === "undefined" || this.inputProductQuery === null || this.inputProductQuery === ""){
      this.validationError = this.translateService.instant('PlaceHolders.input-sku-code'); 
      return false;
    } else if(this.SKU === undefined || this.SKU === "undefined" || this.SKU === null || this.SKU === "") {
      this.validationError = this.translateService.instant('validation-errors.ProductSkuNotFound');
      return false;
    } else if(this.serialNumber){
      if(!this.serialNumber.match("^[0-9]{6,9}$")){
        this.validationError = this.translateService.instant('validation-errors.SerialNumberInvalid');
        return false;
      } else {
        return true;
      }
    }
  }
}
