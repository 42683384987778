import { Component } from '@angular/core';
import { languages } from './languages.consts';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})

export class LanguageDropdownComponent {
    languages = languages;
    selectedLanguage: string;

    updateSelectedLanguage(language) {
        this.selectedLanguage = language;
    }
}
