import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { CustomersService } from '@src/services/customers/customers.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { ConfigService } from '@src/services/layout/config.service';
import { Customer } from '@src/model/customer';
import { DownloadFileService } from '@src/services/files/downloadfile.service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html'
})

export class CustomersComponent extends NgxDatatableBase implements OnInit {
    model = new PagedTableModel();
    firstLoad = false;
    isLoading = false;
    offset = 0;
    queryTermChanged: Subject<string> = new Subject<string>();
    selected: Customer[] = [];
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private router: Router,
        private customersService: CustomersService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private downloadFileService: DownloadFileService) {
        super(translateService);
        this.model.sortOrder = 'saleDate desc';
        this.setTableIconsByOrientation();
    }
    ngOnInit(): void {

        this.queryTermChanged
            .debounceTime(1000) // wait 1000ms after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getCustomers(1);
            });
        this.getCustomers(1);
    }

    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.getCustomers(1);
    }

    setPage(pageInfo) {
        this.getCustomers(pageInfo.page);
    }

    onSearch(event: any) {
        this.queryTermChanged.next(event.target.value.trim());
    }

    GoToCustomerDetails() {
        this.router.navigateByUrl(`customers/${this.selected[0].id}`);
    }

    getCustomers(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        this
            .customersService
            .queryCustomers(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }

    exportCustomers() {
        this.customersService.exportCustomers().subscribe(data => { 
          this.downloadFileService.downloadFile(data); 
        });
    }
}
