import { Component } from '@angular/core';
import { RetailerDetailsComponent } from '@src/base-components/retailers/retailer-details/retailer-details.component';
import { UserRolesEnum } from '@src/model/userRoles';

@Component({
    selector: 'app-retailer-details-messika',
    templateUrl: '../../../../base-components/retailers/retailer-details/retailer-details.component.html',
    styleUrls: ['../../../../base-components/retailers/retailer-details/retailer-details.component.scss']
})

export class RetailerDetailsComponentMessika extends RetailerDetailsComponent {
    getMyRetailer() {
        this.retailersService.getMyRetailer()
        .subscribe(r => {
            this.retailer = r;
            this.isLoaded = true;
            this.editEnabled = false;
            this.canEditUsers = false;
        });
    }
    getRetailerById(retailerId: number) {
        this.retailersService.getRetailerById(retailerId)
            .subscribe(r => {
                this.retailer = r;
                this.isLoaded = true;
                this.isSeller = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.SELLER]);
                this.editEnabled = false;
                this.canEditUsers = false;
            });
    }
}
