import { Component, Input } from '@angular/core';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '@src/services/products/products.service';
import { Product } from '@src/model/product';

@Component({
    selector: 'app-sale-details-operations-detail',
    templateUrl: './sale-details-operations-detail.component.html'
})
export class SaleDetailsOperationsDetailComponent {
    _operation: WarrantyOperation = null;
    changedFields: Array<any> = [];
    oldProduct: Product;
    newProduct: Product;
    fieldsFilter = ['Seller'];
    constructor(private translateService: TranslateService, private productService: ProductsService) { }

    @Input()
    set operation(op: WarrantyOperation) {
        this._operation = op;
        if (op && op.data) {
            const allChangedFields: Array<any> = JSON.parse(op.data);
            this.changedFields = allChangedFields.filter((f) => this.fieldsFilter.includes(f.FieldName));
            const productItemIdChange = allChangedFields.find(c => c.FieldName == 'ProductItemId');
            if (productItemIdChange) {
                this.productService.getProductItemById(productItemIdChange.Values.Oldvalue).subscribe((opi) => {
                    this.oldProduct = opi.product;
                    this.productService.getProductItemById(productItemIdChange.Values.Newvalue).subscribe((npi) => {
                        this.newProduct = npi.product;
                        this.changedFields.push({
                            'FieldName': 'SerialNumber(SKU)',
                            Values: {
                                'Oldvalue': opi.serialNumber + '(' + this.oldProduct.sku + ')',
                                'Newvalue': npi.serialNumber + '(' + this.newProduct.sku + ')'
                            }
                        })
                    })
                });
            }
        }
        else {
            this.changedFields = [];
        }
    }
    get operation() {
        return this._operation;
    }
}
