import { InMemoryDbService, RequestInfo, RequestInfoUtilities, ParsedRequestUrl } from 'angular-in-memory-web-api';
import { ResponseOptions } from '@angular/http';

import { OperationResult } from '../model/operationresult';

import { products } from './data/products-data';
import { productitems } from './data/productItems-data';
import { warrantycards } from './data/warrantyCards-data';
import { warranties } from './data/warranties-data';
import { sales, saleDetails } from './data/sales-data';
import { retailers } from './data/retailers-data';
import { users } from './data/users-data';
import { metrics } from './data/metrics-data';
import { retailerOrMarketNames } from './data/retailer-market-names-data';
import { productOrFamilyName } from './data/product-family-names-data';
import { importeddata } from './data/importeddata-data';
import { importedretailers } from './data/imported-retailers-data';
import { importedproducts } from './data/imported-products-data';
import { importedretailersentries } from './data/imported-retailers-entries-data';
import { importedretailersmetrics } from './data/imported-retailers-metrics-data';
import { importedproductsmetrics } from './data/imported-products-metrics-data';
import { importedproductsentries } from './data/imported-products-entries-data';
import { importedproductserrors } from './data/imported-products-errors-data';
import { importedretailerserrors } from './data/imported-retailers-errors-data';
import { markets } from './data/markets-data';
import { metricssellers } from './data/metrics-sellers-data';
import { saleretailers } from './data/sale-retailers-data';
import { retailersuser } from './data/retailers-user-data';
import { metricsactivationdata } from './data/metrics-activations-data';
import { repairtypes } from './data/repair-types-data';
import { customers, customerDetails} from './data/customers-data';
import { settings, settingsRetailer } from './data/settings-data';
import { inventories, inventoryItems } from './data/inventories-data';
import { notifications } from './data/notifications-data';

import { ParsedEvent } from '@angular/compiler';

export class InMemoryDataService implements InMemoryDbService {

  protected parseRequestUrl(url: String, utils: RequestInfoUtilities): ParsedRequestUrl {
    if (url.indexOf('/importeddata/products') !== -1) {
      if (url.endsWith('errors')) {
        const newUrl = url.replace(/importeddata\/products.*errors/, 'importedproductserrors');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
      if (url.endsWith('entries')) {
        const newUrl = url.replace(/importeddata\/products.*entries/, 'importedproductsentries');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
      if (url.endsWith('metrics')) {
        const newUrl = url.replace(/importeddata\/products.*metrics/, 'importedproductsmetrics');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      } else {
        const newUrl = url.replace('/importeddata/products', '/importedproducts');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
    }
    if (url.indexOf('/importeddata/retailers') !== -1) {
      if (url.endsWith('errors')) {
        const newUrl = url.replace(/importeddata\/retailers.*errors/, 'importedretailerserrors');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
      if (url.endsWith('metrics')) {
        const newUrl = url.replace(/importeddata\/retailers.*metrics/, 'importedretailersmetrics');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
      if (url.endsWith('entries')) {
        const newUrl = url.replace(/importeddata\/retailers.*entries/, 'importedretailersentries');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      } else {
        const newUrl = url.replace('/importeddata/retailers', '/importedretailers');
        console.log('newUrl', newUrl);
        const parsed = utils.parseRequestUrl(newUrl);
        console.log(`parseRequestUrl override of '${url}':`, parsed);
        return parsed;
      }
    }
    if (url.endsWith('metrics')) {
      const newUrl = url.replace(/importeddata\/retailers.*metrics/, '/importedmetrics');
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.endsWith('entries')) {
      const newUrl = url.replace(/importeddata\/retailers.*entries/, '/importedentries');
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.endsWith('/users')) {
      const newUrl = url.replace(/retailers.*users/, 'users');
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/sales/queries') !== -1) {
      const newUrl = '/api/sales';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/customers/queries') !== -1) {
      const newUrl = '/api/customers';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/sales/metrics/types') !== -1) {
      const newUrl = '/api/metricsactivationdata';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/sales/metrics/retailers') !== -1) {
      const newUrl = '/api/saleretailers';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/sales/metrics/topsellers') !== -1) {
      const newUrl = '/api/metricssellers';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/sales/metrics') !== -1) {
      const newUrl = '/api/metrics';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/retailers/queries') !== -1) {
      const newUrl = '/api/retailers';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/retailers/filters') !== -1) {
      const newUrl = '/api/retailers';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/retailers/users') !== -1) {
      const newUrl = '/api/retailersuser';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/products/filters') !== -1) {
      const newUrl = '/api/products';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/repairtypes/parents') !== -1) {
      const newUrl = '/api/repairtypes';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    if (url.indexOf('settings') !== -1 && url.indexOf('retailers') !== -1) {
      const newUrl = url.replace(/retailers.*settings/, 'settings');
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${newUrl}':`, parsed);
      return parsed;
    }
    if (url.indexOf('/inventories') !== -1) {
      const newUrl = '/api/inventories';
      console.log('newUrl', newUrl);
      const parsed = utils.parseRequestUrl(newUrl);
      console.log(`parseRequestUrl override of '${url}':`, parsed);
      return parsed;
    }
    return null;
  }


  protected responseInterceptor(res: ResponseOptions, ri: RequestInfo): ResponseOptions {
    if (ri.collectionName === 'importeddata' && ri.method.toLocaleLowerCase() === 'post') {
      const result = importeddata.products[0];
      result.fileName = 'new-file.csv';
      result.uploadStatus = 2;
      res.body = this.newOpertationResult(result);
      return res;
    }
    if (ri.collectionName === 'customers' && ri.url.indexOf('/customers/queries') === -1)// customer detailes
    {
      res.body = this.newOpertationResult(customerDetails[0]);
      return res;
    }
    if (ri.collectionName === 'sales' && ri.url.endsWith('details?isSearch=false'))// sale detailes
    {
      res.body = this.newOpertationResult(saleDetails[0]);
      return res;
    }
    if (ri.collectionName === 'sales' && ri.url.includes('/sales/search?searchTerm'))// global search
    {
      res.body = this.newOpertationResult([saleDetails[0]]);
      return res;
    }
    if (ri.collectionName === 'metrics' && ri.url.indexOf('metrics/count') > 0) {
      res.body = this.newOpertationResult(40000);
      return res;
    }

    if (ri.collectionName === 'retailers' && ri.url.indexOf('filters') > 0) {
      res.body = this.newOpertationResult(retailerOrMarketNames);
      return res;
    }

    if (ri.collectionName === 'products' && ri.url.indexOf('filters') > 0) {
      res.body = this.newOpertationResult(productOrFamilyName);
      return res;
    }

    if (ri.collectionName === 'inventories') {
      res.body = this.newOpertationResult(inventories);
      return res;
    }
    if (ri.collectionName === 'inventoryItems') {
        res.body = this.newOpertationResult(inventoryItems);
    }

    if (ri.collectionName === 'warranties' && ri.method.toLocaleLowerCase() === 'put') {
      const warranty = {
        'id': 3,
        'warrantyCardId': 1,
        'productId': 1,
        'customerId': null,
        'productItemId': 1,
        'warrantyActivationDate': '2018-04-30T23:00:00',
        'warrantyEndDate': '2020-04-30T23:00:00',
        'isReturned': true,
        'isDuplicate': false,
        'customer': null,
        'warrantyCard': null,
        'sellerId': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
        'seller': null,
        'warrantyOperations':[
           {
              'id': 6,
              'warrantyId': 3,
              'operationTypeId': 2,
              'data': '{\'Id\':3,\'WarrantyCardId\':1,\'ProductId\':1,\'CustomerId\':null,\'ProductItemId\':1,\'SellerId\':\'6c59b3c4-eb12-4519-d79a-08d5bf3d67c7\',\'WarrantyActivationDate\':\'2018-04-30T23:00:00\',\'WarrantyEndDate\':\'2020-04-30T23:00:00\',\'IsReturned\':true,\'IsDuplicate\':false,\'Customer\':null,\'WarrantyCard\':null,\'Seller\':null,\'WarrantyOperations\':null,\'CreatedById\':\'6c59b3c4-eb12-4519-d79a-08d5bf3d67c7\',\'CreatedBy\':null,\'CreatedOn\':\'2018-05-25T16:26:34.1917567\',\'UpdatedById\':\'6c59b3c4-eb12-4519-d79a-08d5bf3d67c7\',\'UpdatedBy\':null,\'UpdatedOn\':\'2018-05-25T18:29:17.7095912+02:00\'}',
              'comment': 'test mario',
              'createdById': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
              'createdBy': null,
              'createdOn': '2018-05-25T16:29:17.7446388Z',
              'updatedById': '00000000-0000-0000-0000-000000000000',
              'updatedBy': null,
              'updatedOn': '2018-05-25T16:29:17.7446388Z'
           },
           {
              'id': 5,
              'warrantyId': 3,
              'operationTypeId': 1,
              'data': '{\'WarrantyCardId\':1,\'ProductId\':1,\'ProductSku\':\'01 733 7675 4754-Set RS\',\'ProductName\':\'Oris Aquis Depth Gauge\',\'CustomerId\':null,\'ProductItemId\':1,\'ProductItemSerialNumber\':\'3459869\',\'WarrantyActivationDate\':\'2018-04-30T23:00:00Z\',\'WarrantyEndDate\':\'2020-04-30T23:00:00Z\',\'IsReturned\':false,\'IsDuplicate\':false,\'RetailerId\':1,\'SellerId\':\'6c59b3c4-eb12-4519-d79a-08d5bf3d67c7\',\'IsFake\':false,\'Distribution\':null}',
              'comment': null,
              'createdById': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
              'createdBy': null,
              'createdOn': '2018-05-25T18:26:34.2826641',
              'updatedById': '00000000-0000-0000-0000-000000000000',
              'updatedBy': null,
              'updatedOn': '2018-05-25T16:26:34.2621795'
           }
        ],
        'createdById': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
        'createdBy': null,
        'createdOn': '2018-05-25T16:26:34.1917567',
        'updatedById': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
        'updatedBy': null,
        'updatedOn': '2018-05-25T18:29:17.7095912+02:00'
     };
      res.status = 200;
      res.body = this.newOpertationResult(warranty);
      return res;
    } else if (ri.method.toLocaleLowerCase() === 'put') {
      res.status = 200;
      res.body = this.newOpertationResult(ri.utils.getJsonBody(ri.req));
      return res;
    }

    res.body = this.newOpertationResult(res.body);
    return res;
  }

  createDb() {
    return { productitems, products, warrantycards, warranties, sales, retailers, users, metrics, importeddata, importedretailers,
      importedproducts, importedretailersentries, importedretailersmetrics, importedproductsmetrics, importedproductsentries,
      importedproductserrors, importedretailerserrors, markets, metricssellers, saleretailers, retailersuser, metricsactivationdata,
      repairtypes, customers, settings, inventories, inventoryItems, notifications };
  }

  private newOpertationResult<t>(value: t): OperationResult<t> {
    const opResult = new OperationResult<t>();
    opResult.isSuccess = true;
    opResult.value = value;
    return opResult;
  }
}
