import { RepairsComponent } from './repairs.component';
import { RepairStepsComponent } from './steps/repair-steps.components';
import { RepairSelectTypeComponent } from './select-type/repair-select-type';
import { RepairTypeElementComponent } from './select-type/repair-type-element';
import { FindWarrantyComponent } from './find-warranty/find-warranty.component';
import { FindWarrantiesComponent } from './find-warranty/results/search/find-warranty-result.component';
import { WarrantyResultComponent } from './find-warranty/results/search/find-warranty-result-element.component';
import { RepairActivationComponent } from './activation/repair-activation.component';
import { TypeWarrantiesComponent } from './find-warranty/results/types/type-warranties.component';
import { TypeWarrantyComponent } from './find-warranty/results/types/type-warranty.component';
import { RepairConfirmationComponent } from './confirmation/repair-confirmation.component';
import { FindProductItemsComponent } from './find-product-items/find-product-items.component';
import { FindProductItemsResultComponent } from './find-product-items/results/find-product-items-result.component';
import { ProductItemResultComponent } from './find-product-items/results/find-product-item.component';
import { CreateWarrantyComponent } from './create-warranty/create-warranty.component';

export const RepairsComponents = [
    RepairsComponent,
    RepairStepsComponent,
    RepairSelectTypeComponent,
    RepairTypeElementComponent,
    FindWarrantyComponent,
    FindWarrantiesComponent,
    WarrantyResultComponent,
    TypeWarrantiesComponent,
    TypeWarrantyComponent,
    RepairActivationComponent,
    RepairConfirmationComponent,
    FindProductItemsComponent,
    FindProductItemsResultComponent,
    ProductItemResultComponent,
    CreateWarrantyComponent
];