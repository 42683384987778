import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartColors } from '@src/assets/charts/charts.colors';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({ 
    selector: 'app-sales-total-volume-chart',
    templateUrl: './sales-total-volume-chart-component.html',
    styleUrls: ['./sales-total-volume-chart-component.scss']
 })

export class SalesTotalVolumeChartComponent {
    @Input() data: { name: string, value: number }[];
    @Input() title = '';
    @Input() isLoading = true;

    options = { scheme: { domain: ChartColors }};
    layoutOrientation: string;

    constructor(private configService: ConfigService, private translateService: TranslateService) {
        this.layoutOrientation = this.configService.templateConf.layout.dir;
    }

}
