import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { IAuthService } from '@src/auth/auth.service.interface';

import { ProductItem } from '@src/model/productItem';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { Warranty } from '@src/model/warranty/warranty';
import { Retailer } from '@src/model/retailer';
import { User } from '@src/model/user';
import { UserRolesEnum } from '@src/model/userRoles';
import { ProductType } from '@src/model/Enums/productType';
import { WizardComponent } from 'angular-archwizard';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@src/services/users/users.service';
import { Customer } from '@src/model/customer';
import { ConfigService } from '@src/services/layout/config.service';
import { ChannelOptions } from '@src/model/channelOptions';
import { ToastrService } from 'ngx-toastr';
import { PastWarrantyActivationSettings } from '@src/model/config/PastWarrantyActivationSettings';
import { FeatureSettings } from '@src/model/config/FeatureSettings';
import { Channel } from '@src/model/channel';
@Component({
  selector: 'app-activation-base',
  templateUrl: './activation.component.html'
})
export class ActivationBaseComponent implements OnInit {

  @ViewChild(WizardComponent) wizard: WizardComponent;
  selectedRetailer = new Retailer();
  public selectedType: number;
  selectedProductItem: ProductItem = new ProductItem();
  pastWarrantyActivationSettings: PastWarrantyActivationSettings = new PastWarrantyActivationSettings();
  overrideWarrantySettings: FeatureSettings = new FeatureSettings();
  cpoSettings: FeatureSettings = new FeatureSettings();
  channels: Channel[] ;
  selectedWarrantyCard: WarrantyCard = new WarrantyCard();
  activationDate: Date = new Date();
  warranty = new Warranty();
  maxFieldLengthFor = {
    'firstName': 50,
    'lastName': 50,
    'email': 255,
    'firstNameRomanized': 50,
    'lastNameRomanized': 50,
    'address': 250,
    'postalCode': 10
};

  warningCheked = false;
  warrantyActivationResult: string;
  validationErrors = [];
  sellerList: User[] = [];
  selectedSeller: User;
  selectedOption: string;
  isFakeActivation = false;
  isCpo = false;
  warrantyCode: string;
  isSeller: boolean;
  isGeneriqueSeller: boolean;
  hasCRM: boolean;
  chevronsIconbyOrientation: string;
  soldBy: string;
  channelOptions: ChannelOptions[];
  channelOption: [];
  channelName: string;
  owActivation: boolean; cpoActivation: boolean;
  activateWarrantydisable: boolean;
  constructor(
    @Inject('AuthService') public authService: IAuthService,
    public warrantiesService: WarrantiesService,
    public retailerService: RetailersService,
    public translateService: TranslateService,
    public usersService: UsersService,
    public configService: ConfigService,
    protected retailersService: RetailersService,
    private toastrService: ToastrService,
  ) {  
    this.getPastWarrantyActivationSettings();
    const loggedUser = this.authService.getCurrentUserInstant();
    if (loggedUser && loggedUser.role[0] === UserRolesEnum.SELLER) {
      this.isSeller = true;
    } else {
      this.isSeller = false;
    }
    if (loggedUser && loggedUser.role[0] === UserRolesEnum.GENERICSELLER) {
      this.isGeneriqueSeller = true;
      this.isSeller = true;
    }
    else {
      this.isGeneriqueSeller = false;
    }
  }

  ngOnInit() {
    const loggedUser = this.authService.getCurrentUserInstant();
    this.usersService.getUser(loggedUser.sub).subscribe(user => {
      if (user == undefined) {
        return;
      }
      const savedItem = localStorage.getItem(`LastSelectedRetailer_${this.authService.getCurrentUserInstant().sub}`);
      if (savedItem) {
          this.selectedRetailer = JSON.parse(savedItem) as Retailer;
          this.checkCRMFeature();
      }
    });

    const layoutOrientation = this.configService.templateConf.layout.dir;

    this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
      'ft-chevrons-left primary' : 'ft-chevrons-right primary';
  }
  getRetailerCpoSetting() {
    this.warrantiesService.getCpoSettings()
    .subscribe(r =>{
        this.cpoSettings = r;
    });
  }
  getPastWarrantyActivationSettings() {
    this.warrantiesService.getPastWarrantyActivationSettings()
    .subscribe(r =>{
        this.pastWarrantyActivationSettings = r;
    });
  }
  getOverrideWarrantySettings() {
    this.warrantiesService.getOverrideWarrantySettings()
    .subscribe(r =>{
        this.overrideWarrantySettings = r;
    });
  }
  getChannelsByRetailerId(id: number) {
    this.retailerService.getChannelsByRetailerId(id)
    .subscribe(r =>{
        this.channels = r;
        if((typeof(this.channels) !== "undefined") && this.channels.length > 0){
          var channel;
          channel = this.channels[0];
          this.channelName = channel.name;
        }
    });
  }
  selectRetailer(retailer: Retailer) {
    this.selectedRetailer = retailer;
    this.checkCRMFeature();
    this.getUsersOfRetailer();
    this.getChannelsByRetailerId(this.selectedRetailer.id);
    this.wizard.navigation.goToNextStep();
  }

  checkCRMFeature() {
    if (this.selectedRetailer) {
      this.retailerService.getSettingsByRetailerId(this.selectedRetailer.id).subscribe(sts => {
        if (sts == undefined) {
          this.hasCRM = false;
        } else {
          this.hasCRM = sts.find(setting => setting.type === 'CRM') !== undefined;
        }
      });
    }
  }

  selectProductType(productType: ProductType) {
    this.selectedType = productType;
    this.wizard.navigation.goToNextStep();
  }
  selectProductItem(prod: ProductItem) {   
    this.getRetailerCpoSetting();
    this.selectedProductItem = prod;     
    this.getOverrideWarrantySettings();    
    this.wizard.navigation.goToNextStep();
  }

  setChannelsOptionsRetailer(channelOptions: ChannelOptions[]) {
    if (channelOptions == undefined || channelOptions.length === 0) {
      return;
    }
  }

  selectProduct(prod: ProductItem) {
    this.selectedProductItem = prod;
    this.isFakeActivation = this.selectedType === ProductType.serialized || this.selectedType == undefined ? true : false;

    this.wizard.navigation.goToNextStep();
  }
  recieveWC(warrantyCard: WarrantyCard) {
    this.selectedWarrantyCard = warrantyCard;
    this.wizard.navigation.goToNextStep();
  }
  recievedPairedWC(warrantyCard: WarrantyCard) {
    this.selectedWarrantyCard = warrantyCard;
    this.wizard.navigation.goToNextStep();
  }
  recievedProductItemPaired(prod: ProductItem) {
    this.selectedProductItem = prod;
  }
  resetComponent() {
    this.wizard.navigation.reset();
  }
  goToCustomerPage() {
    this.wizard.navigation.goToNextStep();
  }
  selectActivationDate(activationDate: Date) {
    this.activationDate = activationDate;
  }
  selectWarrantyDuration(selectedDuration: number){    
    this.warranty.warrantyDurationMonths = selectedDuration;
    if(this.warranty.warrantyDurationMonths == null || this.warranty.warrantyDurationMonths == undefined) {
      this.warranty.warrantyDurationMonths = 0;
    }
  }
  onOwActivation(owActivation: boolean){
    this.owActivation = owActivation;
  }
  onCpoActivation(cpoActivation: boolean){
    this.cpoActivation = cpoActivation;
  }
  updateSoldBy(soldBy: string) {
    this.soldBy = soldBy;
  }
  selectSeller(sellerId: string) {
    this.selectedSeller = this.sellerList.filter(s => s.id === sellerId)[0];
  }
  selectOption(optionID: string) {
    this.selectedOption = optionID;
  }
  getUsersOfRetailer() {
    if (this.selectedRetailer) {
      this.authService.getCurrentUser().subscribe(v => {
        const user = new User();
        user.id = v.sub;
        user.firstName = v['given_name'];
        user.lastName = v['family_name'];

        if (v.isInRole(UserRolesEnum.SELLER) || v.isInRole(UserRolesEnum.GENERICSELLER)) {
          this.sellerList.push(user);
          this.selectedSeller = user;
        } else {
          this.retailerService.getRetailerUsers(this.selectedRetailer.id, [UserRolesEnum.SELLER, UserRolesEnum.GENERICSELLER]).subscribe(
            (users) => {
              if (users !== null && users.length > 0) {
                this.sellerList = users;
              }
              if (this.sellerList.filter(s => s.id === v.sub).length === 0) {
                this.sellerList.unshift(user);
              }
              this.selectedSeller = this.sellerList.filter(s => s.id === v.sub)[0];
            }
          );
        }
      });
    }
  }

  setTitleProductType() {
    return this.selectedType == undefined ? this.translateService.instant('Components.ActivationSteps.step-type') :
      this.selectedType == 1 ? this.translateService.instant('Labels.watch')
        : this.selectedType == 2 ? this.translateService.instant('Labels.accessory') : '';
  }

  setTitleStepRetailer() {
    return this.selectedRetailer.name == undefined ? this.translateService.instant('Components.ActivationSteps.step-retailer')
      : this.translateService.instant('Labels.retailer') + ': ' + this.selectedRetailer.name;
  }

  setTitleStepProductItem() {
    return this.selectedProductItem.product.name == undefined ? this.translateService.instant('Components.ActivationSteps.step-find')
      : this.translateService.instant('Labels.reference') + ': ' + this.selectedProductItem.product.name;
  }

  setCustomerInfo(customer: Customer) {
    this.warranty.customerInfo = customer;
  }

  setTitleStepWarrantyCard() {
    return this.selectedWarrantyCard.code == undefined ? this.translateService.instant('Components.ActivationSteps.step-add-warrantycard')
      : this.translateService.instant('Labels.warranty-code') + ': ' + this.selectedWarrantyCard.code;
  }

  setTitlePrepairedWCSN() {
    return this.selectedWarrantyCard.code == undefined && this.selectedProductItem.product.name == undefined
      ? this.translateService.instant('Components.ActivationSteps.step-add-warrantycard-or-SN') :
      this.translateService.instant('Labels.warranty-code') + ': ' + this.selectedWarrantyCard.code + ' ' +
      this.translateService.instant('Labels.reference') + ': ' + this.selectedProductItem.product.name;
  }

  checkWaring(checked: boolean) {
    this.warningCheked = checked;
  }
  canActivate(skipCRM: boolean): boolean {
    this.validationErrors = [];

    if (this.selectedProductItem.isUsed && !this.warningCheked) {
      this.validationErrors.push('warningCheked');
    }

    if (this.hasCRM && !skipCRM) {
      this.warranty.customerInfo = this.warranty.customerInfo || new Customer();
      
      if (this.warranty.customerInfo.acceptedTerms == undefined || this.warranty.customerInfo.acceptedTerms.toString() == 'false') {
        this.validationErrors.push('acceptedTerms');
      } else if (this.warranty.customerInfo.acceptedTerms.toString() == 'true' && this.validationErrors.find(error => error == 'acceptedTerms')) {
        this.validationErrors = this.validationErrors.filter(error => error != 'acceptedTerms');
      }
      if (this.warranty.customerInfo.email == undefined || !this.warranty.customerInfo.email || this.warranty.customerInfo.email === '' || !this.IsValidEmail(this.warranty.customerInfo.email) || this.warranty.customerInfo.email.length > this.maxFieldLengthFor['email']) {
        this.validationErrors.push('email');
      }
      if (this.warranty.customerInfo.firstName == undefined || !this.warranty.customerInfo.firstName || this.warranty.customerInfo.firstName === '' || this.warranty.customerInfo.firstName.length > this.maxFieldLengthFor['firstName']) {
          this.validationErrors.push('firstName');
      }
      if (this.warranty.customerInfo.lastName == undefined || !this.warranty.customerInfo.lastName || this.warranty.customerInfo.lastName === '' || this.warranty.customerInfo.lastName.length > this.maxFieldLengthFor['lastName']) {
          this.validationErrors.push('lastName');
      }
      if (this.warranty.customerInfo.firstNameRomanized != undefined && this.warranty.customerInfo.firstNameRomanized.length > this.maxFieldLengthFor['firstNameRomanized']) {
          this.validationErrors.push('firstNameRomanized');
      }
      if (this.warranty.customerInfo.lastNameRomanized != undefined && this.warranty.customerInfo.lastNameRomanized.length > this.maxFieldLengthFor['lastNameRomanized']) {
        this.validationErrors.push('lastNameRomanized');
      }
      if (this.warranty.customerInfo.address != undefined && this.warranty.customerInfo.address.length > this.maxFieldLengthFor['address']) {
        this.validationErrors.push('address');
      }
      if (this.warranty.customerInfo.postalCode != undefined && this.warranty.customerInfo.postalCode.length > this.maxFieldLengthFor['postalCode']) {
        this.validationErrors.push('postalCode');
      }
    }
    return this.validationErrors.length === 0;
  }
  skipCustomerInformation(event) {
    this.warranty.customerInfo = null;
    this.activateWarrantyAction(event, true);
  }

  private IsValidEmail(email: string): boolean {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  activateWarranty(event) {
    this.activateWarrantyAction(event, false);
  }

  activateWarrantyAction(event, skipCRM) {
    if (!this.canActivate(skipCRM)) {
      return;
    }
    if (event.detail > 1) {
      return;
    }
    if (this.warranty.customerInfo != null && this.warranty.customerInfo.acceptedTerms != undefined) {
      this.warranty.customerInfo.acceptedTerms = this.warranty.customerInfo.acceptedTerms.toString() == 'true';
    }
    if (this.channelOption != null && this.selectedOption == "") {
      this.toastrService.warning('Please Select Channel Option');
      return;
      //this.validationErrors.push('acceptedTerms');
    }
    if ((this.cpoActivation || this.owActivation) && (this.warranty.warrantyDurationMonths == undefined || this.warranty.warrantyDurationMonths == 0))
    {
      this.toastrService.warning('Please Select a valid warranty durations');
      return;
    }
    this.activateWarrantydisable = true;
    this.warranty.productItemId = this.selectedProductItem.id;
    this.warranty.warrantyCardCode = this.selectedWarrantyCard.code;
    this.warranty.warrantyCardId = this.selectedWarrantyCard.id;
    this.warranty.productId = this.selectedProductItem.product.id;
    this.warranty.warrantyActivationDate = this.activationDate;
    this.warranty.isFake = this.isFakeActivation;
    this.warranty.isSerializedItem = this.selectedType === undefined ? true : this.selectedType === ProductType.serialized;
    this.warranty.hasBlockchainCertificate = this.selectedProductItem.isValid;
    this.warranty.soldBy = this.isGeneriqueSeller ? this.soldBy : "";
    this.warranty.productItemSerialNumber = this.selectedProductItem.serialNumber;
    this.warranty.productSku = this.selectedProductItem.product.sku;
    this.warranty.productName = this.selectedProductItem.product.name;
    this.warranty.sellerId = this.selectedSeller.id;
    this.warranty.retailerId = this.selectedRetailer.id;
    this.warranty.distribution = 'Website';
    this.warranty.channelOption = this.selectedOption; 
    this.warranty.isCpo = this.cpoActivation;
    this.warranty.isOverrideWarranty = this.owActivation;
    this.warrantiesService.addWarranty(this.warranty).subscribe(
      w => {
        this.activateWarrantydisable = false;
        if (w) {
          this.warrantyActivationResult = 's';
          this.warrantyCode = w.warrantyCard && this.selectedType === ProductType.serialized ? w.warrantyCard.code : null;

          this.wizard.navigation.goToNextStep();
          this.channelOption = null;
        } else {
          this.warrantyActivationResult = 'e';
          this.channelOption = null;
        }
      }
    );
    this.selectedOption = "";
    this.cpoActivation = false;
    this.owActivation = false;
  }
}
