import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartColors } from '@src/assets/charts/charts.colors';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({ 
    selector: 'app-top-families-value-chart',
    templateUrl: './top-families-value-chart-component.html',
    styleUrls: ['./top-families-value-chart-component.scss']
 })

export class TopFamiliesValueChartComponent implements OnChanges {
    @Input() chartData: { name: string, value: number }[];
    @Input() title = '';
    @Input() isLoading = true;

    data: any;// { name: string, series: { name: string, value: number }[] }[];
    private _chartData: { name: string, value: number }[];
    options = {
        scheme: {
            domain: ChartColors
        }
    };
    layoutOrientation: string;

    constructor(private configService: ConfigService, private translateService: TranslateService) {
        this.layoutOrientation = this.configService.templateConf.layout.dir;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.chartData) {
            return;
        }

        if (changes.chartData.currentValue !== this._chartData) {
            this._chartData = changes.chartData.currentValue;
            // this.data = [{name: "", series: changes.chartData.currentValue }];
            this.data = this._chartData;
        }

    }
}
