import { Component, Input, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '@src/model/user';
import { UsersService } from '@src/services/users/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-modal-status-user',
        templateUrl: './modal-status-user.component.html'
    })

export class ModalStatusUserComponent implements OnInit {

    @Input() userInEdition: User;
    @Input() inEdition: boolean;
    @Input() name: string;
    @Input() switchdisabled: boolean;
    @Output() switchChange = new EventEmitter<boolean>();

    private user: User;
    validationErrors = [];
    confirmationAction: Function;
    cancelAction: Function;
    classNameConfirmationButton: string;
    label: string;
    title: string;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private usersService: UsersService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        switch(this.name) {
            case 'deactivation': {
                this.confirmationAction = this.confirmDeactivation;
                this.cancelAction = this.cancelConfirmation;
                this.classNameConfirmationButton = 'btn btn-raised btn-danger ml-1 mt-1 mb-0';
                this.label = 'Components.UserDetails.confirm-user-deactivation';
                this.title = 'Components.UserDetails.confirm-user-deactivation-title';
            }
            break;
            case 'activation' : {
                this.confirmationAction = this.confirmActivation;
                this.cancelAction = this.cancelConfirmation;
                this.classNameConfirmationButton = 'btn btn-raised btn-primary ml-1 mt-1 mb-0';
                this.label = 'Components.UserDetails.confirm-user-activation';
                this.title = 'Components.UserDetails.confirm-user-activation-title';
            }
            break;
        }
    }
    onUserReceived(user: User) {
        this.user = user;
        this.userInEdition = cloneDeep(this.user);
    }
    updateUser() {
        this.usersService.updateUser(this.userInEdition.id, this.userInEdition)
            .subscribe(u => {
                this.onUserReceived(u);
                this.closeConfirmaton();
                this.inEdition = false;
            });
    }
    closeConfirmaton() {
        this.switchdisabled = false;
        this.switchChange.emit(this.switchdisabled);
        this.activeModal.close();
    }
    cancelConfirmation() {
        this.userInEdition.isActive = !this.userInEdition.isActive;
        this.closeConfirmaton();
    }
    confirmDeactivation() {
        this.userInEdition.isActive = false;
        this.updateUser();
    }
    confirmActivation() {
        this.userInEdition.isActive = true;
        this.updateUser();
    }
}