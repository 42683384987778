import { Component, OnChanges, Inject } from '@angular/core';
import { ActivationConfirmationComponent } from '@src/base-components/activation/steps/confirmation/activation-confirmation';
import { ProductsService } from '@src/services/products/products.service';
import { AppSettings } from '@src/core/AppSettings';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import * as moment from 'moment';

@Component({
    selector: 'app-activation-confirmation-messika',
    templateUrl: './activation-confirmation.html'
})
export class ActivationConfirmationMessikaComponent extends ActivationConfirmationComponent implements OnChanges {

    constructor(
        appSettings : AppSettings,
        calendar: NgbCalendar,
        translateService: TranslateService,
        public productsService: ProductsService,
        @Inject('AuthService') authService: IAuthService) {
       super( appSettings, calendar, translateService, authService);
    }

    setPastActivationWarrantyDateLimits(appSettings: AppSettings) {
        if (this.loggedUser && this.loggedUser.isInRoles(["admin"])) {
            appSettings.MaximumDaysForPastActivationDate = 20000;
        }
        else if (this.pastWarrantyActivationSettings
            && this.pastWarrantyActivationSettings.enabled
            && this.loggedUser.isInRoles(this.pastWarrantyActivationSettings.pastActivationAuthorizedRoles)) {
            appSettings.MaximumDaysForPastActivationDate = this.pastWarrantyActivationSettings.maximumDaysForPastActivationDate;
        }
        const targetMaxDate = new Date();
        const targetMinDate = moment.utc().add(appSettings.MaximumDaysForPastActivationDate * -1, 'day').toDate();
        // Native date months start @ 0 index. NgbDateStruct starts @ 1.
        this.maxDate = { day: targetMaxDate.getUTCDate(), month: (targetMaxDate.getUTCMonth() + 1), year: targetMaxDate.getUTCFullYear() };
        // Allow activations to occur in the past for 1 month, according to the appsettings of the client.
        this.minDate = { day: targetMinDate.getUTCDate(), month: (targetMinDate.getUTCMonth() + 1), year: targetMinDate.getUTCFullYear() };
    }

    // ngOnChanges(): void {
    //     if (this.productItem.product.sku) {
    //         this.productsService.getImageBySku(this.productItem.product.sku).subscribe(res => {
    //             this.productItem.product.imageUrl = 'data:image/jpeg;base64,' + res;
    //         });
    //     }
    // }
}
