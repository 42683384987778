export const metricsactivationdata = [
    {
        'value': 4,
        'label': 'Fake'
    },
    {
        'value': 50,
        'label': 'Genuine'
    },
    {
        'value': 10,
        'label': 'Duplication'
    }
];