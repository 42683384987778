import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { Retailer } from '@src/model/retailer';
import { IAuthService } from '@src/auth/auth.service.interface';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateInterval } from '@src/model/DateInterval';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { Metric } from '@src/model/metric';
import * as moment from 'moment';

@Component({
    selector: 'app-retailer-details-dashboard',
    templateUrl: './retailer-details-dashboard.component.html'
})

export class RetailerDetailsDashboardComponent implements OnInit {
    retailer: Retailer = new Retailer();
    isLoaded = false;
    from: Date;
    to: Date;
    interval: string = DateInterval.Month;
    retailerId: number;
    topSellersSales: Metric[];
    topSellers = 5;

    constructor(@Inject('AuthService') private authService: IAuthService,
        private router: Router,
        private route: ActivatedRoute,
        private retailersService: RetailersService,
        private dashboardService: DashboardService) {
        this.route.params.subscribe(params => {
            if (params['id']) {
                const retailerId = +params['id'];
                this.getRetailerById(retailerId);
            } else {
                this.getMyRetailer();
            }
        });
    }

    ngOnInit() {
        const dateStr = this.computeTimeRange('last-month');

        this.from = dateStr.from;
        this.to = dateStr.to;
        //this.getSellersMetrics();
    }
    onGoToDetails() {
        this.router.navigate([this.router.routerState.snapshot.url + '/details']);
    }
    getMyRetailer() {
        this.retailersService.getMyRetailer()
            .subscribe(r => {
                this.retailer = r;
                this.retailerId = r.id;
                this.isLoaded = true;
                this.getSellersMetrics();
            });
    }
    getRetailerById(retailerId: number) {
        this.retailersService.getRetailerById(retailerId)
            .subscribe(r => {
                this.retailer = r;
                this.isLoaded = true;
                this.retailerId = r.id;
                this.getSellersMetrics();
            });
    }
    getSellersMetrics() {
        this
            .dashboardService
            .getTopSellersMetrics(this.topSellers, this.retailerId, this.from, this.to)
            .subscribe(data => {
                this.topSellersSales = data;
            });
    }
    changeRangeTime({ from, to, interval }: { from: Date, to: Date, interval: string }) {
        this.from = from;
        this.to = to;
        this.interval = interval;
        this.getSellersMetrics();
    }

    public computeTimeRange(timeRange: string): { from: Date, to: Date, interval: string } {
        const now = new Date();
        switch (timeRange) {
          case 'last-month': {
            return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
          }
          case 'last-week': {
            return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
          }
          case 'last-year': {
            return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Month };
          }
          case 'last-5-years': {
            return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Year };
          }
          default: {
            return { from: null, to: null, interval: DateInterval.Month };
          }
    }
}
}