import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Retailer } from '@src/model/retailer';
import { DataTableHelpers } from '@src/shared/helpers/datatable-helper';
import { TranslateService } from '@ngx-translate/core';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { Subject } from 'rxjs';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-wizard-select-retailer',
    templateUrl: './wizard-select-retailer.html'
})
export class WizardSelectRetailerComponent extends NgxDatatableBase implements OnInit {
    model = new PagedTableModel();
    selected: any[] = [];
    firstLoad = false;
    queryTermChanged: Subject<string> = new Subject<string>();
    @Output() onRetailerSelect = new EventEmitter<Retailer>();
    @Input() numberOfRows = 8;
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;

    constructor(
        private retailersService: RetailersService,
        private translateService: TranslateService,
        private configService: ConfigService) {
        super(translateService);
        this.model.sortOrder = 'name asc';
        this.model.pageSize = this.numberOfRows;
        this.setTableIconsByOrientation();
    }
    ngOnInit() {
        this.queryTermChanged
            .debounceTime(1000) // wait 1000ms after the last event before emitting last event
            .distinctUntilChanged() // only emit if value is different from previous value
            .subscribe(v => {
                this.model.queryTerm = v;
                this.getRetailers(1);
            });
        this.getRetailers(1);
    }
    private setPage(pageInfo) {
        this.getRetailers(pageInfo.page);
    }
    private onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        const sort = event.sorts[0];
        this.model.sortOrder = `${sort.prop} ${sort.dir}`;
        this.getRetailers(1);
    }
    private onSelect(retailer: any) {
        this.onRetailerSelect.emit(retailer.selected[0]);
    }
    private onSearch(event: any) {
        const textValue = event.target.value.trim();
        this.queryTermChanged.next(textValue);
    }
    private getRetailers(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        this
            .retailersService
            .queryActiveRetailers(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }
    reset() {
        this.selected = [];
    }

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }
}

