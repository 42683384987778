import { Component, ViewChild, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Retailer } from '@src/model/retailer';
import { FormControl } from '@angular/forms';
import * as mapboxgl from 'mapbox-gl';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Observable } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Feature } from '@src/model/mapbox/feature';
import { UserProfile } from '@src/auth/user-profile';
import { IAuthService } from '@src/auth/auth.service.interface';
import * as MapboxLanguage from '@mapbox/mapbox-gl-language';

@Component({
    selector: 'app-retailer-details-location',
    templateUrl: './retailer-details-location.component.html',
    styleUrls: ['retailer-details-location.component.scss', ]
})

export class RetailerDetailsLocationComponent implements OnInit {
    inputAddressTerm : string;
    mapBox: any;
    editionMapBox: any;
    enableSaveButton: boolean;
    zoom = 14;
    inEdition: boolean;
    searchControl: FormControl = new FormControl();
    lastValidPlace: Feature;
    extraIndications: string;
    newLong: number;
    newLat: number;
    marker: mapboxgl.Marker;
    currentUser: UserProfile;

    @ViewChild('instance') instance: NgbTypeahead;

    @Input() retailer: Retailer = new Retailer();
    @Input() editEnabled = false;
    @Output() updateRetailer = new EventEmitter<Retailer>();
    formatter = (x: {place_name: string}) => x.place_name;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private retailersService: RetailersService) {

        mapboxgl.accessToken = environment.mapBox.appKey;
        this.authService.getCurrentUser().subscribe(u => this.currentUser = u);
    }

    ngOnInit() {
        const language = this.currentUser.locale;

        const mapLanguage = new MapboxLanguage({
            defaultLanguage: language
        });

        this.mapBox = new mapboxgl.Map({
            container: 'mapContainer',
            style: 'mapbox://styles/mapbox/dark-v9',
            zoom: 12
        });

        this.mapBox.addControl(mapLanguage);
        this.setMapMarker(this.parseFloat(this.retailer.longitude), this.parseFloat(this.retailer.latitude), this.mapBox );
        this.mapBox.addControl(new mapboxgl.FullscreenControl());
        setTimeout(() => {
            this.mapBox.resize();
        }, 500);
    }

    searchAddress = (text$: Observable<string>) =>
    text$.debounceTime(200)
        .distinctUntilChanged()
        .switchMap(
            term => term ? this.retailersService.getMapboxAdresseSuggestions(term, mapboxgl.accessToken).map(r => r.features) : []
        )

    selectAddress(target: any) {
        this.lastValidPlace = target.item;
        this.newLong = this.parseFloat(target.item.geometry.coordinates[0]);
        this.newLat = this.parseFloat(target.item.geometry.coordinates[1]);
        this.setMapMarker(this.newLong, this.newLat, this.mapBox);
        if (target.item.geometry !== undefined
            && (this.lastValidPlace.context.filter(a => a.id.startsWith('place'))[0]
            || this.lastValidPlace.context.filter(a => a.id.startsWith('region'))[0])
            && this.lastValidPlace.context.filter(a => a.id.startsWith('country'))[0]
            && this.lastValidPlace.context.filter(a => a.id.startsWith('postcode'))[0]) {
            this.enableSaveButton = true;
        } else {
            this.enableSaveButton = false;
        }
    }

    setMapMarker(long: number, lat: number, map: any){
        //remove the old marker
        if (this.marker) {
            this.marker.remove();
        }
        //set the new one instace
        this.marker = new mapboxgl.Marker()
            .setLngLat([long, lat])
            .addTo(map);
        //fit to the boundries
        const bounds = new mapboxgl.LngLatBounds();
        bounds.extend([long, lat]);
        map.fitBounds(bounds, {
            padding: 100,
            maxZoom: 12
        });
    }

    edit() {
        this.inEdition = true;
        this.extraIndications = this.retailer.addressIndications;
        this.newLong = this.parseFloat(this.retailer.longitude);
        this.newLat = this.parseFloat(this.retailer.latitude);
        setTimeout(() => {
            this.mapBox.resize();
        }, 0);
    }

    cancelEdit() {
        this.inEdition = false;
        this.inputAddressTerm = '';
        this.setMapMarker(+this.retailer.longitude, +this.retailer.latitude, this.mapBox );
    }

    saveChanges() {
        const newRetailer = this.retailer;
        newRetailer.address = this.lastValidPlace.place_name;
        const city = this.lastValidPlace.context.filter(a => a.id.startsWith('place'))[0]
                    || this.lastValidPlace.context.filter(a => a.id.startsWith('region'))[0] ;
        const country = this.lastValidPlace.context.filter(a => a.id.startsWith('country'))[0];
        const postcode = this.lastValidPlace.context.filter(a => a.id.startsWith('postcode'))[0];

        if (country) {
            newRetailer.isoCountry = country.short_code.toUpperCase();
        }
        if (city) {
            newRetailer.city = city.text;
        }
        if (postcode) {
            newRetailer.zip = postcode.text;
        }
        newRetailer.latitude = this.newLat.toString();
        newRetailer.longitude = this.newLong.toString();
        newRetailer.addressIndications = this.extraIndications;
        this.updateRetailer.emit(newRetailer);
        this.inputAddressTerm = '';
    }

    parseFloat = parseFloat;
}