import { Component, Input } from '@angular/core';
import { ImportedRetailersFileMetrics } from '@src/model/importedRetailersFileMetrics';
import { ImportedDataService } from '@src/services/importedData.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-imported-retailers-metrics',
    templateUrl: './imported-retailers-metrics.component.html'
})
export class ImportedRetailersMetricsComponent {
    metrics: ImportedRetailersFileMetrics =  new ImportedRetailersFileMetrics();
    fileId: number;

    constructor(private importDataService: ImportedDataService,
        private route: ActivatedRoute,
        translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.fileId = +params['id'];

                this.importDataService.getRetailerLogMetrics(this.fileId).subscribe(
                    m => this.metrics = m
                );
            }
        });
    }
}