import { Component, Input } from '@angular/core';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardRetailerFilterBaseComponent } from '../dashboard-retailer-filter-base.component';

@Component({
  selector: 'app-dashboard-retailer-new-filter',
  templateUrl: './dashboard-retailer-new-filter.component.html',
  styleUrls: ['./dashboard-retailer-new-filter.component.scss']
})

export class DashboardRetailerNewFilterComponent extends DashboardRetailerFilterBaseComponent {

  @Input() isLoadingExport: boolean;

  constructor(public configService: ConfigService) {
    super(configService);
  }
}
