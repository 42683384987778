
export class Market {
    constructor(){
    }
    id: number;
    code: string;
    name: string;
    shippingLocation: boolean
}

