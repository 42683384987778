import { TermsComponentParameters } from '@src/model/termsComponentParameters';
import { ConfigService } from '@src/services/layout/config.service';

export class TermsBaseComponent {
    params: TermsComponentParameters = {
        appName: '',
        companyName: '',
        products: '',
        logo:'',
        ContactEmail:''
    };

    logoUrl: '';
    layoutOrientation: string;

    constructor(params: TermsComponentParameters, logoUrl, configService: ConfigService) {
        this.params.appName = params.appName;
        this.params.companyName = params.companyName;
        this.params.products = params.products;
        this.logoUrl = logoUrl;
        this.layoutOrientation = configService.templateConf.layout.dir;
        this.params.ContactEmail = params.ContactEmail;

        configService.setIsPublicPage(true);
    }
}