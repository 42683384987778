import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@src/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { ArchwizardModule } from 'angular-archwizard';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AuthGuard } from '@src/auth/auth.guard.service';
import { InMemoryDataService } from '@src/mock-data/in-memory-data-service.service';
import { ProductsService } from '@src/services/products/products.service';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { SalesService } from '@src/services/sales/sales.service';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ExternalService } from '@src/services/external/external.service';
import { RepairsService } from '@src/services/repairs/repairs.service';
import { RepairTypesService } from '@src/services/repairs/repairtypes.service';
import { CustomersService } from '@src/services/customers/customers.service';
import { InventoriesService } from '@src/services/inventories/inventories.service';
import { ShipmentsService } from '@src/services/shipments/shipments.service';
import { SharedDataService } from '@src/services/sharedData.service';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ModalStatusUserComponent } from './users/user-details/update-status-user/modal-status-user.component';
import { ModalStatusRetailerComponent } from './retailers/retailer-details/update-status-retailer/modal-status-retailer.component';

import { AppBaseComponent } from '@src/base-components/app-base.component';
import { ActivationBaseComponent } from '@src/base-components/activation/activation.component';
import { environment } from '@src/environments/environment';
import { EnvironmentNames } from '@src/environments/environments.name';
import * as $ from 'jquery';
import { NgxQRCodeModule } from 'ngx-qrcode2';

import { UsersService } from '@src/services/users/users.service';
import { ImportedDataService } from '@src/services/importedData.service';
import { ModalReturnSaleComponent } from './sales/sale-details/return-sale/modal-return-sale.component';
import { ConfigService } from '@src/services/layout/config.service';
import { MarketsService } from '@src/services/markets/markets.service';
import { WarrantyExtensionsService } from '@src/services/warranty/warrantyextensions.service';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { QrCodePrintingBaseComponent } from './qr-code-printing/qrcodeprinting.component';
import {CardOrderingsService} from '@src/services/cardOrderings/cardOrderings.service';
import { DownloadFileService } from '@src/services/files/downloadfile.service';
import { ShipmentSerialNumberItemsTableComponent } from './inventories/inventory-details/items-table/shipment-serial-number/shipment-serial-number.component';
import { CommonModule } from '@angular/common';

let devModules = [];
if (environment.envName === EnvironmentNames.mock) {
    devModules = [
        HttpClientInMemoryWebApiModule.forRoot(
            InMemoryDataService, { delay: 100, passThruUnknownUrl: true }
        )
    ];
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

@NgModule({
    imports: [
        StoreModule.forRoot({}),
        NgbModule.forRoot(),
        OAuthModule.forRoot(),
        TranslateModule,
        NgxQRCodeModule,
        PerfectScrollbarModule,
        FormsModule,
        CommonModule,
        ...devModules
    ],
    declarations: [
        AppBaseComponent,
        ActivationBaseComponent,
        QrCodePrintingBaseComponent,
        ModalStatusUserComponent,
        ModalStatusRetailerComponent,
        ModalReturnSaleComponent
        ,
        ShipmentSerialNumberItemsTableComponent
    ],
    exports: [
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        ArchwizardModule,
        StoreModule,
        NgbModule,
        OAuthModule,
        FormsModule,
        ReactiveFormsModule,
        UiSwitchModule,
        TranslateModule,
        CommonModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    entryComponents: [ModalStatusUserComponent, ModalStatusRetailerComponent, ModalReturnSaleComponent,
        ShipmentSerialNumberItemsTableComponent ]
})
export class AppBaseModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppBaseModule,
            providers: [
                ProductsService,
                WarrantiesService,
                WarrantycardsService,
                SalesService,
                DashboardService,
                UsersService,
                RetailersService,
                MarketsService,
                ImportedDataService,
                ExternalService,
                RepairsService,
                RepairTypesService,
                InventoriesService,
                ShipmentsService,
                AuthGuard,
                ConfigService,
                CustomersService,
                WarrantyExtensionsService,
                NotificationsService,
                CardOrderingsService,
                SharedDataService,
                DownloadFileService
            ],
        };
    }
}
