import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WizardSelectTypeComponent } from '@src/base-components/wizard/find-product/wizard-select-type';

@Component({
    selector: 'app-correction-select-type',
    templateUrl: './sale-details-correction-select-type.component.html'
})
export class SaleDetailsCorrectionSelectTypeComponent {
    @Input() selectedType: number = 0;
    @Output() onselectProductType = new EventEmitter<number>();
    @ViewChild(WizardSelectTypeComponent) wizardSelectTypeComponent: WizardSelectTypeComponent;

    selectProductType(productType: number) {
        this.onselectProductType.emit(productType);
    }
    reset() {
        this.wizardSelectTypeComponent.reset();
    }
}
