import { Injectable } from '@angular/core';
import { TemplateConfig } from '@src/model/config/config.interface';
import { FiltersPages } from '@src/model/config/filtersPages';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  public templateConf: TemplateConfig;
  public placement: string = "bottom-right";

  constructor() {
    this.initConfigValue();
  }

  initConfigValue() {
    this.templateConf = {
      layout: {
        variant: 'Light',
        dir: 'ltr',
        customizer: {
          hidden: false
        },
        sidebar: {
          collapsed: false,
          size: 'sidebar-md',
          backgroundColor: 'black',
          backgroundImage: true,
          backgroundImageURL: ''
        }
      },
      noSerializedProducts: false,
      isBetaVersion: false,
      isPublicPage: false,
      filterOptions: new FiltersPages(false, true, true, true, true),
      hasNewFilters: false,
      isPrepairedClient: false,
      hasWarningMessage: false,
      hasCorrection: true
    };
  }

  setOrientationConfig(orientation: string) {
    this.templateConf.layout.dir = orientation;
  }

  getLayoutOrientation() : string {
    return this.templateConf.layout.dir;
  }

  setNoSerializedProducts() {
    this.templateConf.noSerializedProducts = true;
  }

  setVersionWebsite(isBeta: boolean) {
    this.templateConf.isBetaVersion = isBeta;
  }

  displayWarningMessage(display: boolean) {
    this.templateConf.hasWarningMessage = display;
  }

  setIsPublicPage(isPublicPage: boolean) {
    this.templateConf.isPublicPage = isPublicPage;
  }

  setNewFilters(newFilters: FiltersPages) {
    this.templateConf.filterOptions = newFilters;
  }

  public setCorrectionsOperation(option: boolean) {
    this.templateConf.hasCorrection = option;
  }

  hasNewFilter() : FiltersPages {
    return this.templateConf.filterOptions;
  }

  public setPrepairedClient(isPrepairedClient: boolean) {
    this.templateConf.isPrepairedClient = isPrepairedClient;
  }

  public isPrepairedClient() : boolean {
    return this.templateConf.isPrepairedClient;
  }

  public hasCorrection() : boolean {
    return this.templateConf.hasCorrection;
  }
}
