import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { Shipment } from '@src/model/shipment';
import { ShipmentExport } from '@src/model/shipmentExport';
import { OperationResultErrorMessage } from '@src/model/operationResultErrorMessage';
import { ShipmentsParameters } from '@src/model/filter-parameters/shipments-parameters';
import * as moment from 'moment';
@Injectable()
export class ShipmentsService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/shipments';

  constructor(injector: Injector) {
    super(injector);
  }

  queryShipments(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/queries`;
    return this.getWithHeader<Shipment[]>(`${url}?` +
      `${this.buildQueryParameters('queryTerm', queryTerm, )}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  filterShipments(shipmentsParameters: ShipmentsParameters, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/filters`;
    return this.getWithHeader<Shipment[]>(`${url}?` +
      `${this.buildQueryParameters('store', shipmentsParameters.store, )}&` +
      `${this.buildQueryParameters('serialNumber', shipmentsParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('city', shipmentsParameters.city, )}&` +
      `${this.buildQueryParameters('isLast', shipmentsParameters.last, )}&` +
      `${this.buildQueryParameters('from', shipmentsParameters.from == null ? null : moment(shipmentsParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', shipmentsParameters.to == null ? null : moment(shipmentsParameters.to).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getShipment(shipmentId: number): Observable<Shipment> {
    const url = `${this.baseUrl}/${shipmentId}`;
    return this.get<Shipment>(url);
  }

  exportShipment(shipmentId: number): Observable<ShipmentExport> {
    const url = `${this.baseUrl}/${shipmentId}/export`;
    return this.get<ShipmentExport>(url);
  }

  addSerialNumber(retailerCode: string, shipmentId: number, serialNumberList: string[] ) : Observable<OperationResultErrorMessage>{
    const url = `${this.baseUrl}/addSerialNumber?`+
    `${this.buildQueryParameters('shipmentId', shipmentId)}&` +
    `${this.buildQueryParameters('retailerCode', retailerCode)}`;
    return this.post<OperationResultErrorMessage>(url, serialNumberList);
  }

  deleteSerialNumber(retailerCode: string, shipmentId: number, serialNumberList: string[] ) : Observable<OperationResultErrorMessage>{    
    const url = `${this.baseUrl}/deleteSerialNumber?`+
    `${this.buildQueryParameters('shipmentId', shipmentId)}&` +
    `${this.buildQueryParameters('retailerCode', retailerCode)}`;
    return this.post<OperationResultErrorMessage>(url, serialNumberList);
  }
  

  revalidateShipment(referenceNumber: string): Observable<boolean> {
    const url = `${this.baseUrl}/revalidateShipment?` +
    `${this.buildQueryParameters('referenceNumber', referenceNumber)}`;
    return this.put<boolean>(url, null);
  }

  exportShipments(shipmentsParameters: ShipmentsParameters): Observable<string> {
    const url = `${this.baseUrl}/export?` +
      `${this.buildQueryParameters('store', shipmentsParameters.store, )}&` +
      `${this.buildQueryParameters('serialNumber', shipmentsParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('city', shipmentsParameters.city, )}&` +
      `${this.buildQueryParameters('from', shipmentsParameters.from == null ? null : moment(shipmentsParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', shipmentsParameters.to == null ? null : moment(shipmentsParameters.to).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('isLast', shipmentsParameters.last, )}&` +
      `${this.buildQueryParameters('parentNotificationId', shipmentsParameters.parentNotificationId, )}`;

      return this.get<string>(url);
  }
}
