import { Component, Input, OnInit } from "@angular/core";
import { Inventory } from "@src/model/inventory";

@Component({
    selector: 'app-inventory-details-info',
    templateUrl: './inventory-details-info.component.html',
})
export class InventoryDetailsInfoComponent {
    @Input() inventory: Inventory;

    constructor() {};
}
