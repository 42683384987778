import { WarrantyExtensionType } from '../Enums/warrantyExtensionType';
import { User } from '../user';

export class WarrantyExtension {
    id: number;
    warrantyId: number;
    repairWarrantyId: number;
    extensionType: WarrantyExtensionType;
    durationMonths: number;
    comment: string;
    startDate: Date;
    endDate: Date;
    createdBy: User;
    purchaseCountry: string;
    userName: string;
    d2CExtension: boolean;
}