import { Component, OnInit, EventEmitter, Output, Input, ViewChild, Inject } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { ProductsService } from '@src/services/products/products.service';
import { WizardFindProductItemsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-product-items-result';
import { WizardCreateSerialNumberComponent } from '@src/base-components/wizard/create-searial-number/wizard-create-serial-number.component';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { FeatureSettings } from '@src/model/config/FeatureSettings';
@Component({
  selector: 'app-find-productitems',
  templateUrl: './find-productitems.component.html'
})
export class FindProductitemsComponent {
  @ViewChild(WizardFindProductItemsResultComponent) wizardFindProductItemsResultComponent: WizardFindProductItemsResultComponent;
  @ViewChild(WizardCreateSerialNumberComponent) wizardCreateSerialNumberComponent: WizardCreateSerialNumberComponent;

  @Output() onProductItemRecived = new EventEmitter<ProductItem>();
  @Output() onselectProductItem = new EventEmitter<ProductItem>();
  
  @Input() selectedProductItem: ProductItem;
  CreateProductItemSetting: FeatureSettings = new FeatureSettings();
  productItems: ProductItem[];
  code = '';
  searchResult: string;
  createEnabled: boolean;
  constructor(@Inject('AuthService')
  private authService: IAuthService, private productsService: ProductsService) { 
    this.getCreateProductItemSettings();
  }

  getCreateProductItemSettings(){
    this.productsService.getCreateProductItemSetting().subscribe(r => {
      this.CreateProductItemSetting = r;
      if(this.CreateProductItemSetting) {
        if(this.CreateProductItemSetting.enabled) {
          if(this.authService.getCurrentUserInstant().isInRoles(this.CreateProductItemSetting.allowedRoles)){
            this.createEnabled = true;
          }
        }
      }
    });
  }

  getProductItemsBySerialNumber(): void {
    if (!this.code || this.code === '') {
      return;
    }
    this.productsService.getProductItemsBySN(this.code)
        .subscribe(data => {
            if (data === null || data.length === 0) {
                this.productItems = [];
                this.searchResult = 'e';
            } else {
                this.productItems = data;
                this.searchResult = 's';
            }
    });
  }
  getSnByCodeKeyDonw(event) {
    if (event.keyCode === 13) {
        this.getProductItemsBySerialNumber();
    }
  }
  
  selectProductItem(productItem: ProductItem): void {
    if(productItem.securityCode === null) {
      this.productItems = [];
      this.searchResult = 'u';
    }
    else{
      
      this.onselectProductItem.emit(productItem);
    }
  }

  onSaveEvent(data) {  
    if (data === null || data.length === 0) {
      this.productItems = [];
      this.searchResult = 'e';
    } else {
        this.productItems.push(data);
        this.searchResult = 's';
    }
  }     
  // reset(): void {
  //   if (this.wizardFindProductItemsResultComponent) {
  //       this.wizardFindProductItemsResultComponent.reset();
  //   }
  // }
}
