

export const ChartColors = [
    // '#009DA0',
    // '#FF8D60',
    // '#FF586B',
    // '#0CC27E',
    // '#1CBCD8',
    // '#868e96',
    // '#009DA0',
    // '#FF8D60',
    // '#AAAAAA',
    // '#FF586B'
    '#084081',
    '#1a4b8b',
    '#285696',
    '#3461a0',
    '#406cab',
    '#4b78b5',
    '#5684bf',
    '#6290ca',
    '#6d9cd4',
    '#79a8df'
];