import { ShipmentsComponent } from "./shipments.component";
import { ShipmentDetailsComponent } from "./shipment-details/shipment-details.component";
import { ShipmentDetailsItemsTableComponent } from "./shipment-details/items-table/shipment-details-items-table.component";
import { ShipmentItemAddSerialNumberComponent } from "./shipment-details/items-table/shipment-items-add-serial-number/shipment-items-add-serial-number";
import { ShipmentDetailsInfoComponent } from "./shipment-details/info/shipment-details-info.component";
import { ShipmentsFilterComponent } from "./filter/shipments-filter.component";
import { ShipmentItemDeleteSerialNumberComponent } from "./shipment-details/items-table/shipment-items-delete-serial-number/shipment-items-delete-serial-number";

export const ShipmentsComponents = [
    ShipmentsComponent,
    ShipmentDetailsComponent,
    ShipmentDetailsItemsTableComponent,
    ShipmentItemAddSerialNumberComponent,
    ShipmentDetailsInfoComponent,
    ShipmentsFilterComponent,
    ShipmentItemDeleteSerialNumberComponent
];
