export const productOrFamilyName = [
    {
        'id': 9,
        'name': 'Oris Rectangular',
        'type': 'f'
     },
     {
        'id': 2,
        'name': 'Oris Aquis Depth Gauge',
        'type': 'f'
     },
     {
        'id': 8,
        'name': 'Oris Rectangular Complication',
        'type': 'f'
     },
     {
        'id': 5,
        'name': 'Oris Air Racing Edition V',
        'type': 'f'
     },
     {
        'id': 4,
        'name': 'Oris Rectangular Date',
        'type': 'p'
     },
     {
        'id': 3,
        'name': 'Oris Aquis',
        'type': 'f'
     },
     {
        'id': 6,
        'name': 'Oris Artix Pointer Moon, Date',
        'type': 'p'
     },
     {
        'id': 3,
        'name': 'Oris Rectangular Complication',
        'type': 'p'
     },
     {
        'id': 6,
        'name': 'Oris Air Racing Edition',
        'type': 'f'
     },
     {
        'id': 5,
        'name': 'Oris Rectangular Date',
        'type': 'p'
     },
     {
        'id': 2,
        'name': 'Oris Air Racing Edition V',
        'type': 'p'
     },
     {
        'id': 1,
        'name': 'Oris Aquis Depth Gauge',
        'type': 'p'
     }
];