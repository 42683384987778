import { Component, Input, Output, EventEmitter, OnInit, Inject, ViewChild } from '@angular/core';
import { Retailer } from '@src/model/retailer';
import { WizardSelectRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-select-retailer';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component({
    selector: 'app-wizard-pre-selected-retailer',
    templateUrl: './wizard-pre-selected-retailer.html'
})
export class WizardPreSelectedRetailerComponent implements OnInit {
    @ViewChild(WizardSelectRetailerComponent) wizardSelectRetailerComponent: WizardSelectRetailerComponent;
    @Output() onRetailerSelect = new EventEmitter<Retailer>();
    lastSelectedRetailer: Retailer = null;
    isSearching = false;
    constructor(@Inject('AuthService') private authService: IAuthService){
    }
    ngOnInit() {
        this.init();
    }
    private init() {
        //TODO: Create a  USerLocalStoreService that is contextualized to the current user
        const savedItem = localStorage.getItem(`LastSelectedRetailer_${this.authService.getCurrentUserInstant().sub}`);
        if (savedItem) {
            this.lastSelectedRetailer = JSON.parse(savedItem) as Retailer;
            this.isSearching = false;
        } else {
            this.isSearching = true;
        }
        if (this.wizardSelectRetailerComponent) {
            this.wizardSelectRetailerComponent.reset();
        }
    }
    private onLastSelectedClick() {
        this.onRetailerSelect.emit(this.lastSelectedRetailer);
    }
    private onRetailerTableSelected(retailer: Retailer) {
        const savedRetailer = new Retailer();
        savedRetailer.id = retailer.id;
        savedRetailer.name = retailer.name;
        savedRetailer.hasCpo = retailer.hasCpo;
        savedRetailer.isoCountry = retailer.isoCountry;
        localStorage.setItem(`LastSelectedRetailer_${this.authService.getCurrentUserInstant().sub}`, JSON.stringify(savedRetailer));
        this.lastSelectedRetailer = savedRetailer;
        this.onRetailerSelect.emit(retailer);
    }
    private onSearchOtherclick() {
        this.isSearching = true;
    }
    reset() {
        this.init();
    }
}

