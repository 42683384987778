import { Output, EventEmitter, Input, Inject, Component, OnInit } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component({
    selector: 'app-typed-button',
    templateUrl: './typed-button.component.html',
    styleUrls: ['./typed-button.component.scss']
})

export class TypedButtonComponent implements OnInit {

    get UseButtonTemplate() : boolean {
        return this.type === 'common';
    }

    get BeVisible(): boolean {
        return !this._isDisabled && !this.disabled;
    }

    @Output() clickEvent = new EventEmitter();

    @Input() disabled = false;
    @Input() titleName: String;
    @Input() className: String;
    @Input() type: String;
    @Input() size: String;
    @Input() iconClass: String;

    private _isDisabled = true;
    classType: String;

    constructor(
        @Inject('AuthService') private authService: IAuthService) {

        this
        .authService
        .getCurrentUser()
        .subscribe(v => {
          this._isDisabled = v.isReadOnlyRole();
        });
    }

    ngOnInit() {
        switch (this.type.toLowerCase()) {
            case 'add' : {
                if (this.size === 'lg') {
                    this.iconClass = 'ft-plus';
                    this.classType = 'bg-primary';
                } else {
                    this.iconClass = 'ft-plus-circle';
                    this.classType = 'primary';
                }
                break;
            }
            case 'delete' : {
                this.iconClass = 'ft-x-circle';
                this.classType = 'danger delete-button';
                break;
            }
            case 'edit' : this.iconClass = 'ft-edit';
                break;
            case 'common' : {
                if (!this.iconClass) {
                    this.iconClass = '';
                }
            }
            break;
            default: {
                this.iconClass = '';
                this.classType = '';
                break;
            }
        }
    }

    click(): void {
        this.clickEvent.emit();
    }
}
