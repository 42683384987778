import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-print-result',
    templateUrl: './print-result.html'
})
export class PrintResultComponent {
    @Input() printResult: string;
    @Output() resetComponent = new EventEmitter<boolean>();

    constructor() { }

    resetSteps() {
        this.resetComponent.emit(true);
    }
}
