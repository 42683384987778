import { CustomersComponent } from '../../../base-components/customers/customers.component';
import { CustomerDetailsComponent } from '../../../base-components/customers/customers-details/customer-details.component';
import { CustomerDetailsMessikaComponent } from './customers-details/customer-details.component';
import { CustomerInfoDetailsComponent } from '../../../base-components/customers/customers-details/info/customer-info-details.component';
import { CustomerInfoDetailsMessikaComponent } from './customers-details/info/customer-info-details.component';
import { CustomerProductInfoDetailsComponent } from '../../../base-components/customers/customers-details/product/customer-product-info-details.component';

export const CustomersMessikaComponents = [
    CustomersComponent,
    CustomerDetailsComponent,
    CustomerDetailsMessikaComponent,
    CustomerInfoDetailsComponent,
    CustomerInfoDetailsMessikaComponent,
    CustomerProductInfoDetailsComponent
];