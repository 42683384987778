import { UserProfile } from '@src/auth/user-profile';
import { Component, Input, Output, EventEmitter, Inject, ViewChild, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { Retailer } from '@src/model/retailer';
import { ProductItem } from '@src/model/productItem';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { User } from '@src/model/user';
import { AppSettings } from '@src/core/AppSettings';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { PastWarrantyActivationSettings } from '@src/model/config/PastWarrantyActivationSettings';
import { FeatureSettings } from '@src/model/config/FeatureSettings';
@Component({
    selector: 'app-activation-confirmation',
    templateUrl: './activation-confirmation.html'
})
export class ActivationConfirmationComponent implements OnChanges {
    @Input() pastWarrantyActivationSettings: PastWarrantyActivationSettings;
    @Input() overrideWarrantySettings: FeatureSettings = null;
    @Input() channelName: string;
    @Input() productItem: ProductItem;
    @Input() warrantyCard: WarrantyCard;
    @Input() validationErrors = [];
    @Input() sellers: User[]; 
    @Input() channelOption: [];
    @Input() cpoSettings: FeatureSettings = null;
    @Input() selectedRetailer : Retailer;
    @Output() onSelectActivationDate = new EventEmitter<Date>();
    @Output() onSelectWarrantyDuration = new EventEmitter<number>();
    @Output() onOwActivation = new EventEmitter<boolean>();
    @Output() onCpoActivation = new EventEmitter<boolean>();
    @Output() onCheckWarnings = new EventEmitter<boolean>();
    @Output() onSelectSeller = new EventEmitter<string>();
    @Output() onSelectOption = new EventEmitter<string>();
    @Output() onUpdateSoldBy = new EventEmitter<String>();
    @Input() retailer: Retailer = new Retailer();


    warningCheked = false;
    model: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    isSeller = false;
    isGeneriqueSeller = false;
    soldBy: string;
    cpoActivation: boolean = false;
    cpoActivationEnabled: boolean = false;
    owActivation: boolean = false;
    checkCpo: boolean = false;
    selectedModule = null;    
    WarrantyDurations: number[] ;
    selectWarrantyDuration:number = null;
    loggedUser : UserProfile;

    constructor(private appSettings: AppSettings,
        private calendar: NgbCalendar,
        private translateService: TranslateService,
        @Inject('AuthService') private authService: IAuthService) {
        this.setPastActivationWarrantyDateLimits(appSettings);
        this.model = this.calendar.getToday();
        this.loggedUser = this.authService.getCurrentUserInstant();
        if (this.loggedUser && this.loggedUser.role[0] === UserRolesEnum.SELLER) {
            this.isSeller = true;
        }
        if (this.loggedUser && this.loggedUser.role[0] === UserRolesEnum.GENERICSELLER) {
            this.isGeneriqueSeller = true;
            this.isSeller = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pastWarrantyActivationSettings) {
            this.setPastActivationWarrantyDateLimits(this.appSettings);
        }
        if(changes.productItem)
        {
            this.checkCpo = this.CheckCpo();
            this.cpoActivationEnabled= this.productItem.isCpo;
            this.selectWarrantyDuration = this.productItem.warrantyDurationMonths;
        }
        if(changes.cpoSettings)
        {
            this.checkCpo = this.CheckCpo();
            this.cpoActivationEnabled= this.productItem.isCpo;
        }
    }

    setPastActivationWarrantyDateLimits(appSettings: AppSettings) {
        if (this.pastWarrantyActivationSettings
            && this.pastWarrantyActivationSettings.enabled
            && this.loggedUser.isInRoles(this.pastWarrantyActivationSettings.pastActivationAuthorizedRoles)) {
            appSettings.MaximumDaysForPastActivationDate = this.pastWarrantyActivationSettings.maximumDaysForPastActivationDate;
        }
        const targetMaxDate = new Date();
        const targetMinDate = moment.utc().add(appSettings.MaximumDaysForPastActivationDate * -1, 'day').toDate();
        // Native date months start @ 0 index. NgbDateStruct starts @ 1.
        this.maxDate = { day: targetMaxDate.getUTCDate(), month: (targetMaxDate.getUTCMonth() + 1), year: targetMaxDate.getUTCFullYear() };
        // Allow activations to occur in the past for 1 month, according to the appsettings of the client.
        this.minDate = { day: targetMinDate.getUTCDate(), month: (targetMinDate.getUTCMonth() + 1), year: targetMinDate.getUTCFullYear() };
    }

    UpdateSolddBy(soldBy: string) {
        if (soldBy.length > 3) {
            this.soldBy = soldBy;
            this.onUpdateSoldBy.emit(this.soldBy);
        }
    }
    CheckCpo(){
        if(this.cpoSettings != null && this.cpoSettings.enabled){
            if(this.productItem.isCpo)
            {
                this.cpoActivation = true;
                this.WarrantyDurations = this.cpoSettings.allowedDurations; 
                this.onSelectWarrantyDuration.emit(this.selectWarrantyDuration);
                this.onCpoActivation.emit(this.cpoActivation);
                return true;                
            }
            if(this.selectedRetailer.hasCpo){
                this.cpoActivation = this.cpoActivationEnabled; //this.productItem.isCpo;
                this.WarrantyDurations = this.cpoSettings.allowedDurations; 
                this.onCpoActivation.emit(this.cpoActivation);
                this.onSelectWarrantyDuration.emit(this.selectWarrantyDuration);
                return true;
            }
            else {
                return false;
            }
        }        
    }
    checkOverrideWarranty(){
        if(this.CheckCpo())
        {
            return false;
        }
        if(this.overrideWarrantySettings != null){
            if(this.overrideWarrantySettings.enabled && this.overrideWarrantySettings.allowedChannels.find(data => data == this.channelName)) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return false;
        }        
    }

    checkCpoOw(){
        if(this.CheckCpo() && this.cpoActivationEnabled) {
            return true;
        }
        else if(this.owActivation) {   
            if(this.overrideWarrantySettings != null) {
                this.WarrantyDurations = this.overrideWarrantySettings.allowedDurations;
                return true;
            }         
        } else {
            return false;
        }  
    }

    warrantyDurationChanged(){
        this.onSelectWarrantyDuration.emit(this.selectWarrantyDuration);
    }
    onChangeCpoStatus(event){
        if(event && !this.owActivation && this.cpoSettings != null) {
            this.cpoActivationEnabled = event;
            this.WarrantyDurations = this.cpoSettings.allowedDurations;        
        }        
        this.onCpoActivation.emit(event);
        this.onSelectWarrantyDuration.emit(this.selectWarrantyDuration);
    }
    onChangeOWStatus(event){                
        if(event && !this.cpoActivation && this.overrideWarrantySettings != null) {
            this.WarrantyDurations = this.overrideWarrantySettings.allowedDurations;            
        } 
        this.onOwActivation.emit(event);
        this.onSelectWarrantyDuration.emit(this.selectWarrantyDuration);
    }
    onDateSelection(date: NgbDateStruct) {
        // Activation time part is not relevant. Create new UTC Date with the given Year, Month, Date to avoid Local to UTC conversion problems.
        const utcDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
        this.onSelectActivationDate.emit(utcDate);
    }
    CheckWarnings(check: any) {
        this.warningCheked = !this.warningCheked;
        this.onCheckWarnings.emit(this.warningCheked);
    }
    selectSeller(sellerId: string) {
        this.onSelectSeller.emit(sellerId);
    }
    selectOption(optionID: string){
        this.onSelectOption.emit(optionID);
      }
    getWarningMessage(): boolean {
        const keyMessage = 'Warning.warning-activate-correct-product';
        return this.translateService.instant(keyMessage) !== keyMessage;
    }
    reset(): void {
    }
}
