import { Component, Input, Output, EventEmitter, Inject, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { InventoryProductItem } from '@src/model/inventoryProductItem';
import { ConfigService } from '@src/services/layout/config.service';
import { InventoriesService } from '@src/services/inventories/inventories.service';
import { ActivatedRoute } from '@angular/router';
import { DownloadFileService } from '@src/services/files/downloadfile.service';
import { UserRolesEnum } from '@src/model/userRoles';
import { Inventory } from "@src/model/inventory";
import { IAuthService } from '@src/auth/auth.service.interface';
import { ToastrService } from 'ngx-toastr';  
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ShippedItems } from '@src/model/shippedItems';
import { ShipmentFromClient } from '@src/model/shipmentFromClient';
import { Retailer } from '@src/model/retailer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import * as internal from 'assert';
import { InternalNgModuleRef } from '@angular/core/src/linker/ng_module_factory';
import { Alert } from 'selenium-webdriver';

@Component({
    selector: 'app-shipment-serial-number',
    templateUrl: './shipment-serial-number.component.html',
    styleUrls: ['./shipment-serial-number.component.scss']
})
export class ShipmentSerialNumberItemsTableComponent extends NgxDatatableBase implements OnInit {    
    @Input() retailerCode: string; 
    @Input() inventoryId : number;   
    shipment : ShipmentFromClient[];
    checkedSerialNumber : string[] = [];
    currentInvetory : Inventory;
    itemExisted : string[] = [];
    isLoaded = true;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        @Inject('AuthService')
        private authService: IAuthService,
        private configService: ConfigService,
        private translateService: TranslateService,
        private inventoriesService: InventoriesService,
        private route: ActivatedRoute,
        public activeModal: NgbActiveModal,
        private downloadFileService: DownloadFileService) {
        super(translateService);
       }

    ngOnInit() {       
       this.getShipments(this.retailerCode);        
       this.router.events.subscribe((url:any) => console.log(url));
       let urlParams = this.router.url.split('/');
       this.inventoryId = +urlParams[2];
       this.getCurrentInventory(this.inventoryId);
    }
   
    private getShipments(retailerCode: string) {
            this.inventoriesService.getShipmentItems(retailerCode).subscribe(shipment => {
                if (shipment && shipment.length > 0) {                                    
                    this.shipment = shipment;
                    this.isLoaded = false;
                }
                else if(!this.shipment || this.shipment.length == 0){   
                    this.activeModal.close();             
                    alert("There are not shipments to show");
                   
                }
                else{
                    this.activeModal.close();
                    alert("Error calling shipment API endpoint");                   
                }    
            });

          
    }
    private getCurrentInventory(inventoryId: number){
        this.inventoriesService.getInventory(inventoryId).subscribe(inventory => {
            if(inventory){
                this.currentInvetory = inventory;
            }
            else{
                alert("Inventory not found");
            }
        });
    }

    closeModalShipment() {       
        this.activeModal.close();
    }
    findSerialNumber(serialNumber: string, currentSerialNumber: string) {
        return serialNumber === currentSerialNumber
      }
    sendShipmentSerialNumbers(){
       
        for (let index = 0; index < this.shipment.length; index++) {
            this.getSelectedCheckBoxList(this.shipment[index].shippedItems); 
        }

        if(this.checkedSerialNumber.length === 0){
            alert("There is not items selected, please select one at least to continue");
            return;
        }
        this.currentInvetory.inventoryProductItems.forEach(element => {
            var isInto = this.checkedSerialNumber.find((str) => str === element.serialNumber);
            if(isInto) this.itemExisted.push(element.serialNumber);
        });
        if (this.itemExisted.length === this.checkedSerialNumber.length){
            alert("All Serial Number selected are already exist in the inventory");

            for (let index = 0; index < this.shipment.length; index++) {
                this.shipment[index].shippedItems.forEach(element => {
                    element.checked = false;
                });; 
            }
            this.checkedSerialNumber = [];
            this.itemExisted = [];
            return;        
        }
        if (this.itemExisted.length > 0){
            if (window.confirm("Serial Numbers " + this.itemExisted.join(', ') + " will not be created, already exist in the inventory")) {
                
                let checkerSerialNumber = value =>
                                        !this.itemExisted.some(element => value.includes(element));

                let serialNumbers = this.checkedSerialNumber.filter(checkerSerialNumber);
                
                this.inventoriesService.addSerialNumber(this.retailerCode, this.inventoryId, serialNumbers).subscribe(response => {
                    //checking the response and go
                    if(response.created == response.received){
                        var serialCreated = [];
                       
                        serialNumbers.forEach(element => {
                            serialCreated.push(element);
                        });
                        this.checkedSerialNumber = [];
                        this.itemExisted = [];
                        alert("Serial Numbers " + serialCreated.join(', ') + " has been created");
                        this.closeModalShipment();  
                        location.reload();              
                    }
                    else{
                        let errorMessages = response.details.join(', ');
                        alert("There were some errors during the creation Errors: " + response.errored + ", " + errorMessages);
                        this.checkedSerialNumber = [];
                        this.itemExisted = [];
                    }
                });
            }
            
        }
        else{
            this.inventoriesService.addSerialNumber(this.retailerCode, this.inventoryId, this.checkedSerialNumber).subscribe(response => {
                //checking the response and go
                if(response.created == response.received){
                    var serialCreated = [];                    
                   
                    this.checkedSerialNumber.forEach(element => {
                        serialCreated.push(element);
                    });
                    this.checkedSerialNumber = [];
                    this.itemExisted = [];
                    alert("Serial Numbers " + serialCreated.join(', ') + " has been created");
                    this.closeModalShipment();      
                    location.reload();           
                }
                else{
                    let errorMessages = response.details.join(', ');
                    alert("There were some errors during the creation Errors: " + response.errored + ", " + errorMessages);
                    this.checkedSerialNumber = [];
                    this.itemExisted = [];
                }
            });
        }        
        
    }
    checkAllItems(shipmentId){
       const currentShipment = this.shipment.find(e => e.id == shipmentId);
            if (currentShipment.shippedItems.every(val => val.checked == true))
            currentShipment.shippedItems.forEach(val => { val.checked = false });
            else
            currentShipment.shippedItems.forEach(val => { val.checked = true });
          }

    formatDates(shipments : ShipmentFromClient []){
       for (let index = 0; index < shipments.length; index++) {
            shipments[index].dateFormatted = shipments[index].date.toDateString();        
       }
    }
    matchingObjects(sp){
        this.shipment.push({
            id : sp.id,
            dateFormatted : sp.dateTime.toDateString(),
            date : sp.date,
            shippedItems : sp.shippedItems
        });
    }

    getSelectedCheckBoxList(shipmentItems : ShippedItems []){
        let selected = shipmentItems.filter(s => s.checked);
        for (let index = 0; index < selected.length; index++) {
            this.checkedSerialNumber.push(selected[index].sn);         
        }
    }    
}
