import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ProductItem } from '@src/model/productItem';
import { ProductsService } from '@src/services/products/products.service';
import { serializePath } from '@angular/router/src/url_tree';

@Component({
    selector: 'app-find-product-items',
    templateUrl: './find-product-items.component.html'
})

export class FindProductItemsComponent implements OnChanges {
    @Input() serialNumberSelected = '';
    @Output() productItemSelected = new EventEmitter<ProductItem>();

    serialNumber = '';
    productItemsList: ProductItem[] = [];
    searchResult: string = null;

    constructor(
        private productsService: ProductsService) {}

    ngOnChanges() {
        this.getProductItemsBySerialNumber(this.serialNumberSelected);
    }

    getProductItemsBySerialNumber(serialNumber: string): void {
        if (!serialNumber) {
            this.searchResult = 'e';
            return;
        }
        this.productsService.getProductItemsBySN(serialNumber)
            .subscribe(data => {
                if (data === null || data.length === 0) {
                    this.productItemsList = [];
                    this.searchResult = 'e';
                } else {
                    this.productItemsList = data;
                    this.searchResult = 's';
                }
            });
    }

    reset() {
        this.productItemsList = [];
        this.serialNumber = '';
        this.searchResult = null;
    }

    searchAgain() {
        this.reset();
    }

    setSelectedProductItems(productItem: ProductItem) {
        this.productItemSelected.emit(productItem);
    }

}
