import { Component, OnInit, Inject } from '@angular/core';
import { UserProfile } from '@src/auth/user-profile';
import { Retailer } from '@src/model/retailer';
import { UsersService } from '@src/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { environment } from '@src/environments/environment';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Market } from '@src/model/market';

@Component({ selector: 'app-profile', templateUrl: './profile.component.html', styleUrls: ['./profile.component.scss'] })
export class ProfileComponent extends NgxDatatableBase implements OnInit {
  retailers: Retailer[] = [];
  markets: Market[] = [];
  identyUrl: string;
  private currentUser: UserProfile;

  constructor(@Inject('AuthService') private authService: IAuthService,
    private usersService: UsersService,
    private retailerService: RetailersService,
    private translateService: TranslateService) {
      super(translateService);
    this
      .authService
      .getCurrentUser()
      .subscribe(v => {
        this.currentUser = v;
        if (this.currentUser) {
          this.getUserRetailersMarkets();
        }
      });
  }

  ngOnInit() {
    this.identyUrl = environment.authConfig.issuer + "/manage/index";
  }
  getProfileData(key): string {
    if (this.currentUser == null) {
      return '';
    }
    return this.currentUser[key];
  }

  getNameFormatted() {
    return this.currentUser['family_name'] + ', ' + this.currentUser['given_name'];
  }
  getUserRetailersMarkets() {
    this
      .usersService
      .getMyUser()
      .subscribe(u => {
        this.retailers = u.retailers;
        this.markets = u.markets;
      });
  }
}
