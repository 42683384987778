import { ShipmentsService } from './../../../services/shipments/shipments.service';
import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import * as moment from 'moment';
import { SharedDataService } from '@src/services/sharedData.service';
import { Observable } from 'rxjs';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { ShipmentsParameters } from '@src/model/filter-parameters/shipments-parameters';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { UsersService } from '@src/services/users/users.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { UserNotification } from '@src/model/notification';

@Component({
  selector: 'app-shipments-filter',
  templateUrl: './shipments-filter.component.html',
  styleUrls: ['./shipments-filter.component.scss']
})
export class ShipmentsFilterComponent implements OnInit {
  selectedTimeRange: string;
  timeRangeList: string[];
  inputSNQuery: string;
  inputStoreQuery: string;
  inputLocationQuery: string;
  inputCityQuery: string;
  customFrom?: Date;
  customTo?: Date;
  loggedUser: UserProfile;
  layoutDirection: string;
  filterParameters: ShipmentsParameters;
  isPrepairedClient: boolean;
  selectedProductId: number;
  checkedLast: boolean;
  checkedPrev: boolean;
  checkedDuplication: boolean;
  isLoading = false;
  hasCorrection: boolean;
  isOpen = true;

  @Output() changeFilter = new EventEmitter<ShipmentsParameters>();
  @Output() clearFilter = new EventEmitter<ShipmentsParameters>();
  @Input() newfilterParameters: ShipmentsParameters;

  constructor(
    @Inject('AuthService') private authService: IAuthService,
    private productsService: ProductsService,
    private configService: ConfigService,
    private notifcationService: NotificationsService,
    private retailersService: RetailersService,
    private ShipmentsService: ShipmentsService,
    private usersService: UsersService,
    private sharedDataService : SharedDataService) {

    this.layoutDirection = this.configService.getLayoutOrientation();
    this.isPrepairedClient = this.configService.isPrepairedClient();
    this.hasCorrection = this.configService.templateConf.hasCorrection;
    this.authService.getCurrentUser().subscribe(u => {
      this.loggedUser = u;
    });
  }
  ngOnInit() {
    this.selectedTimeRange = 'last-month';
    this.checkedPrev = true;
    this.timeRangeList = [
      'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'today', 'custom'
    ];

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new ShipmentsParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.filterParameters.selectedTimeRange = 'last-month';
    if (this.newfilterParameters !== undefined) {
      this.filterParameters = this.newfilterParameters;
      this.selectedTimeRange = this.newfilterParameters.selectedTimeRange == undefined ? 'last-month' : this.newfilterParameters.selectedTimeRange;
      if (this.newfilterParameters.selectedTimeRange == 'custom') {
        this.customTo = this.newfilterParameters.to;
        this.customFrom = this.newfilterParameters.from;
      }
      if (this.newfilterParameters.last) {
        this.checkedLast = true;
      }
      this.inputSNQuery = this.newfilterParameters.serialNumber;
      this.inputStoreQuery = this.newfilterParameters.store;
      this.inputCityQuery = this.newfilterParameters.city;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  clear() {
    this.selectedTimeRange = 'last-month';
    this.inputLocationQuery = '';
    this.inputCityQuery = '';
    this.inputSNQuery = '';
    this.inputStoreQuery = '';
    this.checkedLast = false;
    this.checkedPrev = true;
    this.checkedDuplication = false;

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new ShipmentsParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.filterParameters.last = false;
    this.clearFilter.emit(this.filterParameters);
  }

  changeTimeRange(event) {
    this.filterParameters.selectedTimeRange = this.selectedTimeRange;
    if (this.selectedTimeRange !== 'custom') {
      const intervalDates = this.computeTimeRange(this.selectedTimeRange);
      this.filterParameters.from = intervalDates.from;
      this.filterParameters.to = intervalDates.to;
      this.changeFilter.emit(this.filterParameters);
    }
  }
  selectCustomFrom(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);

    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }
  selectCustomTo(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }

  changeSNWC(target: any) {
    const entryType = target.item.type;
    this.inputSNQuery = target.item.name;

    if (entryType === 's') {
      this.filterParameters.serialNumber = this.inputSNQuery;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  changeStore(target: any) {
    this.inputStoreQuery = target.item.name;
    this.filterParameters.store = this.inputStoreQuery.match(/\(([^)]+)\)/)[1];
    this.changeFilter.emit(this.filterParameters);
  }

  changeCity(target: any) {
    this.inputCityQuery = target.item.name;

    this.filterParameters.city = this.inputCityQuery;

    this.changeFilter.emit(this.filterParameters);
  }

  // formatter = (x: { name: string }) => x.name;
  formatterSNWC = (x: { name: string }) => {
    if (this.inputSNQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputSNQuery };
    }
    return x.name;
  };
  formatterCity = (x: { name: string }) => {
    if (this.inputCityQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputCityQuery };
    }
    return x.name;
  };
  formatterStore = (x: { name: string }) => {
    if (this.inputStoreQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputStoreQuery };
    }
    return x.name;
  };
  searchSNWCEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.productsService.getProductSerialNumbers(term)
      )
  searchCityEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'city')
      )  
      searchStoreEntry = (text$: Observable<string>) =>
      text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'name', 'code')
      )
  public computeTimeRange(timeRange: string): { from: Date, to: Date } {
    const now = new Date();
    switch (timeRange) {
      case 'today': {
        return { from: moment().add(-1, 'd').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-month': {
        return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-week': {
        return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-year': {
        return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-5-years': {
        return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      default: {
        return { from: null, to: null };
      }
    }
  }

  computeInterval(from = null, to = null): { from: Date, to: Date } {
    const _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
    const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');

    return { to: _to.toDate(), from: _from.toDate() };
  }

  toggleStatus(status: boolean) {
    switch (status) {
      case true:
        this.lastOrPrev(true);
        break;
      case false:
        this.lastOrPrev(false);
        break;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  lastOrPrev(status: boolean) {
    if (status) {
      this.filterParameters.last = status;
      this.checkedPrev = false;
    } else {
      this.filterParameters.last = status;
      this.checkedLast = false;
    }
  }

  exportShipments(event) {
    if (event.detail > 1) {
      return;
    }
    this.isLoading = true;
    this.isOpen = true;
    const userNotification = new UserNotification();
    userNotification.descriptionType = 2;
    userNotification.isDeleted = false;
    userNotification.isRead = false;
    userNotification.notificationType = 2;
    userNotification.sourcePageType=5;
    this.usersService.getMyUser().subscribe(data => {
      userNotification.userId = data.id;
      this.notifcationService.createNotification(userNotification).subscribe(data => {
        this.filterParameters.parentNotificationId=data.id;
        this.ShipmentsService.exportShipments(this.filterParameters).subscribe(data => {
          this.isLoading = false;
          this.isOpen = false;
        });
        setTimeout(() => {
          this.sharedDataService.changeMessage("notification:created");
        }, 60000);
      });
    })
  }

}
