import { Role } from './role';
import { Retailer } from './retailer';
import { Market} from './market';
import { Inventory} from './inventory';

export class User {
    constructor() {
        this.roles = [];
        this.retailers = [];
        this.markets = [];
        this.inventories = [];
    }
    id: string;
    isActive: boolean;
    firstName: string;
    lastName: string;
    userName: string;
    lastNameRomanized?: string;
    firstNameRomanized?: string;
    email: string;
    locale: string;
    markets?: Market[];
    createdOn: Date;
    roles?: Role[];
    retailers?: Retailer[];
    inventories?: Inventory[];
    createdById: string;
    updatedById: string;
    allowedChangePassword: boolean;
    userCreatedBy?: string;
    userUpdatedBy?: string;
}