import { Component, Output, EventEmitter, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import { User } from '@src/model/user';
import { TranslateService } from '@ngx-translate/core';
import { Retailer } from '@src/model/retailer';
import { Market } from '@src/model/market';
import { UsersService } from '@src/services/users/users.service';
import { Role } from '@src/model/role';
import { AllowedRolesCreationByRole, UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ToastrService } from 'ngx-toastr';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { useAnimation } from '@angular/animations';
import { ConfigService } from '@src/services/layout/config.service';
import { environment } from '@src/environments/environment';

const breitiling = 'breitling';

@Component({ selector: 'app-add-user', templateUrl: './add-user.component.html', styleUrls: ['./add-user.component.scss'] })

export class AddUserComponent extends NgxDatatableBase implements OnInit {
    @ViewChild('userEmail') u: ElementRef;
    roles = [];
    selectedRole = '';
    user: User = new User();
    currentUser: User = new User();
    addingNewRetailer = false;
    addingNewMarket = false;
    isCustomUserName = false;
    isUserNameValid = false;
    isValidatingUsername = false;
    canAddRetailers = false;
    canAddMarkets = false;
    validationErrors = [];
    maxFieldLengthFor = {'first name': 50, 'last name': 50, 'first name romanized': 50, 'last name romanized': 50};
    layoutDirection: string;
    allowChangePassword: boolean;
    @Output() onFinishAddNewUser = new EventEmitter();

    constructor(
        @Inject('AuthService') private authService: IAuthService,
        private usersService: UsersService,
        private toastrManager: ToastrService,
        private translateService: TranslateService,
        private configService: ConfigService) {
        super(translateService);
        this.layoutDirection = this.configService.templateConf.layout.dir;
        const loggedUser = authService.getCurrentUserInstant();

        // TODO: depends of the role can change password. I am registering this user then i can say that can enabled or disabled the password
        this.allowChangePassword = false;

        this.canAddRetailers = loggedUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.MARKET_ADMIN]);
        this.canAddMarkets = loggedUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN]);
        this.usersService.getMyUser().subscribe(u => {
            if (u) {
                this.currentUser = u;
            }
        });
    }

    ngOnInit() {
        this.usersService.getRolesByUserRole(this.authService.getCurrentUserInstant().role[0]).subscribe(roles => {
            this.roles = roles;
        });
    }

    isBreitling() {
        return environment.clientName && environment.clientName === breitiling;
    }

    private normalizeUserName(userName: string) {
        // see https://stackoverflow.com/questions/20864893/replace-all-non-alpha-numeric-characters-new-lines-and-multiple-white-space-wi
        return userName.replace(/\s/g, '').toLowerCase();
    }
    
    private getUsernameIconClass(): string {
        if (this.isValidatingUsername) {
            return 'spinner ft-refresh-cw';
        } else if (this.isUserNameValid) {
            return 'ft-check-circle primary';
        } else {
            return 'ft-alert-circle danger';
        }
    }

    getMessageUserName(userName: string) : string {
        if (userName === undefined) {
            return this.translateService.instant('Components.UserDetails.Sections.username-mandatory');
        } else if (userName.match(/[^\u0020-\u007e\u00a0-\u00ff]/g)) {
            return this.translateService.instant('Components.UserDetails.Sections.username-valid');
        } else if (!this.isUserNameValid) {
            return this.translateService.instant('Components.UserDetails.Sections.invalid-username');
        } else {
            return '';
        }
    }

    private onUserNameChange(e) {
        if (this.isCustomUserName) {
            this.user.userName = this.normalizeUserName(this.user.userName);
            this.verifyUserName();
        }
    }
    
    private onFirstLastNameChange(e) {
        if (!this.isCustomUserName) {
            if (this.isValidatingUsername) {
                return;
            }
            this.isUserNameValid = false;
            if (this.user.firstName && this.user.firstName.length > 0 && this.user.lastName && this.user.lastName.length > 0) {
                const proposedUserName = this.user.firstName[0] + this.user.lastName;
                this.user.userName = this.normalizeUserName(proposedUserName);
                this.verifyUserName();
            } else {
                this.user.userName = '';
            }
        }
    }

    removeErrorMessage(fieldName: string, event) {
        const fieldValue = event.target.value;

        if (fieldValue && fieldValue.length > 0) {
            if (fieldName === 'email') {
                if (this.IsValidEmail(fieldValue)) {
                    this.validationErrors = this.validationErrors.filter(function (field) { return field !== fieldName; });
                }
            } else {
                this.validationErrors = this.validationErrors.filter(function (field) { return field !== fieldName; });
            }
        }
    }
    cancel() {
        this.onFinishAddNewUser.emit();
    }
    addNewRetailer() {
        this.addingNewRetailer = true;
    }
    closeAddNewRetailer() {
        this.addingNewRetailer = false;
    }
    addRetailer(retailer: Retailer) {
        if (this.user.retailers.filter(r => r.id === retailer.id).length === 0) {
            this.user.retailers.push(retailer);
        }
        this.addingNewRetailer = false;
    }
    removeRetailer(retailer: Retailer) {
        this.user.retailers = this.user.retailers.filter(r => r.id !== retailer.id);
    }
    canAddRetailer() : boolean {
        return (this.canAddRetailers && !this.selectedRole.includes('agent') && !this.selectedRole.includes('market admin'));
    }
    canAddMarket() : boolean {
        return (this.canAddMarkets && (this.selectedRole.includes('agent') || this.selectedRole.includes('market admin')));
    }
    addNewMarket() {
        this.addingNewMarket = true;
    }
    closeAddNewMarket() {
        this.addingNewMarket = false;
    }
    addMarket(market: Market) {
        if (this.user.markets.filter(r => r.id === market.id).length === 0) {
            this.user.markets.push(market);
        }
        this.addingNewMarket = false;
    }
    removeMarket(market: Market) {
        this.user.markets = this.user.markets.filter(r => r.id !== market.id);
    }

    verifyUserName() {
        if (this.isValidatingUsername) {
            return;
        }
        if (this.user.userName.match(/^.*[ !$?\#/].*/g)) {
            this.isUserNameValid = false;
        } else if (this.user.userName.length === 0) {
            this.isUserNameValid = false;
        } else {
            this.isValidatingUsername = true;
            this.usersService.isUserNameFree(this.user.userName).subscribe(isValid => {
                this.isUserNameValid = isValid;
                this.isValidatingUsername = false;
            });
        }
    }
    addUser() {
        this.validationErrors = [];
        if (this.selectedRole === '') {
            this.validationErrors.push('selectedrole');
        }
        if (!this.user.email || this.user.email === '' || !this.IsValidEmail(this.user.email)) {
            this.validationErrors.push('email');
        }
        if (!this.user.firstName || this.user.firstName === '' || this.user.firstName.length > this.maxFieldLengthFor['first name']) {
            this.validationErrors.push('firstName');
        }
        if (!this.user.lastName || this.user.lastName === '' || this.user.lastName.length > this.maxFieldLengthFor['last name']) {
            this.validationErrors.push('lastName');
        }
        if (this.user.firstNameRomanized && this.user.firstNameRomanized.length > this.maxFieldLengthFor['first name romanized']) {
            this.validationErrors.push('firstNameRomanized');
        }
        if (this.user.lastNameRomanized && this.user.lastNameRomanized.length > this.maxFieldLengthFor['last name romanized']) {
            this.validationErrors.push('lastNameRomanized');
        }
        if (!this.isUserNameValid) {
            this.validationErrors.push('userName');
        }
        if (this.selectedRole === UserRolesEnum.AGENT) {
            if (this.user.markets.length == 0) {
                this.validationErrors.push('selectedmarket');
            }
        }
        if (this.selectedRole === UserRolesEnum.MARKET_ADMIN) {
            if (this.user.markets.length == 0) {
                this.validationErrors.push('selectedmarket');
            }
        }
        if (this.validationErrors.length > 0) {
            return;
        }
        // Pre-Validate here if the role<->retailer count is not allowed.
        if (this.selectedRole === UserRolesEnum.RETAILER && this.user.retailers.length !== 1) {
            this.toastrManager.warning(this.translateService.instant('validation-errors.UserCannotHaveMultipleRetailers'));
            return;
        }

        if (this.selectedRole === (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER) &&
            this.currentUser.roles.find((role) => role.name === UserRolesEnum.RETAILER) !== undefined) {
            this.user.retailers = this.currentUser.retailers;
        }

        //validation passed, prepare for sending the user.
        this.user.roles = [];
        this.user.roles.push(new Role(this.selectedRole));
        this.user.isActive = true;
        this.user.email = this.user.email.toLowerCase().trim();
        //clear out retailers if it's and agent
        if (this.selectedRole === UserRolesEnum.AGENT) {
            this.user.retailers = [];
        }
        else if (this.selectedRole === UserRolesEnum.MARKET_ADMIN) {
            this.user.retailers = [];
        }
        else {
            //ensure marketId is null if not an agent
            this.user.markets = [];
        }

        this.usersService.createUser(this.user).subscribe(u => {
            if (u) {
                this.user = u;
                this.onFinishAddNewUser.emit();
            }
        });
    }

    IsAffectedRoleToMandatory() {
        const rolesHavingMarketMandatory = [ UserRolesEnum.MARKET_ADMIN, UserRolesEnum.AGENT ];
        if (this.selectedRole !== null && this.selectedRole !== undefined && rolesHavingMarketMandatory
            .includes(this.selectedRole.trim())) {
            return true;
        }

        return false;
    }

    getInvalidErrorMessage(fieldName: string, fieldLength: number = 0): string {
        switch (fieldName) {
            case '"email"': {
                if (!this.IsValidEmail(this.user.email) && this.user.email) {
                    return this.translateService.instant('Components.UserDetails.Sections.invalid-email');
                }
                return this.translateService.instant('Components.UserDetails.Sections.invalid-field', { fieldName: fieldName });
            }
            case '"userName"': {
                return this.translateService.instant('Components.UserDetails.Sections.invalid-username');
            }
            default: {
                const maxLength = this.maxFieldLengthFor[fieldName.replace(/"/g, '')];
                const errorMessage = fieldLength > maxLength ?
                this.translateService.instant('validation-errors.FieldValueLengthTooBig', { numberOfCharacters: maxLength}) :
                this.translateService.instant('Components.UserDetails.Sections.invalid-field', { fieldName: fieldName });
                return  errorMessage;
            }
        }
    }

    private IsValidEmail(email: string): boolean {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }
}
