import {EnvironmentConfig} from '@src/environments/EnvironmentConfig';
import {EnvironmentNames} from '@src/environments/environments.name';
import {AuthService} from '@src/auth/auth.service';

export const environment: EnvironmentConfig = {
  authServiceType: AuthService,
  clientName: 'messika',
  envName: EnvironmentNames.qa,
  siteKey: '',
  authConfig: {
    // Url of the Identity Provider
    issuer: 'https://auth-was-preprod-bymessika.messika.xyz',
    loginUrl: 'https://auth-was-preprod-bymessika.messika.xyz/authorize',
    postLogoutRedirectUri: 'https://www-was-preprod-bymessika.messika.xyz',
    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://www-was-preprod-bymessika.messika.xyz/login',
    silentRefreshRedirectUri: 'https://www-was-preprod-bymessika.messika.xyz/silent-refresh.html',
    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'WAS_SPA',
    responseType: 'token',
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile was roles email',
    oidc: true,
    showDebugInformation: false
  },
  apiBaseUrl: 'https://api-was-preprod-bymessika.messika.xyz',
  mapBox: {
    appKey: 'pk.eyJ1IjoiZGVubmlzY2FycmVpcmEiLCJhIjoiY2plemh5bWlqMGJyajMzcGRrODJ0MTlkZCJ9.jaEGWDdTSALHzEoJsMszyQ'
  },
  scannerURL: "https://scanner-was-preprod-bymessika.messika.xyz"
};
