export const users = [
    {
        'id': '077f0d4a-c1b9-4ebb-d799-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'Daniel',
        'lastName': 'Simões',
        'userName': 'daniel.simoes@b-i.com',
        'email': 'daniel.simoes@b-i.com',
        'createdOn': '2018-05-21T17:07:59.7617761',
        'roles': [
           {
              'id': 1,
              'name': 'admin'
           }
        ],
        'retailers': []
     },
     {
        'id': '6c59b3c4-eb12-4519-d79a-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'Mário',
        'lastName': 'Fernandes',
        'userName': 'mario.fernandes@b-i.com',
        'email': 'mario.fernandes@b-i.com',
        'createdOn': '2018-05-21T17:08:02.5759727',
        'roles': [
           {
              'id': 1,
              'name': 'admin'
           }
        ],
        'retailers': []
     },
     {
        'id': '91e10fda-d1b8-4c02-d79b-08d5bf3d67c7',
        'isActive': false,
        'firstName': 'Dennis',
        'lastName': 'Carreira',
        'userName': 'dennis.carreira@b-i.com',
        'email': 'dennis.carreira@b-i.com',
        'createdOn': '2018-05-21T17:08:04.5169502',
        'roles': [
           {
              'id': 1,
              'name': 'admin'
           }
        ],
        'retailers': []
     },
     {
        'id': 'b08ae10b-33a8-4a02-d79c-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'Thomas',
        'lastName': 'Coombs',
        'userName': 'thomas.coombs@b-i.com',
        'email': 'thomas.coombs@b-i.com',
        'createdOn': '2018-05-21T17:08:05.7414511',
        'roles': [
           {
              'id': 1,
              'name': 'admin'
           }
        ],
        'retailers': []
     },
     {
        'id': 'bec91578-b733-4fbd-d79d-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'Jordane',
        'lastName': 'Coetsier',
        'userName': 'jordane.coetsier@b-i.com',
        'email': 'jordane.coetsier@b-i.com',
        'createdOn': '2018-05-21T17:08:10.040928',
        'roles': [
           {
              'id': 1,
              'name': 'admin'
           }
        ],
        'retailers': [
           {
              'id': 1,
              'code': '1169',
              'name': 'Oris SA MyWatches',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.4242013',
              'longitude': '7.7692057',
              'isActive': true,
              'marketId': 2,
              'market': null
           }
        ]
     },
     {
        'id': '399321f5-8626-4b17-d79e-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'Joe',
        'lastName': 'Seller',
        'userName': 'joe.seller@b-i.com',
        'email': 'daniel.simoes@b-i.com',
        'createdOn': '2018-05-21T17:08:11.3659961',
        'roles': [
           {
              'id': 5,
              'name': 'seller'
           }
        ],
        'retailers': [
           {
              'id': 1,
              'code': '1169',
              'name': 'Oris SA MyWatches',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.4242013',
              'longitude': '7.7692057',
              'isActive': true,
              'marketId': 2,
              'market': null
           }
        ]
     },
     {
        'id': 'd4a2d75c-597f-4ba1-d79f-08d5bf3d67c7',
        'isActive': false,
        'firstName': 'Fake',
        'lastName': 'Seller',
        'userName': 'fake.seller@b-i.com',
        'email': 'daniel.simoes@b-i.com',
        'createdOn': '2018-05-21T17:08:12.9271183',
        'roles': [
           {
              'id': 5,
              'name': 'seller'
           }
        ],
        'retailers': [
           {
              'id': 1,
              'code': '1169',
              'name': 'Oris SA MyWatches',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.4242013',
              'longitude': '7.7692057',
              'isActive': true,
              'marketId': 2,
              'market': null
           }
        ]
     },
     {
        'id': '6e66a406-b918-448e-d7a0-08d5bf3d67c7',
        'isActive': false,
        'firstName': 'John',
        'lastName': 'Retailer',
        'userName': 'john.retailer@b-i.com',
        'email': 'daniel.simoes@b-i.com',
        'createdOn': '2018-05-21T17:08:14.1899018',
        'roles': [
           {
              'id': 2,
              'name': 'retailer'
           }
        ],
        'retailers': [
           {
              'id': 1,
              'code': '1169',
              'name': 'Oris SA MyWatches',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.4242013',
              'longitude': '7.7692057',
              'isActive': true,
              'marketId': 2,
              'market': null
           }
        ]
     },
     {
        'id': '3f32a52a-ca42-4c97-d7a1-08d5bf3d67c7',
        'isActive': true,
        'firstName': 'John',
        'lastName': 'Master',
        'userName': 'john.master@b-i.com',
        'email': 'daniel.simoes@b-i.com',
        'createdOn': '2018-05-21T17:08:14.9654204',
        'roles': [
           {
              'id': 3,
              'name': 'seller'
           }
        ],
        'retailers': [
           {
              'id': 1,
              'code': '1169',
              'name': 'Oris SA MyWatches',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.4242013',
              'longitude': '7.7692057',
              'isActive': true,
              'marketId': 2,
              'market': null
           },
           {
              'id': 2,
              'code': '1511',
              'name': 'Oris SA',
              'address1': 'Ribigasse 1',
              'address2': '',
              'zip': '4434',
              'city': 'Hölstein',
              'isoCountry': 'CH',
              'latitude': '47.424084',
              'longitude': '7.769381',
              'isActive': true,
              'marketId': 2,
              'market': null
           }
        ]
     }
];