import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';
import { Market } from '../../model/market';

@Injectable()
export class MarketsService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/markets';
  constructor(injector: Injector) {
    super(injector);
  }

  queryActiveMarkets(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<Market[]>(`${url}/queries/?queryTerm=${queryTerm}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getChildrenMarket(marketId: number): Observable<Market[]> {
    const url = this.baseUrl + `/${marketId}/children`;
    return this.get<Market[]>(url);
  }

  getMarkets() : Observable<Market[]>{
    const url = this.baseUrl ;
    return this.get<Market[]>(url);
  }

  getMyMarkets(): Observable<Market[]> {
    const url = this.baseUrl + `/my`;
    return this.get<Market[]>(url);
  }
}
