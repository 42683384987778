import {Retailer} from './retailer';
import {User} from './user';

export class Customer {
    id: number;
    saleDate: Date;
    firstName: string;
    lastName: string;
    firstNameRomanized: string;
    lastNameRomanized: string;
    email: string;
    isoCountry: string;
    productSku: string;
    seller: User;
    birthDate: Date;
    gender: string;
    foreign: boolean;
    wantEmails: boolean;
    acceptedTerms: boolean;
    retailer: Retailer;
    address: string;
    postalCode: string;
    saleId: number;
    language: string;
}

