import { productitems } from './productItems-data';

export const customers = [
   {
      'id': 265,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'O',
      'foreign': false,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 266,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 267,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 268,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 269,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 270,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 271,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 272,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 273,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 273,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 274,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 275,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 276,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 277,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   },
   {
      'id': 278,
      'date': '2018-09-10T03:25:38.9018153',
      'productSku': '01 733 7720 3185-Set LS',
      'firstName': 'あきひこ',
      'lastName': '雪',
      'firstNameRomanized': 'Akihiko',
      'lastNameRomanized': 'Snow',
      'email': 'yoyo@formosawatch.com.tw',
      'isoCountry': 'TW',
      'birthDate': '1970-06-01T03:25:38.9018153',
      'gender': 'M',
      'foreign': true,
      'wantEmails': true,
      'acceptedTerms': true,
      'retailer': {
         'id': 1,
         'code': '1169',
         'name': 'Oris SA MyWatches',
         'address': 'Ribigasse 1',
         'addressIndications': '',
         'zip': '4434',
         'city': 'Hölstein',
         'isoCountry': 'CH',
         'latitude': '47.4242013',
         'longitude': '7.7692057',
         'isActive': true,
         'marketId': 2,
         'email': 'fakeemail@b-i.com',
         'market': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-06-06T16: 59: 52.9197467',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-06-06T16: 59: 52.9197467'
      },
      'seller': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'createdOn': '2018-12-20T12:07:35.7770991',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': {
         'id': '99999999-9999-9999-9999-999999999999',
         'isActive': true,
         'firstName': 'WAS',
         'lastName': 'System',
         'userName': 'was-system@b-i.com',
         'email': 'was-system@b-i.com',
         'locale': 'en',
         'marketId': null,
         'privacyPolicyAcceptationDate': '2018-10-08T10:03:06.5828891',
         'createdOn': '2018-06-28T11:23:57.72',
         'imageUrl': null,
         'roles': [],
         'retailers': []
      },
      'updatedOn': '2018-12-20T12:08:05.810798'
   }
];

export const customerDetails = [{
    'customer':   customers[0],
    'productItem': {
      'id': 720312,
      'productId': 2601,
      'serialNumber': '7720336',
      'isFake': false,
      'isUsed': true,
      'product': {
         'id': 2601,
         'name': 'Oris Formosa Violin Limited Edition',
         'sku': '01 733 7720 4388-Set LS',
         'description': 'Oris Divers, 42.00 mm, 1.654 inches, Stainless steel',
         'productDate': '2018-10-03T00:00:00',
         'productFamilyId': 387,
         'productTypeId': 26,
         'isSerializable': true,
         'iscustomizable': true,
         'isWarrantyCardMandatory': true,
         'imageUrl': 'https://www.oris.ch/data/24184_0173377204388-SetLS_verticale.png',
         'productFamily': null,
         'productType': null,
         'createdById': '99999999-9999-9999-9999-999999999999',
         'createdBy': null,
         'createdOn': '2018-12-18T21:12:05.9314994',
         'updatedById': '99999999-9999-9999-9999-999999999999',
         'updatedBy': null,
         'updatedOn': '2018-12-18T21:12:05.9314994'
      },
      'createdById': '99999999-9999-9999-9999-999999999999',
      'createdBy': null,
      'createdOn': '2018-12-18T21:12:27.7055283',
      'updatedById': '99999999-9999-9999-9999-999999999999',
      'updatedBy': null,
      'updatedOn': '2018-12-20T00:16:52.8857528'
   }
},
{
    'customer':   customers[1],
    'productItem':   productitems[1]
}];