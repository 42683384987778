import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { CardOrdering } from '@src/model/cardOrdering';
import { CardOrderingItem } from '@src/model/cardOrderingItem';
import { CardOrderingExport } from '@src/model/cardOrderingExport';
import { OperationResultErrorMessage } from '@src/model/operationResultErrorMessage';
import { CardOrderingParameters } from '@src/model/filter-parameters/cardordering-parameters';


@Injectable()
export class CardOrderingsService extends BaseService {

   
  protected baseUrl = environment.apiBaseUrl + '/api/CardOrderings';
  constructor(injector: Injector) {
    super(injector);
  }
  
  createCardOrder(cardOrdering: CardOrdering) {
    const url = this.baseUrl;
    return this.post<CardOrdering>(url, cardOrdering);
  }


  validateCardOrder(items: CardOrderingItem[]) {
    const url = `${this.baseUrl}/validate`;
    return this.post<CardOrderingItem[]>(url, items);
  }

  queryCardOrders(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/query`;
    return this.getWithHeader<CardOrdering[]>(`${url}?` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getCardOrdering(cardOrderingId: number) : Observable<CardOrdering>{      
      const url = this.baseUrl;
      return this.get<CardOrdering>(`${url}/${cardOrderingId}`);
  }

  exportCardOrdering(cardOrderingId: number): Observable<CardOrderingExport> {
    const url = `${this.baseUrl}/${cardOrderingId}/export`;
    return this.get<CardOrderingExport>(url);
  }

  deleteCardOrderingItem(retailerCode: string, cardOrderingId: number, serialNumberList: string[] ) : Observable<OperationResultErrorMessage>{    
    const url = `${this.baseUrl}/deleteCardOrderingItem?`+
    `${this.buildQueryParameters('cardOrderingId', cardOrderingId)}&` +
    `${this.buildQueryParameters('retailerCode', retailerCode)}`;
    return this.post<OperationResultErrorMessage>(url, serialNumberList);
  }

  deleteCardOrdering(cardOrderingId){
    const url = `${this.baseUrl}/DeleteCardOrdering/${cardOrderingId}`;
    return this.post<OperationResultErrorMessage>(url, cardOrderingId);
  }

  filterCardOrdering(usersParameters: CardOrderingParameters, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/query?` +
      `${this.buildQueryParameters('serialNumber', usersParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('sku', usersParameters.sku, )}&` +
      `${this.buildQueryParameters('marketId', usersParameters.marketId, )}&` +
      `${this.buildQueryParameters('statusId', usersParameters.statusId, )}&` +
      `${this.buildQueryParameters('retailerCode', usersParameters.retailerCode, )}&` +
      `${this.buildQueryParameters('fromDate', usersParameters.fromDate, )}&` +
      `${this.buildQueryParameters('toDate', usersParameters.toDate, )}&` +      
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`;

      return this.getWithHeader<CardOrdering[]>(url);
  }

  exportCardOrderings(retailersParameters: CardOrderingParameters): Observable<string> {
    const url = `${this.baseUrl}/export?` +
      `${this.buildQueryParameters('serialNumber', retailersParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('sku', retailersParameters.sku, )}&` +
      `${this.buildQueryParameters('marketId', retailersParameters.marketId, )}&` +
      `${this.buildQueryParameters('statusId', retailersParameters.statusId, )}&` +
      `${this.buildQueryParameters('retailerCode', retailersParameters.retailerCode, )}`;      

      return this.get<string>(url);
  }
}