import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';
import { UserNotification } from '@src/model/notification';

@Injectable()
export class NotificationsService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/notifications';

  constructor(injector: Injector) {
    super(injector);
  }

  getNotifications(): Observable<UserNotification[]> {
    return this.get<UserNotification[]>(this.baseUrl);
  }

  getNotification(notificationId: number): Observable<UserNotification> {
    const url = `${this.baseUrl}/${notificationId}`;
    return this.get<UserNotification>(url);
  }

  updateNotification(notificationId: number, notification: UserNotification) {
    const url = `${this.baseUrl}/${notificationId}`;
    return this.put<UserNotification>(url, notification);
  }

  createNotification( notification: UserNotification) {
    const url = `${this.baseUrl}`;
    return this.post<UserNotification>(url, notification);
  }

}
