import { Component, OnInit, Output, EventEmitter, Inject, OnChanges, Input } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { ProductsParameters } from '@src/model/filter-parameters/products-parameters';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { UsersService } from '@src/services/users/users.service';

@Component({
  selector: 'app-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss']
})

export class ProductsFilterComponent implements OnInit {
  selectedTimeRange: string;
  timeRangeList: string[];
  inputSNQuery: string;
  inputLocationQuery: string;
  inputProductQuery: string;
  customFrom?: Date;
  customTo?: Date;
  loggedUser: UserProfile;
  layoutDirection: string;
  filterParameters: ProductsParameters;
  isPrepairedClient: boolean;
  selectedProductId: number;
  checkedYes: boolean;
  checkedNo: boolean;
  checkedDuplication: boolean;
  isLoading = false;
  hasCorrection: boolean;
  isOpen = true;

  @Output() changeFilter = new EventEmitter<ProductsParameters>();
  @Output() clearFilter = new EventEmitter<ProductsParameters>();
  @Input() newfilterParameters: ProductsParameters;

  constructor(
    @Inject('AuthService') private authService: IAuthService,
    private productsService: ProductsService,
    private configService: ConfigService,
    private notifcationService: NotificationsService,
    private usersService: UsersService) {

    this.layoutDirection = this.configService.getLayoutOrientation();
    this.isPrepairedClient = this.configService.isPrepairedClient();
    this.hasCorrection = this.configService.templateConf.hasCorrection;
    this.authService.getCurrentUser().subscribe(u => {
      this.loggedUser = u;
    });
  }
  ngOnInit() {
    this.selectedTimeRange = 'last-month';
    this.timeRangeList = [
      'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'today', 'custom'
    ];

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new ProductsParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.filterParameters.selectedTimeRange = 'last-month';
    if (this.newfilterParameters !== undefined) {
      this.filterParameters = this.newfilterParameters;
      this.selectedTimeRange = this.newfilterParameters.selectedTimeRange == undefined ? 'last-month' : this.newfilterParameters.selectedTimeRange;
      if (this.newfilterParameters.selectedTimeRange == 'custom') {
        this.customTo = this.newfilterParameters.to;
        this.customFrom = this.newfilterParameters.from;
      }
      if(this.newfilterParameters.yesCPO)
      {
        this.checkedYes = true;
      }
      if(this.newfilterParameters.noCPO)
      {
        this.checkedNo = true;
      }
      this.inputSNQuery = this.newfilterParameters.serialNumber;
      this.inputProductQuery = this.newfilterParameters.productSku;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  clear() {
    this.selectedTimeRange = 'last-month';
    this.inputLocationQuery = '';
    this.inputProductQuery = '';
    this.inputSNQuery = '';
    this.checkedYes = false;
    this.checkedNo = false;
    this.checkedDuplication = false;

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new ProductsParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.clearFilter.emit(this.filterParameters);
  }

  changeTimeRange(event) {
    this.filterParameters.selectedTimeRange = this.selectedTimeRange;
    if (this.selectedTimeRange !== 'custom') {
      const intervalDates = this.computeTimeRange(this.selectedTimeRange);
      this.filterParameters.from = intervalDates.from;
      this.filterParameters.to = intervalDates.to;
      this.changeFilter.emit(this.filterParameters);
    }
  }
  selectCustomFrom(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);

    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }
  selectCustomTo(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }

  changeSNWC(target: any) {
    const entryType = target.item.type;
    this.inputSNQuery = target.item.name;

    if (entryType === 's') {
      this.filterParameters.serialNumber = this.inputSNQuery;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  selectProductEntry(target: any) {
    this.selectedProductId = target.item.id;
    this.inputProductQuery = target.item.name;

    this.productsService.getProductById(this.selectedProductId).subscribe(product => {
      if (product) {
        this.filterParameters.productSku = product.sku;
        this.changeFilter.emit(this.filterParameters);
      }
    });
  }

  // formatter = (x: { name: string }) => x.name;
  formatterSNWC = (x: { name: string }) => {
    if (this.inputSNQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputSNQuery };
    }
    return x.name;
  };

  formatterProduct = (x: { name: string }) =>{ 
    if(this.inputProductQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputProductQuery};
    }
    return x.name;
  };

  searchSNWCEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.productsService.getProductSerialNumbers(term)
      )

  searchProductEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.productsService.getProductsNames(term, false)
      )

  public computeTimeRange(timeRange: string): { from: Date, to: Date } {
    const now = new Date();
    switch (timeRange) {
      case 'today': {
        return { from: moment().add(-1, 'd').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-month': {
        return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-week': {
        return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-year': {
        return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-5-years': {
        return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }      
      default: {
        return { from: null, to: null };
      }
    }
  }

  computeInterval(from = null, to = null): { from: Date, to: Date } {
    const _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
    const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');

    return { to: _to.toDate(), from: _from.toDate() };
  }

  toggleCPO(cpoType: boolean) {
    switch (cpoType) {
      case true:
        this.yesOrNoCPO(true);
        break;  
        case false:
          this.yesOrNoCPO(false);
          break;    
    }
    this.changeFilter.emit(this.filterParameters);
  }

  yesOrNoCPO(cpoType: boolean) {
    if (cpoType) {
      this.filterParameters.yesCPO = this.checkedYes;
    } else {
      this.filterParameters.noCPO = this.checkedNo;
    }
  }

  export(event) {
    
  }

}
