import { Component } from '@angular/core';
import { AlertMessageComponent } from './alert-message.component';

@Component({
    selector: 'app-warning-message',
    templateUrl: './alert-message.component.html',
    styles: [`:host >>> .alert-custom {
        background-image: linear-gradient(45deg,#ffe0b2,#ffccbc);
    }`]
})

export class WarningMessageComponent extends AlertMessageComponent {
}