import { Component, OnInit, Input, EventEmitter, Output, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { Warranty } from '@src/model/warranty/warranty';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { Retailer } from '@src/model/retailer';
import { Router } from '@angular/router';
import { WarrantyExtensionsService } from '@src/services/warranty/warrantyextensions.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-warranties-table',
  templateUrl: './warranties-table.component.html',
  styleUrls: ['./warranties-table.component.scss']
})
export class WarrantiesTableComponent extends NgxDatatableBase implements OnInit {
  @ViewChild('warrantiesTable') warrantiesTable: any;
  isSeller = false;
  @Output() onChangeSeller = new EventEmitter<{warranty: Warranty, sellerId: string}>();
  @Input() warranties: Warranty[];
  @Input() inEdition: boolean;
  @Input() isSearchResult: boolean;
  showD2CExtension = false;

  constructor(@Inject('AuthService')
    private authService: IAuthService,
    private translateService: TranslateService,
    private warrantiesExtensionsService: WarrantyExtensionsService,private cdRef:ChangeDetectorRef) {

    super(translateService);

    const currentUser = this.authService.getCurrentUserInstant();
    if (currentUser.role[0] === (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER)) {
        this.isSeller = true;
    }
    this.showD2CExtension = this.showD2CExtensionData();
  }

  ngOnInit() {
    this.warranties.forEach(warranty => {
      if (warranty.warrantyExtensions.length > 0) {
        const extensions = warranty.warrantyExtensions.sort((e1, e2) => new Date(e1.endDate).getTime() - new Date(e2.endDate).getTime());

        warranty.extendedWarrantyEndDate = extensions[extensions.length - 1].endDate;
        warranty.isExpired = new Date() > this.setWarrantyEndDate(warranty);
      }
    });
  }
  ngAfterViewChecked() {
    if (this.warrantiesTable && this.warrantiesTable.rowDetail) {
        this.warrantiesTable.rowDetail.expandAllRows();
        this.cdRef.detectChanges();
    }
  }
  changeSeller(warranty: Warranty, sellerId: string) {
    this.onChangeSeller.emit({warranty,sellerId});
  }

  setCountry(retailer: Retailer) : string {
    return retailer.isoCountry != undefined ? this.translateService.instant('CountriesName.' + retailer.isoCountry.toUpperCase()) : '';
  }

  setPurchaseCountry(country: string) : string {
    var countryName = country != undefined ? this.translateService.instant('CountriesName.' + country.toUpperCase()): "";
    return countryName == 'CountriesName.' + country.toUpperCase() ? country.toUpperCase() : countryName;
  }

  setWarrantyEndDate(warranty: Warranty) : Date {
    return warranty.extendedWarrantyEndDate != null ? new Date(warranty.extendedWarrantyEndDate) : new Date(warranty.warrantyEndDate);
  }

  private showD2CExtensionData() {
    return environment.clientName && environment.clientName === 'tag';
  }
}
