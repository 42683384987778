import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@src/model/user';
import { UsersService } from '@src/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { Market } from '@src/model/market';
import { MarketsService } from '@src/services/markets/markets.service';

declare var require: any;
const cloneDeep = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-user-add-market',
        templateUrl: './user-add-market.component.html'
    })

export class UserAddMarketComponent extends NgxDatatableBase {
    @Input() roles: Array<string>;
    @Input() userInEdition: User;
    @Input() inEdition: boolean;
    @Input() selectedRole: string;

    private user: User;
    addingNewMarket = false;
    canAddMarkets = false;
    markets: Array<Market> = [];

    constructor(@Inject('AuthService')
    private authService: IAuthService,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private marketsService: MarketsService,
        private translateService: TranslateService
    ) {

        super(translateService);

        this.onUserReceived = this.onUserReceived.bind(this);
        const currentUser = authService.getCurrentUserInstant();
        if (currentUser.role[0] !== (UserRolesEnum.SELLER || UserRolesEnum.GENERICSELLER)) {
            this.route.params.subscribe(params => {
                this.usersService.getUser(params['id']).subscribe(user => this.user = user);
            });

            this.canAddMarkets = currentUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN]);
        }
    }

    onUserReceived(user: User) {
        this.selectedRole = user.roles[0].name;
        this.user = user;
        this.userInEdition = cloneDeep(this.user);
    }
    canAddMarket() {
        const currentUserRole = this.userInEdition.roles[0].name;
        if (currentUserRole === UserRolesEnum.AGENT) {
            return this.userInEdition.markets.length === 0;
        }
        return true;
    }
    updateUser() {
        this.canAddMarkets = this.canAddMarkets && this.selectedRole.includes('agent');
        this.usersService.updateUser(this.userInEdition.id, this.userInEdition)
            .subscribe(u => {
                this.onUserReceived(u);
                this.inEdition = false;
            });
    }

    addNewMarket() {
        this.addingNewMarket = true;
    }

    closeAddNewMarket() {
        this.addingNewMarket = false;
    }
    addMarket(market: Market) {
        if (this.userInEdition.markets.filter(r => r.id === market.id).length === 0) {
            this.userInEdition.markets.push(market);
        }
        this.addingNewMarket = false;
    }
    removeMarket(market: Market) {
        this.userInEdition.markets = this.userInEdition.markets.filter(r => r.id !== market.id);
    }
    CanAddMarkets() {
        return this.canAddMarkets && (this.selectedRole === UserRolesEnum.AGENT || this.selectedRole === UserRolesEnum.MARKET_ADMIN)
    }
}