import { Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { ConfigService } from '@src/services/layout/config.service';
import { WarrantyCard } from '@src/model/warranty/warrantycard';
import { ProductsService } from '@src/services/products/products.service';
import { ProductItem } from '@src/model/productItem';

@Component({
    selector: 'app-wizard-find-wc-result',
    templateUrl: './wizard-find-wc-result.html',
    styleUrls: ['./wizard-find-wc-result.scss']
})
export class WizardFindWarrantyCardsResultComponent implements OnInit {
    @Input() warrantyCard: WarrantyCard;
    @Input() isSelected: boolean;
    
    chevronsIconbyOrientation: string;
    productItemSelected: ProductItem = new ProductItem();

    constructor(protected configService: ConfigService, protected productsService: ProductsService) {
        const layoutOrientation = this.configService.templateConf.layout.dir;

        this.chevronsIconbyOrientation = layoutOrientation === 'rtl' ?
            'ft-chevrons-left font-large-1 primary' : 'ft-chevrons-right font-large-1 primary';
    }

    ngOnInit() {
        this.productsService.getProductItemsBySN(this.warrantyCard.prePairedSerialNumber)
            .subscribe(productItems => {
                if (productItems) {
                    const pi = productItems.find(prd => prd.id === this.warrantyCard.prePairedProductItemId);
                    if (pi) {
                        this.productItemSelected = pi;
                    }
                }
            });
    }
}