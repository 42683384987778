import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProductsService } from '@src/services/products/products.service';
import { ProductItem } from '@src/model/productItem';
import { Product } from '@src/model/product';
import { WizardFindNotSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-not-serializable-product';

@Component({
    selector: 'app-wizard-find-not-serializable-product-messika',
    templateUrl: './wizard-find-not-serializable-product.html'
})
export class WizardFindNotSerializableProductComponentMessika extends WizardFindNotSerializableProductComponent{
   
    constructor(productsService: ProductsService, private ref: ChangeDetectorRef) { 
        super(productsService)
    }
}
