import { Component, Inject } from '@angular/core';
import { NavbarComponent } from '@src/shared/navbar/navbar.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ConfigService } from '@src/services/layout/config.service';
import { LayoutService } from '@src/services/layout/layout.service';
import { MessikaPageType } from './../../messika/services/messika-page-type.service';

@Component({
  selector: 'app-messika-navbar',
  templateUrl: './navbar-messika.component.html',
  styleUrls: ['./navbar-messika.component.scss'],
  providers: [ MessikaPageType ]
})

export class NavbarMessikaComponent extends NavbarComponent {
  currentLang = 'en';

  constructor(
    translate: TranslateService,
    @Inject('AuthService') authService : IAuthService,
    router: Router,
    configService: ConfigService,
    layoutService: LayoutService,
    public messikaPageType: MessikaPageType) {
      super(translate, authService, router, configService, layoutService);
    }

  public IsInternalPage() {
    return this.messikaPageType.isInternal();
  }
}
