import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DateInterval } from '@src/model/DateInterval';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-dashboard-retailer-filter-base',
  templateUrl: './dashboard-retailer-filter-base.component.html'
})

export class DashboardRetailerFilterBaseComponent implements OnInit {
  selectedTimeRange: string;
  timeRangeList: string[];
  customFrom?: Date;
  customTo?: Date;
  layoutOrientation: string;
  @Output() onChangeTimeRange = new EventEmitter<{ from: Date, to: Date, interval: string }>();
  @Output() onChangeLocation = new EventEmitter<{ marketId?: number, retailerId?: number }>();
  @Output() callExportSales = new EventEmitter<boolean>();

  constructor(protected configService: ConfigService) {
    this.layoutOrientation = this.configService.getLayoutOrientation();
  }

  init() {
    this.ngOnInit();
    this.selectedTimeRange = 'last-month';
  }

  ngOnInit() {
    this.timeRangeList = [
      'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'custom'
    ];
  }

  clear() {
    this.selectedTimeRange = 'last-month';
    this.onChangeTimeRange.emit(this.computeTimeRange(this.selectedTimeRange));
    this.onChangeLocation.emit({});
  }

  changeTimeRange(event) {
    const timeRange = event.target.value;
    this.selectedTimeRange = timeRange;
    if (timeRange !== 'custom') {
      this.onChangeTimeRange.emit(this.computeTimeRange(timeRange));
    }
  }
  selectCustomFrom(date: any) {
    this.customFrom = date;
    this.onChangeTimeRange.emit(this.computeInterval(this.customFrom, this.customTo));
  }
  selectCustomTo(date: any) {
    this.customTo = date;
    this.onChangeTimeRange.emit(this.computeInterval(this.customFrom, this.customTo));
  }

  public computeTimeRange(timeRange: string): { from: Date, to: Date, interval: string } {
    const now = new Date();
    switch (timeRange) {
      case 'last-month': {
        return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
      }
      case 'last-week': {
        return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Day };
      }
      case 'last-year': {
        return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Month };
      }
      case 'last-5-years': {
        return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate(), interval: DateInterval.Year };
      }
      default: {
        return { from: null, to: null, interval: DateInterval.Month };
      }
    }
  }

  private computeInterval(from = null, to = null): { from: Date, to: Date, interval: string } {
    const _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
    const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');
    const timeDif = _to.diff(_from);
    const halfYear = moment.duration(6, 'M').asMilliseconds();

    return timeDif > halfYear ? { to: _to.toDate(), from: _from.toDate(), interval: DateInterval.Month }
      : { to: _to.toDate(), from: _from.toDate(), interval: DateInterval.Day };
  }

  exportSales() {
    this.callExportSales.emit(true);
  }
}
