import { Component } from '@angular/core';
import { WizardSelectTypeComponent } from '@src/base-components/wizard/find-product/wizard-select-type';


@Component({
    selector: 'app-wizard-select-type-messika',
    templateUrl: './wizard-select-type.html'
})
export class WizardSelectTypeComponentMessika extends WizardSelectTypeComponent {

}