import { Sale } from './sale';
import { ProductItem } from './productItem';
import { Warranty } from './warranty/warranty';

export class SaleDetails {
    constructor() {
        this.sale = new Sale();
        this.productItem = new ProductItem();
        this.warranty = new Warranty();
    }
    sale: Sale;
    productItem: ProductItem;
    warranty: Warranty;
}