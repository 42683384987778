import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import { ConfigService } from '@src/services/layout/config.service';

@Component(
    {
        selector: 'app-page-header',
        templateUrl: './page-header.component.html',
        styleUrls: ['./page-header.component.scss']
    })

export class PageHeaderComponent {

    @Input()pageTitle : string;
    @Input()showBackButton : boolean;
    @Input()backLink : string;

    layoutOrientation: string;

    constructor(
        private location : Location,
        private router : Router,
        private configService : ConfigService) {

        this.layoutOrientation = this.configService.templateConf.layout.dir;
    }

    onBack() {
        if (this.backLink && this.backLink.length > 0) {
            this
                .router
                .navigateByUrl(this.backLink);
        } else {
            this
                .location
                .back();
        }
    }
}
