import { Component } from '@angular/core';
import { WizardSelectRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-select-retailer';

@Component({
    selector: 'app-wizard-select-retailer-messika',
    templateUrl: './wizard-select-retailer.html'
})
export class WizardSelectRetailerComponentMessika extends WizardSelectRetailerComponent {

}

