import { User } from './user';

export class ImportedFile {
    constructor() {
        this.user = new User();
    }
    id: number;
    fileName: string;
    type: string;
    uploadType: number;
    uploadTypeStr: string;
    updatedOn: string;
    logId: number;
    uploadStatus: number;
    uploadStatusStr: string;
    user: User;
    fileContent: any;
}