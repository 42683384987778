import { Component, Input, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Retailer } from '@src/model/retailer';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-modal-status-retailer',
        templateUrl: './modal-status-retailer.component.html'
    })

export class ModalStatusRetailerComponent implements OnInit {

    @Input() retailerInEdition: Retailer;
    @Input() name: string;
    @Input() switchdisabled: boolean;
    @Output() switchChange = new EventEmitter<boolean>();

    private retailer: Retailer;
    confirmationAction: Function;
    cancelAction: Function;
    classNameConfirmationButton: string;
    label: string;
    title: string;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private retailersService: RetailersService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        switch(this.name) {
            case 'deactivation': {
                this.confirmationAction = this.confirmDeactivation;
                this.cancelAction = this.cancelConfirmation;
                this.classNameConfirmationButton = 'btn btn-raised btn-danger ml-1 mt-1 mb-0';
                this.label = 'Components.RetailersDetails.confirm-retailer-deactivation';
                this.title = 'Components.RetailersDetails.confirm-retailer-deactivation-title';
            }
            break;
            case 'activation' : {
                this.confirmationAction = this.confirmActivation;
                this.cancelAction = this.cancelConfirmation;
                this.classNameConfirmationButton = 'btn btn-raised btn-primary ml-1 mt-1 mb-0';
                this.label = 'Components.RetailersDetails.confirm-retailer-activation';
                this.title = 'Components.RetailersDetails.confirm-retailer-activation-title';
            }
            break;
        }
    }
    onRetailerReceived(retailer: Retailer) {
        this.retailer = retailer;
        this.retailerInEdition = cloneDeep(this.retailer);
    }
    updateRetailer() {
        this.retailersService.updateRetailer(this.retailerInEdition)
            .subscribe(r => {
                this.onRetailerReceived(r);
                this.closeConfirmaton();
            });
        this.switchdisabled = true;
    }
    closeConfirmaton() {
        this.switchdisabled = false;
        this.switchChange.emit(this.switchdisabled);
        this.activeModal.close();
    }
    cancelConfirmation() {
        this.retailerInEdition.isActive = !this.retailerInEdition.isActive;
        this.closeConfirmaton();
    }
    confirmDeactivation() {
        this.retailerInEdition.isActive = false;
        this.updateRetailer();
    }
    confirmActivation() {
        this.retailerInEdition.isActive = true;
        this.updateRetailer();
    }
}