import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-imported-metrics-element',
    templateUrl: './imported-metrics-element.component.html',
    styleUrls: ['./imported-metrics-element.component.scss']
})
export class ImportedMetricsElementComponent {
    @Input() metricValue: string;
    @Input() metricLabel: string;
}