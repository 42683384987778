import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartColors } from '@src/assets/charts/charts.colors';
import { ConfigService } from '@src/services/layout/config.service';

@Component({ 
    selector: 'app-dashboard-donut-chart',
    templateUrl: './dashboard-donut-chart-component.html',
    styleUrls: ['./dashboard-donut-chart-component.scss']
})

export class DashboardDonutChartComponent implements OnChanges {
    @Input() chartData: { name: string, value: number }[];
    @Input() title = '';
    view: any[] = [1300, 350];
    data: any;
    options = {
        scheme: {
            domain: ChartColors
        }
    };
    layoutOrientation: string;

    constructor(private configService: ConfigService) {
        this.layoutOrientation = this.configService.templateConf.layout.dir;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.chartData = changes.chartData.currentValue;
        // this.data = [{name: "", series: changes.chartData.currentValue }];
        this.data = this.chartData;
    }

    percentageFormatting(c) {
        return Math.round(c);
    }
}
