import { UploadStatus } from '@src/model/Enums/uploadStatus';

export class ImportedFileHelper {
    public static computeFileStatus(status: number): string {
        switch (status) {
            case UploadStatus.tostart: {
                return UploadStatus[UploadStatus.inprogress];
            }
            default: {
                return UploadStatus[status];
            }
        }
    }
}