import { Input, Component } from '@angular/core';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
})

export class AlertMessageComponent {

    @Input() message: String;
}