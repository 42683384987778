import { Component } from '@angular/core';
import { TermsBaseComponent } from '@src/base-components/terms/terms-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-page-terms',
    templateUrl: '../../../base-components/terms/terms-base.component.html',
    styleUrls: ['../../../base-components/terms/terms-base.component.scss']
})
export class TermsComponent extends  TermsBaseComponent {
    constructor(configService: ConfigService) {
        const params = {
            appName: 'eActivation',
            companyName: 'Messika',
            products: 'jewelries or accessories',
            logo : 'assets/default/messika-full-logo.png',
            ContactEmail : 'bymessika@messikagroup.com'
        };
        const logoUrl = params.logo;
        super(params, logoUrl, configService);
    }
}